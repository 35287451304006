import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import ModalContainer from 'PageTemplates/ModalTemplate';
import { useDispatch } from 'react-redux';
import { setSpinner } from 'services/store/actions/view';
import { updateOrder } from 'services/apiRequests/orders/update';
import { setOrder } from 'services/store/actions/orders';
import { useAlert } from 'react-alert';

const Ventilation = ({ order, closeModal }) => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const [ventilation, setVentilation] = useState('');

  const handleInput = (e) => {
    const { value } = e.target;
    const numbers = /^[\d]*$/;
    if (!value.match(numbers)) return;
    setVentilation(parseInt(value, 10) || 0);
  };

  const handleSubmit = async () => {
    dispatch(setSpinner(true));
    await updateOrder(
      'ventilation',
      order._id,
      { ventilation },
      (order) => {
        dispatch(setOrder(order));
        dispatch(setSpinner(false));
        alert.success('Zaktualizowano kratki wentylacyjne');
        closeModal();
      },
      () => {
        dispatch(setSpinner(false));
        alert.error('Błąd!');
        closeModal();
      },
    );
  };
  return (
    <ModalContainer
      title="Kratki wentylacyjne"
      closeModal={closeModal}
      footer={
        <>
          <Button variant="success" onClick={handleSubmit}>
            Zmień
          </Button>
          <Button variant="danger" onClick={closeModal}>
            Anuluj
          </Button>
        </>
      }
    >
      Aktualna ilość kratek wentylacyjnych wynosi:{' '}
      <strong>{order.ventilations.quantity || '0'}</strong> szt.
      <br />
      Chcesz zmienić na:{' '}
      <Form.Control
        type="text"
        placeholder="ilość kratek"
        style={{ width: '30%', display: 'inline' }}
        value={ventilation}
        onChange={handleInput}
      />
    </ModalContainer>
  );
};

export default Ventilation;
