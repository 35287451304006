import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { setPaintOrderValue } from 'services/store/actions/paints';

const StyledInput = styled.input`
  width: 100px;
  border-radius: 3px;
  border: 1px solid lightgray;
  &::placeholder {
    color: #999999;
  }
`;

const PaintValue = ({
  index,
  order: { surfaceRight, surfaceLeft, _id, quantity },
}) => {
  const dispatch = useDispatch();
  const skipped = useSelector((state) => state.paints.skipped);

  let placeholder = 0;
  if (surfaceRight) placeholder += surfaceRight * 0.33;
  if (surfaceLeft) placeholder += surfaceLeft * 0.4;

  const handleInput = ({ target: { value } }) => {
    const numbers = /^[\d|.|,]*$/;
    if (!value.match(numbers)) return;
    dispatch(
      setPaintOrderValue(index, 'quantity', value.replace(',', '.') || ''),
    );
  };

  return (
    <td data-isnotclickable="1">
      <StyledInput
        data-isnotclickable="1"
        placeholder={placeholder ? placeholder.toFixed(2) : 0}
        style={{ width: 100 }}
        disabled={skipped.includes(_id)}
        type="text"
        value={quantity}
        onChange={handleInput}
      />
    </td>
  );
};

export default PaintValue;
