/* eslint-disable no-shadow */
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import ModalContainer from 'PageTemplates/ModalTemplate';
import { useDispatch } from 'react-redux';
import { setSpinner } from 'services/store/actions/view';
import { updateOrder } from 'services/apiRequests/orders/update';
import { setOrder } from 'services/store/actions/orders';
import { useAlert } from 'react-alert';
import DatePicker from 'components/DatePicker';

const SendToCNC = ({ order, closeModal }) => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const date = order.dateSendToCNC;

  const [dateSendToCNC, setDateSendToCNC] = useState(
    date ? new Date(date) : new Date(),
  );

  const handleSubmit = async () => {
    dispatch(setSpinner(true));
    await updateOrder(
      'send-to-cnc',
      order._id,
      { dateSendToCNC },
      (order) => {
        dispatch(setOrder(order));
        dispatch(setSpinner(false));
        alert.success('Ustalono datę wysłania do CNC');
        closeModal();
      },
      () => {
        dispatch(setSpinner(false));
        alert.error('Błąd!');
        closeModal();
      },
    );
  };
  return (
    <ModalContainer
      title="Data wysłania do CNC"
      closeModal={closeModal}
      footer={
        <>
          <Button variant="success" onClick={handleSubmit}>
            Zmień
          </Button>
          <Button variant="danger" onClick={closeModal}>
            Anuluj
          </Button>
        </>
      }
    >
      Data wysłania zamówienia do frezowania to:{' '}
      <DatePicker
        style={{ width: '50%', display: 'inline' }}
        value={dateSendToCNC}
        onChange={(date) => {
          setDateSendToCNC(date);
        }}
      />
    </ModalContainer>
  );
};

export default SendToCNC;
