import Axios from 'axios';
import FileSaver from 'file-saver';

export const newPaintsOrder = async (data, onEnd, onErr) => {
  try {
    const res = await Axios({
      url: `/api/paints/`,
      method: 'POST',
      responseType: 'arraybuffer',
      data,
    });
    FileSaver.saveAs(
      new Blob([res.data], { type: 'application/xlsx' }),
      `lakiery.xlsx`,
    );

    return onEnd();
  } catch (error) {
    console.log(error);
    onErr();
  }
};
