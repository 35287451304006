import { OrderPaymentStatus } from 'services/enums/Orders.enum';

export const styles = {
  getPaymentStatusDisplayColor: (
    paymentStatus: OrderPaymentStatus,
  ): string => {
    if (paymentStatus === OrderPaymentStatus.NOT_PAID) return 'red';
    if (paymentStatus === OrderPaymentStatus.PAID) return 'green';
    if (paymentStatus === OrderPaymentStatus.HALF_PAID) return 'orange';
    if (paymentStatus === OrderPaymentStatus.TRANSFER) return 'gold';
    return 'black';
  },
};
