import React, { ChangeEvent, FC } from 'react';
import { IOrder } from 'services/interfaces/orders/Orders.interface';

export interface IOrderCheckboxOnChange
  extends ChangeEvent<HTMLInputElement> {
  order: IOrder;
}

interface IProps {
  order: IOrder;
  onCheck: (event: IOrderCheckboxOnChange) => void;
  isChecked: (order: IOrder) => boolean;
}

const OrderCheckboxCell: FC<IProps> = ({ order, onCheck, isChecked }) => {
  return (
    <td>
      <input
        style={{ margin: '5px' }}
        type="checkbox"
        name={order._id}
        id={order._id}
        value={order._id}
        onChange={(e) => onCheck({ ...e, order })}
        checked={isChecked(order)}
      />
    </td>
  );
};

export default OrderCheckboxCell;
