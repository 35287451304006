import React from 'react';
import { IOrder } from 'services/interfaces/orders/Orders.interface';
import { IUser } from 'services/interfaces/users/Users.interface';

interface IProps {
  onChange: () => {};
  order: IOrder;
  checked?: (orderId: string) => boolean;
}
// --------------------------------------------------------------------------
// NOTE DEPRACATED
// --------------------------------------------------------------------------
const Checkbox: React.FC<IProps> = ({ onChange, order, checked }) => {
  const value = {
    _id: order._id,
    orderName: `${(order.user as IUser).company} ${order.number}`,
    paintMaker: order.paintMaker,
  };
  const isChecked = (orderId: string) => {
    if (!checked) return undefined;
    return checked(orderId);
  };
  return (
    <td>
      <input
        style={{ margin: '5px' }}
        type="checkbox"
        name={order._id}
        id={order._id}
        value={JSON.stringify(value)}
        onChange={onChange}
        checked={isChecked(order._id)}
      />
    </td>
  );
};

export default Checkbox;
