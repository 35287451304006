import {
  VIEW_SET_SPINNER,
  VIEW_SET_HOMEPAGE_KEY,
  VIEW_SET_MODEL,
} from '../types/view';

export const setSpinner = (isSpinner) => ({
  type: VIEW_SET_SPINNER,
  isSpinner,
});

export const setHomePageKey = (key) => ({
  type: VIEW_SET_HOMEPAGE_KEY,
  key,
});

export const setItemModelEdge = (key, value) => ({
  type: VIEW_SET_MODEL,
  key,
  value,
});
