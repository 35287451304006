import { Button } from 'react-bootstrap';
import { PageHeader } from 'components/header';
import withContext from 'hoc/withContext';
import React, { useEffect, useState } from 'react';
import PageTemplate from 'PageTemplates/PageTemplate';
import { useDispatch, useSelector } from 'react-redux';
import { setSpinner } from 'services/store/actions/view';
import { getCatalog, removeFront } from 'services/store/actions/catalog';
import { useAlert } from 'react-alert';
import { useModal } from 'hooks/useModal';
import AddMillingModal from '../components/AddMillingModal/AddMillingModal';
import PricingGroup from '../components/MillingPricingGroup/PricingGroup';
import {
  setNewOrderCustomPilaster,
  setNewOrderPilaster,
} from 'services/store/actions/newOrder';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileUpload } from '@fortawesome/free-solid-svg-icons';

const StyledInputFile = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;
const StyledFileLabel = styled.label`
  color: white;
  background-color: white;
  border: 1px solid #007bff;
  color: #007bff;
  padding: 5px 30px;
  letter-spacing: 1px;
  border-radius: 5px;
  margin: 5px 5px 10px;
  cursor: pointer;
  transition: background-color 200ms ease;
  &:hover {
    background-color: #007bff;
    color: white;
  }
`;

const initModals = {
  addPilaster: false,
};

const Pilasters = ({
  closeModal: closeNewOrderModal,
  permissionContext,
}) => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const { modal, showModal, closeModal } = useModal(initModals);
  const pilasters = useSelector((state) => state.catalog.pilasters);
  const newOrder = useSelector((state) => state.newOrder);
  const [pilasterToUpdate, setPilasterToUpdate] = useState(null);

  useEffect(() => {
    if (!pilasters.length) dispatch(setSpinner(true));
    dispatch(
      getCatalog(
        () => {},
        () => {
          alert.error('Błąd pobierania katalogu');
        },
      ),
    );
  }, []);

  const handlePilaster = (pilaster) => {
    dispatch(setNewOrderPilaster(pilaster.symbol));
    alert.success('Wybrałeś pilaster');
    if (closeNewOrderModal) closeNewOrderModal();
  };

  const handleAddCustomPilaster = (e) => {
    dispatch(setNewOrderCustomPilaster(e.target.files[0]));
    alert.success('Wybrałeś pilaster ze zdjęcia');
    if (closeNewOrderModal) closeNewOrderModal();
  };

  const handleEditPilaster = (front, index) =>
    setPilasterToUpdate({ ...front, index });

  const handleRemovePilaster = async (front) => {
    dispatch(setSpinner(true));
    dispatch(
      removeFront(
        front,
        () => {
          alert.success('Pilaster usunięty');
        },
        () => {
          alert.error('Błąd!');
        },
      ),
    );
  };
  return (
    <PageTemplate>
      <PageHeader
        title="Pilastry"
        extra={[
          permissionContext === 'admin' && (
            <Button key={1} onClick={() => showModal('addPilaster')}>
              Dodaj nowy pilaster
            </Button>
          ),
          <StyledInputFile
            key="3"
            type="file"
            accept="image/*,application/pdf"
            id="file_pilaster"
            onChange={handleAddCustomPilaster}
          />,
          <StyledFileLabel htmlFor="file_pilaster" key="4">
            <FontAwesomeIcon
              icon={faFileUpload}
              style={
                newOrder.customPilaster?.file ? { color: 'black' } : null
              }
            />{' '}
            Dodaj ze zdjęcia
          </StyledFileLabel>,
        ]}
      />
      <PricingGroup
        group={1}
        fronts={pilasters}
        removeFront={handleRemovePilaster}
        editFront={handleEditPilaster}
        handleFront={handlePilaster}
      />

      {/* MODALS */}
      {(modal.addPilaster || pilasterToUpdate) && (
        <AddMillingModal
          closeModal={() => {
            setPilasterToUpdate(null);
            closeModal();
          }}
          toUpdate={pilasterToUpdate}
          type="pilaster"
        />
      )}
    </PageTemplate>
  );
};

export default withContext(Pilasters);
