import { ChangeEvent } from 'react';
import {
  faFileUpload,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';

const StyledInputFile = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;
const StyledFileLabel = styled.label`
  background-color: white;
  color: #007bff;
  border: 1px solid #007bff;
  padding: 5px 30px;
  letter-spacing: 1px;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 200ms ease;
  &:hover {
    background-color: #007bff;
    color: white;
  }
`;

interface IProps {
  file: File | null;
  filePath?: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  removeFile: () => void;
}

const FileInput: React.FC<IProps> = ({
  file,
  filePath,
  onChange,
  removeFile,
}) => {
  return file || filePath ? (
    <div>
      <Button
        variant="outline-danger"
        onClick={removeFile}
        style={{
          margin: 0,
        }}
      >
        <FontAwesomeIcon
          icon={faTrashAlt}
          style={{
            color: '#c01212',
            cursor: 'pointer',
          }}
        />
        Usuń plik
      </Button>
      <div>
        <small>{file && file.name}</small>
      </div>
    </div>
  ) : (
    <div>
      <StyledInputFile
        type="file"
        id={`file_banner`}
        onChange={onChange}
      />
      <StyledFileLabel htmlFor={`file_banner`}>
        <FontAwesomeIcon icon={faFileUpload} /> Dodaj plik
      </StyledFileLabel>
    </div>
  );
};

export default FileInput;
