export const getChamferingPrice = (order, prices) => {
  const { orderType, chamfering, chamferingBy, items } = order;
  const { services } = prices;

  if (orderType.toLowerCase() === 'reklamacja (wina blow)') return 0;
  else if (
    ['materiał klienta', 'poprawa (wina klienta)'].includes(
      orderType.toLowerCase(),
    )
  ) {
    // chamfered by blow
    if (chamferingBy === 'blow') return chamfering * services.chamfering;
    else {
      //   order includes items from blow
      if (items.some((item) => item.blowMaterial)) {
        const blowChamferingLength = items.reduce((length, item) => {
          if (item.blowMaterial) {
            return (length += itemChamferingLength(item));
          }
          return length;
        }, 0);

        return blowChamferingLength * services.chamfering;
      } else return 0;
    }
  }
  return chamfering * services.chamfering;
};

const itemChamferingLength = ({
  h1L,
  h2L,
  w1L,
  w2L,
  h1P,
  h2P,
  w1P,
  w2P,
  height,
  width,
  quantity,
}) => {
  let length = 0;
  if ('gierunek' === h1L.toLowerCase())
    length += quantity * height * 0.001;
  if ('gierunek' === h1P.toLowerCase())
    length += quantity * height * 0.001;
  if ('gierunek' === h2L.toLowerCase())
    length += quantity * height * 0.001;
  if ('gierunek' === h2P.toLowerCase())
    length += quantity * height * 0.001;
  if ('gierunek' === w1L.toLowerCase()) length += quantity * width * 0.001;
  if ('gierunek' === w1P.toLowerCase()) length += quantity * width * 0.001;
  if ('gierunek' === w2L.toLowerCase()) length += quantity * width * 0.001;
  if ('gierunek' === w2P.toLowerCase()) length += quantity * width * 0.001;
  return length;
};
