import axios, { AxiosResponse } from 'axios';
import { IUser } from 'services/interfaces/users/Users.interface';
import setAuthToken from 'services/utils/setAuthToken';

export const customersApi = {
  getCustomers: async (): Promise<
    AxiosResponse<{ customers: IUser[] }>
  > => {
    setAuthToken();
    return axios.get(`/api/customers`);
  },
};
