import Axios from 'axios';

export const employeeAccountRemove = async (
  employeeData,
  onEnd,
  onErr,
) => {
  try {
    await Axios.put(`/api/employees/remove/me`, employeeData);

    onEnd();
  } catch (error) {
    console.log(error.response.data);
    if (error.response && error.response.status === 401)
      return onErr(error.response.data.message);
    return onErr();
  }
};

export const employeePermRemove = async (employeeId, onEnd, onError) => {
  try {
    await Axios.delete(`/api/employees/remove/${employeeId}`);

    onEnd();
  } catch (error) {
    console.log(error.response);
    onError();
  }
};
