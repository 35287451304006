import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { PageHeader } from 'components/header';
import { Tabs, Tab, Form } from 'react-bootstrap';
import PageTemplate from 'PageTemplates/PageTemplate';
import ALPI from 'assets/data/VeneersAlpi.json';
import CALIFORNIA from 'assets/data/VeneersCalifornia.json';
import NATURAL from 'assets/data/VeneersNatural.json';
import { filterCallback } from '../utils';
import FlexRow from 'PageTemplates/FlexRowTemplate';
import ImageCard from '../components/ImageCard';
import { setNewOrderVeneer } from 'services/store/actions/newOrder';
import { useAlert } from 'react-alert';

const StyledWrapper = styled.div`
  margin-top: 5px;
  width: 400px;
  @media (max-width: 600px) {
    margin: 5px auto;
    width: 100%;
  }
`;

const Veneers = ({ closeModal }) => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const [key, setKey] = useState('ALPI');
  const [search, setSearch] = useState('');
  const [newVeneers, setNewVeneers] = useState([]);

  useEffect(() => {
    let newVeneers = ALPI.concat(CALIFORNIA, NATURAL).filter((item) =>
      filterCallback(item, search),
    );
    setNewVeneers(newVeneers);
  }, [search]);

  const handleClick = (name) => {
    dispatch(setNewOrderVeneer(name));
    alert.success(`Wybrałeś fornir: ${name}`);
    if (closeModal) closeModal();
  };
  const handleSearch = (e) => setSearch(e.target.value);

  return (
    <PageTemplate>
      <PageHeader
        title="Forniry"
        extra={[
          <StyledWrapper key="1">
            <Form.Control
              type="text"
              value={search}
              onChange={handleSearch}
              placeholder="Wyszukaj"
            />
          </StyledWrapper>,
        ]}
      />
      {!search ? (
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(key) => setKey(key)}
        >
          <Tab eventKey="ALPI" title="Alpi">
            <ImagesCard
              activeKey={key}
              tabKey="ALPI"
              array={ALPI}
              handleClick={handleClick}
            />
          </Tab>
          <Tab eventKey="CALIFORNIA" title="California">
            <ImagesCard
              activeKey={key}
              tabKey="CALIFORNIA"
              array={CALIFORNIA}
              handleClick={handleClick}
            />
          </Tab>
          <Tab eventKey="NATURAL" title="Natural">
            <ImagesCard
              activeKey={key}
              tabKey="NATURAL"
              array={NATURAL}
              handleClick={handleClick}
            />
          </Tab>
        </Tabs>
      ) : (
        <FlexRow wrap>
          {newVeneers.map((item) => (
            <ImageCard
              key={item.name + Math.random()}
              item={item}
              type="veneers"
              handleClick={handleClick}
            />
          ))}
        </FlexRow>
      )}
    </PageTemplate>
  );
};

export default Veneers;

const ImagesCard = ({ activeKey, tabKey, array, handleClick }) => {
  if (activeKey === tabKey)
    return (
      <FlexRow wrap>
        {array.map((item) => (
          <ImageCard
            key={item.name}
            type="veneers"
            item={item}
            handleClick={handleClick}
          />
        ))}
      </FlexRow>
    );
  return null;
};
