import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import * as moment from 'moment';
import { useAlert } from 'react-alert';
import { Button, Form, Tab, Tabs } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import DatePicker from 'components/DatePicker';
import { PageHeader } from 'components/header';
import InvoicesList from 'components/invoicesList/InvoicesList';
import { getCustomers } from 'services/store/actions/customers';
import { setSpinner } from 'services/store/actions/view';
import { invoicePaymentStatus } from 'services/utils/const';
import { firstLetterToUpperCase } from 'services/utils/string';
import FlexRow from 'PageTemplates/FlexRowTemplate';
import PageTemplate from 'PageTemplates/PageTemplate';
import InvoicesSummary from './InvoicesSummary';

const InvoicesPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const alert = useAlert();
  const customers = useSelector((state) => state.customers.customers);

  const [key, setKey] = useState(invoicePaymentStatus.NOT_PAID);
  const [customer, setCustomer] = useState('');
  const [dateFrom, setDateFrom] = useState(
    moment().subtract(1, 'month').toDate(),
  );
  const [dateTo, setDateTo] = useState(moment().toDate());
  const [invoices, setInvoices] = useState([]);
  const [summary, setSummary] = useState({ all: 0, terminated: 0 });

  const getInvoices = useCallback(async () => {
    try {
      dispatch(setSpinner(true));
      const res = await axios.get(`/api/transfers`, {
        params: { status: key, dateFrom, dateTo, customer },
      });
      setInvoices(res.data.invoices);
      if (key === invoicePaymentStatus.NOT_PAID) {
        const all = res.data.invoices.reduce((acc, curr) => {
          const newAcc = acc + curr.price;
          return newAcc;
        }, 0);
        const terminated = res.data.invoices.reduce((acc, curr) => {
          if (moment(curr?.paymentTo)?.diff(moment(), 'hours') < 0) {
            const newAcc = acc + curr.price;
            return newAcc;
          }
          return acc;
        }, 0);

        setSummary({ all, terminated });
      }
    } catch (error) {
      console.log(error);
      alert.error('Błąd');
    } finally {
      dispatch(setSpinner(false));
    }
  }, [key, customer, dateFrom, dateTo]);

  useEffect(() => {
    getInvoices();
  }, [getInvoices]);

  useEffect(() => {
    dispatch(
      getCustomers(
        () => {},
        () => {
          alert.error('Błąd!');
        },
      ),
    );
  }, []);

  const goToNewTransfer = () => history.push(`/new-fv`);
  return (
    <PageTemplate>
      <PageHeader
        title="Faktury"
        extra={[
          <Button key={1} onClick={goToNewTransfer}>
            Nowa faktura
          </Button>,
        ]}
      />
      <FlexRow justify="flex-end">
        {key === invoicePaymentStatus.PAID && (
          <>
            <div>
              <div>
                Od <small>(Data wystawienia)</small>
              </div>
              <DatePicker value={dateFrom} onChange={setDateFrom} />
            </div>
            <div>
              <div>Do</div>
              <DatePicker value={dateTo} onChange={setDateTo} />
            </div>
          </>
        )}
        <div>
          Klient{' '}
          <CustomersSelect
            items={customers}
            value={customer}
            handleChange={(value) => setCustomer(value)}
          />
        </div>
      </FlexRow>
      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(key) => {
          setInvoices([]);
          setKey(key);
        }}
      >
        <Tab eventKey={invoicePaymentStatus.NOT_PAID} title="Nie opłacone">
          {!!invoices.length && (
            <>
              <InvoicesList invoices={invoices} />
              <FlexRow justify="flex-end">
                <InvoicesSummary
                  all={summary.all}
                  terminated={summary.terminated}
                />
              </FlexRow>
            </>
          )}
        </Tab>

        <Tab eventKey={invoicePaymentStatus.PAID} title="Opłacone">
          {!!invoices.length && <InvoicesList invoices={invoices} />}
        </Tab>
      </Tabs>
    </PageTemplate>
  );
};

export default InvoicesPage;

const CustomersSelect = ({ items, value, handleChange }) => (
  <div>
    <Form.Control
      as="select"
      onChange={(e) => handleChange(e.target.value)}
      value={value}
      disabled={!items}
      style={{ width: 300, margin: '0 3px' }}
    >
      <option value="" />
      {items &&
        items
          .sort((a, b) => `${a.company}`.localeCompare(b.company))
          .map((item) => (
            <option value={item._id} key={item._id} status={item}>
              {firstLetterToUpperCase(
                `${item.company} [${item.firstname}]`,
              )}
            </option>
          ))}
    </Form.Control>
  </div>
);
