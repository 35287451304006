import React, { useEffect } from 'react';
import { Table } from 'react-bootstrap';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import ListRow from './ListRow';
import { addNewOrderCustomItem } from 'services/store/actions/newOrder';

const Th = styled.th`
  vertical-align: middle;
  width: 50px;
  background-color: ${({ side, theme }) => {
    if (side === 'left') return theme.blowWarning;
    if (side === 'right') return theme.blowGreen;
  }};
`;

const List = () => {
  const dispatch = useDispatch();
  const order = useSelector((state) => state.newOrder);

  useEffect(() => {
    if (!order.customItems.length) dispatch(addNewOrderCustomItem());
  }, []);

  return (
    <>
      <Table striped bordered hover size="sm" responsive="md">
        <thead>
          <tr>
            <Th rowSpan={2}>
              <small>Materiał BLOW</small>
            </Th>

            <Th rowSpan="2">Lp</Th>
            <th rowSpan="2" style={{ width: 100 }}>
              Typ
            </th>
            <th rowSpan="2" style={{ width: 80 }}>
              Długość
            </th>
            <th rowSpan="2" style={{ width: 80 }}>
              Szerokość
            </th>
            <Th rowSpan="2">
              Grubość płyty
              <br />
              <small>[mm]</small>
            </Th>
            <Th rowSpan="2">Ilość</Th>
            <th style={{ width: 100 }} colSpan="2">
              Lakierowane strony
            </th>

            <Th rowSpan="2">Rys</Th>
            <th rowSpan="2" style={{ width: 600 }}>
              Uwagi
            </th>
            <th rowSpan="2" style={{ width: 20 }}></th>
          </tr>
          <tr>
            <Th side="right">P</Th>
            <Th side="left">L</Th>
          </tr>
        </thead>
        <tbody>
          {order.customItems.map((item, index) => (
            <ListRow key={index} item={item} index={index} />
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default List;
