import * as yup from 'yup';

export const schema = yup.object({
  firstname: yup
    .string()
    .matches(/^[a-zA-ZżźćńółęąśŻŹĆĄŚĘŁÓŃ]+$/, 'Nieprawidłowy format')
    .required(''),
  surname: yup
    .string()
    .matches(/^[a-zA-ZżźćńółęąśŻŹĆĄŚĘŁÓŃ]+$/, 'Nieprawidłowy format')
    .required(''),
  email: yup
    .string()
    .email('Format adresu email jest nieprawidłowy')
    .required('Email jest wymagany'),
  password: yup
    .string()
    .min(6, 'Hasło musi składać się z min. 6 znaków')
    .required('Hasło jest wymagane'),
  password2: yup
    .string()
    .min(6, 'Hasło musi składać się z min. 6 znaków')
    .required('Potwierdzenie hasła jest wymagane'),
});
