/* eslint-disable no-self-assign */
export const substractSimilarObjects = (a, b) => {
  const result = build(b, a);
  return result;
};

export const getFromObject = (obj, path) =>
  path.split('.').reduce((acc, key) => {
    if (!acc) return acc;
    else return acc[key] || undefined;
  }, obj);

export const isObjectEmpty = (obj) => {
  if (typeof obj !== 'object') return true;
  else return !Object.keys(obj).length;
};

const build = (a, b, o = null, prev = '') =>
  Object.keys(a).reduce(
    (acc, key) => {
      const path = prev + (prev ? '.' + key : key);
      const bObj = getFromObject(b, path);
      const value = bObj - a[key] || 0;

      if (typeof a[key] === 'object') {
        if (isObjectEmpty(a[key]) && isObjectEmpty(bObj)) {
          if (key in acc) acc[key] = acc[key];
          else acc[key] = true;
        } else if (!bObj && isObjectEmpty(a[key])) {
          acc[key] = value;
        } else {
          acc[key] = build(a[key], b, acc[key], path);
        }
      } else {
        acc[key] = value;
      }
      return acc;
    },
    o ? o : {},
  );
