import React, { ChangeEvent, useState } from 'react';
import {
  faFileUpload,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMutation, useQueries } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { Formik } from 'formik';
import moment from 'moment';
import { useAlert } from 'react-alert';
import { Button, Col, Form, FormGroup, Row } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router';
import styled from 'styled-components';
import DatePicker from 'components/DatePicker';
import FileInput from 'components/FormInputs/StyledFileInput';
import { PageHeader } from 'components/header';
import OrdersList from 'components/ordersList/static';
import Spinner from 'components/spinner';
import { invoicesApi } from 'services/apiRequests/invoices.api';
import { ordersApi } from 'services/apiRequests/orders.api';
import { IInvoice } from 'services/interfaces/invoices/Invoices.interface';
import { IOrder } from 'services/interfaces/orders/Orders.interface';
import { IUser } from 'services/interfaces/users/Users.interface';
import { parseStringToNumber } from 'services/utils/parseStringToNumber';
import { getUserName } from 'services/utils/string';
import FlexRow from 'PageTemplates/FlexRowTemplate';
import PageTemplate from 'PageTemplates/PageTemplate';
import { updateFvValidate } from './utils/validate';

const EditInvoicePage = () => {
  const { id }: { id: string } = useParams();
  const alert = useAlert();
  const history = useHistory();
  const [customerId, setCustomerId] = useState<string | null>(null);

  const [file, setFile] = useState<File | null>(null);
  const [pickedOrders, setPickedOrders] = useState<string[]>([]);

  const [invoice, orders, removeFile] = useQueries({
    queries: [
      {
        queryKey: ['invoice'],
        queryFn: () => invoicesApi.getInvoice(id),
        onError: (err: any) => {
          console.log(err, err.response);
          alert.error('Błąd');
        },
        onSuccess: ({ data }: AxiosResponse<IInvoice>) => {
          setCustomerId((data.customer as IUser)._id);
          const picked = data.orders.map((order) => order.order._id);
          setPickedOrders(picked);
        },
      },
      {
        queryKey: ['orders', customerId],
        queryFn: () =>
          customerId && ordersApi.getCustomerOrdersToInvoice(customerId),
        enabled: !!customerId,
      },
      {
        queryKey: ['removeFile'],
        queryFn: () => invoicesApi.removeFile(id),
        enabled: false,
        retry: false,
        onSuccess: () => {
          invoice.refetch();
        },
        onError: (err: any) => {
          console.log(err, err.response);
          alert.error('Błąd');
        },
      },
    ],
  });

  const { mutate: updateInvoice, isLoading: isUpdating } = useMutation(
    async (updateData: Partial<IInvoice>) => {
      const data = new FormData();
      data.append(
        `invoiceUpdate`,
        JSON.stringify({
          ...updateData,
          orders: pickedOrders.map((order) => ({ order })),
        }),
      );

      if (file) {
        const filename = file.name.split('/').join('_');
        data.append('file', file, filename);
      }

      return invoicesApi.updateInvoice(id, data);
    },
    {
      onSuccess: () => {
        history.push(`/fv/${id}`);
      },
      onError: (err: any) => {
        console.log(err, err.response);
        alert.error('Błąd');
      },
    },
  );

  const handlePickOrder = (e: ChangeEvent<HTMLInputElement>) => {
    let { checked, value } = e.target;

    const obj = JSON.parse(value);
    const orderId: string = obj._id;
    if (checked) {
      if (!pickedOrders.includes(orderId))
        setPickedOrders(pickedOrders.concat(orderId));
    } else {
      const newList = pickedOrders.filter((item) => item !== orderId);
      setPickedOrders(newList);
    }
  };

  const handleAddFile = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target?.files?.length) setFile(e.target.files[0]);
  };
  const handleRemoveFile = () => {
    setFile(null);
    if (invoice && invoice.data?.data.filePath) {
      removeFile.refetch();
    }
  };

  return (
    <PageTemplate>
      {(invoice.isLoading ||
        orders.isLoading ||
        removeFile.isFetching ||
        isUpdating) && <Spinner />}
      <PageHeader title="Edycja faktury" />
      {invoice.data && (
        <Formik
          validationSchema={updateFvValidate}
          // ONSUBMIT REQUEST
          onSubmit={(values) => updateInvoice(values)}
          initialValues={invoice.data.data}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            setFieldValue,
            values,
            touched,
            errors,
            isValid,
          }) => {
            return (
              <Form noValidate onSubmit={handleSubmit}>
                <FlexRow justify="space-between">
                  <Form.Group controlId="numberId">
                    <strong>Numer FV</strong>
                    <Form.Control
                      required
                      type="text"
                      name="number"
                      value={values.number}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Numer FV*"
                      className={errors.number ? 'is-invalid' : ''}
                    />
                    {errors.number && (
                      <div className="invalid">{errors.number}</div>
                    )}
                  </Form.Group>

                  <div>
                    <strong>Data wystawienia FV</strong>
                    <DatePicker
                      value={moment(values.createdAt).toDate()}
                      onChange={(date: Date) =>
                        setFieldValue('createdAt', date)
                      }
                    />
                    {errors.createdAt && (
                      <div className="invalid">{errors.createdAt}</div>
                    )}
                  </div>

                  <div>
                    <strong>Termin płatności</strong>
                    <DatePicker
                      value={moment(values.paymentTo).toDate()}
                      onChange={(date: Date) =>
                        setFieldValue('paymentTo', date)
                      }
                    />
                    {errors.paymentTo && (
                      <div className="invalid">{errors.paymentTo}</div>
                    )}
                  </div>

                  <div>
                    <strong>Plik z fakturą</strong>
                    <FileInput
                      file={file}
                      filePath={invoice.data.data.filePath}
                      onChange={handleAddFile}
                      removeFile={handleRemoveFile}
                    />
                  </div>
                </FlexRow>

                <hr />
                <FlexRow justify="space-between">
                  <div>
                    <strong>
                      Klient:{' '}
                      {getUserName(invoice.data?.data.customer as IUser)}
                    </strong>

                    <div>
                      <small
                        style={{
                          color: 'lightgray',
                        }}
                      >
                        Pobrane są zamówienia nieopłacone i nieuwzględnione
                        wcześniej w FV
                      </small>
                    </div>
                  </div>

                  <div style={{ textAlign: 'right' }}>
                    <small>
                      Liczba wybranych zamówień: {pickedOrders.length}
                    </small>
                  </div>
                </FlexRow>

                {orders.data && (
                  <OrdersList
                    orders={orders.data.data}
                    listName="customer"
                    paymentStatus
                    pickUpDate
                    status
                    fv
                    checkbox
                    onCheck={handlePickOrder}
                    checked={(orderId: string) =>
                      pickedOrders.includes(orderId)
                    }
                    redirect={false}
                    price
                  />
                )}
                <hr />
                <FlexRow justify="flex-end">
                  <div>
                    <div style={{ marginBottom: 10, textAlign: 'right' }}>
                      <small>
                        Liczba wybranych zamówień: {pickedOrders.length}
                      </small>
                    </div>

                    <div>
                      <strong>Cena</strong>
                      <Form.Control
                        type="text"
                        placeholder="Cena"
                        value={values.price}
                        onChange={(e) => {
                          const { value } = e.target;
                          const price = parseStringToNumber(value);
                          setFieldValue('price', price);
                        }}
                      />
                      {errors.price && (
                        <div className="invalid">{errors.price}</div>
                      )}
                    </div>
                    <div>
                      <strong>Cena brutto</strong>
                      <Form.Control
                        type="text"
                        placeholder="Cena brutto"
                        value={values.priceGross}
                        onChange={(e) => {
                          const { value } = e.target;
                          const price = parseStringToNumber(value);
                          setFieldValue('priceGross', price);
                        }}
                      />
                      {errors.price && (
                        <div className="invalid">{errors.priceGross}</div>
                      )}
                    </div>
                  </div>
                </FlexRow>
                <Button
                  disabled={
                    !values.price || !pickedOrders.length || !isValid
                  }
                  // block
                  type="submit"
                  variant="success"
                >
                  Zapisz zmiany
                </Button>
              </Form>
            );
          }}
        </Formik>
      )}
    </PageTemplate>
  );
};

export default EditInvoicePage;
