export const getHandlesPrice = (order, prices) => {
  const {
    orderType,
    milledHandle,
    milledPartHandle,
    handlesMilledBy,
    items,
  } = order;
  const {
    services: { handle, partHandle, paintHandle, paintPartHandle },
  } = prices;

  if (orderType.toLowerCase() === 'reklamacja (wina blow)') return 0;
  if (
    ['materiał klienta', ' poprawa (wina klienta)'].includes(
      orderType.toLowerCase(),
    )
  ) {
    //  milled by blow
    if (handlesMilledBy === 'blow')
      return milledHandle * handle + milledPartHandle * partHandle;

    // order includes items from blow
    if (items.some((item) => item.blowMaterial)) {
      const values = {
        blow: { handle: 0, part: 0 },
        customer: { handle: 0, part: 0 },
      };
      items.forEach((item) => {
        if (item.blowMaterial) {
          values.blow.handle += item.milledHandle || 0;
          values.blow.part += item.milledPartHandle || 0;
        } else {
          values.customer.handle += item.milledHandle || 0;
          values.customer.part += item.milledPartHandle || 0;
        }
      });
      return (
        values.blow.handle * handle +
        values.blow.part * partHandle +
        values.customer.handle * paintHandle +
        values.customer.part * paintPartHandle
      );
    }
    return milledHandle * paintHandle + milledPartHandle * paintPartHandle;
  }

  return milledHandle * handle + milledPartHandle * partHandle;
};
