/* eslint-disable no-unsafe-optional-chaining */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { dateToString } from 'services/utils/date';
import { Table } from 'react-bootstrap';
import styled from 'styled-components';
import { firstLetterToUpperCase } from 'services/utils/string';
import { getOrdersSchedules } from './utils';
import { StyledH3 } from 'components/header';

const Wrapper = styled.div`
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
`;
const Tr = styled.tr`
  &:hover {
    background-color: #ececec;
  }
`;
const Th = styled.th`
  background-color: ${({ dayName }) => {
    if (dayName === 'Sobota') return '#e6f8e2';
    if (dayName === 'Niedziela') return '#f7eded';
    return 'inherit';
  }};
`;
const Td = styled.td`
  position: 'relative';
  background-color: ${({ dayName }) => {
    if (dayName === 'Sobota') return '#e6f8e2';
    if (dayName === 'Niedziela') return '#f7eded';
    return 'inherit';
  }};
`;

const TableView = ({ timetables, user }) => {
  const history = useHistory();
  const [days, setDays] = useState(null);
  const [orders, setOrders] = useState(null);
  const [filteredOrders, setFilteredOrders] = useState(null);

  useEffect(() => {
    if (timetables) {
      const result = getOrdersSchedules(timetables);
      setOrders(() => result.schedules);
      setDays(() => result.dates);
    }
  }, [timetables]);

  useEffect(() => {
    if (orders && user) {
      let newFilteredOrders = [...orders];
      if (user)
        newFilteredOrders = newFilteredOrders.filter((item) => {
          return [
            user._id,
            ...user.subordinates?.map((s) => s.id),
          ].includes(item.customerId);
        });
      setFilteredOrders(newFilteredOrders);
    }
  }, [orders, user]);

  if (filteredOrders && filteredOrders.length)
    return (
      <Wrapper>
        <StyledH3>Harmonogram</StyledH3>
        <Table bordered>
          <thead>
            <tr>
              <th style={{ width: 150 }}>Zamówienie</th>
              {days &&
                days.map((day) => {
                  return (
                    <Th key={day.date} dayName={day.name}>
                      {dateToString(day.date)} {day.name}
                    </Th>
                  );
                })}
            </tr>
          </thead>
          <tbody>
            {filteredOrders &&
              filteredOrders.map((order) => {
                const isOutOfTime = order.order.productionPlan
                  .filter((item) => item.planedOn && item.done)
                  .some((item) => {
                    const datePlaned = new Date(item.planedOn);
                    const dateDone = new Date(item.done);
                    return (
                      dateDone.getTime() - datePlaned.getTime() > 86400000
                    );
                  });
                console.log(order);
                return (
                  <Tr key={order.orderId}>
                    <td
                      onClick={() =>
                        history.push(`/order/${order.orderId}`)
                      }
                      style={{
                        fontWeight: 'bold',
                        cursor: 'pointer',
                        color: isOutOfTime ? 'red' : 'black',
                      }}
                      title={
                        isOutOfTime
                          ? 'Powstało opóźnienie na jednym ze stanowisk'
                          : ''
                      }
                    >
                      {order.orderName}
                      <div
                        style={{
                          fontWeight: 'normal',
                          fontSize: 12,
                          lineHeight: 1.2,
                        }}
                      >
                        {order.orderFullName}
                      </div>
                    </td>
                    {order.schedule.map((item, index) => {
                      return (
                        <Td
                          key={item.positions.join('.') + index}
                          dayName={item.dayName}
                        >
                          {item.positions.map((pos) => (
                            <span key={pos} className={pos}>
                              {firstLetterToUpperCase(pos)},{' '}
                            </span>
                          ))}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
          </tbody>
        </Table>
      </Wrapper>
    );
  return null;
};

export default TableView;
