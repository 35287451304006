import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { dateToStringWithHour } from 'services/utils/date';
import { isOrderNeedsToBeSandedAgain } from 'services/utils/orders';

const LastOperation = ({ order }) => {
  const { lastOperation } = order;
  let warning = isOrderNeedsToBeSandedAgain(order);
  return (
    <td style={{ wordWrap: 'break-word' }}>
      <>
        {warning ? (
          <>
            <FontAwesomeIcon
              style={{ color: 'red' }}
              icon={faClock}
              title="Oczekuje dłużej niż 12h"
            />
          </>
        ) : null}
        {lastOperation &&
          `${lastOperation.position} - ${dateToStringWithHour(
            lastOperation.date,
          )}`}
      </>
    </td>
  );
};

export default LastOperation;
