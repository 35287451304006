import React from 'react';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { ordersUtils } from 'services/utils/orders/orders.utils';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useAlert } from 'react-alert';

const FVStatus = ({ order }) => {
  const alert = useAlert();
  const user = useSelector((state) => state.auth.user);
  const history = useHistory();

  const goToFV = () => (order.fv ? history.push(`/fv/${order.fv}`) : null);
  const getFvName = () => {
    if (order) {
      return ordersUtils.payments.getOrderNameToFV(order);
    }
    return '';
  };

  const showOnCopyMessage = () => {
    if (order) {
      const name = ordersUtils.payments.getOrderNameToFV(order);
      return alert.success(`Skopiowano: ${name}`);
    }
    return null;
  };
  return (
    <td data-isnotclickable="1">
      {!!order.fv && (
        <Button data-isnotclickable="1" size="sm" onClick={goToFV}>
          Przejdź do
        </Button>
      )}
      {user && user.isBoss && (
        <CopyToClipboard
          text={getFvName()}
          onCopy={() => showOnCopyMessage()}
          data-isnotclickable="1"
        >
          <Button
            style={{ padding: 2 }}
            size="sm"
            variant="outline-primary"
            data-isnotclickable="1"
          >
            Nazwa do fv
          </Button>
        </CopyToClipboard>
      )}
    </td>
  );
};

export default FVStatus;
