import { StyledH3 } from 'components/header';
import OrdersList from 'components/ordersList/static';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { getOrderPrice } from 'services/utils/orders';
import ModalContainer from 'PageTemplates/ModalTemplate';

const OrdersPriceModal = ({ orders, closeModal }) => {
  const [price, setPrice] = useState(0);
  const [withAdvancePayment, setWithAdvancePayment] = useState(false);
  const [pickedOrders, setPickedOrders] = useState([]);

  useEffect(() => {
    if (pickedOrders.length) {
      const ordersPrice = pickedOrders.reduce((acc, order) => {
        const p = getOrderPrice(order, withAdvancePayment);
        return (acc += parseFloat(p));
      }, 0);
      setPrice(ordersPrice);
    }
  }, [pickedOrders, withAdvancePayment]);

  const handleCheck = (e) => {
    const orderValue = JSON.parse(e.target.value);
    if (e.target.checked) {
      const order = orders.find((o) => o._id === orderValue._id);
      setPickedOrders([...pickedOrders, order]);
    } else {
      const newPickedOrders = pickedOrders.filter(
        (o) => o._id === orderValue._id,
      );
      setPickedOrders(newPickedOrders);
    }
  };

  return (
    <ModalContainer
      title="Zaznacz zamówienia"
      closeModal={closeModal}
      dialogClassName="mybootstrapmodal"
      footer={<></>}
    >
      <Form.Group controlId="advancePaymentCheckbox">
        <Form.Check
          checked={!withAdvancePayment}
          onChange={(e) => setWithAdvancePayment(!e.target.checked)}
          type="checkbox"
          label="Odejmij zaliczki"
        />
      </Form.Group>
      <StyledH3>Cena wybranych zamówień: {price.toFixed(2)}zł</StyledH3>
      <OrdersList
        orders={orders}
        checkbox
        onCheck={handleCheck}
        listName="customer"
        redirect={false}
        price
        status
      />
    </ModalContainer>
  );
};

export default OrdersPriceModal;
