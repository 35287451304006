import {
  faFileUpload,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import withContext from 'hoc/withContext';
import React, { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { sendCustomerMessage } from 'services/apiRequests/messages/post';
import { setCustomerMessages } from 'services/store/actions/auth';
import { getCustomers } from 'services/store/actions/customers';
import { setSpinner } from 'services/store/actions/view';
import styled from 'styled-components';
import Modal from 'PageTemplates/ModalTemplate';
import CustomersList from './CustomersList';
import MessagesList from './MessagesList';

const StyledInputFile = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;
const StyledFileLabel = styled.label`
  background-color: white;
  color: #007bff;
  border: 1px solid #007bff;
  padding: 5px 30px;
  letter-spacing: 1px;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 200ms ease;
  &:hover {
    background-color: #007bff;
    color: white;
  }
`;

const CustomersMessages = ({
  closeModal,
  permissionContext,
  setIsBanner,
}) => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const customers = useSelector((state) => state.customers.customers);
  const messages = useSelector((state) => state.auth.messages);
  const user = useSelector((state) => state.auth.user);

  const [message, setMessage] = useState('');
  const [toUser, setToUser] = useState('');
  const [img, setImg] = useState();
  const [toAll, setToAll] = useState(false);

  const [customersMessagesList, setCustomersMessagesList] = useState([]);
  const [customer, setCustomer] = useState(null);

  useEffect(() => {
    if (permissionContext === 'admin' && !customers) {
      dispatch(setSpinner(true));
      dispatch(
        getCustomers(
          () => {
            dispatch(setSpinner(false));
          },
          () => {
            dispatch(setSpinner(false));
            alert.error('Błąd!');
          },
        ),
      );
    }
  }, []);

  useEffect(() => {
    if (messages) {
      console.log(messages);
      const customersList = messages
        .reduce((acc, msg) => {
          if (acc.includes(msg.authorId)) return acc;
          return [...acc, msg.authorId];
        }, [])
        .map((author) => {
          const authorData = messages.find(
            (msg) => msg.authorId === author,
          );
          const authorMessages = messages.filter(
            (msg) =>
              msg.authorId === author || msg.toUsers.includes(author),
          );
          return {
            author: authorData.author,
            authorId: author,
            messages: authorMessages,
            includeUnreaded: authorMessages.some(
              (msg) => !msg.readedBy.includes(user._id),
            ),
          };
        })
        .filter((msg) =>
          permissionContext === 'admin' ? msg.authorId !== user._id : true,
        )
        .sort((a, b) => `${a.author}`.localeCompare(b.author));
      if (permissionContext === 'user' && customersList[0]) {
        setCustomer(customersList[0]);
      } else setCustomersMessagesList(customersList);
    }
  }, [messages, user]);

  const handleBanner = () => {
    setIsBanner(true);
    closeModal();
  };

  const handleCustomer = (customer) => {
    setCustomer(() => customer);
    setToUser(() => customer.authorId);
  };
  const handleGoBack = () => {
    setCustomer(() => null);
    setToUser(() => '');
  };

  const handleToAll = (checked) => {
    if (checked) {
      setToUser('');
      setToAll(true);
    } else {
      setToAll(false);
    }
  };

  const handleSendMessage = async () => {
    dispatch(setSpinner(true));
    await sendCustomerMessage(
      { message, toAll, toUser },
      (newMessage) => {
        dispatch(setSpinner(false));
        alert.success('Wiadomość wysłana');
        const newMessages = messages
          ? [newMessage, ...messages]
          : [newMessage];
        dispatch(setCustomerMessages(newMessages));
        setMessage('');
        setToAll(false);
        setToUser('');
        setImg();
      },
      () => {
        dispatch(setSpinner(false));
        alert.error('Błąd!');
      },
      img,
    );
  };

  return (
    <Modal
      title="Wiadomości"
      closeModal={closeModal}
      footer={
        permissionContext === 'admin' ? (
          <Button variant="success" onClick={handleBanner}>
            BANNER
          </Button>
        ) : null
      }
      size="xl"
    >
      <Row>
        <Col xs={12} sm={6}>
          {customer ? (
            <MessagesList
              messages={customer.messages}
              goBack={handleGoBack}
            />
          ) : (
            <CustomersList
              customers={customersMessagesList}
              setCustomer={handleCustomer}
            />
          )}
        </Col>
        <Col xs={12} sm={6}>
          <Form.Control
            as="textarea"
            placeholder="Treść nowej wiadomości..."
            style={{ height: '100px', marginBottom: 10 }}
            value={message}
            onChange={(e) => {
              setMessage(e.target.value);
            }}
          />
          {!img ? (
            <>
              <StyledInputFile
                type="file"
                accept="image/*"
                id={`file_banner`}
                onChange={(e) => setImg(e.target.files[0])}
              />
              <StyledFileLabel htmlFor={`file_banner`}>
                <FontAwesomeIcon icon={faFileUpload} /> Dodaj zdjęcie
              </StyledFileLabel>
            </>
          ) : (
            <div>
              {' '}
              <Button variant="outline-danger" onClick={() => setImg()}>
                <FontAwesomeIcon
                  icon={faTrashAlt}
                  style={{ color: '#c01212', cursor: 'pointer' }}
                />{' '}
                Usuń zdjęcie
              </Button>
              <div>
                <small>{img && img.name}</small>
              </div>
            </div>
          )}
          {permissionContext === 'admin' && (
            <>
              <div>
                <Form.Check
                  style={{ marginBottom: 5 }}
                  value={toAll}
                  id="toAll"
                  type="checkbox"
                  label="Do wszystkich"
                  onChange={(e) => handleToAll(e.target.checked)}
                />
              </div>
              <div>
                <Form.Group controlId="exampleForm.ControlSelect1">
                  <Form.Control
                    disabled={toAll}
                    as="select"
                    value={toUser}
                    onChange={(e) => setToUser(e.target.value)}
                  >
                    <option value="" />
                    {customers &&
                      customers
                        .sort((a, b) =>
                          `${a.company}`.localeCompare(`${b.company}`),
                        )
                        .map((item) => (
                          <option value={item._id} key={item._id}>
                            {item.company} - {item.firstname[0]}
                          </option>
                        ))}
                  </Form.Control>
                </Form.Group>
              </div>
            </>
          )}
          <div>
            <Button
              onClick={handleSendMessage}
              disabled={
                (permissionContext === 'admin' && !toAll && !toUser) ||
                !message ||
                (permissionContext === 'user' && !message)
              }
            >
              Wyślij
            </Button>
          </div>
        </Col>
      </Row>
    </Modal>
  );
};

export default withContext(CustomersMessages);
