import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Form } from 'react-bootstrap';
import PageTemplate from 'PageTemplates/PageTemplate';
import FlexRow from 'PageTemplates/FlexRowTemplate';
import { PageHeader } from 'components/header';
import FAST from 'assets/data/ColorsFast.json';
import RAL from 'assets/data/ColorsRal.json';
import NCS from 'assets/data/ColorsNcs.json';
import ICA from 'assets/data/ColorsIca.json';
import mordantBrown from 'assets/data/mordantBrown.json';
import mordantYellow from 'assets/data/mordantYellow.json';
import mordantWhite from 'assets/data/mordantWhite.json';
import mordantGreen from 'assets/data/mordantGreen.json';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { setNewOrderColor } from 'services/store/actions/newOrder';
import { useAlert } from 'react-alert';
import ColorCard from './components/ColorCard';
import InputWithButton from './components/InputWithButton';
import ImageCard from '../components/ImageCard';
import { filterCallback } from '../utils';
import { setSpinner } from 'services/store/actions/view';
import { addPaintToSecondPricingGroup } from 'services/apiRequests/settings/update';

const StyledWrapper = styled.div`
  margin-top: 5px;
  width: 400px;
  @media (max-width: 600px) {
    margin: 5px auto;
    width: 100%;
  }
`;

const Colors = ({ closeModal }) => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const [key, setKey] = useState('FAST');
  const [mordant, setMordant] = useState('');
  const [search, setSearch] = useState('');
  const [newMordant, setNewMordant] = useState(null);
  const [newColors, setNewColors] = useState(null);
  const MORDANT = mordantWhite.concat(
    mordantBrown,
    mordantYellow,
    mordantGreen,
  );

  useEffect(() => {
    const newMordant = mordantBrown
      .concat(mordantGreen, mordantWhite, mordantYellow)
      .filter((item) => filterCallback(item, search));

    const newColors = RAL.concat(NCS, ICA).filter((item) =>
      filterCallback(item, search),
    );
    setNewColors(newColors);
    setNewMordant(newMordant);
  }, [search]);

  const handleSetColor = (name, type, hexValue = '') => {
    const colorName = type === 'mordant' ? `bejca ${name}` : name;
    dispatch(setNewOrderColor(colorName, hexValue));

    alert.success(`Wybrałeś kolor: ${colorName}`);
    if (closeModal) closeModal();
  };

  const handleCustomMordant = (e) => setMordant(e.target.value);
  const handleSearch = (e) => setSearch(e.target.value);

  const handleAddToSecondPricingGroup = async (color) => {
    dispatch(setSpinner(true));
    await addPaintToSecondPricingGroup(
      color,
      () => {
        alert.success(`Dodano ${color} do drugiej grupy cenowej`);
        dispatch(setSpinner(false));
      },
      () => {
        alert.error('Błąd!');
        dispatch(setSpinner(false));
      },
    );
  };

  return (
    <PageTemplate>
      <PageHeader
        title="Kolory"
        extra={[
          <StyledWrapper key="1">
            <Form.Control
              type="text"
              value={search}
              onChange={handleSearch}
              placeholder="Wyszukaj"
            />
          </StyledWrapper>,
        ]}
      />

      {!search ? (
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(key) => setKey(key)}
        >
          <Tab eventKey="FAST" title="Szybki wybór">
            <ColorsCards
              activeKey={key}
              tabKey="FAST"
              array={FAST}
              handleClick={handleSetColor}
              handleAddToSecondPricingGroup={handleAddToSecondPricingGroup}
            />
          </Tab>
          <Tab eventKey="RAL" title="RAL">
            <ColorsCards
              activeKey={key}
              tabKey="RAL"
              array={RAL}
              handleClick={handleSetColor}
              handleAddToSecondPricingGroup={handleAddToSecondPricingGroup}
            />
          </Tab>
          <Tab eventKey="NCS" title="NCS">
            <ColorsCards
              activeKey={key}
              tabKey="NCS"
              array={NCS}
              handleClick={handleSetColor}
              handleAddToSecondPricingGroup={handleAddToSecondPricingGroup}
            />
          </Tab>
          <Tab eventKey="ICA" title="ICA">
            <ColorsCards
              activeKey={key}
              tabKey="ICA"
              array={ICA}
              handleClick={handleSetColor}
              handleAddToSecondPricingGroup={handleAddToSecondPricingGroup}
            />
          </Tab>
          <Tab eventKey="MORDANT" title="BEJCA">
            {key === 'MORDANT' && (
              <>
                <StyledWrapper>
                  <InputWithButton
                    value={mordant}
                    handleChange={handleCustomMordant}
                    handleClick={() => handleSetColor(mordant, 'mordant')}
                    placeholder="Numer bejcy"
                    buttonName="Dodaj"
                  />
                  <small>
                    Pełny wzornik bejc na stronie:{' '}
                    <a href="https://www.sopur.com.pl/pl/katalog-kolorow">
                      sopur.com.pl
                    </a>
                    .
                  </small>
                </StyledWrapper>
                <hr />
                <FlexRow wrap>
                  {newMordant.map((item) => (
                    <ImageCard
                      key={item.name}
                      item={item}
                      handleClick={handleSetColor}
                      type="mordant"
                    />
                  ))}
                </FlexRow>
              </>
            )}
          </Tab>
        </Tabs>
      ) : (
        <>
          <FlexRow wrap>
            {newColors.map((item) => (
              <ColorCard
                key={item.name}
                item={item}
                handleClick={handleSetColor}
              />
            ))}
          </FlexRow>
          <FlexRow wrap>
            {MORDANT.map((item) => (
              <ImageCard
                key={item.name}
                item={item}
                handleClick={handleSetColor}
                type="mordant"
              />
            ))}
          </FlexRow>
        </>
      )}
    </PageTemplate>
  );
};

export default Colors;

const ColorsCards = ({
  activeKey,
  tabKey,
  array,
  handleClick,
  handleAddToSecondPricingGroup,
}) => {
  if (activeKey === tabKey)
    return (
      <FlexRow wrap>
        {array.map((item) => (
          <ColorCard
            key={item.name}
            item={item}
            handleClick={handleClick}
            handleAddToSecondPricingGroup={handleAddToSecondPricingGroup}
          />
        ))}
      </FlexRow>
    );
  return null;
};
