import React, { useState } from 'react';
import Modal from 'PageTemplates/ModalTemplate';
import { Button, Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { setSpinner } from 'services/store/actions/view';
import { updateOrder } from 'services/apiRequests/orders/update';
import { setOrder } from 'services/store/actions/orders';
import { useAlert } from 'react-alert';
import { firstLetterToUpperCase } from 'services/utils/string';

const AddComment = ({ order, closeModal, position = null }) => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const [comment, setComment] = useState('');

  const handleComment = (e) => setComment(e.target.value);
  const handleSubmit = async () => {
    dispatch(setSpinner(true));
    await updateOrder(
      'comment',
      order._id,
      {
        comment,
        position: position ? firstLetterToUpperCase(position) : 'Biuro',
      },
      (order) => {
        dispatch(setOrder(order));
        dispatch(setSpinner(false));
        alert.success('Dodano komentarz do zamówienia');
        closeModal();
      },
      () => {
        dispatch(setSpinner(false));
        alert.error('Błąd!');
      },
    );
  };
  return (
    <Modal
      title="Dodaj komentarz"
      closeModal={closeModal}
      footer={
        <>
          <Button
            variant="success"
            disabled={!comment}
            onClick={handleSubmit}
          >
            Dodaj
          </Button>
          <Button variant="danger" onClick={closeModal}>
            Anuluj
          </Button>
        </>
      }
    >
      <Form.Control
        value={comment}
        onChange={handleComment}
        placeholder="Komentarz..."
        as="textarea"
        rows="3"
      />
    </Modal>
  );
};

export default AddComment;
