import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import withContext from 'hoc/withContext';
import { firstLetterToUpperCase } from 'services/utils/string';

const Td = styled.td`
  background-color: ${({ primary, secondary, theme }) => {
    if (primary) return theme.blowGreenLight;
    if (secondary) return theme.blowWarningLight;
  }}!important;
  font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
`;
const Tr = styled.tr`
  background-color: ${({ type, theme }) => {
    if (type) return theme.items[type];
    return 'inherit';
  }}!important;
`;
const CuttingStatus = styled.th`
  background-color: ${({ cuttingStatus, theme }) => {
    if (cuttingStatus === 'do wycięcia') return theme.blowSuccessLight;
    else if (cuttingStatus === 'wstrzymany') return theme.blowDangerLight;
    else if (cuttingStatus === 'pominięty') return 'gray';
    else if (cuttingStatus === 'gotowy') return theme.blowSuccess;
    return 'inherit';
  }} !important;
`;

const ListRow = ({ item, order, index, permissionContext }) => {
  const [lost, setLost] = useState(null);
  const [toCorrect, setToCorrect] = useState(null);

  useEffect(() => {
    checkItemStatus('elementLost', item, setLost);
    checkItemStatus('elementToCorrect', item, setToCorrect);
  }, [item, order]);

  const checkItemType = () => {
    if (lost) return 'lost';
    if (toCorrect) return 'toCorrect';
    if (
      permissionContext === 'admin' &&
      (item.width >= 2800 || item.height >= 2800)
    )
      return 'long';
    if (
      !['employee', 'display'].includes(permissionContext) &&
      !item.includedToPrice
    )
      return 'notIncludedToPrice';
    return null;
  };

  return (
    <Tr type={checkItemType()}>
      {permissionContext !== 'user' && (
        <CuttingStatus cuttingStatus={item.cuttingStatus}>
          <small>
            {item.cuttingStatus &&
              firstLetterToUpperCase(item.cuttingStatus)}
          </small>
        </CuttingStatus>
      )}
      {[
        'Materiał klienta',
        'Poprawa (wina klienta)',
        'Domówienie',
      ].includes(order.orderType) && (
        <th>
          {item.blowMaterial ? <FontAwesomeIcon icon={faCheck} /> : ''}
        </th>
      )}
      <td>{index + 1}</td>
      <td>{firstLetterToUpperCase(item.type)}</td>
      <Td bold>{item.height}</Td>
      <Td primary>{edgeCell(item.h1P)}</Td>
      <Td primary>{edgeCell(item.h2P)}</Td>
      <Td secondary>{edgeCell(item.h1L)}</Td>
      <Td secondary>{edgeCell(item.h2L)}</Td>
      <Td secondary>
        {item.hLPaintedEdge ? <FontAwesomeIcon icon={faCheck} /> : ''}
      </Td>
      <Td bold>{item.width}</Td>
      <Td primary>{edgeCell(item.w1P)}</Td>
      <Td primary>{edgeCell(item.w2P)}</Td>
      <Td secondary>{edgeCell(item.w1L)}</Td>
      <Td secondary>{edgeCell(item.w2L)}</Td>
      <Td secondary>
        {item.wLPaintedEdge ? <FontAwesomeIcon icon={faCheck} /> : ''}
      </Td>
      <td>{item.thickness}</td>
      <td>{item.quantity}</td>
      <Td primary>
        {item.paintRight ? <FontAwesomeIcon icon={faCheck} /> : ''}
      </Td>
      <Td secondary>
        {item.paintLeft ? <FontAwesomeIcon icon={faCheck} /> : ''}
      </Td>

      {order.isUnderGlass && (
        <Td>
          {item.underGlass ? <FontAwesomeIcon icon={faCheck} /> : ''}
        </Td>
      )}
      {order.muntinType === 'x' && <Td>{item.muntinX}</Td>}
      {order.muntinType === '+' && (
        <>
          <Td>{item.muntinH}</Td>
          <Td>{item.muntinW}</Td>
        </>
      )}

      {order.coloredWater && (
        <Td secondary>
          {item.coloredWaterBothSide ? (
            <FontAwesomeIcon icon={faCheck} />
          ) : (
            ''
          )}
        </Td>
      )}
      {order.transparent && (
        <Td secondary>
          {item.transparentBothSide ? (
            <FontAwesomeIcon icon={faCheck} />
          ) : (
            ''
          )}
        </Td>
      )}
      <td>{item.comments}</td>
      <Td primary>
        {item.surfaceRight ? item.surfaceRight.toFixed(3) : ''}
      </Td>
      <Td secondary>
        {item.surfaceLeft ? item.surfaceLeft.toFixed(3) : ''}
      </Td>
      {permissionContext !== 'user' && (
        <>
          <td style={{ backgroundColor: 'white' }}>{lost}</td>
          <td style={{ backgroundColor: 'white' }}>{toCorrect}</td>
        </>
      )}
    </Tr>
  );
};

export default withContext(ListRow);

const edgeCell = (value) =>
  value.length < 5
    ? value.toUpperCase()
    : value.length >= 5
    ? `${firstLetterToUpperCase(value.substring(0, 4))}.`
    : firstLetterToUpperCase(value);

const checkItemStatus = (key, item, setList) => {
  if (item[key] && item[key].position) {
    const { position, quantity } = item[key];
    setList(
      <small
        style={{ cursor: 'default' }}
        title={`${position} - ${quantity}szt`}
        className={position.toLowerCase()}
      >
        {firstLetterToUpperCase(position.slice(0, 3))}_{quantity}
      </small>,
    );
  } else setList(null);
};
