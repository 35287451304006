import React, { FC } from 'react';
import { IOrder } from 'services/interfaces/orders/Orders.interface';
import { ordersUtils } from 'services/utils/orders/orders.utils';
import { CSSProperties } from 'styled-components';

interface IProps {
  order: IOrder;
}

const OrderPaymentStatusDisplayCell: FC<IProps> = ({ order }) => {
  const style: CSSProperties = {
    fontWeight: 'bold',
    color: ordersUtils.styles.getPaymentStatusDisplayColor(
      order.paymentStatus,
    ),
  };

  return <td style={style}>{order.paymentStatus}</td>;
};

export default OrderPaymentStatusDisplayCell;
