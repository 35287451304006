/* eslint-disable no-shadow */
import React from 'react';
import { Button } from 'react-bootstrap';
import ModalContainer from 'PageTemplates/ModalTemplate';
import { useDispatch } from 'react-redux';
import { setSpinner } from 'services/store/actions/view';

const ConfirmNewPrice = ({
  currentPrice,
  order,
  postOrder,
  closeModal,
}) => {
  const dispatch = useDispatch();

  const confirm = async (changePrice) => {
    dispatch(setSpinner(true));
    if (changePrice) {
      postOrder(order);
    } else {
      postOrder({ ...order, price: currentPrice });
    }
  };

  return (
    <ModalContainer
      title="Cena zamówienia uległa zmianie"
      closeModal={closeModal}
      footer={
        <>
          <Button variant="success" onClick={() => confirm(true)}>
            Tak
          </Button>
          <Button variant="danger" onClick={() => confirm(false)}>
            Nie
          </Button>
        </>
      }
    >
      Aktualna cena lakierowania zamówienia wynosi:{' '}
      <strong>{currentPrice.toFixed(2)}</strong> zł.
      <br />
      Nowa cena lakierowania to:
      <strong>{order.price ? order.price.toFixed(2) : 0}</strong> zł.
      <br />
      Czy chcesz ją zmienić?
      <hr />
    </ModalContainer>
  );
};

export default ConfirmNewPrice;
