import React, { useEffect } from 'react';
import PageTemplate from 'PageTemplates/PageTemplate';
import { PageHeader, StyledH6, StyledH3 } from 'components/header';
import { setSpinner } from 'services/store/actions/view';
import { getGlobalSettings } from 'services/store/actions/settings';
import { useSelector, useDispatch } from 'react-redux';
import { useAlert } from 'react-alert';
import styled from 'styled-components';
import FlexRow from 'PageTemplates/FlexRowTemplate';
import { firstLetterToUpperCase } from 'services/utils/string';

const Wrapper = styled.div`
  margin-right: 50px;
`;

const Contact = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const contact = useSelector((state) => state.settings.contact);

  useEffect(() => {
    dispatch(setSpinner(true));
    dispatch(
      getGlobalSettings(
        () => {
          dispatch(setSpinner(false));
        },
        () => {
          dispatch(setSpinner(false));
          alert.error('Błąd!');
        },
      ),
    );
  }, []);

  return (
    <PageTemplate>
      <PageHeader title={contact ? contact.companyName : 'Kontakt'} />
      {contact && (
        <>
          <FlexRow justify="flex-start">
            <Wrapper>
              <StyledH3>Dane firmy</StyledH3>
              <Item name="NIP" value={contact.NIP} />
              <Item name="REGON" value={contact.REGON} />
              <StyledH6>Adres:</StyledH6>
              {contact.addresses.map((address) => (
                <Address key={address.name} {...address} />
              ))}
              <StyledH6>Nr konta:</StyledH6>
              {contact.bankAccounts.map((bankAccount) => (
                <Item
                  key={bankAccount.accountNumber}
                  name={bankAccount.name}
                  value={`${bankAccount.bankName} ${bankAccount.accountNumber}`}
                />
              ))}
            </Wrapper>
            <Wrapper>
              <StyledH3>Kontakt z nami</StyledH3>
              <StyledH6>Telefon</StyledH6>
              {contact.phones.map((phone) => (
                <Item
                  key={phone.number}
                  name={phone.name}
                  value={phone.number}
                />
              ))}
              <StyledH6>E-mail</StyledH6>
              {contact.emails.map((email) => (
                <Item
                  key={email.email}
                  name={email.name}
                  value={email.email}
                />
              ))}
              <StyledH6>Internet</StyledH6>
              {contact.webPages.map((webpage) => (
                <Item
                  key={webpage.webPage}
                  name={webpage.name}
                  value={webpage.webPage}
                />
              ))}
            </Wrapper>
          </FlexRow>
        </>
      )}
    </PageTemplate>
  );
};

export default Contact;

const P = styled.p`
  margin-bottom: 5px;
`;

const Item = ({ name, value }) => {
  return (
    <div>
      <P>
        {firstLetterToUpperCase(name)}:{' '}
        <span>
          <strong>{value}</strong>
        </span>
      </P>
    </div>
  );
};
const Address = ({ name, postcode, city, street, description }) => {
  return (
    <div style={{ width: 'fit-content' }}>
      <Item name="Nazwa" value={name} />
      <Item name="Miejscowość" value={city} />
      <Item name="Kod pocztowy" value={postcode} />
      <Item name="Ulica" value={street} />
      <Item name="Opis" value={description} />
      <hr />
    </div>
  );
};
