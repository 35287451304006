import React, { useEffect } from 'react';
import PageTemplate from 'PageTemplates/PageTemplate';
import { PageHeader } from 'components/header';
import { useDispatch, useSelector } from 'react-redux';
import { setSpinner } from 'services/store/actions/view';
import { useAlert } from 'react-alert';
import { getCustomers } from 'services/store/actions/customers';
import List from './components/List';

const CustomersList = () => {
  const dispatch = useDispatch();
  const alert = useAlert();

  const customers = useSelector((state) => state.customers.customers);

  const getCustomersList = async () =>
    dispatch(
      getCustomers(
        () => {
          dispatch(setSpinner(false));
        },
        () => {
          dispatch(setSpinner(false));
          alert.error('Błąd!');
        },
      ),
    );

  useEffect(() => {
    if (!customers) {
      dispatch(setSpinner(true));
      getCustomersList();
    } else {
      getCustomersList();
    }
  }, []);
  return (
    <PageTemplate>
      <PageHeader title="Klienci" />
      <List customers={customers} />
      {/* MDOALS */}
    </PageTemplate>
  );
};

export default CustomersList;
