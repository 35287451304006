/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import PageTemplate from 'PageTemplates/PageTemplate';
import { PageHeader } from 'components/header';
import { useDispatch, useSelector } from 'react-redux';
import { useAlert } from 'react-alert';
import { setSpinner } from 'services/store/actions/view';
import {
  getAllTimetables,
  timetablesSetView,
} from 'services/store/actions/timetables';
import { Button, Form } from 'react-bootstrap';
import withContext from 'hoc/withContext';
import { getGlobalSettings } from 'services/store/actions/settings';
import PlanOrder from './components/Modals/PlanOrder';
import DayEdit from './components/Modals/DayEdit';
import TableView from './components/TableView';
import CardsView from './components/CardsView';
import PaintRoomEdit from './components/Modals/PaintRoomEdit';

const initModals = {
  planOrder: false,
  editOrderPlan: false,
  day: null,
  paintRoom: null,
};

const Timetable = ({ permissionContext }) => {
  const dispatch = useDispatch();
  const alert = useAlert();

  const user = useSelector((state) => state.auth.user);
  const timetables = useSelector((state) => state.timetables.timetables);
  const positions = useSelector((state) => state.timetables.positions);
  const view = useSelector((state) => state.timetables.view);

  const [modals, setModals] = useState(initModals);

  useEffect(() => {
    dispatch(setSpinner(true));
    dispatch(
      getAllTimetables(
        () => {
          dispatch(setSpinner(false));
        },
        () => {
          dispatch(setSpinner(false));
          alert.error('Błąd!');
        },
      ),
    );
    dispatch(
      getGlobalSettings(
        () => {},
        () => {
          alert.error('Błąd pobierania limitów');
        },
      ),
    );
  }, []);

  const handleView = (e) => dispatch(timetablesSetView(e.target.value));
  const handleModal = (key, value = true) =>
    setModals({ ...modals, [key]: value });
  const closeModal = () => setModals(initModals);

  return (
    <PageTemplate>
      <PageHeader
        title="Planowanie"
        extra={[
          <ViewSelect handleView={handleView} view={view} key={0} />,
          (permissionContext === 'admin' ||
            (permissionContext === 'employee' &&
              user &&
              user.isManager)) && (
            <div key={1} style={{ marginLeft: 5 }}>
              <Button onClick={() => handleModal('planOrder')}>
                Zaplanuj zamówienie
              </Button>
            </div>
          ),
        ]}
      />
      {timetables && view === 'cards' ? (
        <CardsView
          timetables={timetables}
          positions={positions}
          handleModal={handleModal}
        />
      ) : view === 'table' ? (
        <TableView timetables={timetables} />
      ) : null}

      {/* MODALS */}
      {modals.planOrder && <PlanOrder closeModal={closeModal} />}

      {modals.day && <DayEdit closeModal={closeModal} day={modals.day} />}
      {modals.paintRoom && (
        <PaintRoomEdit closeModal={closeModal} day={modals.paintRoom} />
      )}
    </PageTemplate>
  );
};

export default withContext(Timetable);

const ViewSelect = ({ handleView, view }) => (
  <Form.Control
    as="select"
    value={view}
    onChange={handleView}
    style={{ margin: 5 }}
  >
    <option value="cards">Karty</option>
    <option value="table">Tabela</option>
  </Form.Control>
);
