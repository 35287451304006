import React, { CSSProperties, FC } from 'react';

interface IProps {
  onRowClick?: () => void;
}

const OrdersListTableRow: FC<IProps> = ({ onRowClick, children }) => {
  const style: CSSProperties = !!onRowClick ? { cursor: 'pointer' } : {};

  return (
    <tr style={style} onClick={onRowClick}>
      {children}
    </tr>
  );
};

export default OrdersListTableRow;
