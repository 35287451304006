import dayjs from 'dayjs';
import React, { FC } from 'react';
import { IOrder } from 'services/interfaces/orders/Orders.interface';

interface IProps {
  order: IOrder;
}

const OrderDateCell: FC<IProps> = ({ order }) => {
  return <td>{dayjs(order.date).format('DD.MM.YYYY r.')}</td>;
};

export default OrderDateCell;
