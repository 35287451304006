import Axios from 'axios';

export const getUserOrdersInProduction = async (
  customerId,
  onEnd,
  onErr,
) => {
  try {
    const res = await Axios.get(
      `/api/orders/customer/${customerId}/inproduction`,
    );
    const { orders } = res.data;

    onEnd(orders);
  } catch (error) {
    console.log('ERROR_AUTH_USER: ', error.response);
    onErr();
  }
};

export const getAllCustomerOrders = async (customerId, onEnd, onErr) => {
  try {
    const res = await Axios.get(`/api/orders/customer/${customerId}/all`);
    const { orders } = res.data;

    onEnd(orders);
  } catch (error) {
    console.log(error.response);
    onErr();
  }
};
