import React, { useEffect } from 'react';
import PageTemplate from 'PageTemplates/PageTemplate';
import { PageHeader } from 'components/header';
import { useDispatch, useSelector } from 'react-redux';
import { useAlert } from 'react-alert';
import { useParams } from 'react-router';
import { setSpinner } from 'services/store/actions/view';
import { getTimetablesForPosition } from 'services/store/actions/timetables';
import FlexRow from 'PageTemplates/FlexRowTemplate';
import PositionTimetable from '../Timetable/components/CardsView/components/PositionTimetable';

const TimetableForPosition = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const params = useParams();
  const { position } = params;
  const timetable = useSelector((state) => state.timetables.timetables);

  useEffect(() => {
    if (!timetable) {
      dispatch(setSpinner(true));
      dispatch(
        getTimetablesForPosition(
          position,
          () => {
            dispatch(setSpinner(false));
          },
          () => {
            dispatch(setSpinner(false));
            alert.error('Błąd!');
          },
        ),
      );
    }
  }, []);

  return (
    <PageTemplate>
      <PageHeader title="Terminarz" />
      <FlexRow justify="space-around" wrap>
        {timetable && (
          <PositionTimetable
            position={position}
            timetable={timetable[position]}
          />
        )}
        {timetable && position === 'pakowanie' && timetable.transport && (
          <PositionTimetable
            position="transport"
            timetable={timetable.transport}
          />
        )}
      </FlexRow>
    </PageTemplate>
  );
};

export default TimetableForPosition;
