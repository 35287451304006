import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

const StyledSelect = styled.select`
  height: 28px;
  width: 100%;
  border-radius: 3px;
  border: 1px solid lightgray;
  &:focus {
    background-color: ${({ theme }) => theme.blowPrimaryLight};
  }
`;

const ItemType = ({
  handleChange,
  handleFocus,
  handleBlur,
  value,
  tabIndex,
}) => {
  const order = useSelector((state) => state.newOrder);
  return (
    <StyledSelect
      tabIndex={tabIndex}
      name="type"
      value={value}
      onChange={handleChange}
      onFocus={handleFocus}
      onBlur={handleBlur}
      required
    >
      {order.millings.includes('gładki CNC') && (
        <option value="gładki CNC">Gładki CNC</option>
      )}
      {((order.millings.length === 1 &&
        !order.millings.includes('gładki CNC') &&
        !order.millings.includes('ryflowany')) ||
        order.millings.length > 1) && <option value="frez">Frez</option>}
      <option value="gładki">Gładki</option>
      {order.millings.includes('ryflowany') && (
        <option value="ryflowany">Ryflowany</option>
      )}
      {order.glassCase && <option value="witryna">Witryna</option>}
    </StyledSelect>
  );
};

export default ItemType;
