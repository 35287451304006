import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

const StyledSelect = styled.select`
  height: 28px;
  width: 100%;
  border-radius: 3px;
  border: 1px solid lightgray;
  &:focus {
    background-color: ${({ theme }) => theme.blowPrimaryLight};
  }
`;

const CustomItemType = ({ handleChange, value }) => {
  const order = useSelector((state) => state.newOrder);
  return (
    <StyledSelect
      name="type"
      value={value}
      onChange={handleChange}
      required
    >
      {order.pilaster && <option value="pilaster">Pilaster</option>}

      {order.crown && <option value="korona">Korona</option>}
    </StyledSelect>
  );
};

export default CustomItemType;
