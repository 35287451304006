import React from 'react';
import Modal from 'PageTemplates/ModalTemplate';
import { Button } from 'react-bootstrap';
import { Formik, Form } from 'formik';
import TextInput from 'components/FormInputs/TextInput';
import * as yup from 'yup';

const AddPhone = ({ closeModal, phones, setFieldValue }) => {
  return (
    <Modal
      title="Dodaj numer telefonu"
      closeModal={closeModal}
      footer={
        <>
          <Button variant="success" type="submit" form="AddPhoneModal">
            Dodaj
          </Button>
          <Button variant="danger" onClick={closeModal}>
            Anuluj
          </Button>
        </>
      }
    >
      <Formik
        validationSchema={schema}
        // ONSUBMIT REQUEST
        onSubmit={async (values) => {
          const newPhones = phones.concat(values);
          setFieldValue('phones', newPhones);
          closeModal();
        }}
        initialValues={{
          name: '',
          number: '',
        }}
        render={(props) => {
          return (
            <Form
              noValidate
              onSubmit={props.handleSubmit}
              id="AddPhoneModal"
            >
              <TextInput {...props} name="name" label="Nazwa" required />
              <TextInput {...props} name="number" label="Numer" required />
            </Form>
          );
        }}
      />
    </Modal>
  );
};

export default AddPhone;

const schema = yup.object({
  name: yup.string().required('Nazwa jest wymagana'),
  number: yup
    .string()
    .matches(/^[0-9]+$/, 'Tylko cyfry')
    .min(7, 'Min 7 znaków')
    .max(9, 'Max 9 znaków')
    .required('Numer jest wymagany'),
});
