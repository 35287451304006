import React, { useState } from 'react';
import { StyledH1 } from 'components/header';
import { useDispatch, useSelector } from 'react-redux';
import { useAlert } from 'react-alert';
import styled from 'styled-components';
import { Button, Form } from 'react-bootstrap';
import { updateCustomerDataByAdmin } from 'services/apiRequests/customers/update';
import { setCustomer } from 'services/store/actions/customers';
import FlexRow from 'PageTemplates/FlexRowTemplate';
import TextInput from 'components/FormInputs/TextInput';
import { Formik } from 'formik';
import * as yup from 'yup';
import { setSpinner } from 'services/store/actions/view';

const Wrapper = styled.div`
  max-width: 800px;
`;

const UserTerms = ({ customer }) => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const realizationDates = useSelector(
    (state) => state.settings.realizationDates,
  );
  const [isEdit, setIsEdit] = useState(false);

  const handleSubmit = async (values) => {
    dispatch(setSpinner(true));
    await updateCustomerDataByAdmin(
      customer._id,
      {
        realizationDates: values,
      },
      (c) => {
        setIsEdit(false);
        alert.success('Ustalono indywidualne terminy');
        if (c) dispatch(setCustomer(c));
        dispatch(setSpinner(false));
      },
      () => {
        alert.error('Błąd!');
        dispatch(setSpinner(false));
      },
    );
  };

  const handleSetDefault = async (setValues) => {
    await handleSubmit({ veneer: 0, milling: 0, gloss: 0, semiGloss: 0 });
    setValues(realizationDates);
  };
  if (customer && realizationDates)
    return (
      <Formik
        validationSchema={schema}
        // ONSUBMIT REQUEST
        onSubmit={async (values) => {
          await handleSubmit(values);
        }}
        initialValues={customer.realizationDates}
        render={(props) => {
          return (
            <Form noValidate onSubmit={props.handleSubmit}>
              <Wrapper>
                <StyledH1>Indywidualne terminy</StyledH1>
                <div>
                  <small>
                    Jeżeli wartości wskazują <b>0</b>, termin realizacji
                    będzie odpowiadał wartości z ustawień globalnych
                  </small>
                </div>
                {customer.isVip && (
                  <div>
                    <small style={{ color: 'red' }}>
                      Ustalając indywidualne terminy realizacji, należy
                      wypełnić wszystkie pola!
                    </small>
                  </div>
                )}
                <TextInput
                  {...props}
                  name="veneer"
                  label="Fornir"
                  disabled={!isEdit}
                  required
                />
                <TextInput
                  {...props}
                  name="milling"
                  label="CNC"
                  disabled={!isEdit}
                  required
                />
                <TextInput
                  {...props}
                  name="gloss"
                  label="Połysk"
                  disabled={!isEdit}
                  required
                />
                <TextInput
                  {...props}
                  name="semiGloss"
                  label="Półmat / mat"
                  disabled={!isEdit}
                  required
                />

                <FlexRow justify="flex-end">
                  <Button
                    variant="success"
                    type="submit"
                    disabled={!isEdit}
                  >
                    Zatwierdź
                  </Button>
                  <Button
                    disabled={!isEdit}
                    onClick={() => handleSetDefault(props.setValues)}
                  >
                    Ustaw domyślne
                  </Button>
                  <Button
                    variant={isEdit ? 'outline-secondary' : 'secondary'}
                    onClick={() => setIsEdit(!isEdit)}
                    disabled={!customer.isVip}
                    title={
                      !customer.isVip
                        ? 'Klient nie posiada statusu VIP'
                        : ''
                    }
                  >
                    {isEdit ? 'Zablokuj edycję' : 'Edytuj'}
                  </Button>
                </FlexRow>
              </Wrapper>
            </Form>
          );
        }}
      />
    );
  return null;
};

export default UserTerms;

const schema = yup.object({
  veneer: yup
    .number()
    .typeError('wartość musi być liczbą')
    .required('wymagane'),
  milling: yup
    .number()
    .typeError('wartość musi być liczbą')
    .required('wymagane'),
  gloss: yup
    .number()
    .typeError('wartość musi być liczbą')
    .required('wymagane'),
  semiGloss: yup
    .number()
    .typeError('wartość musi być liczbą')
    .required('wymagane'),
});
