import React from 'react';
import { Table } from 'react-bootstrap';

const InvoicesSummary = ({ all = 0, terminated = 0 }) => {
  return (
    <Table responsive="md" bordered hover style={{ width: 300 }}>
      <tbody>
        <tr>
          <th style={{ color: 'red' }}>Po terminie</th>
          <td style={{ color: 'red' }}>{terminated.toFixed(2)}</td>
        </tr>
        <tr>
          <th>Wszystkie</th>
          <td>{all.toFixed(2)}</td>
        </tr>
      </tbody>
    </Table>
  );
};

export default InvoicesSummary;
