import { upperFirst } from 'lodash';
import React, { FC } from 'react';
import { OrderStatus } from 'services/enums/Orders.enum';
import { IOrder } from 'services/interfaces/orders/Orders.interface';
import styled from 'styled-components';

const Status = styled.span<{ status: OrderStatus }>`
  font-weight: bold;
  color: ${({ theme, status }) => theme.positions[status]};
  text-shadow: ${({ status }) =>
    status === 'podkład' ? ' 1px 1px 3px rgb(75, 75, 75)' : 'none'};
`;

interface IProps {
  order: IOrder;
}

const OrderStatusCell: FC<IProps> = ({ order }) => {
  return (
    <td>
      {order.status.map((status) => (
        <Status key={status} status={status}>
          {upperFirst(status)}
          {/* {position === 'pakowanie' && order.isReadyToPickUp && (
              <span>
                <FontAwesomeIcon
                  style={{ color: '#e165b9' }}
                  icon={faCheck}
                  title="Do odbioru"
                />
              </span>
            )} */}
        </Status>
      ))}
    </td>
  );
};

export default OrderStatusCell;
