import React, { useState } from 'react';
import withContext from 'hoc/withContext';
import logo from 'assets/images/logo.png';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { logOutUser } from 'services/store/actions/auth';
import styled, { keyframes } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { Navbar, Nav, Container, Stack } from 'react-bootstrap';
import { clearNewOrder } from 'services/store/actions/newOrder';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { isUnreadedMessageInArray } from 'services/utils/messages';
import AdminNav from './components/AdminNav';
import CustomerNav from './components/CustomerNav';
import EmployeeNav from './components/EmployeeNav';
import CustomerMessages from '../modals/CustomerMessages';
import Banner from 'components/modals/Banner';

const StyledNav = styled(Nav)`
  .nav-link {
    margin: 0 5px;
    font-size: 14px;
    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
  button {
    font-size: 14px;
  }
`;
const MessagesIcon = styled(FontAwesomeIcon)`
  margin: 3px;
  font-size: 20px;
  animation: ${({ ismessages, theme }) =>
      ismessages
        ? keyframes`
       0% {
        color: ${theme.blowDanger};
       }
       50% {
        color: gray;
        padding:2px;
       }
       100% {
        color: ${theme.blowDanger};
       }
      `
        : 'none'}
    2s infinite;
`;

const Navigation = ({ permissionContext }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.auth.user);
  const messages = useSelector((state) => state.auth.messages);
  const [isMessages, setIsMessages] = useState(false);
  const [isBanner, setIsBanner] = useState(false);

  const handleNewOrder = () => {
    dispatch(clearNewOrder());
    history.push('/newOrder');
  };
  const handleLogOut = () => dispatch(logOutUser());

  let nav = null;
  if (permissionContext === 'admin')
    nav = (
      <AdminNav
        handleNewOrder={handleNewOrder}
        handleLogOut={handleLogOut}
      />
    );
  else if (permissionContext === 'user')
    nav = (
      <CustomerNav
        handleNewOrder={handleNewOrder}
        handleLogOut={handleLogOut}
      />
    );
  else if (permissionContext === 'employee')
    nav = <EmployeeNav user={user} handleLogOut={handleLogOut} />;

  if (nav)
    return (
      <Navbar bg="light" expand="xl">
        <Container fluid>
          <Stack direction="horizontal">
            <Navbar.Brand>
              <NavLink to="/" exact className="nav-brand">
                <img
                  src={logo}
                  height="40"
                  className="d-inline-block align-top"
                  alt="Blow logo"
                />
              </NavLink>
            </Navbar.Brand>
            {(permissionContext === 'admin' ||
              permissionContext === 'user') && (
              <Nav.Item
                style={{ cursor: 'pointer' }}
                onClick={() => setIsMessages(true)}
              >
                <MessagesIcon
                  icon={faEnvelope}
                  ismessages={
                    isUnreadedMessageInArray(user, messages || []) ? 1 : 0
                  }
                />
              </Nav.Item>
            )}
          </Stack>

          <Nav>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <StyledNav className="ml-auto justify-content-end">
                {nav}
              </StyledNav>
            </Navbar.Collapse>
            {/* MODALS */}
            {isMessages && (
              <CustomerMessages
                closeModal={() => setIsMessages(false)}
                setIsBanner={setIsBanner}
              />
            )}
            {isBanner && <Banner closeModal={() => setIsBanner(false)} />}
          </Nav>
        </Container>
      </Navbar>
    );
  return null;
};

export default withContext(Navigation);
