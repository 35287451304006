import CustomerNameCell from './OrderCustomerNameCell';
import OrderColorCell from './OrderColorCell';
import OrderCustomElementsNumber from './OrderCustomElementsNumber';
import OrderDateCell from './OrderDateCell';
import OrderElementsNumberCell from './OrderElementsNumberCell';
import OrderNameCell from './OrderNameCell';
import OrderNumberCell from './OrderNumberCell';
import OrderPaintTypeCell from './OrderPaintTypeCell';
import OrderSurfaceCell from './OrderSurfaceCell';
import OrderTypeCell from './OrderTypeCell';
import OrderFinishDateCell from './OrderFinishDateCell';
import OrderPriceCell from './OrderPriceCell';
import OrderPaymentsListCell from './OrderPaymentsListCell';
import OrderPaymentStatusDisplayCell from './OrderPaymentStatusCell';
import OrderCheckboxCell from './OrderCheckboxCell';
import OrderStatusCell from './OrderStatusCell';

export const OrdersListCells = {
  customerName: CustomerNameCell,
  orderNumber: OrderNumberCell,
  orderName: OrderNameCell,
  color: OrderColorCell,
  paintType: OrderPaintTypeCell,
  surface: OrderSurfaceCell,
  elements: OrderElementsNumberCell,
  customElements: OrderCustomElementsNumber,
  orderType: OrderTypeCell,
  date: OrderDateCell,
  finishDate: OrderFinishDateCell,
  price: OrderPriceCell,
  paymentsList: OrderPaymentsListCell,
  paymentStatusDisplay: OrderPaymentStatusDisplayCell,
  paymentStatusHandle: null,
  checkbox: OrderCheckboxCell,
  orderStatus: OrderStatusCell,
};
