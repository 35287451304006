import useHasPermission from 'hooks/useHasPermission';
import React, { FC } from 'react';
import { Permissions } from 'services/enums/Permissions.enum';
import { IOrder } from 'services/interfaces/orders/Orders.interface';

interface IProps {
  order: IOrder;
}

const OrderColorCell: FC<IProps> = ({ order }) => {
  const { hasPermission } = useHasPermission();

  const style =
    order.isBlackBase &&
    hasPermission([Permissions.ADMIN, Permissions.EMPLOYEE])
      ? { backgtoundColor: 'black', color: 'white' }
      : {};

  return (
    <td style={style}>
      {order.color.toUpperCase()} {order.coloredWater && '(WB)'}
    </td>
  );
};

export default OrderColorCell;
