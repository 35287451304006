export const containsOneOf = (array, pattern) => {
  if (Array.isArray(pattern)) {
    let state = 0;
    pattern.forEach((item) => {
      if (array.includes(item)) state = 1;
    });
    return state === 1;
  }
  return false;
};
export const removeDuplicates = (array) =>
  array.reduce(
    (unique, item) => (unique.includes(item) ? unique : [...unique, item]),
    [],
  );

export const removeItemFromArr = (arr, value) =>
  arr.filter((item) => item !== value);

export const addUndefinedAtArrayBegin = (array) => {
  if (!array) return [undefined];
  return [undefined, ...array];
};
