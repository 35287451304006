import React, { useEffect, useState } from 'react';
import moment from 'moment';
import PageTemplate from 'PageTemplates/PageTemplate';
import { PageHeader } from 'components/header';
import FlexRow from 'PageTemplates/FlexRowTemplate';
import DatePicker from 'components/DatePicker';
import { getPaymentsByDate } from 'services/apiRequests/payments/get';
import { useDispatch, useSelector } from 'react-redux';
import { setSpinner } from 'services/store/actions/view';
import { useAlert } from 'react-alert';
import { getDataFromOrders } from './utils';
import { getGlobalSettings } from 'services/store/actions/settings';
import OrdersList from 'components/ordersList/static';
import { Table } from 'react-bootstrap';

const Payments = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const [orders, setOrders] = useState([]);
  const [data, setData] = useState(null);
  const [dates, setDates] = useState({
    from: new Date(moment().startOf('month')),
    to: new Date(moment().endOf('day')),
  });
  const paintSecondGroup = useSelector(
    (state) => state.settings.paintSecondGroup,
  );

  useEffect(() => {
    dispatch(
      getGlobalSettings(
        () => {},
        () => {
          alert.error('Błąd!');
        },
      ),
    );
  }, []);

  useEffect(() => {
    const getData = async () => {
      dispatch(setSpinner(true));
      await getPaymentsByDate(
        dates,
        (payments) => {
          setOrders(payments);
        },
        () => {
          alert.error('Błąd!');
        },
      );
      dispatch(setSpinner(false));
    };
    if (dates.from && dates.to) {
      getData();
    }
  }, [dates]);

  useEffect(() => {
    if (orders && paintSecondGroup) {
      const data = getDataFromOrders(orders, paintSecondGroup);
      setData(data);
    }
  }, [orders, paintSecondGroup]);

  const handleDate = (key, date) => setDates({ ...dates, [key]: date });
  return (
    <PageTemplate>
      <PageHeader
        title="Rozliczenia"
        extra={[
          <FlexRow key={1} justify="flex-start" buttonFormat={false}>
            <div>
              {' '}
              Od{' '}
              <DatePicker
                value={dates.from}
                onChange={(date) => handleDate('from', date)}
              />
            </div>
            <div style={{ marginRight: 50 }}>
              Do{' '}
              <DatePicker
                value={dates.to}
                onChange={(date) => handleDate('to', date)}
              />
            </div>
          </FlexRow>,
        ]}
      />
      <hr />
      <Table responsive="lg" bordered hover size="sm">
        <thead>
          <tr>
            <th>
              Cena <sup>[zł]</sup>
            </th>
            <th>
              Półmat/mat <sup>[m2]</sup>
            </th>
            <th>
              Połysk <sub>1gr</sub> <sup>[m2]</sup>
            </th>
            <th>
              Połysk <sub>2gr</sub> <sup>[m2]</sup>
            </th>
            <th>
              CNC <sup>[m2]</sup>
            </th>
          </tr>
        </thead>
        <tbody>
          {data && (
            <tr>
              <td>{data.price ? data.price.toFixed(2) : '-'}</td>
              <td>{data.semiGloss ? data.semiGloss.toFixed(2) : '-'}</td>
              <td>{data.gloss ? data.gloss.toFixed(2) : '-'}</td>
              <td>
                {data.glossSecondGroup
                  ? data.glossSecondGroup.toFixed(2)
                  : '-'}
              </td>
              <td>{data.cnc ? data.cnc.toFixed(2) : '-'}</td>
            </tr>
          )}
        </tbody>
      </Table>
      <hr />
      {orders && orders.length ? (
        <OrdersList
          orders={
            orders
              ? orders
                  .filter((order) => {
                    console.log(order);
                    if (order.order) return order;
                  })
                  .map(({ order }) => order)
              : []
          }
          listName="default"
          status
        />
      ) : null}
    </PageTemplate>
  );
};

export default Payments;
