export const getOrderPrice = (order, withoutAdvancePayment = false) => {
  let orderPrice = order.price;
  if (order.manHours && order.manHours.price)
    orderPrice += order.manHours.price;
  if (order.ventilations && order.ventilations.price)
    orderPrice += order.ventilations.price;

  if (withoutAdvancePayment && order.advancePayment && orderPrice)
    return (orderPrice - order.advancePayment).toFixed(2);
  return orderPrice ? orderPrice.toFixed(2) : 0;
};

export const isDeadlinePassed = (date) => {
  const currentDate = new Date();
  const deadline = new Date(date);
  return deadline < currentDate;
};

export const getOrderCustomerName = (order) => {
  const company = order.user ? order.user.company : '';
  const customerLetter =
    order.user && order.user.firstname ? order.user.firstname[0] : '';

  return `${company}${customerLetter ? '.' : ''}${customerLetter}`;
};

export const isOrderNeedsToBeSandedAgain = (order) => {
  const currentDate = Date.now();

  let timeFromGriding;
  if (order.veneer) {
    return false;
  }
  if (order.satatus && order.status.includes('lakiernia')) {
    if (order.lastOperation) {
      if (order.lastOperation.position.toLowerCase() === 'szlifiernia') {
        timeFromGriding = 43200000;
        const timeStop = new Date(order.lastOperation.date).getTime();
        if (currentDate - timeStop > timeFromGriding) {
          return true;
        }
      }
    }
  }
  return false;
};

export const isOrderWetForStaticList = (order) => {
  const currentDate = Date.now();

  let dryTime;
  if (order.status[0] !== 'zakończone') {
    if (order.paintType === 'połysk') {
      if (order.lastOperation) {
        if (order.lastOperation.position === 'lakiernia') {
          dryTime = 86400000;
          const timeStop = new Date(order.lastOperation.date).getTime();
          if (currentDate - timeStop < dryTime) {
            return true;
          }
        }
      }
    } else if (order.lastOperation) {
      if (order.lastOperation.position === 'podkład') {
        if (!order.isHalfGriding) {
          dryTime = 28800000;
          const timeStop = new Date(order.lastOperation.date).getTime();
          if (currentDate - timeStop < dryTime) {
            return true;
          }
        } else {
          dryTime = 7200000;
          const timeStop = new Date(order.lastOperation.date).getTime();
          if (currentDate - timeStop < dryTime) {
            return true;
          }
        }
      } else if (order.lastOperation.position === 'lakiernia') {
        dryTime = 18000000;
        const timeStop = new Date(order.lastOperation.date).getTime();
        if (currentDate - timeStop < dryTime) {
          return true;
        }
      }
    }
  }
  return false;
};

export const isOrderWetForPosition = (order, position) => {
  const currentDate = Date.now();
  let dryTime;
  if (order.status[0] !== 'zakończone') {
    if (position === 'szlifiernia') {
      if (order.lastOperation) {
        if (order.lastOperation.position === 'podkład') {
          if (!order.isHalfGriding) {
            dryTime = 28800000;
            const timeStop = new Date(order.lastOperation.date).getTime();
            if (currentDate - timeStop < dryTime) {
              return true;
            }
          } else {
            dryTime = 7200000;
            const timeStop = new Date(order.lastOperation.date).getTime();
            if (currentDate - timeStop < dryTime) {
              return true;
            }
          }
        } else if (order.lastOperation.position === 'lakiernia') {
          dryTime = 18000000;
          const timeStop = new Date(order.lastOperation.date).getTime();
          if (currentDate - timeStop < dryTime) {
            return true;
          }
        }
      }
    } else if (position === 'polernia') {
      if (order.lastOperation) {
        if (order.lastOperation.position === 'lakiernia') {
          dryTime = 86400000;
          const timeStop = new Date(order.lastOperation.date).getTime();
          if (currentDate - timeStop < dryTime) {
            return true;
          }
        }
      }
    } else if (position === 'pakowanie') {
      if (order.lastOperation) {
        if (order.lastOperation.position === 'lakiernia') {
          dryTime = 18000000;
          const timeStop = new Date(order.lastOperation.date).getTime();
          if (currentDate - timeStop < dryTime) {
            return true;
          }
        }
      }
    }
  }
  return false;
};

export const isLostItemsInOrder = (order, position = null) => {
  let result = false;
  if (order) {
    if (order.items && order.items.length) {
      const isLostItem = !position
        ? order.items.some(
            (item) =>
              item.elementLost.position && item.elementLost.quantity > 0,
          )
        : order.items.some(
            (item) =>
              item.elementLost.position &&
              item.elementLost.position.toLowerCase() ===
                position.toLowerCase() &&
              item.elementLost.quantity > 0,
          );
      if (isLostItem) result = true;
    }
  }
  return result;
};

export const isItemsCorrectedInOrder = (order, position = null) => {
  let result = false;
  if (order) {
    if (order.items && order.items.length) {
      const isItemCorrected = !position
        ? order.items.some(
            (item) =>
              item.elementToCorrect.position &&
              item.elementToCorrect.quantity > 0,
          )
        : order.items.some(
            (item) =>
              item.elementToCorrect.position &&
              item.elementToCorrect.position.toLowerCase() ===
                position.toLowerCase() &&
              item.elementToCorrect.quantity > 0,
          );
      if (isItemCorrected) result = true;
    }
  }
  return result;
};

export const getLostItemsOnPosition = (order, position) =>
  order.items.filter(
    (item) =>
      item.elementLost.position.toLowerCase() === position.toLowerCase(),
  );

export const getCorrectedElementsOnPosition = (order, position) =>
  order.items.filter(
    (item) =>
      item.elementToCorrect.position.toLowerCase() ===
      position.toLowerCase(),
  );
