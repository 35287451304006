import axios, { AxiosResponse } from 'axios';
import { IInvoice } from 'services/interfaces/invoices/Invoices.interface';
import setAuthToken from 'services/utils/setAuthToken';

export const invoicesApi = {
  getInvoice: async (
    invoiceId: string,
  ): Promise<AxiosResponse<IInvoice>> => {
    setAuthToken();
    return axios.get(`/api/transfers/${invoiceId}`);
  },
  updateInvoice: async (
    invoiceId: string,
    updateData: FormData,
  ): Promise<AxiosResponse<IInvoice>> => {
    setAuthToken();
    return axios.put(`/api/transfers/${invoiceId}`, updateData);
  },
  removeFile: async (
    invoiceId: string,
  ): Promise<AxiosResponse<IInvoice>> => {
    setAuthToken();
    return axios.put(`/api/transfers/${invoiceId}/remove-file`);
  },
};
