import Axios from 'axios';

export const customerAccountRemove = async (
  customerData,
  onEnd,
  onErr,
) => {
  try {
    await Axios.put(`/api/customers/remove/me`, customerData);

    onEnd();
  } catch (error) {
    console.log(error.response.data);
    if (error.response && error.response.status === 401)
      return onErr(error.response.data.message);
    return onErr();
  }
};

export const customerPermRemove = async (customerId, onEnd, onError) => {
  try {
    await Axios.delete(`/api/customers/remove/${customerId}`);

    onEnd();
  } catch (error) {
    console.log(error.response);
    onError();
  }
};
