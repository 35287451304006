import React, { useState } from 'react';
import { faSort } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { Table } from 'react-bootstrap';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { InvoicePaymentStatus } from 'services/enums/Invoices.enum';
import { IInvoice } from 'services/interfaces/invoices/Invoices.interface';
import { IUser } from 'services/interfaces/users/Users.interface';
import { invoicePaymentStatus } from 'services/utils/const';
import { getUserName } from 'services/utils/string';
import { isInvoiceTerminated } from './utils/isInvoiceTerminated';

const PaymentDateCell = styled.td`
  font-weight: bold;
  color: ${({ invoice }: { invoice: IInvoice }) =>
    isInvoiceTerminated(invoice) ? 'red' : 'black'};
`;

const PaymentStatusCell = styled.td`
  font-weight: bold;
  color: ${({ invoice }: { invoice: IInvoice }) =>
    invoice.status === InvoicePaymentStatus.NOT_PAID ? 'red' : 'green'};
`;

interface IProps {
  invoices: IInvoice[];
}

const InvoicesList: React.FC<IProps> = ({ invoices }) => {
  const [sortDirection, setSortDirection] = useState<string>('asc');
  const [list, setList] = useState<IInvoice[]>(invoices);

  const history = useHistory();
  const goToFV = (id: string) => history.push(`/fv/${id}`);

  const sortByDates = () => {
    if (!list.length) return;
    const newList = list.sort((a: IInvoice, b: IInvoice) => {
      const dateA = new Date(a.createdAt).getTime();
      const dateB = new Date(b.createdAt).getTime();

      if (sortDirection === 'asc') return dateA - dateB;
      return dateB - dateA;
    });
    setList(newList);
    setSortDirection((p) => (p === 'asc' ? 'desc' : 'asc'));
  };

  return (
    <div>
      <Table responsive="lg" bordered hover size="sm">
        <thead>
          <tr>
            <td>Lp.</td>
            <td>Klient</td>
            <td>Numer FV</td>
            <td style={{ cursor: 'pointer' }} onClick={sortByDates}>
              Data utworzenia <FontAwesomeIcon icon={faSort} />
            </td>
            <td>Data płatności</td>
            <td>Ilość zamówień</td>
            <td>Kwota</td>
            <td>Kwota brutto</td>
            <td>Status</td>
          </tr>
        </thead>
        <tbody>
          {list.map((fv, i) => (
            <tr
              style={{ cursor: 'pointer' }}
              key={fv._id}
              onClick={() => goToFV(fv._id)}
            >
              <td>{i + 1}</td>
              <td>{getUserName(fv.customer as IUser)}</td>
              <td>{fv.number.toUpperCase()}</td>
              <td>{moment(fv.createdAt).format('DD.MM.YYYY')}</td>
              <PaymentDateCell invoice={fv}>
                {moment(fv.paymentTo).format('DD.MM.YYYY')}
              </PaymentDateCell>
              <td>{fv.orders.length}</td>
              <td>{fv.price.toFixed(2)}</td>
              <td>{fv.priceGross?.toFixed(2)}</td>
              <PaymentStatusCell invoice={fv}>
                {fv.status.toUpperCase()}
              </PaymentStatusCell>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default InvoicesList;
