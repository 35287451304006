import axios, { AxiosResponse } from 'axios';
import { ISettings } from 'services/interfaces/settings/Settings.interface';
import setAuthToken from 'services/utils/setAuthToken';

// TODO Add endpoints to get specific field
export const settingsApi = {
  getSettings: async (): Promise<
    AxiosResponse<{ settings: ISettings }>
  > => {
    setAuthToken();
    return axios.get(`/api/settings`);
  },
};
