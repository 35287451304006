import React from 'react';

const Action = ({ order }) => {
  const { actions } = order;
  let action = '';
  if (actions) {
    if (actions.all) action = 'Wszystko';
    else if (actions.left) action = 'Lewe';
    else if (actions.corrections) action = 'Poprawki';
    else if (actions.losts) action = 'Braki';
  }
  return <td>{action}</td>;
};

export default Action;
