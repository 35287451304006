import { upperFirst } from 'lodash';
import { OrderPaymentStatus } from 'services/enums/Orders.enum';
import { IOrder } from 'services/interfaces/orders/Orders.interface';

export const payments = {
  getOrderPrice: (
    order: IOrder,
    excludeAdvancePayment: boolean = false,
  ): number => {
    let orderPrice = order.price;
    if (order.manHours && order.manHours.price)
      orderPrice += order.manHours.price;
    if (order.ventilations && order.ventilations.price)
      orderPrice += order.ventilations.price;

    if (excludeAdvancePayment && order.advancePayment && orderPrice)
      orderPrice = orderPrice - order.advancePayment;

    return orderPrice;
  },
  getPaymentsValue: (order: IOrder): number => {
    if (!order.payments) return 0;
    return order.payments.reduce((acc, payment) => {
      return (acc += payment);
    }, 0);
  },
  getPaymentStatus: (
    orderPrice: number,
    payments: number,
  ): OrderPaymentStatus => {
    if (payments < orderPrice) return OrderPaymentStatus.HALF_PAID;
    if (payments === orderPrice) return OrderPaymentStatus.PAID;
    return OrderPaymentStatus.NOT_PAID;
  },
  getOrderNameToFV: (order: IOrder) => {
    return `Elemety meblowe Zam. ${
      order.number
    } ${order.color.toUpperCase()} ${upperFirst(order.paintType)}`;
  },
};
