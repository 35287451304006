import axios, { AxiosResponse } from 'axios';
import { IOrder } from 'services/interfaces/orders/Orders.interface';
import setAuthToken from 'services/utils/setAuthToken';
import { updateOrder } from './orders/update';

export interface IGetOrdersParams {
  // FIXME to są statusty po przecinku, przekazywać tablicę
  status?: string;
  user?: string;
  isPaid?: boolean;
  // FIXME to są statusty po przecinku, przekazywać tablicę
  paymentStatus?: string;
}
type TOrderUpdateType = 'price';

export const ordersApi = {
  getOrder: async (
    id: string,
  ): Promise<AxiosResponse<{ order: IOrder }>> => {
    setAuthToken();
    return axios.get(`/api/orders/${id}`);
  },
  getOrdersWithParams: async (
    params: IGetOrdersParams,
  ): Promise<AxiosResponse<{ orders: IOrder[] }>> => {
    setAuthToken();
    return axios.get(`/api/orders`, { params });
  },
  getCustomerOrdersToInvoice: async (
    customerId: string,
    onlyWithoutInvoice = false,
  ): Promise<AxiosResponse<IOrder[]>> => {
    setAuthToken();
    return axios.get(`/api/orders/customer/${customerId}/to-fv`, {
      params: { onlyWithoutInvoice },
    });
  },

  updateOrder: async (
    toUpdate: TOrderUpdateType,
    orderId: string,
    body: Partial<IOrder>,
  ): Promise<AxiosResponse<{ order: IOrder }>> => {
    setAuthToken();
    const res = await axios.put(
      `/api/orders/${toUpdate}/${orderId}`,
      body,
    );
    sessionStorage.setItem('lastEditedOrder', orderId);
    return res;
  },

  updatePrice: async (orderId: string, body: { price: number }) => {
    return ordersApi.updateOrder('price', orderId, body);
  },
};
