import OrdersList from 'components/ordersList/OrdersList';
import React, { ChangeEvent, FC } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { IOrder } from 'services/interfaces/orders/Orders.interface';
import { parseStringToNumber } from 'services/utils/parseStringToNumber';
import OrdersToSettleList from './OrdersToSettleList';
import { ISettleOrderPayment } from './SettleOrders.modal';

interface IProps {
  value: number;
  setValue: (value: number) => void;
  info: string;
  setInfo: (info: string) => void;
  pickedOrders: ISettleOrderPayment[];
  errorMessage: string;
  updatePickedOrder: (order: IOrder) => void;
  updatePickedOrderPayment: (order: IOrder, value: string) => void;
}

const SettleOrdersStep: FC<IProps> = ({
  value,
  setValue,
  info,
  setInfo,
  pickedOrders,
  errorMessage,
  updatePickedOrder,
  updatePickedOrderPayment,
}) => {
  const handleValue = (e: ChangeEvent<HTMLInputElement>) => {
    const value = parseStringToNumber(e.target.value);
    setValue(value);
  };

  return (
    <div>
      <Row>
        <Col sm={3}>
          <div>
            <strong>Kwota wpłaty:</strong>
          </div>
          <Form.Control
            placeholder="Kwota wpłaty"
            onChange={handleValue}
            value={value}
          />
        </Col>
      </Row>
      <div>
        {errorMessage ? (
          <small style={{ color: 'red' }}>{errorMessage}</small>
        ) : (
          <small>
            Reszta:{' '}
            {(
              value -
              pickedOrders.reduce((acc, o) => (acc += o.payment), 0)
            ).toFixed(2)}
            zł
          </small>
        )}
      </div>

      <OrdersToSettleList
        orders={pickedOrders}
        setOrderPayment={updatePickedOrderPayment}
        updatePickedOrder={updatePickedOrder}
      />
      <Row>
        <Col sm={3}>
          <Form.Control
            type="text"
            placeholder="Dodatkowe informacje"
            value={info}
            onChange={(e) => setInfo(e.target.value)}
          />
        </Col>
      </Row>
    </div>
  );
};

export default SettleOrdersStep;
