import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import withContext from 'hoc/withContext';
import { dateToString } from 'services/utils/date';
import { Button, Row } from 'react-bootstrap';
import { firstLetterToUpperCase } from 'services/utils/string';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import {
  isLostItemsInOrder,
  isItemsCorrectedInOrder,
} from 'services/utils/orders';
import FlexRow from 'PageTemplates/FlexRowTemplate';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { ordersUtils } from 'services/utils/orders/orders.utils';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useAlert } from 'react-alert';

const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 500px;
  max-width: 95vw;
`;
const StyledStatus = styled.span`
  font-size: ${({ size }) => (size ? size : 20)}px;
`;
const BoldSpan = styled.span`
  font-weight: bold;
  color: ${({ isPaid }) => {
    if (isPaid === true) return 'green';
    if (isPaid === false) return 'red';
  }};
`;
const StyledButton = styled(Button)`
  padding: 2px 15px !important;
  margin: 0 !important;
`;

const Status = ({
  order,
  ordersInProduction,
  setModal,
  permissionContext,
}) => {
  const history = useHistory();
  const [isWarning, setIsWarning] = useState(false);

  const alert = useAlert();
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    setIsWarning(false);
    const itemsStatuses = order.items.reduce((acc, item) => {
      const { elementLost, elementToCorrect } = item;
      if (elementLost.position && !acc.includes(elementLost.position))
        return acc.concat(elementLost.position);
      if (
        elementToCorrect.position &&
        !acc.includes(elementToCorrect.position)
      )
        return acc.concat(elementToCorrect.position);
      return acc;
    }, []);
    itemsStatuses.forEach((item) =>
      !order.status.includes(item) ? setIsWarning(true) : null,
    );
  }, [order, order.items, order.status]);

  const goToFV = (id) => history.push(`/fv/${id}`);

  const getFvName = () => {
    if (order) {
      return ordersUtils.payments.getOrderNameToFV(order);
    }
    return '';
  };

  const showOnCopyMessage = () => {
    if (order) {
      const name = ordersUtils.payments.getOrderNameToFV(order);
      return alert.success(`Skopiowano: ${name}`);
    }
    return null;
  };

  return (
    <>
      <StyledWrapper>
        {permissionContext === 'admin' ? (
          <div style={{ textAlign: 'left' }}>
            <div>
              <StyledStatus size={15}>
                Status płatności:{' '}
                <BoldSpan>{order.paymentStatus}</BoldSpan>
              </StyledStatus>
            </div>
            {order.fv && (
              <div>
                <StyledStatus size={15}>
                  FV: <BoldSpan>{order.fv.number.toUpperCase()}</BoldSpan>
                </StyledStatus>
                <div>
                  <Button
                    size="sm"
                    variant="outline-primary"
                    block
                    style={{ margin: 0, padding: '1px 15px' }}
                    onClick={() => goToFV(order.fv._id)}
                  >
                    Zobacz FV
                  </Button>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div />
        )}{' '}
        <div style={{ textAlign: 'right' }}>
          <div>
            <StyledStatus>
              {permissionContext === 'admin' && isWarning && (
                <FontAwesomeIcon
                  icon={faExclamationCircle}
                  title="Zamówienie nie zostanie obsłużone prawidłowo!"
                  style={{ color: 'red' }}
                />
              )}{' '}
              Status:{' '}
              {order.status.map((item) => (
                <BoldSpan key={item} className={item}>
                  {firstLetterToUpperCase(item)}{' '}
                </BoldSpan>
              ))}
            </StyledStatus>
          </div>
          {order.pickUpDate && (
            <div>
              <StyledStatus size={18}>
                Data odbioru:{' '}
                <BoldSpan>{dateToString(order.pickUpDate)}</BoldSpan>
              </StyledStatus>
            </div>
          )}
          {permissionContext !== 'user' && (
            <div>
              <StyledStatus size={17}>
                Lakier:{' '}
                <BoldSpan>
                  {order.paintOrderStatus === 0
                    ? 'Nie zamówiony'
                    : order.paintOrderStatus === 1
                    ? 'Zamówiony'
                    : order.paintOrderStatus === 2
                    ? 'Odebrany'
                    : '?'}
                </BoldSpan>
              </StyledStatus>
            </div>
          )}
          <div style={{ textAlign: 'right' }}>
            {isLostItemsInOrder(order) && (
              <div>
                <small style={{ fontWeight: 'bold', color: 'red' }}>
                  Zamówienie nie jest kompletne
                </small>
              </div>
            )}
            {isItemsCorrectedInOrder(order) && (
              <div>
                <small style={{ fontWeight: 'bold', color: 'orange' }}>
                  Zamówienie zawiera elementy do poprawy
                </small>
              </div>
            )}
          </div>
        </div>
      </StyledWrapper>{' '}
      <hr />
      <FlexRow justify="space-between">
        <StyledButton
          size="sm"
          // block
          onClick={() => setModal('_ordersInProduction')}
          disabled={!ordersInProduction.length}
        >
          Zamówienia na produkcji
        </StyledButton>
        {!!user && !!user.isBoss && (
          <CopyToClipboard
            text={getFvName()}
            onCopy={() => showOnCopyMessage()}
          >
            <StyledButton size="sm" variant="outline-primary">
              Kopiuj nazwę do fv
            </StyledButton>
          </CopyToClipboard>
        )}
      </FlexRow>
    </>
  );
};

export default withContext(Status);
