import React from 'react';
import Modal from 'PageTemplates/ModalTemplate';
import { useDispatch } from 'react-redux';
import { useAlert } from 'react-alert';
import { setSpinner } from 'services/store/actions/view';
import { updateOrder } from 'services/apiRequests/orders/update';
import { setOrder } from 'services/store/actions/orders';

const ReadyToPickUp = ({ order, closeModal }) => {
  const dispatch = useDispatch();
  const alert = useAlert();

  const handleSubmit = async () => {
    dispatch(setSpinner(true));
    await updateOrder(
      'readytopickup',
      order._id,
      {},
      (order) => {
        dispatch(setSpinner(false));
        closeModal();
        dispatch(setOrder(order));
      },
      () => {
        dispatch(setSpinner(false));
        alert.error('Błąd!');
      },
    );
  };

  return (
    <Modal
      title="Gotowe do odbioru"
      closeModal={closeModal}
      onOk={handleSubmit}
    >
      <small>Klient zostanie poinformowany przy pomocy e-mail.</small>
    </Modal>
  );
};

export default ReadyToPickUp;
