import update from 'immutability-helper';
import { orderCustomItemTemplate, orderItemTemplate } from './const';

// Adding order item fields depends on whole orders properties
export const addOrderItem = (state) => {
  const { millings, veneer, paintType, paintStyle, orderType } = state;
  let pEdge = '-';
  let type = 'gładki';
  let paintLeft = false;
  let blowMaterial = true;
  let thickness = 19;

  if (!veneer) {
    if (paintType === 'połysk') pEdge = 'r2';
    else pEdge = 'r1';
  }
  if (millings.length && !millings.includes('ryflowany')) {
    if (millings[0].toLowerCase() === 'gładki cnc') type = 'gładki';
    else {
      type = 'frez';
      thickness = 19;
    }
  }
  if (
    paintStyle === 'dwustronne' ||
    paintStyle === 'prawa - połysk / lewa - półmat'
  )
    paintLeft = true;
  if (
    [
      'materiał klienta',
      'poprawa (wina klienta)',
      'reklamacja (wina blow)',
    ].includes(orderType.toLowerCase())
  )
    blowMaterial = false;

  const item = {
    ...orderItemTemplate,
    type,
    w1P: pEdge,
    w2P: pEdge,
    h1P: pEdge,
    h2P: pEdge,
    paintLeft,
    blowMaterial,
    thickness,
  };

  return { ...state, items: [...state.items, item] };
};

// Adding order item fields depends on whole orders properties
export const addNewOrderCustomItem = (state) => {
  const { pilaster, crown, paintStyle, orderType } = state;
  let type = '';
  let paintLeft = false;
  let blowMaterial = true;

  if (pilaster && !crown) type = 'pilaster';
  else if (!pilaster && crown) {
    type = 'korona';
    blowMaterial = false;
  } else if (pilaster && crown) type = 'pilaster';

  if (
    paintStyle === 'dwustronne' ||
    paintStyle === 'prawa - połysk / lewa - półmat'
  )
    paintLeft = true;
  if (
    [
      'materiał klienta',
      'poprawa (wina klienta)',
      'reklamacja (wina blow)',
    ].includes(orderType.toLowerCase())
  )
    blowMaterial = false;

  const customItem = {
    ...orderCustomItemTemplate,
    type,
    paintLeft,
    blowMaterial,
  };

  return { ...state, customItems: [...state.customItems, customItem] };
};

export const removeOrderItem = (state, index) => {
  const items = state.items.filter((item, i) => i !== index);

  return { ...state, items };
};

export const removeOrderCustomItem = (state, index) => {
  const customItems = state.customItems.filter((item, i) => i !== index);

  return { ...state, customItems };
};

export const handleInput = (state, index, field, value, isNumber) => {
  if (field === 'hLPaintedEdge') {
    let newh2LEdge = state.items[index].h2LEdge;
    if (value) {
      newh2LEdge = state.paintType === 'połysk' ? 'r2' : 'r1';
    } else {
      newh2LEdge = '-';
    }
    return update(state, {
      items: {
        [index]: {
          [field]: { $set: value },
          h2L: { $set: newh2LEdge },
        },
      },
    });
  }
  if (
    field === 'transparentBothSide' ||
    field === 'coloredWaterBothSide'
  ) {
    if (value) {
      return update(state, {
        items: {
          [index]: {
            [field]: { $set: value },
            paintLeft: { $set: value },
            paintRight: { $set: value },
          },
        },
      });
    }
  }
  if (field === 'paintLeft' || field === 'paintRight') {
    if (!value) {
      return update(state, {
        items: {
          [index]: {
            [field]: { $set: value },
            transparentBothSide: { $set: value },
            coloredWaterBothSide: { $set: value },
          },
        },
      });
    }
  }
  if (field === 'underGlass') {
    let comment = state.items[index].comments;
    if (value) comment = `${comment} +podfrezowanie`;
    else comment = comment.replace('+podfrezowanie', '');

    return update(state, {
      items: {
        [index]: {
          [field]: { $set: value },
          comments: { $set: comment },
        },
      },
    });
  }

  if (field === 'type' && !['frez', 'witryna'].includes(value)) {
    const comment = state.items[index].comments.replace(
      '+podfrezowanie',
      ' ',
    );
    return update(state, {
      items: {
        [index]: {
          [field]: { $set: value },
          muntinX: { $set: 0 },
          muntinH: { $set: 0 },
          muntinW: { $set: 0 },
          underGlass: { $set: false },
          comments: {
            $set: comment,
          },
        },
      },
    });
  }

  const numbers = /^(\s*|\d+)$/;
  if (isNumber) {
    if (!value.match(numbers)) return { ...state };
    if (!value) value = 0;
    value = parseInt(value, 10);
  }

  return update(state, {
    items: { [index]: { [field]: { $set: value } } },
  });
};

export const handleCustomItemInput = (
  state,
  index,
  field,
  value,
  isNumber,
) => {
  if (field === 'type') {
    if (value === 'korona')
      return update(state, {
        customItems: {
          [index]: {
            [field]: { $set: value },
            blowMaterial: { $set: false },
          },
        },
      });
    else if (value === 'pilaster')
      return update(state, {
        customItems: {
          [index]: {
            [field]: { $set: value },
          },
        },
      });
  }

  const numbers = /^(\s*|\d+)$/;
  if (isNumber) {
    if (!value.match(numbers)) return { ...state };
    if (!value) value = 0;
    value = parseInt(value, 10);
  }

  return update(state, {
    customItems: { [index]: { [field]: { $set: value } } },
  });
};

export const addItemImage = (state, index, file) => {
  const filename = file.name.split('/').join('_');
  const items = state.items.map((item, i) => {
    if (i === index) {
      item.image = { ...item.image, file, path: filename };
      item.comments = `${item.comments} rysunek`;
    }
    return item;
  });
  return { ...state, items };
};

export const addCustomItemImage = (state, index, file) => {
  const filename = file.name.split('/').join('_');
  const customItems = state.customItems.map((item, i) => {
    if (i === index) {
      item.image = { ...item.image, file, path: filename };
      item.comments = `${item.comments} rysunek`;
    }
    return item;
  });
  return { ...state, customItems };
};

export const removeItemImage = (state, index) => {
  const items = state.items.map((item, i) => {
    if (i === index) {
      item.image = { path: '', file: null };
      item.comments = item.comments.replace('rysunek', '');
    }

    return item;
  });

  return { ...state, items };
};

export const removeCustomItemImage = (state, index) => {
  const customItems = state.customItems.map((item, i) => {
    if (i === index) {
      item.image = { path: '', file: null };
      item.comments = item.comments.replace('rysunek', '');
    }

    return item;
  });

  return { ...state, customItems };
};

export const importDataFromFile = (state, items) => {
  const newItems = [];

  items.forEach((item) => {
    const { millings, veneer, paintType, paintStyle, orderType } = state;
    let pEdge = '-';
    let type = 'gładki';
    let paintLeft = false;
    let blowMaterial = true;

    if (!veneer) {
      if (paintType === 'połysk') pEdge = 'r2';
      else pEdge = 'r1';
    }
    if (millings.length && !millings.includes('ryflowany')) type = 'frez';
    if (
      paintStyle === 'dwustronne' ||
      paintStyle === 'prawa - połysk / lewa - półmat'
    )
      paintLeft = true;
    if (
      [
        'materiał klienta',
        'poprawa (wina klienta)',
        'reklamacja (wina blow)',
      ].includes(orderType.toLowerCase())
    )
      blowMaterial = false;

    let thicknessOptions = [
      3, 6, 8, 10, 12, 16, 18, 19, 22, 25, 28, 30, 38,
    ];
    if (type === 'frez') thicknessOptions = [18, 19, 22, 25, 28, 30, 38];

    const itemObj = {
      ...orderItemTemplate,
      height: item[4],
      width: item[9],
      quantity: item[15] || 1,
      comments: item[1] || '',
      type,
      w1P: pEdge,
      w2P: pEdge,
      h1P: pEdge,
      h2P: pEdge,
      paintLeft,
      blowMaterial,
    };
    if (item[14] && thicknessOptions.includes(item[14])) {
      itemObj.thickness = item[14];
    }

    if (
      item[4] &&
      item[9] &&
      typeof item[4] === 'number' &&
      typeof item[9] === 'number' &&
      typeof item[15] === 'number'
    )
      newItems.push(itemObj);
  });

  return { ...state, items: newItems };
};

// HELPERS
export const handleItemEdges = (right, left, item, callback) => {
  const rightEdges = ['h1P', 'h2P', 'w1P', 'w2P'];
  const leftEdges = ['h1L', 'h2L', 'w1L', 'w2L'];

  if (right)
    rightEdges.forEach((edgeName) => callback(item[edgeName], edgeName));
  if (left)
    leftEdges.forEach((edgeName) => callback(item[edgeName], edgeName));
};
