import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { darken } from 'polished';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlay,
  faEdit,
  faStop,
  faPause,
  faCheck,
  faFileAlt,
  faStickyNote,
  faBinoculars,
  faReply,
  faDownload,
  faCalendar,
} from '@fortawesome/free-solid-svg-icons';
import { useAlert } from 'react-alert';
import { setSpinner } from 'services/store/actions/view';
import { updateOrder } from 'services/apiRequests/orders/update';
import { useHistory } from 'react-router';

const StyledButton = styled(Button)`
  background-color: ${({ theme }) => theme.blowPurple};
  border-color: ${({ theme }) => theme.blowPurple};
  &:hover {
    background-color: ${({ theme }) => darken(0.2, theme.blowPurple)};
    border-color: ${({ theme }) => theme.blowPurple};
  }
`;

const EmployeeButtons = ({ order, setModal, getFiles, getLabel }) => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const history = useHistory();

  const position = useSelector((state) => state.employee.activePosition);
  const user = useSelector((state) => state.auth.user);

  const handleOrderDeliver = async () => {
    dispatch(setSpinner(true));
    await updateOrder(
      'deliver',
      order._id,
      {},
      () => {
        dispatch(setSpinner(false));
        alert.success('Zamówienie dostarczone');
        history.push('/');
      },
      () => {
        dispatch(setSpinner(false));
        alert.error('Błąd!');
      },
    );
  };

  return (
    <div>
      {position && position !== 'transport' && (
        <>
          {user && user.isManager && (
            <Button
              variant="primary"
              onClick={() => setModal('_planOrder')}
              disabled={order.planedByAdmin}
            >
              <FontAwesomeIcon icon={faCalendar} /> Zaplanuj zamówienie
            </Button>
          )}
          {position === 'surówka' && (
            <Button
              variant="info"
              onClick={() => setModal('_backFromCNC')}
              disabled={order.dateBackFromCNC}
            >
              <FontAwesomeIcon icon={faEdit} /> Zatwierdź odbiór CNC
            </Button>
          )}
          {position === 'surówka' && (
            <Button onClick={() => setModal('_changeDimensions')}>
              <FontAwesomeIcon icon={faEdit} /> Zmień wymiary
            </Button>
          )}
          <Button
            disabled={!order.status.includes(position)}
            variant="danger"
            onClick={() => setModal('_elementsLost')}
          >
            <FontAwesomeIcon icon={faBinoculars} /> Elementy brakujące
          </Button>
          <Button
            disabled={!order.status.includes(position)}
            variant="warning"
            onClick={() => setModal('_elementsToCorrect')}
          >
            <FontAwesomeIcon icon={faReply} /> Elementy do poprawy
          </Button>
          {order.inProduction.includes(position) && (
            <Button
              variant="outline-success"
              onClick={() => setModal('_pauseOrder')}
            >
              <FontAwesomeIcon icon={faPause} /> Pauza
            </Button>
          )}
          <Button
            variant="success"
            onClick={() =>
              order.inProduction.includes(position)
                ? setModal('_stopOrder')
                : setModal('_startOrder')
            }
            disabled={!order.status.includes(position)}
          >
            {order.inProduction.includes(position) ? (
              <>
                <FontAwesomeIcon icon={faStop} /> Zakończ
              </>
            ) : (
              <>
                <FontAwesomeIcon icon={faPlay} /> Rozpocznij
              </>
            )}
          </Button>
          {position === 'pakowanie' &&
            order.status.length === 1 &&
            order.status[0] === 'zakończone' &&
            !order.isReadyToPickUp && (
              <StyledButton onClick={() => setModal('_readyToPickUp')}>
                <FontAwesomeIcon icon={faCheck} /> Gotowe do odbioru
              </StyledButton>
            )}
          {position === 'pakowanie' && (
            <Button variant="dark" onClick={getFiles}>
              <FontAwesomeIcon icon={faDownload} /> Pobierz
            </Button>
          )}
          {position === 'pakowanie' && (
            <Button variant="outline-dark" onClick={getLabel}>
              <FontAwesomeIcon icon={faStickyNote} /> Pobierz naklejkę
            </Button>
          )}
          {position === 'pakowanie' &&
            order.status.includes('zakończone') && (
              <Button
                variant="primary"
                onClick={() => setModal('_getPickUpReport')}
              >
                <FontAwesomeIcon icon={faFileAlt} /> Protokół odbioru
              </Button>
            )}
          <Button
            variant="secondary"
            onClick={() => setModal(`_addComment`)}
          >
            <FontAwesomeIcon icon={faEdit} /> Dodaj komentarz
          </Button>
        </>
      )}
      {position && position === 'transport' && (
        <Button onClick={() => handleOrderDeliver(position)}>
          Zamówienie dostarczone
        </Button>
      )}
    </div>
  );
};

export default EmployeeButtons;
