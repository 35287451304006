import React from 'react';
import { Form, Table } from 'react-bootstrap';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const Th = styled.th`
  width: 20px;
`;
const TightColumn = styled.th`
  width: 65px;
  background-color: ${({ side, theme }) => {
    if (side === 'left') return theme.blowWarning;
    if (side === 'right') return theme.blowGreen;
    return null;
  }};
`;
const PrimaryColumn = styled.td`
  vertical-align: middle;
  background-color: rgba(205, 231, 169, 0.5);
`;
const SecondaryColumn = styled.td`
  vertical-align: middle;
  background-color: rgba(252, 215, 160, 0.5);
`;

const ItemsList = (props) => {
  const { items } = props;
  return (
    <Table responsive="lg" hover bordered>
      <thead>
        <tr>
          <Th>Zostaw</Th>
          <Th>LP</Th>
          <TightColumn>Wys.</TightColumn>
          <TightColumn>Szer.</TightColumn>
          <TightColumn side="right">P</TightColumn>
          <TightColumn side="left">L</TightColumn>
          <Th>Ilość</Th>
          {/* <TightColumn>Ilość braków</TightColumn> */}
        </tr>
      </thead>
      <tbody>
        {items.map((item, index) => {
          return (
            <TableRow
              key={item._id}
              item={item}
              index={index}
              {...props}
            />
          );
        })}
      </tbody>
    </Table>
  );
};

export default ItemsList;

const TableRow = ({ item, index, checkedList, handleCheck }) => {
  return (
    <tr>
      <td>
        <Form.Check
          id={item._id}
          checked={checkedList.includes(item._id)}
          key={item._id}
          value={item._id}
          type="checkbox"
          onChange={handleCheck}
        />
      </td>
      <td>{index + 1}</td>
      <td>{item.height}</td>
      <td>{item.width}</td>
      <PrimaryColumn>
        {item.paintRight ? <FontAwesomeIcon icon={faCheck} /> : ''}
      </PrimaryColumn>
      <SecondaryColumn>
        {item.paintLeft ? <FontAwesomeIcon icon={faCheck} /> : ''}
      </SecondaryColumn>
      <td>{item.quantity}</td>
      {/* <td>{item.elementLost.quantity}</td> */}
    </tr>
  );
};
