import * as yup from 'yup';

export const schema = yup.object({
  paint: yup.object().shape({
    companyMaterial: yup.object().shape({
      gloss: yup.object().shape({
        oneSide: yup
          .number()
          .typeError('wartość musi być liczbą')
          .positive('wartość nie może być ujemna')
          .required('wymagane'),
        twoSides: yup
          .number()
          .typeError('wartość musi być liczbą')
          .positive('wartość nie może być ujemna')
          .required('wymagane'),
      }),
      semiGloss: yup.object().shape({
        oneSide: yup
          .number()
          .typeError('wartość musi być liczbą')
          .positive('wartość nie może być ujemna')
          .required('wymagane'),
        groovedOneSide: yup
          .number()
          .typeError('wartość musi być liczbą')
          .positive('wartość nie może być ujemna')
          .required('wymagane'),
      }),
      cnc: yup.object().shape({
        1: yup
          .number()
          .typeError('wartość musi być liczbą')
          .positive('wartość nie może być ujemna')
          .required('wymagane'),
        2: yup
          .number()
          .typeError('wartość musi być liczbą')
          .positive('wartość nie może być ujemna')
          .required('wymagane'),
        3: yup
          .number()
          .typeError('wartość musi być liczbą')
          .positive('wartość nie może być ujemna')
          .required('wymagane'),
        4: yup
          .number()
          .typeError('wartość musi być liczbą')
          .positive('wartość nie może być ujemna')
          .required('wymagane'),
        flat: yup
          .number()
          .typeError('wartość musi być liczbą')
          .positive('wartość nie może być ujemna')
          .required('wymagane'),
      }),
      glassCase: yup.object().shape({
        1: yup
          .number()
          .typeError('wartość musi być liczbą')
          .positive('wartość nie może być ujemna')
          .required('wymagane'),
        2: yup
          .number()
          .typeError('wartość musi być liczbą')
          .positive('wartość nie może być ujemna')
          .required('wymagane'),
        3: yup
          .number()
          .typeError('wartość musi być liczbą')
          .positive('wartość nie może być ujemna')
          .required('wymagane'),
        4: yup
          .number()
          .typeError('wartość musi być liczbą')
          .positive('wartość nie może być ujemna')
          .required('wymagane'),
      }),
      longElement: yup
        .number()
        .typeError('wartość musi być liczbą')
        .positive('wartość nie może być ujemna')
        .required('wymagane'),
      leftSide: yup
        .number()
        .typeError('wartość musi być liczbą')
        .positive('wartość nie może być ujemna')
        .required('wymagane'),
    }),

    customerMaterial: yup.object().shape({
      gloss: yup.object().shape({
        oneSide: yup
          .number()
          .typeError('wartość musi być liczbą')
          .positive('wartość nie może być ujemna')
          .required('wymagane'),
        twoSides: yup
          .number()
          .typeError('wartość musi być liczbą')
          .positive('wartość nie może być ujemna')
          .required('wymagane'),
      }),
      semiGloss: yup.object().shape({
        oneSide: yup
          .number()
          .typeError('wartość musi być liczbą')
          .positive('wartość nie może być ujemna')
          .required('wymagane'),
        groovedOneSide: yup
          .number()
          .typeError('wartość musi być liczbą')
          .positive('wartość nie może być ujemna')
          .required('wymagane'),
      }),
    }),
    veneer: yup.object().shape({
      mordantOneSide: yup
        .number()
        .typeError('wartość musi być liczbą')
        .positive('wartość nie może być ujemna')
        .required('wymagane'),
      mordantTwoSides: yup
        .number()
        .typeError('wartość musi być liczbą')
        .positive('wartość nie może być ujemna')
        .required('wymagane'),
      colorlessOneSide: yup
        .number()
        .typeError('wartość musi być liczbą')
        .positive('wartość nie może być ujemna')
        .required('wymagane'),
      colorlessTwoSides: yup
        .number()
        .typeError('wartość musi być liczbą')
        .positive('wartość nie może być ujemna')
        .required('wymagane'),
      groovedColorlessOneSide: yup
        .number()
        .typeError('wartość musi być liczbą')
        .positive('wartość nie może być ujemna')
        .required('wymagane'),
      groovedColorlessTwoSides: yup
        .number()
        .typeError('wartość musi być liczbą')
        .positive('wartość nie może być ujemna')
        .required('wymagane'),
    }),
    transparent: yup.object().shape({
      oneSide: yup
        .number()
        .typeError('wartość musi być liczbą')
        .positive('wartość nie może być ujemna')
        .required('wymagane'),
      twoSides: yup
        .number()
        .typeError('wartość musi być liczbą')
        .positive('wartość nie może być ujemna')
        .required('wymagane'),
    }),
    glossSecondGroup: yup
      .number()
      .typeError('wartość musi być liczbą')
      .positive('wartość nie może być ujemna')
      .required('wymagane'),
    extraColor: yup
      .number()
      .typeError('wartość musi być liczbą')
      .positive('wartość nie może być ujemna')
      .required('wymagane'),
    muntin: yup
      .number()
      .typeError('wartość musi być liczbą')
      .positive('wartość nie może być ujemna')
      .required('wymagane'),
  }),
  bold: yup.object().shape({
    22: yup
      .number()
      .typeError('wartość musi być liczbą')
      .positive('wartość nie może być ujemna')
      .required('wymagane'),
    25: yup
      .number()
      .typeError('wartość musi być liczbą')
      .positive('wartość nie może być ujemna')
      .required('wymagane'),
    28: yup
      .number()
      .typeError('wartość musi być liczbą')
      .positive('wartość nie może być ujemna')
      .required('wymagane'),
    30: yup
      .number()
      .typeError('wartość musi być liczbą')
      .positive('wartość nie może być ujemna')
      .required('wymagane'),
    38: yup
      .number()
      .typeError('wartość musi być liczbą')
      .positive('wartość nie może być ujemna')
      .required('wymagane'),
  }),
  services: yup.object().shape({
    chamfering: yup
      .number()
      .typeError('wartość musi być liczbą')
      .positive('wartość nie może być ujemna')
      .required('wymagane'),
    backMilling: yup
      .number()
      .typeError('wartość musi być liczbą')
      .positive('wartość nie może być ujemna')
      .required('wymagane'),
    manHour: yup
      .number()
      .typeError('wartość musi być liczbą')
      .positive('wartość nie może być ujemna')
      .required('wymagane'),
    ventilations: yup
      .number()
      .typeError('wartość musi być liczbą')
      .positive('wartość nie może być ujemna')
      .required('wymagane'),
    hingeHole: yup
      .number()
      .typeError('wartość musi być liczbą')
      .positive('wartość nie może być ujemna')
      .required('wymagane'),
    handle: yup
      .number()
      .typeError('wartość musi być liczbą')
      .positive('wartość nie może być ujemna')
      .required('wymagane'),
    partHandle: yup
      .number()
      .typeError('wartość musi być liczbą')
      .positive('wartość nie może być ujemna')
      .required('wymagane'),
    paintHandle: yup
      .number()
      .typeError('wartość musi być liczbą')
      .positive('wartość nie może być ujemna')
      .required('wymagane'),
    paintPartHandle: yup
      .number()
      .typeError('wartość musi być liczbą')
      .positive('wartość nie może być ujemna')
      .required('wymagane'),
    millingHandle: yup
      .number()
      .typeError('wartość musi być liczbą')
      .positive('wartość nie może być ujemna')
      .required('wymagane'),
  }),
});
