// export const domain = `http://localhost:5000`;
export const domain = `https://hurt.zamowfronty.pl`;

export const veneerHandles = [
  'uk45',
  'p45',
  'up45',
  'UK45',
  'UP45',
  'P45',
];
export const veneerExcludedHandles = ['up', 'uc', 'uk', 'UP', 'UC', 'UK'];

export const veneerMillings = ['ryflowany'];

export const statsPositions = [
  'blow',
  'nowe',
  'surówka',
  'podkład',
  'szlifiernia',
  'lakiernia',
  'polernia',
  'pakowanie',
  'odebrane',
];

export const orderStatuses = [
  'piła',
  'obróbka',
  'surówka',
  'podkład',
  'szlifiernia',
  'lakiernia',
  'polernia',
  'pakowanie',
  'zakończone',
  'odebrane',
];

export const productionStatuses = [
  'piła',
  'obróbka',
  'surówka',
  'podkład',
  'szlifiernia',
  'lakiernia',
  'polernia',
  'pakowanie',
  'zakończone',
];

export const employeePositions = [
  'piła',
  'obróbka',
  'surówka',
  'podkład',
  'szlifiernia',
  'lakiernia',
  'polernia',
  'pakowanie',
  'transport',
];
// CASH REGISTER
export const cashRegisterStoryType = {
  IN: 'wpłata',
  OUT: 'wypłata',
};
// ---
export const cuttingPackageStatus = {
  PENDING: 'oczekuje',
  IN_PROGRESS: 'w trakcie',
  NOT_COMPLETE: 'nie kompletne',
  READY: 'gotowy',
};
export const orderItemCuttingStatus = {
  TO_CUT: 'do wycięcia',
  SUSPENDED: 'wstrzymany',
  SKIPPED: 'pominięty',
  READY: 'gotowy',
};
export const orderItemCuttingStatuses = [
  'do wycięcia',
  'wstrzymany',
  'pominięty',
  'gotowy',
];
export const invoicePaymentStatus = {
  PAID: 'opłacona',
  NOT_PAID: 'nieopłacona',
};
export const orderPaymentStatus = {
  PAID: 'Opłacone',
  HALF_PAID: 'Opłacone częściowo',
  NOT_PAID: 'Nieopłacone',
  TRANSFER: 'Przelew',
};
export const orderPaymentsStatuses = [
  'Opłacone',
  'Opłacone częściowo',
  'Przelew',
  'Nieopłacone',
];

export const paintTypes = ['półmat', 'mat', 'połysk'];
export const paintStyled = [
  'jednostronne',
  'dwustronne',
  'prawa - połysk / lewa - półmat',
];

export const orderTypes = [
  'Reklamacja (wina BLOW)',
  'Poprawa (wina klienta)',
  'Domówienie',
  'Materiał klienta',
  'Lista elementów',
];

export const weekDays = [
  'niedziela',
  'poniedziałek',
  'wtorek',
  'środa',
  'czwartek',
  'piątek',
  'sobota',
];

export const months = [
  'styczeń',
  'luty',
  'marzec',
  'kwiecień',
  'maj',
  'czerwiec',
  'lipiec',
  'sierpień',
  'wrzesień',
  'październik',
  'listopad',
  'grudzień',
];

export const reasonsOfComplaint = [
  'paproch',
  'uszkodzenie mechaniczne',
  'uszkodzony laminat',
  'zaciek',
  'element źle polakierowany',
  'niepolakierowana druga strona',
  'przetarcie',
  'zły wymiar',
  'inny',
];
