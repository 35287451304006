import { upperFirst } from 'lodash';
import React, { FC } from 'react';
import { OrderPaintType } from 'services/enums/Orders.enum';
import { IOrder } from 'services/interfaces/orders/Orders.interface';

interface IProps {
  order: IOrder;
}

const OrderPaintTypeCell: FC<IProps> = ({ order }) => {
  const style =
    order.paintType === OrderPaintType.GLOSS
      ? { backgroundColor: '#0080FF' }
      : {};

  return (
    <td style={style}>
      {upperFirst(order.paintType)} {order.tranparent && '(+B)'}
    </td>
  );
};

export default OrderPaintTypeCell;
