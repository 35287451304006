import { StyledH3, StyledH6 } from 'components/header';
import OrdersList from 'components/ordersList/static';
import React from 'react';
import ItemsList from './ItemsList';
import FlexRow from 'PageTemplates/FlexRowTemplate';

const CuttingPackageOrder = ({ order }) => {
  return (
    <div>
      <FlexRow justify="space-between">
        <StyledH3>
          {order.user.company} - {order.user.firstname} nr. {order.number}
        </StyledH3>
      </FlexRow>
      <OrdersList orders={[order]} listName="new" />
      <StyledH6>Lista elementów</StyledH6>
      <ItemsList order={order} />
      <hr style={{ marginBottom: 50, marginTop: 30 }} />
    </div>
  );
};

export default CuttingPackageOrder;
