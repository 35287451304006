import React from 'react';
import styled from 'styled-components';
import { Form } from 'react-bootstrap';
import { Field, getIn } from 'formik';

const StyledWrapper = styled(Form.Group)`
  margin: 5px 30px 5px 0;
  display: flex;
  input {
    width: 300px;
  }
  label {
    width: 270px;
  }
  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 5px;
  }
  @media (max-width: 600px) {
    margin: 5px auto;
    flex-direction: column;
    text-align: center;
    input {
      width: 100%;
    }
    label {
      width: 100%;
    }
  }
`;

const TextInput = ({
  name,
  label,
  placeholder,
  required,
  disabled,
  type = 'text',
}) => {
  return (
    <StyledWrapper controlId={`form${name}`}>
      <Field name={name}>
        {(props) => {
          const {
            field,
            form: { touched, errors },
          } = props;
          return (
            <>
              {label ? <Form.Label>{label}</Form.Label> : null}
              <div>
                <Form.Control
                  {...field}
                  disabled={disabled}
                  required={required}
                  type={type}
                  isValid={getIn(touched, name) && !getIn(errors, name)}
                  placeholder={placeholder}
                  className={
                    getIn(errors, name) &&
                    getIn(touched, name) &&
                    'is-invalid'
                  }
                />{' '}
                {getIn(errors, name) && getIn(touched, name) && (
                  <div className="invalid">{getIn(errors, name)}</div>
                )}
              </div>
            </>
          );
        }}
      </Field>
    </StyledWrapper>
  );
};

export default TextInput;
