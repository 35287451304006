import React from 'react';
import styled from 'styled-components';

const Customer = styled.div`
  margin: 3px 0;
  padding: 3px;
  border-radius: 3px;
  border: 1px solid darkgray;
  background-color: ${({ isUnreaded }) =>
    isUnreaded ? 'lightgray' : 'white'};
  cursor: pointer;
`;

const CustomersList = ({ customers, setCustomer }) => {
  return (
    <div>
      {customers.map((item) => (
        <Customer
          key={item.authorId}
          isUnreaded={item.includeUnreaded}
          onClick={() => setCustomer(item)}
        >
          {item.author}
        </Customer>
      ))}
    </div>
  );
};

export default CustomersList;
