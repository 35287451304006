import { useState } from 'react';

export const useModal = <T, K extends keyof T>(initModals: T) => {
  const [modal, setModals] = useState(initModals);

  const showModal = (key: K) => setModals({ ...modal, [key]: true });
  const closeModal = () => setModals(initModals);

  return { modal, showModal, closeModal };
};
