import useHasPermission from 'hooks/useHasPermission';
import React, { FC, useMemo } from 'react';
import { Permissions } from 'services/enums/Permissions.enum';
import { IOrder } from 'services/interfaces/orders/Orders.interface';
import { ordersUtils } from 'services/utils/orders/orders.utils';
import styled from 'styled-components';

const LostElementsSpan = styled.span`
  background-color: ${({ theme }) => theme.items.lost};
`;
const ToCorrectElementsSpan = styled.span`
  background-color: ${({ theme }) => theme.items.toCorrect};
`;

interface IProps {
  order: IOrder;
}

const OrderElementsNumberCell: FC<IProps> = ({ order }) => {
  const { hasPermission } = useHasPermission();
  const lostElementsCount = useMemo(
    () =>
      ordersUtils.elements.getLostElemnetsCount(
        order.items,
        order.customItems,
      ),
    [order.items, order.customItems],
  );
  const elementsToCorrectCount = useMemo(
    () =>
      ordersUtils.elements.getElementsToCorrectCount(
        order.items,
        order.customItems,
      ),
    [order.items, order.customItems],
  );

  return (
    <td>
      {order.elements}{' '}
      {hasPermission([Permissions.ADMIN, Permissions.EMPLOYEE]) && (
        <>
          {!!lostElementsCount && (
            <LostElementsSpan>{lostElementsCount}</LostElementsSpan>
          )}
          {!!elementsToCorrectCount && (
            <ToCorrectElementsSpan>
              {elementsToCorrectCount}
            </ToCorrectElementsSpan>
          )}
        </>
      )}
    </td>
  );
};

export default OrderElementsNumberCell;
