import React, { useEffect, useState } from 'react';
import Modal from 'PageTemplates/ModalTemplate';
import { StyledH3 } from 'components/header';
import { useSelector, useDispatch } from 'react-redux';
import {
  getPaintsSummaryFromOrders,
  getPaintsFromOthers,
  getOtherProductsSummary,
  getSkippedOrders,
} from './utils';
import { Table, Button } from 'react-bootstrap';
import { firstLetterToUpperCase } from 'services/utils/string';
import FlexRow from 'PageTemplates/FlexRowTemplate';
import OrdersList from 'components/ordersList/static';
import { useAlert } from 'react-alert';
import { setSpinner } from 'services/store/actions/view';
import { newPaintsOrder } from 'services/apiRequests/paintsOrders/post';
import { clearPaints } from 'services/store/actions/paints';

const OrderSummary = ({ closeModal }) => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const paints = useSelector((state) => state.paints.paints);
  const skipped = useSelector((state) => state.paints.skipped);
  const other = useSelector((state) => state.paints.other);

  const [paintsSummary, setPaintsSummary] = useState([]);
  const [othersSummary, setOthersSummary] = useState([]);
  const [skippedSummary, setSkippedSummary] = useState([]);

  useEffect(() => {
    // Paints
    if (paints && other.paints) {
      const summaryOrders = getPaintsSummaryFromOrders(paints);
      const othersPaints = getPaintsFromOthers(other.paints);

      setPaintsSummary(summaryOrders.concat(othersPaints));
    }
    if (other.other) {
      const otherSummary = getOtherProductsSummary(other.other);
      setOthersSummary(otherSummary);
    }
    if (skipped.length) {
      const skippedSummary = getSkippedOrders(skipped, paints);
      setSkippedSummary(skippedSummary);
    }
  }, [paints, skipped, other]);

  const handleRoundUp = () => {
    const newPaints = paintsSummary.map((item) => {
      item.quantity = Math.ceil(item.quantity);

      return item;
    });
    setPaintsSummary(newPaints);
  };

  const handleSubmit = async () => {
    dispatch(setSpinner(true));
    await newPaintsOrder(
      {
        paints: paintsSummary,
        other: othersSummary,
        skipped,
      },
      () => {
        dispatch(setSpinner(false));
        dispatch(clearPaints(paintsSummary));
        alert.success('Złożono zamówienie');
        closeModal();
      },
      () => {
        dispatch(setSpinner(false));
        alert.error('Błąd!');
      },
    );
  };

  return (
    <Modal
      title="Podsumowanie"
      size="xl"
      closeModal={closeModal}
      disabled={
        !paintsSummary.length &&
        !othersSummary.length &&
        !skippedSummary.length
      }
      onOk={handleSubmit}
    >
      <FlexRow justify="flex-end">
        <Button variant="outline-primary" onClick={handleRoundUp}>
          Zaokrąglij w górę
        </Button>
      </FlexRow>
      {paintsSummary.length ? (
        <>
          <StyledH3>Lakiery</StyledH3>
          <List items={paintsSummary} orders={paints} />
        </>
      ) : null}
      {othersSummary.length ? (
        <>
          <StyledH3>Inne</StyledH3>
          <List items={othersSummary} />
        </>
      ) : null}
      {skippedSummary.length ? (
        <>
          <StyledH3>Pominięte</StyledH3>
          <OrdersList orders={skippedSummary} listName="paints" status />
        </>
      ) : null}
      {!paintsSummary.length &&
        !othersSummary.length &&
        !skippedSummary.length && (
          <div style={{ textAlign: 'center' }}>
            <StyledH3>Jakoś tu pusto...</StyledH3>
            <p>Wpisz ilości lakierów które chcesz zamówić</p>
          </div>
        )}
    </Modal>
  );
};

export default OrderSummary;

const getOrderName = (id, orders) => {
  const order = orders.find((item) => item._id === id);
  if (order) return `${order.user.company} - ${order.number}`;
  return '?';
};

const List = ({ items, orders = null }) => {
  if (items)
    return (
      <Table bordered responsive="lg" striped hover size="sm">
        <thead>
          <tr>
            <th style={{ width: 20 }}>Lp</th>
            <th>Zamówienia</th>
            <th>PL</th>
            <th>PP</th>
            <th>Nazwa</th>
            <th>Typ</th>
            <th>
              Ilość <sup>[kg]</sup>
            </th>
          </tr>
        </thead>
        <tbody>
          {items.map((item, i) => (
            <tr key={item.name + item.type}>
              <td>{i + 1}</td>
              <td>
                <small>
                  {item.orders
                    ? item.orders
                        .sort((a, b) => a.localeCompare(b))
                        .map((order) =>
                          orders
                            ? getOrderName(order, orders)
                            : `${order}, `,
                        )
                    : '-'}
                </small>
              </td>
              <td>
                {item.surfaceRight ? item.surfaceRight.toFixed(2) : ''}
              </td>
              <td>
                {item.surfaceLeft ? item.surfaceLeft.toFixed(2) : ''}
              </td>
              <td>{item.name.toUpperCase()}</td>
              <td
                style={
                  item.type.toLowerCase() === 'połysk'
                    ? { backgroundColor: '#0080FF' }
                    : {}
                }
              >
                {firstLetterToUpperCase(item.type)}
              </td>
              <td>{item.quantity.toFixed(2)}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  return null;
};
