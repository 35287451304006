import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  @media (max-width: 600px) {
    flex-direction: column;
  }
`;
const Sidebar = styled.div`
  width: 300px;
  padding: 0 10px;
  position: fixed;
  @media (max-width: 600px) {
    position: relative;
    width: 100%;
  }
`;
const Content = styled.div`
  width: 100%;
  padding: 0 20px;
  margin-left: 300px;
  @media (max-width: 600px) {
    margin-left: 0;
  }
`;

const SidebarTemplate = ({ Component, children }) => {
  return (
    <Wrapper>
      <Sidebar>{Component}</Sidebar>
      <Content>{children}</Content>
    </Wrapper>
  );
};

export default SidebarTemplate;
