import React, { useState, useEffect } from 'react';
import Modal from 'PageTemplates/ModalTemplate';
import { updateOrder } from 'services/apiRequests/orders/update';
import { setSpinner } from 'services/store/actions/view';
import { setOrder } from 'services/store/actions/orders';
import { useDispatch, useSelector } from 'react-redux';
import { useAlert } from 'react-alert';
import { getEmployees } from 'services/store/actions/employees';
import { StyledH6 } from 'components/header';
import { Form } from 'react-bootstrap';

const PauseOrder = ({ order, position, closeModal }) => {
  const dispatch = useDispatch();
  const alert = useAlert();

  const user = useSelector((state) => state.auth.user);
  const employees = useSelector((state) => state.employees.employees);

  useEffect(() => {
    if (!employees) {
      dispatch(setSpinner(true));
      dispatch(
        getEmployees(
          () => {
            dispatch(setSpinner(false));
          },
          () => {
            dispatch(setSpinner(false));
            alert.error('Błąd');
          },
        ),
      );
    }
  }, []);

  const [orderEmployees, setOrderEmployees] = useState([]);

  const handleCheck = ({ target: { value, checked } }) => {
    if (checked) setOrderEmployees(orderEmployees.concat(value));
    else {
      const newList = orderEmployees.filter((item) => item !== value);
      setOrderEmployees(newList);
    }
  };

  const handleStart = async () => {
    dispatch(setSpinner(true));
    await updateOrder(
      'pause',
      order._id,
      { position, employees: orderEmployees },
      (order) => {
        dispatch(setOrder(order));
        dispatch(setSpinner(false));
        alert.success('Zatrzymano wykonywanie zamówienia');
        closeModal();
      },
      () => {
        dispatch(setSpinner(false));
        alert.error('Błąd!');
        closeModal();
      },
    );
  };

  return (
    <Modal title="Pauza" closeModal={closeModal} onOk={handleStart}>
      <StyledH6>Kto wykonywał z Tobą to zamówienie?</StyledH6>
      {employees &&
        employees.map((item) => {
          if (item.positions.includes(position) && item._id !== user._id)
            return (
              <Form.Check
                style={{ marginBottom: 10 }}
                id={item._id}
                checked={orderEmployees.includes(item._id)}
                key={item._id}
                value={item._id}
                type="checkbox"
                label={`${item.firstname} ${item.surname}`}
                onChange={handleCheck}
              />
            );
          return null;
        })}
    </Modal>
  );
};

export default PauseOrder;
