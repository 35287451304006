import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { dateToString } from 'services/utils/date';
import styled from 'styled-components';
import { Form, Button } from 'react-bootstrap';
import { employeePositions } from 'services/utils/const';
import { firstLetterToUpperCase } from 'services/utils/string';
import DatePicker from 'components/DatePicker';
import OrdersList from 'components/ordersList/static';
import { setSpinner } from 'services/store/actions/view';
import { sendMessage } from 'services/apiRequests/messages/post';
import { getAllMessages } from 'services/store/actions/messages';
import { useAlert } from 'react-alert';

const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  div {
    width: 95%;
  }
`;
const StyledWrapper = styled.div`
  margin: 0 2px;
  text-align: center;
  input {
    border-color: #ced4da;
  }
`;
const StyledParagraph = styled.p`
  margin-bottom: 2px;
`;
const StyledListWrapper = styled.div`
  max-height: 60vh;
  overflow-y: auto;
  margin-top: 20px;
`;

const GenerateOrder = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const orders = useSelector((state) => state.orders.productionOrders);

  const currentDate = new Date().toISOString().substr(0, 10);
  const [date, setDate] = useState(currentDate);
  const [position, setPosition] = useState([]);
  const [info, setInfo] = useState('');
  const [text, setText] = useState('');
  const [message, setMessage] = useState('');
  const [ordersArr, setOrdersArr] = useState([]);

  useEffect(() => {
    setInfo(
      `Proszę o wykonanie do dnia: ${dateToString(
        date,
      )} następujących zamówień: ${ordersArr.map(
        (order) => ` ${order}`,
      )}.`,
    );
  }, [date, ordersArr]);
  useEffect(() => {
    setMessage(`${info} ${text}`);
  }, [info, text]);

  // HANDLERS
  const handleDate = (e) => setDate(e.target.value);
  const handlePosition = (e) => setPosition([e.target.value]);
  const handleText = (e) => setText(e.target.value);
  const handleOrders = (e) => {
    let { value, checked } = e.target;
    const obj = JSON.parse(value);
    value = obj.orderName;

    if (checked) {
      if (!ordersArr.includes(value))
        setOrdersArr(ordersArr.concat(value));
    } else {
      const newOrders = ordersArr.filter((item) => item !== value);
      setOrdersArr(newOrders);
    }
  };

  const handleSendMessage = async () => {
    dispatch(setSpinner(true));
    await sendMessage(
      { message, positions: position },
      () => {
        dispatch(
          getAllMessages(
            () => {
              dispatch(setSpinner(false));
            },
            () => {
              dispatch(setSpinner(false));
              alert.error('Błąd!');
            },
          ),
        );
      },
      () => {
        dispatch(setSpinner(false));
        alert.error('Błąd!');
      },
    );
  };

  return (
    <div>
      <StyledRow>
        <StyledWrapper>
          <StyledParagraph>
            <strong>Stanowisko:</strong>
          </StyledParagraph>
          <Form.Control
            as="select"
            value={position[0]}
            onChange={handlePosition}
          >
            <option value="" />{' '}
            <option value="Biuro" style={{ fontWeight: 'bold' }}>
              Biuro
            </option>
            {employeePositions.map((item) => (
              <option
                key={item}
                value={item}
                className={item}
                style={{ fontWeight: 'bold', color: 'black' }}
              >
                {firstLetterToUpperCase(item)}
              </option>
            ))}
          </Form.Control>
        </StyledWrapper>

        <StyledWrapper>
          <StyledParagraph>
            <strong>Wiadomość:</strong>
          </StyledParagraph>
          <Form.Control type="text" onChange={handleText} value={text} />
        </StyledWrapper>

        <StyledWrapper>
          <StyledParagraph>
            <strong>Na kiedy:</strong>
          </StyledParagraph>
          <DatePicker value={date} onChange={handleDate} />
        </StyledWrapper>

        <StyledWrapper>
          <StyledParagraph>
            <strong>Treść:</strong>
          </StyledParagraph>
          <Form.Control as="textarea" rows="4" value={message} disabled />
        </StyledWrapper>
        <Button onClick={handleSendMessage} style={{ margin: '0 5px' }}>
          Wyślij
        </Button>
      </StyledRow>
      <StyledListWrapper>
        {orders && (
          <OrdersList
            orders={orders}
            listName="production"
            checkbox
            onCheck={handleOrders}
            status
            lastOperation
            redirect={false}
          />
        )}
      </StyledListWrapper>
    </div>
  );
};

export default GenerateOrder;
