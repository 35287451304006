import { Button } from 'react-bootstrap';
import { PageHeader, StyledH3 } from 'components/header';
import withContext from 'hoc/withContext';
import React, { useEffect, useState } from 'react';
import PageTemplate from 'PageTemplates/PageTemplate';
import { useDispatch, useSelector } from 'react-redux';
import { setSpinner } from 'services/store/actions/view';
import { getCatalog, removeFront } from 'services/store/actions/catalog';
import { useAlert } from 'react-alert';
import { useModal } from 'hooks/useModal';
import AddMillingModal from '../components/AddMillingModal/AddMillingModal';
import { sortFrontsByPricingGroup } from '../utils';
import PricingGroup from '../components/MillingPricingGroup/PricingGroup';
import {
  setNewOrderCustomMilling,
  setNewOrderMilling,
} from 'services/store/actions/newOrder';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileUpload } from '@fortawesome/free-solid-svg-icons';
import { veneerMillings } from 'services/utils/const';
import CustomFront from '../components/MillingPricingGroup/CustomFront';

const StyledInputFile = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;
const StyledFileLabel = styled.label`
  color: white;
  background-color: white;
  border: 1px solid #007bff;
  color: #007bff;
  padding: 5px 30px;
  letter-spacing: 1px;
  border-radius: 5px;
  margin: 5px 5px 10px;
  cursor: pointer;
  transition: background-color 200ms ease;
  &:hover {
    background-color: #007bff;
    color: white;
  }
`;

const initModals = {
  addFront: false,
};

const Millings = ({
  permissionContext,
  closeModal: closeNewOrderModal,
}) => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const { modal, showModal, closeModal } = useModal(initModals);
  const fronts = useSelector((state) => state.catalog.fronts);
  const newOrder = useSelector((state) => state.newOrder);
  const [frontToUpdate, setFrontToUpdate] = useState(null);

  const [frontsToDisplay, setFrontsToDisplay] = useState({
    1: [],
    2: [],
    3: [],
    4: [],
  });

  useEffect(() => {
    if (!fronts.length) dispatch(setSpinner(true));
    dispatch(
      getCatalog(
        () => {},
        () => {
          alert.error('Błąd pobierania katalogu');
        },
      ),
    );
  }, []);

  useEffect(() => {
    if (fronts) {
      let newFronts = fronts;
      if (
        newOrder.veneer ||
        newOrder.color.toLowerCase().includes('bejca')
      )
        newFronts = fronts.filter((item) =>
          veneerMillings.includes(item.symbol.toLowerCase()),
        );
      const toDisplay = sortFrontsByPricingGroup(newFronts);
      setFrontsToDisplay(toDisplay);
    }
  }, [fronts, newOrder.veneer, newOrder.color]);

  const handleMilling = (milling) => {
    dispatch(setNewOrderMilling(milling));
    alert.success('Wybrałeś wzór frezowania');
    if (closeNewOrderModal) closeNewOrderModal();
  };

  const handleAddCustomMilling = (e) => {
    dispatch(setNewOrderCustomMilling(e.target.files[0]));
    console.log(e.target.files[0]);
    alert.success('Wybrałeś niestandardowy wzór frezowania');
    if (closeNewOrderModal) closeNewOrderModal();
  };

  const handleEditFront = (front, index) =>
    setFrontToUpdate({ ...front, index });

  const handleRemoveFront = async (front) => {
    dispatch(setSpinner(true));
    dispatch(
      removeFront(
        front,
        () => {
          alert.success('Front usunięty');
        },
        () => {
          alert.error('Błąd!');
        },
      ),
    );
  };

  return (
    <PageTemplate>
      <PageHeader
        title="Fronty"
        extra={[
          permissionContext === 'admin' && (
            <Button key={1} onClick={() => showModal('addFront')}>
              Dodaj nowy front
            </Button>
          ),
          <Button
            variant="outline-primary"
            key={2}
            onClick={() =>
              handleMilling({
                symbol: 'gładki CNC',
                pricingGroup: 1,
              })
            }
          >
            Gładki CNC
          </Button>,
          <StyledInputFile
            key="3"
            disabled={newOrder.veneerSymbol}
            type="file"
            accept="image/*,application/pdf"
            id="file"
            onChange={handleAddCustomMilling}
          />,
          <StyledFileLabel htmlFor="file" key="4">
            <FontAwesomeIcon
              icon={faFileUpload}
              style={
                newOrder.customMilling.file ? { color: 'black' } : null
              }
            />{' '}
            Dodaj ze zdjęcia
          </StyledFileLabel>,
        ]}
      />

      {[1, 2, 3, 4].map((group) => (
        <PricingGroup
          key={group}
          group={group}
          fronts={frontsToDisplay[group]}
          removeFront={handleRemoveFront}
          editFront={handleEditFront}
          handleFront={handleMilling}
        />
      ))}
      <StyledH3>Inne</StyledH3>
      <CustomFront
        handleFront={handleMilling}
        front={{
          type: 'frony',
          symbol: 'ryflowany',
        }}
      />

      {/* MODALS */}
      {(modal.addFront || frontToUpdate) && (
        <AddMillingModal
          closeModal={() => {
            setFrontToUpdate(null);
            closeModal();
          }}
          toUpdate={frontToUpdate}
          type="front"
        />
      )}
    </PageTemplate>
  );
};

export default withContext(Millings);
