import React, { useState, useEffect } from 'react';
import FlexRow from 'PageTemplates/FlexRowTemplate';
import DatePicker, { TimePicker } from 'components/DatePicker';
import { Form } from 'react-bootstrap';
import { employeePositions } from 'services/utils/const';
import { firstLetterToUpperCase } from 'services/utils/string';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import { isDatesEqual } from 'services/utils/date';
import moment from 'moment';
import { getDaySurfaces } from '../utils';

const ScheduleItem = ({
  item,
  index,
  handleScheduleValue,
  removeSchedulePosition,
  order,
  limits,
}) => {
  const timetables = useSelector((state) => state.timetables.timetables);

  const [values, setValues] = useState(null);
  const [isLimitReached, setIsLimitReached] = useState(null);

  useEffect(() => {
    if (timetables && item.date && item.position) {
      const dayTimetable = timetables[item.position].find((day) => {
        const itemDate = new Date(item.date);
        const dayDate = new Date(day.date);
        if (isDatesEqual(itemDate, dayDate)) return item;
        return undefined;
      });
      if (dayTimetable) {
        const surfaces = getDaySurfaces(dayTimetable.orders, {
          order,
          actions: item.actions,
        });
        return setValues(surfaces);
      }
    }
    return setValues(null);
  }, [item]);

  useEffect(() => {
    if (values && limits && item.position) {
      const limit = limits[item.position];
      if (limit) {
        if (item.position !== 'pakowanie') {
          if (limit <= values.surface) return setIsLimitReached('surface');
        } else if (limit <= values.elements)
          return setIsLimitReached('elements');
      }
    }
    return setIsLimitReached(null);
  }, [values, limits, item]);

  const handleAction = (e, action) => {
    handleScheduleValue(
      {
        all: false,
        left: false,
        corrections: false,
        losts: false,
        [action]: e.target.checked,
      },
      index,
      'actions',
    );
  };

  return (
    <>
      <tr>
        <td>
          <DatePicker
            selected={item.date}
            dateFormat="dd.MM.yyyy"
            onChange={(date) => handleScheduleValue(date, index, 'date')}
            locale="pl"
            minDate={new Date()}
            maxDate={new Date(moment().add(28, 'days'))}
          />
        </td>
        <td>
          <Form.Control
            value={item.position}
            as="select"
            onChange={(e) =>
              handleScheduleValue(e.target.value, index, 'position')
            }
          >
            <option value="" disabled />
            {employeePositions
              .filter((pos) => !['piła', 'obróbka'].includes(pos))
              .map((pos) => (
                <option
                  key={pos}
                  value={pos}
                  className={pos}
                  style={{ fontWeight: 'bold', color: 'black' }}
                >
                  {firstLetterToUpperCase(pos)}
                </option>
              ))}
          </Form.Control>
        </td>
        <td>
          <TimePicker
            selected={item.hour}
            onChange={(date) => {
              return handleScheduleValue(date, index, 'hour');
            }}
          />
        </td>
        <td>
          <Form.Check
            type="radio"
            checked={item.actions.all}
            onChange={(e) => handleAction(e, 'all')}
          />
        </td>
        <td>
          <Form.Check
            type="radio"
            checked={item.actions.left}
            onChange={(e) => handleAction(e, 'left')}
          />
        </td>
        <td>
          <Form.Check
            type="radio"
            checked={item.actions.corrections}
            onChange={(e) => handleAction(e, 'corrections')}
          />
        </td>
        <td>
          <Form.Check
            type="radio"
            checked={item.actions.losts}
            onChange={(e) => handleAction(e, 'losts')}
          />
        </td>
        <td>
          <FontAwesomeIcon
            icon={faTrashAlt}
            style={{ color: 'red', cursor: 'pointer' }}
            onClick={() => removeSchedulePosition(index)}
          />
        </td>
      </tr>
      <tr>
        <td colSpan={7}>
          <FlexRow justify="space-around">
            <small>
              <strong>CNC: </strong>
              {values && values.cnc.toFixed(1)}
            </small>
            <small
              style={isLimitReached === 'surface' ? { color: 'red' } : {}}
            >
              <strong>Pow: </strong>
              {values && values.surface.toFixed(1)}
            </small>
            <small
              style={isLimitReached === 'elements' ? { color: 'red' } : {}}
            >
              <strong>Elem: </strong>
              {values && values.elements}
            </small>
          </FlexRow>
        </td>
      </tr>
    </>
  );
};

export default ScheduleItem;
