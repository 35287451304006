export const isUnreadedMessageInArray = (user, messages) =>
  messages.some((message) => {
    if (
      !message.readedBy.includes(user._id) &&
      message.authorId !== user._id
    )
      return 1;
    return 0;
  });

export const isMessageUnreaded = (user, message) =>
  !message.readedBy.includes(user._id) && message.authorId !== user._id;
