import React, { useState, useEffect, useCallback } from 'react';
import PageTemplate from 'PageTemplates/PageTemplate';
import { PageHeader } from 'components/header';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearOrdersList,
  getCustomerOrdersInProgress,
} from 'services/store/actions/orders';
import { useAlert } from 'react-alert';
import OrdersList from 'components/ordersList/static';
import { getCustomerMessages } from 'services/store/actions/auth';
import Filters from './components/Filters';
import { Tabs, Tab, Spinner, Button } from 'react-bootstrap';
import { setHomePageKey } from 'services/store/actions/view';
import EndedOrdersList from '../Admin/components/EndedOrdersList';
import { getBanner } from 'services/store/actions/messages';
import { getAllTimetables } from 'services/store/actions/timetables';
import TableView from './components/TableView';
import axios from 'axios';
import { useModal } from 'hooks/useModal';
import OrdersPriceModal from './components/OrdersPriceModal';

const CustomerHomePage = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const user = useSelector((state) => state.auth.user);
  const key = useSelector((state) => state.view.homePageKey);
  const orders = useSelector((state) => state.orders);
  const filters = useSelector((state) => state.orders.filters.customer);
  const timetables = useSelector((state) => state.timetables.timetables);

  const [isLoading, setIsLoading] = useState(false);
  const [endedOrders, setEndedOrders] = useState([]);

  const { modal, closeModal, showModal } = useModal({
    ordersPrice: false,
  });

  const getEndedOrders = useCallback(() => {
    if (user._id) {
      setIsLoading(true);
      axios
        .get(`/api/orders`, {
          params: {
            status: 'odebrane',
            pickUpDateFrom: filters.dateFrom,
            pickUpDateTo: filters.dateTo,
            user: user._id,
            subordinates: (user.subordinates || []).map((sub) => sub.id),
            color: filters.color || undefined,
            name: filters.name || undefined,
            number: filters.number || undefined,
          },
        })
        .then((res) => {
          setEndedOrders(res.data.orders);
          setIsLoading(false);
        });
    }
  }, [user, filters]);

  useEffect(() => {
    if (user._id) {
      dispatch(getCustomerMessages(user._id));
      dispatch(
        getBanner(
          () => {},
          () => {},
        ),
      );
      dispatch(
        getAllTimetables(
          () => {},
          () => {
            alert.error('Błąd pobierania harmobogramu!');
          },
        ),
      );

      if (key === 'new') {
        setIsLoading(true);
        dispatch(
          getCustomerOrdersInProgress(
            user._id,
            () => {
              setIsLoading(false);
            },
            () => {
              alert.error('Błąd pobierania zamówień');
              setIsLoading(false);
            },
          ),
        );
      } else if (key === 'ended') {
        getEndedOrders();
      }
    }
  }, [user, key, filters]);

  return (
    <PageTemplate>
      <PageHeader
        title="Zamówienia"
        extra={
          key === 'ended'
            ? [
                isLoading && (
                  <Spinner
                    animation="border"
                    variant="success"
                    key={1}
                    style={{ marginRight: 5 }}
                  />
                ),
                <Filters key={2} />,
              ]
            : [
                isLoading && (
                  <Spinner
                    animation="border"
                    variant="success"
                    key={1}
                    style={{ marginRight: 5 }}
                  />
                ),
                <Button
                  disabled={!orders.newOrders}
                  key={2}
                  onClick={() => showModal('ordersPrice')}
                >
                  Podlicz zamówienia
                </Button>,
              ]
        }
      />
      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(key) => {
          dispatch(clearOrdersList());
          dispatch(setHomePageKey(key));
          closeModal();
        }}
      >
        <Tab eventKey="new" title="Nowe">
          {key === 'new' && (
            <>
              <OrdersList
                orders={orders.newOrders}
                listName="customer"
                summary
                status
                price
              />
            </>
          )}
        </Tab>
        <Tab eventKey="ended" title="Odebrane">
          {key === 'ended' && (
            <EndedOrdersList
              orders={endedOrders}
              listName="customer"
              pickUpDate
              summary
            />
          )}
        </Tab>
      </Tabs>
      <hr />
      {timetables && (
        <div>
          <TableView timetables={timetables} user={user} />
        </div>
      )}

      {/* MODALS */}
      {modal.ordersPrice && key === 'new' && (
        <OrdersPriceModal
          orders={orders.newOrders}
          closeModal={closeModal}
        />
      )}
    </PageTemplate>
  );
};

export default CustomerHomePage;
