import React, { useEffect, useState } from 'react';
import PageTemplate from 'PageTemplates/PageTemplate';
import { PageHeader } from 'components/header';
import DatePicker from 'components/DatePicker';
import { useSelector, useDispatch } from 'react-redux';
import { useAlert } from 'react-alert';
import { getCustomers } from 'services/store/actions/customers';
import { setSpinner } from 'services/store/actions/view';
import {
  setStatsDate,
  setStatsField,
  clearStats,
} from 'services/store/actions/statistics';
import { firstLetterToUpperCase } from 'services/utils/string';
import { Form, Button } from 'react-bootstrap';
import { getCustomerOrdersToStats } from 'services/store/actions/orders';
import Statistics from './components/Statistics';
import AllCustomersStats from './components/AllCustomersStatsModal';
import withContext from 'hoc/withContext';

const initModals = { allCustomers: false };

const CustomerStatistics = ({ permissionContext }) => {
  const dispatch = useDispatch();
  const alert = useAlert();

  const user = useSelector((state) => state.auth.user);
  const orders = useSelector((state) => state.orders.orders);
  const date = useSelector((state) => state.statistics.date.customer);
  const customer = useSelector((state) => state.statistics.customer);
  const stats = useSelector((state) => state.statistics.stats);
  const customers = useSelector((state) => state.customers.customers);

  const [modals, setModals] = useState(initModals);

  useEffect(() => {
    if (permissionContext === 'admin') {
      dispatch(setSpinner(true));
      dispatch(
        getCustomers(
          () => {
            dispatch(setSpinner(false));
          },
          () => {
            dispatch(setSpinner(false));
            alert.error('Błąd!');
          },
        ),
      );
    } else {
      if (user) dispatch(setStatsField('customer', user._id || ''));
    }
  }, [user]);

  useEffect(() => {
    if (date.from && date.to && customer) {
      dispatch(setSpinner(true));
      dispatch(
        getCustomerOrdersToStats(
          customer,
          date.from,
          date.to,
          () => {
            dispatch(setSpinner(false));
          },
          () => {
            dispatch(setSpinner(false));
            alert.error('Błąd!');
          },
        ),
      );
    }
  }, [date, customer]);

  useEffect(() => {
    if (!customer && stats) {
      dispatch(clearStats());
    }
  }, [customer, stats]);

  const handleModal = (key) => setModals({ ...modals, [key]: true });
  const closeModal = () => setModals(initModals);
  const handleDate = (key, value) =>
    dispatch(setStatsDate('customer', key, value));
  const handleCustomer = (value) =>
    dispatch(setStatsField('customer', value));

  return (
    <PageTemplate>
      <PageHeader
        title="Statystyki klienta"
        extra={[
          <div key={0}>
            Od{' '}
            <DatePicker
              value={date.from}
              onChange={(d) => handleDate('from', d)}
            />
          </div>,
          <div key={1}>
            Do{' '}
            <DatePicker
              value={date.to}
              onChange={(d) => handleDate('to', d)}
            />
          </div>,
          permissionContext === 'admin' && (
            <div key={2}>
              Dla{' '}
              <CustomersSelect
                items={customers}
                value={customer}
                handleChange={handleCustomer}
              />
            </div>
          ),
          permissionContext === 'admin' && (
            <Button
              key={3}
              style={{ height: '85%' }}
              onClick={() => handleModal('allCustomers')}
            >
              Pobierz wszystkie
            </Button>
          ),
        ]}
      />
      <Statistics date={date} orders={orders} customer={customer} />
      {/* MODALS */}
      {modals.allCustomers && (
        <AllCustomersStats closeModal={closeModal} dates={date} />
      )}
    </PageTemplate>
  );
};

export default withContext(CustomerStatistics);

const CustomersSelect = ({ items, value, handleChange }) => (
  <div>
    <Form.Control
      as="select"
      onChange={(e) => handleChange(e.target.value)}
      value={value}
      disabled={!items}
      style={{ width: 300, margin: '0 3px' }}
    >
      <option value="" />
      {items &&
        items
          .sort((a, b) => `${a.company}`.localeCompare(b.company))
          .map((item) => (
            <option value={item._id} key={item._id} status={item}>
              {firstLetterToUpperCase(
                `${item.company} [${item.firstname}]`,
              )}
            </option>
          ))}
    </Form.Control>
  </div>
);
