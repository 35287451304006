export const getUnderGlassPrice = (order, prices) => {
  const { orderType, glassCase, isUnderGlass, items } = order;
  const { services } = prices;

  if (
    orderType.toLowerCase() === 'reklamacja (wina blow)' ||
    !glassCase ||
    !isUnderGlass
  )
    return 0;
  else {
    const underGlassQty = items.reduce((acc, item) => {
      if (item.type === 'witryna' && item.underGlass)
        return (acc += item.quantity);
      return acc;
    }, 0);
    return (underGlassQty || 0) * services.underGlass;
  }
};
