/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react';
import update from 'immutability-helper';
import Modal from 'PageTemplates/ModalTemplate';
import { useSelector, useDispatch } from 'react-redux';
import { setSpinner } from 'services/store/actions/view';
import {
  getProductionOrders,
  setOrder as setOrderData,
} from 'services/store/actions/orders';
import { useAlert } from 'react-alert';
import OrdersList from 'components/ordersList/static';
import {
  firstLetterToUpperCase,
  getUserName,
} from 'services/utils/string';
import { Button, Form } from 'react-bootstrap';
import {
  clearOrderSchedule,
  planOrder,
} from 'services/apiRequests/timetable/update';
import { getAllTimetables } from 'services/store/actions/timetables';
import { getGlobalSettings } from 'services/store/actions/settings';
import FlexRow from 'PageTemplates/FlexRowTemplate';
import withContext from 'hoc/withContext';
import OrderScheduleDetails from './components/OrderScheduleDetails';
import { getOrderSchedule } from './utils';

const scheduleObject = {
  date: new Date(),
  position: '',
  actions: { all: true, left: false, corrections: false, losts: false },
  hour: '',
};

const PlanOrder = ({ closeModal, orderData, permissionContext }) => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const orders = useSelector((state) => state.orders.productionOrders);
  const customers = useSelector(
    (state) => state.orders.ordersCustomersProduction,
  );
  const timetables = useSelector((state) => state.timetables.timetables);
  const limits = useSelector((state) => state.settings.productionLimits);

  const [filteredOrders, setFilteredOrders] = useState(orders);
  const [customer, setCustomer] = useState('');
  const [planedOrders, setPlanedOrders] = useState(false);
  const [order, setOrder] = useState(orderData || null);

  const [schedule, setSchedule] = useState([scheduleObject]);
  const [isPartPlaned, setIsPartPlaned] = useState(false);

  useEffect(() => {
    if (!orders) dispatch(setSpinner(true));
    dispatch(
      getProductionOrders(
        () => {
          dispatch(setSpinner(false));
        },
        () => {
          dispatch(setSpinner(false));
          alert.error('Błąd!');
        },
      ),
    );
    if (!timetables) {
      dispatch(
        getAllTimetables(
          () => {},
          () => {
            alert.error('Błąd!');
          },
        ),
      );
    }
    if (!limits) {
      dispatch(
        getGlobalSettings(
          () => {},
          () => {},
        ),
      );
    }
  }, []);

  useEffect(() => {
    if (orders) {
      let newOrders = orders;
      if (permissionContext === 'employee')
        newOrders = newOrders.filter((item) => !item.planedByAdmin);
      if (!planedOrders)
        newOrders = newOrders.filter(
          (item) => !item.productionPlan || !item.productionPlan.length,
        );
      else if (planedOrders)
        newOrders = newOrders.filter(
          (item) => item.productionPlan && item.productionPlan.length,
        );

      if (customer)
        newOrders = newOrders.filter((item) => item.user._id === customer);
      setFilteredOrders(newOrders);
    }
  }, [orders, customer, planedOrders]);

  useEffect(() => {
    if (
      order &&
      order.productionPlan &&
      order.productionPlan.length &&
      timetables
    ) {
      getOrderSchedule(timetables, order._id, setSchedule);
    }
  }, [order, timetables]);

  const handleOrder = (order) => setOrder(order);
  const handleFilter = ({ target: { value } }) => setCustomer(value);

  const handleScheduleValue = (value, i, field) =>
    setSchedule(update(schedule, { [i]: { [field]: { $set: value } } }));
  const removeSchedulePosition = (index) =>
    setSchedule(schedule.filter((item, i) => i !== index));

  const clearSchedule = async () => {
    dispatch(setSpinner(true));
    await clearOrderSchedule(
      order._id,
      (ord) => {
        if (orderData) dispatch(setOrderData(ord));
        setOrder(ord);
        dispatch(setSpinner(false));
        setSchedule([scheduleObject]);
        alert.success('Wyczyszczono harmonogram');
      },
      () => {
        dispatch(setSpinner(false));
        alert.error('Błąd!');
      },
    );
  };

  const handleSubmit = async () => {
    dispatch(setSpinner(true));
    const body = { orderId: order._id, schedule, isPartPlaned };
    await planOrder(
      body,
      () => {
        dispatch(
          getAllTimetables(
            () => {
              dispatch(setSpinner(false));
            },
            () => {
              dispatch(setSpinner(false));
              alert.error('Błąd!');
            },
          ),
        );
        closeModal();
      },
      () => {
        dispatch(setSpinner(false));
        alert.error('Błąd!');
      },
    );
  };

  return (
    <Modal
      title="Zaplanuj zamówienie"
      closeModal={closeModal}
      footer={
        <>
          <Form.Group className="mb-3" controlId="formBasicCheckbox">
            <Form.Check
              type="checkbox"
              label="Zaplanuj częściowo"
              checked={isPartPlaned}
              onChange={(e) => setIsPartPlaned(e.target.checked)}
              style={{ marginTop: 15, marginRight: 10 }}
            />
          </Form.Group>

          <Button
            style={{ marginRight: 20 }}
            variant="outline-danger"
            onClick={clearSchedule}
            disabled={
              !order ||
              !order.productionPlan ||
              !order.productionPlan.length
            }
          >
            Wyczyść harmonogram
          </Button>
          <Button
            variant="success"
            onClick={handleSubmit}
            disabled={
              !order ||
              !schedule.length ||
              schedule.find((item) => !item.position)
            }
          >
            Zatwierdź
          </Button>
          <Button variant="danger" onClick={closeModal}>
            Anuluj
          </Button>
        </>
      }
      dialogClassName="mybootstrapmodal"
    >
      {!order ? (
        <>
          <div>
            <Filter
              value={customer}
              customers={customers}
              disabled={!orders}
              onChange={handleFilter}
              planedOrders={planedOrders}
              setPlanedOrders={setPlanedOrders}
            />
          </div>
          <OrdersList
            orders={filteredOrders}
            listName="production"
            isRedirect={false}
            handleClickRow={handleOrder}
            status
            lastOperation
          />
        </>
      ) : (
        <OrderScheduleDetails
          order={order}
          schedule={schedule}
          setSchedule={setSchedule}
          handleScheduleValue={handleScheduleValue}
          removeSchedulePosition={removeSchedulePosition}
          limits={limits}
        />
      )}
    </Modal>
  );
};

export default withContext(PlanOrder);

const Filter = ({
  value,
  disabled,
  onChange,
  customers,
  planedOrders,
  setPlanedOrders,
}) => (
  <FlexRow justify="flex-start">
    <Form.Control
      as="select"
      onChange={onChange}
      value={value}
      disabled={disabled}
      style={{ width: 200, marginBottom: 5 }}
    >
      <option value="" />
      {customers &&
        customers
          .sort((a, b) => `${a.company}`.localeCompare(b.company))
          .map((item) => (
            <option value={item._id} key={item._id} status={item}>
              {firstLetterToUpperCase(getUserName(item))}
            </option>
          ))}
    </Form.Control>
    <Form.Check
      type="checkbox"
      id="editPlanId"
      label="Edytuj harmonogram"
      checked={planedOrders}
      onChange={(e) => setPlanedOrders(e.target.checked)}
      style={{ margin: 5 }}
    />
  </FlexRow>
);
