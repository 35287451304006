import React, { useEffect, useState } from 'react';
import Modal from 'PageTemplates/ModalTemplate';
import { useDispatch } from 'react-redux';
import { setSpinner } from 'services/store/actions/view';
import { getAllCustomerOrders } from 'services/apiRequests/orders/get';
import { useAlert } from 'react-alert';
import { orderTypes, reasonsOfComplaint } from 'services/utils/const';
import { Form } from 'react-bootstrap';
import {
  setNewOrderManyFields,
  setNewOrderType,
} from 'services/store/actions/newOrder';
import { firstLetterToUpperCase } from 'services/utils/string';

// EDIT ORDER

const initData = {
  orderType: undefined,
  number: undefined,
  reasonOfComplaint: undefined,
};

const ChangeType = ({ order, closeModal }) => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const [userOrders, setUserOrders] = useState(null);
  const [data, setData] = useState(initData);
  const [step, setStep] = useState(0);

  useEffect(() => {
    if (order) {
      setData({
        orderType: order.orderType,
        number: order.number.toString().split('.')[0],
      });
    }
  }, []);

  useEffect(() => {
    if (order.user && !userOrders) {
      dispatch(setSpinner(true));
      getAllCustomerOrders(
        order.user._id,
        (orders) => {
          dispatch(setSpinner(false));
          setUserOrders(orders);
        },
        () => {
          dispatch(setSpinner(false));
          alert.error('Błąd pobierania zamówień klienta');
        },
      );
    }
  }, [order]);

  const handleData = (field, value) =>
    setData({ ...data, [field]: value });
  const handleNext = () => {
    if (step === 0) {
      if (
        [
          'Reklamacja (wina BLOW)',
          'Poprawa (wina klienta)',
          'Domówienie',
        ].includes(data.orderType)
      )
        return setStep(step + 1);
      return handleSubmit();
    }
    return handleSubmit();
  };

  const handleSubmit = () => {
    const fields = { ...data };
    if (['Materiał klienta', 'Lista elementów'].includes(data.orderType)) {
      if (order.number.includes('.'))
        fields.number = order.user.currentFreeOrderId;
    } else {
      fields.number = `${fields.number}.${fields.orderType[0]}`;
    }
    dispatch(setNewOrderManyFields({ ...fields }));
    dispatch(setNewOrderType(data.orderType));
    closeModal();
  };

  return (
    <Modal
      title="Zmiana typu zamówienia"
      closeModal={closeModal}
      onOk={handleNext}
      disabled={
        (step === 0 &&
          (!data.orderType ||
            (data.orderType.toLowerCase() === 'reklamacja (wina blow)' &&
              !data.reasonOfComplaint))) ||
        (step === 1 && !data.number)
      }
    >
      {step === 0 ? (
        <OrderTypeCheck
          orderType={data.orderType}
          reasonOfComplaint={data.reasonOfComplaint}
          handleData={handleData}
        />
      ) : step === 1 ? (
        <OrderNumberCheck
          orders={userOrders}
          number={data.number}
          handleData={handleData}
        />
      ) : null}
    </Modal>
  );
};

export default ChangeType;

const OrderNumberCheck = ({ orders, number, handleData }) => {
  return (
    <>
      <strong>Wybierz numer zamówienia</strong>
      <Form.Control
        as="select"
        onChange={(e) => handleData('number', e.target.value)}
        value={number}
      >
        <option value="" />
        {orders.map((order) => {
          if (!order.number.includes('.')) {
            return (
              <option key={order._id} value={order.number}>
                {order.number}
              </option>
            );
          }
          return null;
        })}
      </Form.Control>
    </>
  );
};

const OrderTypeCheck = ({ reasonOfComplaint, orderType, handleData }) => {
  return (
    <>
      <strong>Wybierz typ zamówienia</strong>
      <Form.Group
        onChange={(e) => handleData('orderType', e.target.value)}
        value={orderType}
      >
        {orderTypes.map((item) => (
          <Form.Check
            custom
            key={item}
            value={item}
            type="radio"
            label={item}
            id={item}
            name="orderType"
          />
        ))}
      </Form.Group>
      {orderType && orderType.toLowerCase() === 'reklamacja (wina blow)' && (
        <>
          <strong>Przyczyna reklamacji</strong>
          <Form.Group
            onChange={(e) =>
              handleData('reasonOfComplaint', e.target.value)
            }
            value={reasonOfComplaint}
          >
            {reasonsOfComplaint.map((item) => (
              <Form.Check
                custom
                key={item}
                value={item}
                type="radio"
                label={firstLetterToUpperCase(item)}
                id={item}
                name="reasonOfComplaint"
              />
            ))}
          </Form.Group>
        </>
      )}
    </>
  );
};
