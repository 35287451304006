import React from 'react';
import { useAlert } from 'react-alert';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { changeCuttingPackageStatus } from 'services/apiRequests/cutting/update';
import { setSpinner } from 'services/store/actions/view';
import { cuttingPackageStatus } from 'services/utils/const';
import Modal from 'PageTemplates/ModalTemplate';

const ChangeStatusModal = ({ cuttingPackageId, closeModal }) => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const history = useHistory();

  const handleSubmit = async () => {
    dispatch(setSpinner(true));
    await changeCuttingPackageStatus(
      cuttingPackageId,
      cuttingPackageStatus.READY,
      () => {
        dispatch(setSpinner(false));
        alert.success('Paczka wycięta');
        history.push('/cutting');
        closeModal();
      },
      () => {
        dispatch(setSpinner(false));
        alert.error('Błąd!');
      },
    );
  };
  return (
    <Modal
      title={'Zmiana statusu'}
      closeModal={closeModal}
      onOk={handleSubmit}
      backdrop="static"
    >
      Chcesz zatwierdzić wycięcie paczki?
    </Modal>
  );
};

export default ChangeStatusModal;
