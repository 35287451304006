/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import Modal from 'PageTemplates/ModalTemplate';
import { useDispatch } from 'react-redux';
import { useAlert } from 'react-alert';
import { setSpinner } from 'services/store/actions/view';
import { getAllCustomersStats } from 'services/apiRequests/stats/get';
import { formatDateToDatePicker } from 'services/utils/date';
import { Table } from 'react-bootstrap';

const AllCustomersStats = ({ closeModal, dates: { from, to } }) => {
  const dispatch = useDispatch();
  const alert = useAlert();

  const [data, setData] = useState(null);

  const getData = async () => {
    dispatch(setSpinner(true));
    await getAllCustomersStats(
      {
        from: formatDateToDatePicker(from),
        to: formatDateToDatePicker(to),
      },
      (data) => {
        setData(data);
        dispatch(setSpinner(false));
      },
      () => {
        dispatch(setSpinner(false));
        alert.error('Błąd!');
      },
    );
  };
  useEffect(() => {
    if (from && to) {
      getData();
    }
  }, [from, to]);

  return (
    <Modal
      size="xl"
      title="Statystyki klientów"
      closeModal={closeModal}
      scrollable
    >
      <div>{data && <DataTable data={data} />}</div>
    </Modal>
  );
};

export default AllCustomersStats;

const DataTable = ({ data }) => {
  return (
    <Table responsive="lg" bordered hover size="sm">
      <thead>
        <tr>
          <th>Lp.</th>
          <th>Klient</th>
          <th>Zamówień</th>
          <th>
            Cena <small>zł</small>
          </th>
          <th>
            Pow.{' '}
            <small>
              m<sup>2</sup>
            </small>
          </th>
          <th>
            PL{' '}
            <small>
              m<sup>2</sup>
            </small>
          </th>
          <th>
            PP{' '}
            <small>
              m<sup>2</sup>
            </small>
          </th>
          <th>
            Połysk{' '}
            <small>
              m<sup>2</sup>
            </small>
          </th>
          <th>
            Półmat{' '}
            <small>
              m<sup>2</sup>
            </small>
          </th>
          <th>
            Elem. <small>szt.</small>
          </th>
          <th>
            CNC{' '}
            <small>
              m<sup>2</sup>
            </small>
          </th>
          <th>
            Fornir{' '}
            <small>
              m<sup>2</sup>
            </small>
          </th>
          <th>
            Uchw. <small>mb</small>
          </th>
          <th>
            Uchw. Cz. <small>szt.</small>
          </th>
          <th>
            Otw. pod zaw. <small>szt.</small>
          </th>
        </tr>
      </thead>
      <tbody>
        {data.map((user, i) => {
          return (
            <tr key={user.customerId}>
              <td>{i + 1}</td>
              <td>
                {user.company} <small>{user.userName}</small>
              </td>
              <td>{user.ordersNumber}</td>
              <td style={{ fontWeight: 'bold' }}>
                {user.price ? user.price.toFixed(2) : user.price}
              </td>
              <td>
                {user.surface ? user.surface.toFixed(2) : user.surface}
              </td>
              <td>{user.PL ? user.PL.toFixed(2) : user.PL}</td>
              <td>{user.PP ? user.PP.toFixed(2) : user.PP}</td>
              <td>{user.gloss ? user.gloss.toFixed(2) : user.gloss}</td>
              <td>
                {user.semiGloss
                  ? user.semiGloss.toFixed(2)
                  : user.semiGloss}
              </td>
              <td>{user.elements}</td>
              <td>{user.cnc ? user.cnc.toFixed(2) : user.cnc}</td>
              <td>{user.veneer ? user.veneer.toFixed(2) : user.veneer}</td>
              <td>
                {user.handles ? user.handles.toFixed(2) : user.handles}
              </td>
              <td>{user.partHandles}</td>
              <td>{user.holes}</td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};
