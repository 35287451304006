import React, { useEffect, useState } from 'react';
import withContext from 'hoc/withContext';
import {
  isOrderWetForStaticList,
  isOrderWetForPosition,
} from 'services/utils/orders';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExclamation,
  faComments,
  faClock,
  faTint,
  faTruck,
  faUndo,
  faCalendarAlt,
} from '@fortawesome/free-solid-svg-icons';

const Icons = ({ index, order, permissionContext, position }) => {
  const [iconsArr, setIconsArr] = useState([]);
  let isWet;
  if (permissionContext === 'admin')
    isWet = isOrderWetForStaticList(order);
  if (permissionContext === 'employee')
    isWet = isOrderWetForPosition(order, position);

  useEffect(() => {
    if (order) {
      const arr = [];

      if (
        ['materiał klienta', 'poprawa (wina klienta)'].includes(
          order.orderType.toLowerCase(),
        )
      )
        arr.push({ title: 'Materiał powierzony', icon: faTruck });

      if (['employee', 'display'].includes(permissionContext)) {
        if (order.priority)
          arr.push({ title: 'Priorytet', icon: faExclamation });
        if (order.employeesComments && order.employeesComments.length)
          arr.push({ title: 'Komentarze', icon: faComments });
        if (position !== 'surówka') {
          if (isWet) arr.push({ title: 'Mokre', icon: faTint });
        }
        if (position === 'szlifiernia') {
          if (order.wasInGriding) arr.push({ title: '', icon: faUndo });
        }
      } else if (permissionContext === 'admin') {
        if (order.priority)
          arr.push({ title: 'Priorytet', icon: faExclamation });
        if (order.employeesComments && order.employeesComments.length)
          arr.push({ title: 'Komentarze', icon: faComments });
        if (order.productionPlan && order.productionPlan.length) {
          const isOutOfTime = order.productionPlan
            .filter((item) => item.planedOn && item.done)
            .some((item) => {
              const datePlaned = new Date(item.planedOn);
              const dateDone = new Date(item.done);
              return dateDone.getTime() - datePlaned.getTime() > 86400000;
            });
          arr.push({
            title: order.isPartPlaned
              ? 'Zaplanowane częściowo'
              : 'Zaplanowane',
            icon: faCalendarAlt,
            style: order.isPartPlaned
              ? { color: 'blue' }
              : isOutOfTime
              ? { color: 'red' }
              : {},
          });
        }
        if (order.inProduction && order.inProduction.length)
          arr.push({
            title: `${order.inProduction.join(', ')}`,
            icon: faClock,
          });
      }
      setIconsArr(arr);
    }
  }, [order]);

  return (
    <td>
      {iconsArr.length ? (
        <>
          {iconsArr.map((item) => (
            <FontAwesomeIcon
              key={item.title}
              title={item.title}
              icon={item.icon}
              style={{ margin: 1, ...item.style }}
            />
          ))}
        </>
      ) : (
        index + 1
      )}
    </td>
  );
};

export default withContext(Icons);
