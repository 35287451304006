import React from 'react';
import styled from 'styled-components';
import {
  ContextMenuTrigger,
  ContextMenu,
  MenuItem,
} from 'react-contextmenu';
import { updateOrder } from 'services/apiRequests/orders/update';
import { useDispatch, useSelector } from 'react-redux';
import { setSpinner } from 'services/store/actions/view';
import withContext from 'hoc/withContext';
import { useAlert } from 'react-alert';
import {
  orderPaymentsStatuses,
  orderPaymentStatus,
} from 'services/utils/const';

const StyledTd = styled.td`
  padding: 0px !important;
`;
const StyledContextMenu = styled(ContextMenu)`
  background-color: white;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.blowLightGrey};
`;
const StyledMenuItem = styled(MenuItem)`
  font-weight: bold;
  padding: 3px 20px;
  &:hover {
    background-color: ${({ theme }) => theme.blowPrimary};
  }
`;

const PaymentStatus = ({
  order,
  setIsRedirect,
  permissionContext,
  updateInList,
  disabledPaymentStatusChange,
}) => {
  const user = useSelector((state) => state.auth.user);
  const { paymentStatus, _id } = order;
  return (
    <StyledTd style={{ padding: 0 }}>
      <>
        <ContextMenuTrigger
          id={`menuItemId_${_id}`}
          disable={!!disabledPaymentStatusChange}
        >
          <div
            style={{
              color:
                paymentStatus === orderPaymentStatus.PAID
                  ? 'green'
                  : paymentStatus === orderPaymentStatus.NOT_PAID
                  ? 'red'
                  : paymentStatus === orderPaymentStatus.HALF_PAID
                  ? 'orange'
                  : paymentStatus === orderPaymentStatus.TRANSFER
                  ? 'gold'
                  : 'black',
              fontWeight: 'bold',
            }}
          >
            {paymentStatus}
          </div>
        </ContextMenuTrigger>
        {permissionContext === 'admin' && user.isBoss && (
          <PaymentContextMenu
            setIsRedirect={setIsRedirect}
            orderId={_id}
            updateInList={updateInList}
          />
        )}
      </>
    </StyledTd>
  );
};

const PaymentContextMenu = ({ orderId, setIsRedirect, updateInList }) => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const updatePaymentStatus = async (paymentStatus) => {
    dispatch(setSpinner(true));
    await updateOrder(
      'paymentstatus',
      orderId,
      { paymentStatus },
      (order) => {
        updateInList(order);
        dispatch(setSpinner(false));

        return paymentStatus === orderPaymentStatus.PAID
          ? alert.success(`Zamówienie opłacone`)
          : paymentStatus === orderPaymentStatus.NOT_PAID
          ? alert.success(`Zamówienie nieopłacone`)
          : paymentStatus === orderPaymentStatus.HALF_PAID
          ? alert.warning('Zamówienie opłacone częściowo')
          : () => {};
      },
      () => {
        dispatch(setSpinner(false));
        alert.error('błąd!');
      },
    );
  };
  return (
    <>
      <StyledContextMenu
        id={`menuItemId_${orderId}`}
        onHide={() => setIsRedirect(true)}
        onShow={() => setIsRedirect(false)}
      >
        {orderPaymentsStatuses.map((status) => (
          <StyledMenuItem
            key={status}
            onClick={async () => updatePaymentStatus(status)}
          >
            <span
              style={{
                color:
                  status === orderPaymentStatus.PAID
                    ? 'green'
                    : status === orderPaymentStatus.NOT_PAID
                    ? 'red'
                    : status === orderPaymentStatus.HALF_PAID
                    ? 'orange'
                    : 'black',
              }}
            >
              {status}
            </span>
          </StyledMenuItem>
        ))}
      </StyledContextMenu>
    </>
  );
};

export default withContext(PaymentStatus);
