import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';
import OrderProperties from '../OrderProperties';
import OrderData from '../OrderData';
import OrderInfo from '../OrderInfo';
import Items from '../Items';
import MechanicalBy from './components/MechanicalBy';
import ImportDataFromFile from '../Modals/ImportDataFromFile';
import CustomItems from '../CustomItems';

const Hr = styled.hr`
  margin: 30px 0;
`;
const Row = styled.div`
  margin-top: 80px;
  display: flex;
  justify-content: space-between;
`;
const NextButton = styled(Button)`
  padding: 10px 30px;
  letter-spacing: 8px;
`;
const ColorInfo = styled.h1`
  text-align: center;
  font-weight: bold;
  letter-spacing: 10px;
  color: ${({ theme, color }) => theme[color]};
`;

const OrderForm = ({ order, handleNext }) => {
  const [isMachining, setIsMachining] = useState(false);
  const [importDimensions, setImportDimensions] = useState(false);

  const goToSummary = () => {
    if (
      ['Materiał klienta', 'Poprawa (wina klienta)'].includes(
        order.orderType,
      ) &&
      ((order.items.some((item) => !item.blowMaterial) &&
        order.handles.length) ||
        order.items.some((item) =>
          checkIsItemHas('otw', false, true, item),
        ) ||
        order.items.some((item) =>
          checkIsItemHas('nut', false, true, item),
        ) ||
        order.items.some((item) =>
          checkIsItemHas('felc', false, true, item),
        ) ||
        order.items.some((item) =>
          checkIsItemHas('gierunek', true, true, item),
        ))
    )
      return setIsMachining(true);
    return handleNext();
  };
  return (
    <div>
      <OrderProperties order={order} />
      <Hr />
      <OrderData order={order} />
      <Hr />
      <OrderInfo order={order} />
      <Hr />

      {!order.color ? (
        <ColorInfo color="blowGreen">Musisz wybrać kolor</ColorInfo>
      ) : order.color.includes('bejca') && !order.veneer ? (
        <ColorInfo color="blowWarning">Musisz wybrać fornir</ColorInfo>
      ) : (
        <>
          <Items order={order} setImportDimensions={setImportDimensions} />
          {(order.pilaster || order.crown) && (
            <CustomItems order={order} />
          )}
          <Row>
            <NextButton size="xl" variant="success" onClick={goToSummary}>
              Dalej
            </NextButton>
          </Row>
        </>
      )}

      {/* MODALS */}
      {isMachining && (
        <MechanicalBy
          order={order}
          closeModal={() => setIsMachining(false)}
          goNext={handleNext}
        />
      )}
      {importDimensions && (
        <ImportDataFromFile
          closeModal={() => setImportDimensions(false)}
        />
      )}
    </div>
  );
};

export default OrderForm;

const checkIsItemHas = (value, right, left, item) => {
  const { h1L, h2L, w1L, w2L, h1P, h2P, w1P, w2P } = item;
  if (
    !item.blowMaterial &&
    left &&
    (h1L.toLowerCase().includes(value.toLowerCase()) ||
      h2L.toLowerCase().includes(value.toLowerCase()) ||
      w1L.toLowerCase().includes(value.toLowerCase()) ||
      w2L.toLowerCase().includes(value.toLowerCase()))
  )
    return true;
  if (
    !item.blowMaterial &&
    right &&
    (h1P.toLowerCase().includes(value.toLowerCase()) ||
      h2P.toLowerCase().includes(value.toLowerCase()) ||
      w1P.toLowerCase().includes(value.toLowerCase()) ||
      w2P.toLowerCase().includes(value.toLowerCase()))
  )
    return true;
  return false;
};
