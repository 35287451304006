import React from 'react';
import moment from 'moment';
import withContext from 'hoc/withContext';

const Type = ({ order, permissionContext }) => {
  const { veneer, millings } = order;
  const type = veneer ? 'Fornir' : millings.length ? 'CNC' : '';
  let title = '';
  const style = { fontWeight: 'bold' };
  if (permissionContext !== 'user' && type === 'CNC') {
    if (order.dateBackFromCNC) {
      style.color = 'green';
      title = 'Odebrane z CNC';
    } else if (
      order.dateSendToCNC &&
      moment().diff(moment(order.dateSendToCNC), 'days') > 7
    ) {
      style.color = 'red';
      title = 'Opóźnienie w CNC';
    } else if (
      order.dateSendToCNC &&
      moment().diff(moment(order.dateSendToCNC), 'days') < 7
    ) {
      style.color = 'blue';
      title = `Wysłano do CNC ${moment().diff(
        moment(order.dateSendToCNC),
        'days',
      )} dni temu`;
    } else {
      style.color = 'black';
    }
  }

  return (
    <td style={style} title={title}>
      {type}
    </td>
  );
};

export default withContext(Type);
