import { OrderTypes } from 'services/enums/Orders.enum';
import { IOrder } from 'services/interfaces/orders/Orders.interface';
import { IUser } from 'services/interfaces/users/Users.interface';

export const data = {
  getOrderType: (order: IOrder): string => {
    if (order.veneer) return OrderTypes.VENEER;
    if (!!order.millings.length) return OrderTypes.CNC;
    return '';
  },
  getOrderCustomerName: (order: IOrder): string => {
    let result = '-';
    if (!order.user) return result;
    result = (order.user as IUser).company;

    if ((order.user as IUser).firstname)
      result += ` - [${(order.user as IUser).firstname?.[0]}]`;

    return result;
  },
};
