import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  getCustomers,
  setCustomer,
} from 'services/store/actions/customers';
import { setSpinner } from 'services/store/actions/view';
import { useAlert } from 'react-alert';
import styled from 'styled-components';
import { sortMethod } from 'services/utils/sortMethods';
import { Form, Button } from 'react-bootstrap';
import Modal from 'PageTemplates/ModalTemplate';
import { updateCustomerDataByAdmin } from 'services/apiRequests/customers/update';

const StyledWrapper = styled.div`
  max-height: 80vh;
  width: inherit;
  display: flex;
  flex-direction: column;
  flex-flow: column wrap;
  overflow-y: scroll;
`;
const StyledCheckbox = styled(Form.Check)`
  margin: 3px;
`;

const SetSubordinatesModal = ({ customerId, closeModal }) => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const customers = useSelector((state) => state.customers.customers);

  const [subordinates, setSubordinates] = useState([]);

  useEffect(() => {
    if (!customers) {
      dispatch(setSpinner(true));
      dispatch(
        getCustomers(
          () => {
            dispatch(setSpinner(false));
          },
          () => {
            dispatch(setSpinner(false));
            alert.error('Błąd pobierania listy klientów');
          },
        ),
      );
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (e.target.checked) {
      setSubordinates(subordinates.concat({ id: value, name }));
    } else {
      const newSubordinates = subordinates.filter(
        (item) => item.id !== value,
      );
      setSubordinates(newSubordinates);
    }
  };

  const handleSetSubordinates = async () => {
    dispatch(setSpinner(true));
    await updateCustomerDataByAdmin(
      customerId,
      { subordinates },
      (customer) => {
        dispatch(setSpinner(false));
        alert.success('Zaktualizowano podwładnych');
        dispatch(setCustomer(customer));
        closeModal();
      },
      () => {
        dispatch(setSpinner(false));
        alert.error('Błąd');
      },
    );
  };
  return (
    <Modal
      title="Ustalanie podwładnych"
      size="xl"
      closeModal={closeModal}
      footer={
        <>
          <Button variant="success" onClick={handleSetSubordinates}>
            Zatwierdź
          </Button>
          <Button variant="danger" onClick={closeModal}>
            Anuluj
          </Button>
        </>
      }
    >
      <StyledWrapper>
        {customers &&
          customers.length &&
          customers
            .sort((a, b) =>
              sortMethod[sortMethod.getSortMethod('customerCompany')](
                a,
                b,
                'customerCompany',
                'ascend',
              ),
            )
            .map((item) => (
              <StyledCheckbox
                key={item._id}
                value={item._id}
                type="checkbox"
                //    checked={positions.includes(item)}
                id={item._id}
                name={`${item.company} - ${item.firstname}`}
                label={
                  <small>
                    {item.company} - {item.firstname}
                  </small>
                }
                onChange={handleChange}
              />
            ))}
      </StyledWrapper>
    </Modal>
  );
};

export default SetSubordinatesModal;
