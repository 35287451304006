import {
  EMPLOYEES_LIST_LOAD_SUCCESS,
  EMPLOYEES_LIST_LOAD_ERROR,
  EMPLOYEES_SET_SORT,
  EMPLOYEES_SET_EMPLOYEE,
  EMPLOYEES_CLEAR_EMPLOYEE_DATA,
} from '../types/employees';
import update from 'immutability-helper';

const initialState = {
  employees: null,
  employeeData: null,
  sort: {
    sortBy: 'surname',
    direction: 'ascend',
  },
};

const employeesReducer = (state = initialState, action) => {
  switch (action.type) {
    case EMPLOYEES_LIST_LOAD_SUCCESS: {
      return { ...state, employees: action.employees };
    }
    case EMPLOYEES_LIST_LOAD_ERROR: {
      return { ...state, employees: null };
    }
    case EMPLOYEES_SET_SORT: {
      return update(state, {
        sort: {
          sortBy: { $set: action.sortBy },
          direction: { $set: action.direction },
        },
      });
    }
    case EMPLOYEES_SET_EMPLOYEE: {
      return { ...state, employeeData: action.employee };
    }
    case EMPLOYEES_CLEAR_EMPLOYEE_DATA: {
      return { ...state, employeeData: null };
    }
    default:
      return state;
  }
};

export { employeesReducer };
