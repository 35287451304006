import React, { useEffect } from 'react';
import { StyledH1 } from 'components/header';
import { useSelector, useDispatch } from 'react-redux';
import { getGlobalSettings } from 'services/store/actions/settings';
import { useAlert } from 'react-alert';
import styled from 'styled-components';
import { useState } from 'react';
import { Spinner } from 'react-bootstrap';
import Prices from 'components/Prices';
import { substractSimilarObjects } from 'services/utils/object';
import { updateCustomerDataByAdmin } from 'services/apiRequests/customers/update';
import { setCustomer } from 'services/store/actions/customers';

const Wrapper = styled.div`
  position: relative;
`;
const SpinnerWrapper = styled.div`
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Discounts = ({ discounts, customerId }) => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const prices = useSelector((state) => state.settings.prices);

  const [isLoading, setIsLoading] = useState(false);
  const [userPrices, setUserPrices] = useState(null);

  useEffect(() => {
    if (!prices) {
      setIsLoading(true);
      dispatch(
        getGlobalSettings(
          () => {
            setIsLoading(false);
          },
          () => {
            alert.error('Błąd cennika');
            setIsLoading(false);
          },
        ),
      );
    }
  }, []);

  useEffect(() => {
    if (discounts && prices) {
      const values = substractSimilarObjects(prices, discounts);
      setUserPrices(values);
    }
  }, [discounts, prices]);

  const updatePrices = async (values, disableEdit, message) => {
    setIsLoading(true);
    const discounts = substractSimilarObjects(prices, values);

    await updateCustomerDataByAdmin(
      customerId,
      { discounts },
      (customer) => {
        disableEdit();
        alert.success(message);
        if (customer) dispatch(setCustomer(customer));
        setIsLoading(false);
      },
      () => {
        alert.error('Błąd!');
        setIsLoading(false);
      },
    );
  };

  const handleSubmit = async (values, disableEdit, message) =>
    await updatePrices(values.prices, disableEdit, message);

  const setDefault = async (disableEdit) =>
    await updatePrices(prices, disableEdit, 'Ustawiono domyślne ceny');

  return (
    <Wrapper>
      {isLoading && (
        <SpinnerWrapper>
          <Spinner animation="border" variant="success" />
        </SpinnerWrapper>
      )}
      <StyledH1>Cennik klienta</StyledH1>
      <Prices
        prices={userPrices}
        handleSubmit={handleSubmit}
        discounts={discounts}
        setDefault={setDefault}
      />
    </Wrapper>
  );
};

export default Discounts;
