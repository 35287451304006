export interface IUser {
  _id: string;
  firstname?: string;
  surname?: string;
  company: string;
  permission: UserPermission;
}

export enum UserPermission {
  USER = 'user',
  ADMIN = 'admin',
}
