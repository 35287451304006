import React from 'react';
import { StyledH6 } from 'components/header';
import FlexRow from 'PageTemplates/FlexRowTemplate';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { clearCustomerData } from 'services/store/actions/customers';

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  div {
    margin-right: 20px;
  }
`;
const DataWrapper = styled.div`
  min-width: 400px;
  max-width: 500px;
`;
const SubordinateItem = styled.strong`
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.blowGreen};
  }
`;

const CustomerData = ({ customer }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSubordinate = (id) => {
    dispatch(clearCustomerData());
    history.push(`/customers/${id}`);
  };

  return (
    <div>
      <Wrapper>
        <DataWrapper>
          <StyledH6>{customer.company}</StyledH6>
          <DataItem
            name="Imię i nazwisko"
            value={`${customer.firstname} ${customer.surname}`}
          />
          <DataItem name="E-mail" value={customer.email} />
          <DataItem name="Nr telefonu" value={customer.phone} />
          <DataItem name="Nazwa firmy" value={customer.company} />
          <DataItem name="NIP" value={customer.NIP} />
          <DataItem name="Miejscowość" value={customer.city} />
          <DataItem name="Kod pocztowy" value={customer.postcode} />
          <DataItem name="Ulica" value={customer.street} />
        </DataWrapper>
        {customer.subordinates && customer.subordinates.length ? (
          <DataWrapper>
            <StyledH6>Podwładni</StyledH6>
            {customer.subordinates.map((item) => (
              <Subordinate
                key={item._id}
                subordinate={item}
                handleSubordinate={handleSubordinate}
              />
            ))}
          </DataWrapper>
        ) : null}
      </Wrapper>
    </div>
  );
};

export default CustomerData;

const DataItem = ({ name, value }) => (
  <FlexRow justify="space-between">
    <span>{name}: </span>
    <strong>{value || '-'}</strong>
  </FlexRow>
);
const Subordinate = ({ subordinate, handleSubordinate }) => (
  <div>
    <SubordinateItem onClick={() => handleSubordinate(subordinate.id)}>
      {subordinate.name}
    </SubordinateItem>
  </div>
);
