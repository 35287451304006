import moment from 'moment';
import {
  OrderItemCuttingStatus,
  OrderItemRightSideEdge,
  OrderItemType,
} from 'services/enums/Orders.enum';
import { ICuttingPackage } from 'services/interfaces/cutting-package/CuttingPackage.interface';
import {
  IOrder,
  IOrderItem,
} from 'services/interfaces/orders/Orders.interface';
import FileSaver from 'file-saver';

interface ICuttingPackageItem extends IOrderItem {
  numberInOrder: number;
  orderName: string;
  orderNumber: string;
  orderMilling: string | null;
  orderGlassCase: string | null;
}

export const createCSVPackageFileForCNC = ({
  orders,
}: ICuttingPackage) => {
  const date = moment().format('DD.MM.YYYYr');
  const items = (orders as IOrder[])
    .reduce((allItems: ICuttingPackageItem[], order) => {
      return [
        ...allItems,
        ...order.items.map((item, index) => ({
          ...item,
          numberInOrder: index + 1,
          orderName: `${order.userName} - ${order.number}`,
          orderNumber: order.number,
          orderMilling: order.millings[0],
          orderGlassCase: order.glassCase,
        })),
      ];
    }, [])
    .filter(
      (item) => item.cuttingStatus === OrderItemCuttingStatus.TO_CUT,
    );

  const T = `nazwa,Material,x,y,Ilosc,Grubosc,Kat usłojenia,Kat manipualcji,ramka,sciezka do pliku,typ plyty,typ uchwytu,uchwyt x,uchwyt y,obrobka,opis\r`;

  const dataT = items.map(
    (item, index) =>
      `${item.orderName} [${item.numberInOrder}],MZ${item.thickness},${
        item.width
      },${item.height},${item.quantity},${
        item.thickness
      },0,90,0,${getFilePath(item)},zwykla,${isHandle(
        item,
      )},${isHandleOnWidth(item)},${isHandleOnHeight(item)},${
        item.isMachiningRequired ? 'TAK' : '-'
      },\r`,
  );
  const CSV = `${T}${dataT.join('')}`;

  FileSaver.saveAs(
    new Blob([CSV], { type: 'text/csv;charset=windows-1252;' }),
    `ciecie_CSV_${date}.csv`,
  );
};

const getFilePath = (item: ICuttingPackageItem) => {
  const basicPath = `Nesting\\Projekty\\`;
  if (item.type === OrderItemType.FLAT) return basicPath + 'Gladki.fmc';
  if (item.type === OrderItemType.MILLED)
    return basicPath + item.orderMilling + '.fmc';
  if (item.type === OrderItemType.GLASS_CASE)
    return basicPath + item.orderGlassCase + '.fmc';
  return '-';
};

const isHandle = (item: IOrderItem) => {
  if (
    ![
      OrderItemRightSideEdge.R1.toLowerCase(),
      OrderItemRightSideEdge.R2.toLowerCase(),
      OrderItemRightSideEdge.EMPTY.toLowerCase(),
    ].includes(item.w1P.toLowerCase())
  )
    return item.w1P;
  if (
    ![
      OrderItemRightSideEdge.R1.toLowerCase(),
      OrderItemRightSideEdge.R2.toLowerCase(),
      OrderItemRightSideEdge.EMPTY.toLowerCase(),
    ].includes(item.w2P.toLowerCase())
  )
    return item.w2P;
  if (
    ![
      OrderItemRightSideEdge.R1.toLowerCase(),
      OrderItemRightSideEdge.R2.toLowerCase(),
      OrderItemRightSideEdge.EMPTY.toLowerCase(),
    ].includes(item.h1P.toLowerCase())
  )
    return item.h1P;
  if (
    ![
      OrderItemRightSideEdge.R1.toLowerCase(),
      OrderItemRightSideEdge.R2.toLowerCase(),
      OrderItemRightSideEdge.EMPTY.toLowerCase(),
    ].includes(item.h2P.toLowerCase())
  )
    return item.h2P;
  return '-';
};
const isHandleOnWidth = (item: IOrderItem) => {
  if (
    ![
      OrderItemRightSideEdge.R1.toLowerCase(),
      OrderItemRightSideEdge.R2.toLowerCase(),
      OrderItemRightSideEdge.EMPTY.toLowerCase(),
    ].includes(item.w1P.toLowerCase())
  )
    return 'TAK';
  if (
    ![
      OrderItemRightSideEdge.R1.toLowerCase(),
      OrderItemRightSideEdge.R2.toLowerCase(),
      OrderItemRightSideEdge.EMPTY.toLowerCase(),
    ].includes(item.w2P.toLowerCase())
  )
    return 'TAK';
  return '-';
};
const isHandleOnHeight = (item: IOrderItem) => {
  if (
    ![
      OrderItemRightSideEdge.R1.toLowerCase(),
      OrderItemRightSideEdge.R2.toLowerCase(),
      OrderItemRightSideEdge.EMPTY.toLowerCase(),
    ].includes(item.h1P.toLowerCase())
  )
    return 'TAK';
  if (
    ![
      OrderItemRightSideEdge.R1.toLowerCase(),
      OrderItemRightSideEdge.R2.toLowerCase(),
      OrderItemRightSideEdge.EMPTY.toLowerCase(),
    ].includes(item.h2P.toLowerCase())
  )
    return 'TAK';
  return '-';
};
const getCuttingItemType = (item: ICuttingPackageItem) => {
  const type = item.type.toLowerCase();
  if (type === OrderItemType.MILLED) return item.orderMilling;
  if (type === OrderItemType.GLASS_CASE) return item.orderGlassCase;
  if (type === OrderItemType.FLAT) return OrderItemType.FLAT;
  if (type === OrderItemType.GROOVED) return OrderItemType.GROOVED;
  return OrderItemType.FLAT;
};
