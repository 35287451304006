import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Fade from 'react-reveal/Fade';
import {
  clearNewOrder,
  setNewOrderManyFields,
} from 'services/store/actions/newOrder';
import { Button } from 'react-bootstrap';

const OrderData = () => {
  const dispatch = useDispatch();
  const newOrder = useSelector((state) => state.newOrder);
  const user = useSelector((state) => state.auth.user);

  const handleClearOrder = () => {
    const fields = {
      user: user._id,
      userName: `${user.company} - ${user.firstname}`,
      userPrices: user.discounts,
      number: user.currentFreeOrderId,
      createdBy: user._id,
    };
    dispatch(clearNewOrder());
    dispatch(setNewOrderManyFields(fields));
  };

  return (
    <div>
      <Item name="Kolor" value={newOrder.color} />
      {newOrder.handles.length
        ? newOrder.handles.map((item, i) => (
            <Item
              key={item}
              name={`Uchwyt ${i + 1}`}
              value={item.toUpperCase()}
            />
          ))
        : null}
      {newOrder.millings.length
        ? newOrder.millings.map((item) => (
            <Item
              key={item}
              name={`Frezowanie`}
              value={item.toUpperCase()}
            />
          ))
        : null}
      <Item name="Witryna" value={newOrder.glassCase} />
      <Item name="Fornir" value={newOrder.veneer} />
      {newOrder.color ||
      newOrder.handles.length ||
      newOrder.millings.length ||
      newOrder.glassCase ||
      newOrder.veneer ? (
        <Button variant="danger" size="sm" onClick={handleClearOrder}>
          Resetuj
        </Button>
      ) : null}
    </div>
  );
};

export default OrderData;

const Item = ({ name, value }) => {
  if (value)
    return (
      <Fade>
        <div>
          <strong>{name}: </strong>
          {value.toUpperCase()}
        </div>
      </Fade>
    );
  return null;
};
