import React, { useRef, useState } from 'react';
import Fade from 'react-reveal/Fade';
import List from './List';
import { StyledH3 } from 'components/header';
import { FormControl, Button } from 'react-bootstrap';
import FlexRow from 'PageTemplates/FlexRowTemplate';
import { addNewOrderCustomItem } from 'services/store/actions/newOrder';
import { useDispatch } from 'react-redux';

const CustomItems = () => {
  const scrollTo = useRef(null);
  const dispatch = useDispatch();

  const [newItems, setNewItems] = useState('');

  const scrollToBottom = () =>
    setTimeout(
      () => scrollTo.current.scrollIntoView({ behavior: 'smooth' }),
      200,
    );

  const handleNewItems = ({ target: { value } }) => {
    const numbers = /^(\s*|\d+)$/;
    if (!value.match(numbers)) return;
    setNewItems(value);
  };

  const handleAddItems = () => {
    if (!newItems) {
      dispatch(addNewOrderCustomItem());
      scrollToBottom();
    } else {
      for (let i = 0; i < newItems; i++) {
        dispatch(addNewOrderCustomItem());
      }
    }
    setNewItems('');
  };
  return (
    <Fade right>
      <div style={{ marginTop: 50 }}>
        <StyledH3>Elementy niestandardowe</StyledH3>
        <List />
        <FlexRow justify="flex-end">
          <FormControl
            placeholder="liczba pozycji"
            value={newItems}
            onChange={handleNewItems}
            style={{ width: 200 }}
          />
          <Button
            variant="outline-primary"
            onClick={handleAddItems}
            style={{ margin: 0 }}
          >
            Dodaj
          </Button>
        </FlexRow>

        <div ref={scrollTo} />
      </div>
    </Fade>
  );
};

export default CustomItems;
