import React, { useState } from 'react';
import styled from 'styled-components';
import withContext from 'hoc/withContext';
import { lighten } from 'polished';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import Cells from '../components/cells';
import { dateToHours } from 'services/utils/date';

const StyledTableRow = styled.tr`
  cursor: pointer;
  background-color: ${({
    status,
    isNeedPlan,
    theme,
    listName,
    inProduction,
    isHalfGriding,
  }) => {
    if (isNeedPlan) return lighten(0.12, theme.blowWarning);
    if (listName === 'production') return theme.positions[status] || '';
    if (inProduction) return lighten(0.12, theme.blowGreenLight);
    if (isHalfGriding) return '#fff3cb';

    return 'none';
  }};
`;

const ListRow = (props) => {
  const {
    order,
    index,
    pickUpDate,
    status,
    edited,
    paymentStatus,
    handleClickRow,
    lastOperation,
    listName,
    checkbox,
    onCheck,
    checked,
    paintsOrder,
    redirect = true,
    desc,
    permissionContext,
    actions,
    planedOnHour,
    price,
    fv,
    paymentsList,
  } = props;
  const history = useHistory();

  const position = useSelector((state) => state.employee.activePosition);

  const [isRedirect, setIsRedirect] = useState(redirect);

  const handleClick = (e) => {
    const isNotClicable = e.target.getAttribute(`data-isnotclickable`);

    if (!isRedirect || isNotClicable) return;
    if (handleClickRow) return handleClickRow(order);

    return history.push(`/order/${order._id}`);
  };
  if (!order.inProduction || !order.status || !order.number) {
    return null;
  }
  return (
    <StyledTableRow
      onClick={handleClick}
      status={order && order.status ? order.status[0] : '-'}
      listName={listName}
      inProduction={
        order.inProduction && order.inProduction.includes(position)
      }
      isHalfGriding={
        permissionContext === 'employee' &&
        position === 'szlifiernia' &&
        order.isHalfGriding
      }
      isNeedPlan={
        permissionContext === 'admin' &&
        listName === 'new' &&
        order.user.isVip &&
        !order.productionPlan.length
      }
    >
      {checkbox && (
        <Cells.Checkbox
          onChange={onCheck}
          checked={checked}
          order={order}
        />
      )}
      {edited && <Cells.LastEdit order={order} />}
      <Cells.Icons index={index} order={order} position={position} />
      {planedOnHour && (
        <td>{order.hour ? dateToHours(order.hour) : ''}</td>
      )}
      {actions && <Cells.Action order={order} />}
      <Cells.Customer user={order.user} />
      <td>{order.number && order.number.toUpperCase()}</td>
      <Cells.Name name={order.name} />
      <Cells.Color order={order} />
      <Cells.PaintType order={order} />
      <Cells.Elements order={order} />
      <Cells.Surface order={order} surface="surfaceRight" />
      <Cells.Surface order={order} surface="surfaceLeft" />
      <Cells.CustomItemsLength order={order} />
      <Cells.Type order={order} />
      <Cells.Date date={order.date} />
      <Cells.FinishDate date={order.finishDate} />
      {status && (
        <Cells.Status {...props} order={order} position={position} />
      )}
      {price && <Cells.Price {...props} order={order} />}
      {paymentsList && <Cells.PaymentsList {...props} order={order} />}
      {paintsOrder && <Cells.SkippedOrder {...props} order={order} />}
      {paintsOrder && <Cells.PaintValue {...props} order={order} />}
      {paintsOrder && <Cells.ProducerCell {...props} order={order} />}
      {lastOperation && <Cells.LastOperation order={order} />}
      {pickUpDate && (
        <Cells.PickUpDate
          pickUpDate={order.pickUpDate}
          transportDate={order.transportDate}
        />
      )}
      {paymentStatus && (
        <Cells.PaymentStatus
          order={order}
          setIsRedirect={setIsRedirect}
          {...props}
        />
      )}
      {fv && <Cells.FVStatus order={order} {...props} />}
      {desc && (
        <td>
          <small>{order.desc}</small>
        </td>
      )}
    </StyledTableRow>
  );
};

export default withContext(ListRow);
