/* eslint-disable no-shadow */
/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-use-before-define */
import React, { useState } from 'react';
import ModalTemplate from 'PageTemplates/ModalTemplate';
import { Form, Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { setNewOrderManyFields } from 'services/store/actions/newOrder';

const orderTypes = ['Nowe zamówienie', 'Materiał klienta'];

const CustomerOrderType = ({ closeModal, order }) => {
  const dispatch = useDispatch();
  const [orderType, setOrderType] = useState(order.orderType);

  const handleSetOrder = () => {
    const fields = { orderType };

    dispatch(setNewOrderManyFields({ ...fields }));
    closeModal();
  };

  return (
    <ModalTemplate
      title="Typ zamówienia"
      closeModal={closeModal}
      footer={
        <>
          <Button
            variant="success"
            disabled={!orderType}
            onClick={handleSetOrder}
          >
            Dalej
          </Button>
          <Button variant="danger" onClick={closeModal}>
            Anuluj
          </Button>
        </>
      }
    >
      <strong>Wybierz typ zamówienia</strong>
      <Form.Group
        onChange={(e) => setOrderType(e.target.value)}
        value={orderType}
      >
        {orderTypes.map((item) => (
          <Form.Check
            custom
            key={item}
            value={item}
            type="radio"
            label={item}
            id={item}
            name="orderType"
          />
        ))}
      </Form.Group>
    </ModalTemplate>
  );
};

export default CustomerOrderType;
