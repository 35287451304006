import { OrdersListCells } from 'components/ordersList/Cells';
import OrdersList from 'components/ordersList/OrdersList';
import React, { FC, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { OrderItemSurfaces } from 'services/enums/Orders.enum';
import { IOrder } from 'services/interfaces/orders/Orders.interface';
import { ordersUtils } from 'services/utils/orders/orders.utils';
import { ISettleOrderPayment } from './SettleOrders.modal';
import ChangeOrderPriceModal from 'scenes/Order/components/modals/ChangeOrderPrice.modal';

interface IProps {
  orders: ISettleOrderPayment[];
  setOrderPayment: (order: IOrder, value: string) => void;
  updatePickedOrder: (order: IOrder) => void;
}

const OrdersToSettleList: FC<IProps> = ({
  orders,
  setOrderPayment,
  updatePickedOrder,
}) => {
  const [orderToUpdate, setOrderToUpdate] = useState<IOrder | undefined>();

  const getInputBackgroundColor = (order: IOrder, payment: number) => {
    const orderCurrentPayments =
      ordersUtils.payments.getPaymentsValue(order);
    const orderPayments = orderCurrentPayments + payment;
    const orderPrice = ordersUtils.payments.getOrderPrice(order, true);

    const backgroundColor =
      orderPayments !== orderPrice ? '#ffd18c' : '#e7ffba';
    return backgroundColor;
  };

  return (
    <>
      <OrdersList
        columns={{
          price: true,
          paymentsList: true,
          paymentStatusDisplay: true,
          priceChange: true,
          paymentValue: true,
        }}
      >
        {orders.map(({ order, payment }, index) => {
          return (
            <tr key={order._id}>
              <td>{index + 1}</td>
              <OrdersListCells.customerName order={order} />
              <OrdersListCells.orderNumber order={order} />
              <OrdersListCells.orderName order={order} />
              <OrdersListCells.color order={order} />
              <OrdersListCells.paintType order={order} />
              <OrdersListCells.elements order={order} />
              <OrdersListCells.surface
                surfaceType={OrderItemSurfaces.RIGHT}
                surfaceValue={order.surfaceRight}
                order={order}
              />
              <OrdersListCells.surface
                surfaceType={OrderItemSurfaces.LEFT}
                surfaceValue={order.surfaceLeft}
                order={order}
              />
              <OrdersListCells.customElements order={order} />
              <OrdersListCells.orderType order={order} />
              <OrdersListCells.date order={order} />
              <OrdersListCells.finishDate order={order} />
              <OrdersListCells.price order={order} />
              <td>
                <Button size="sm" onClick={() => setOrderToUpdate(order)}>
                  Zmień cenę
                </Button>
              </td>
              <td>
                <Form.Control
                  style={{
                    backgroundColor: getInputBackgroundColor(
                      order,
                      payment,
                    ),
                  }}
                  value={payment}
                  onChange={(e) => setOrderPayment(order, e.target.value)}
                />
              </td>
              <OrdersListCells.paymentsList order={order} />
              <OrdersListCells.paymentStatusDisplay order={order} />
            </tr>
          );
        })}
      </OrdersList>

      {/*NOTE MODALS */}
      {orderToUpdate && (
        <ChangeOrderPriceModal
          order={orderToUpdate}
          closeModal={() => setOrderToUpdate(undefined)}
          afterSubmit={updatePickedOrder}
        />
      )}
    </>
  );
};

export default OrdersToSettleList;
