import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import withContext from 'hoc/withContext';

const PrivateRoute = ({
  component: Component,
  permissionContext,
  permissions,
  ...props
}) => {
  return (
    <Route
      {...props}
      render={(props) =>
        permissions.includes(permissionContext) ? (
          <Component {...props} />
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};

export default withContext(PrivateRoute);
