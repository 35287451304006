import {
  EMPLOYEE_SET_ACTIVE_POSITION,
  EMPLOYEE_SET_PAINTROOM,
} from '../types/employee';

const initialState = {
  activePosition: null,
  activePaintRoom: 1,
};

const employeeReducer = (state = initialState, action) => {
  switch (action.type) {
    case EMPLOYEE_SET_ACTIVE_POSITION: {
      localStorage.setItem('activePosition', action.position);
      return { ...state, activePosition: action.position };
    }
    case EMPLOYEE_SET_PAINTROOM: {
      localStorage.setItem('paintRoom', action.paintRoom);
      return { ...state, paintRoom: action.paintRoom };
    }
    default:
      return state;
  }
};

export { employeeReducer };
