export interface IOrderListColumns {
  lp: boolean;
  customer: boolean;
  number: boolean;
  name: boolean;
  color: boolean;
  paintType: boolean;
  elements: boolean;
  surfaceRight: boolean;
  surfaceLeft: boolean;
  customElements: boolean;
  type: boolean;
  date: boolean;
  finishDate: boolean;

  checkbox: boolean;
  price: boolean;
  status: boolean;
  paymentStatusDisplay: boolean;
  paymentStatusHandle: boolean;
  paymentsList: boolean;

  priceChange: boolean;
  paymentValue: boolean;
}

export const initColumnns: IOrderListColumns = {
  lp: true,
  customer: true,
  number: true,
  name: true,
  color: true,
  paintType: true,
  elements: true,
  surfaceRight: true,
  surfaceLeft: true,
  customElements: true,
  type: true,
  date: true,
  finishDate: true,

  checkbox: false,
  price: false,
  status: false,
  paymentStatusDisplay: false,
  paymentStatusHandle: false,
  paymentsList: false,

  priceChange: false,
  paymentValue: false,
};
