import update from 'immutability-helper';
import {
  TIMETABLES_LOAD_SUCCESS,
  TIMETABLES_LOAD_ERROR,
  TIMETABLES_SET_POSITIONS,
  TIMETABLES_SET_VIEW,
} from '../types/timetables';
import { formatTimetablesData } from './utils/timetables';

const initialState = {
  timetables: null,
  activeDay: null,
  positions: {
    surówka: true,
    podkład: true,
    szlifiernia: true,
    lakiernia: true,
    polernia: true,
    pakowanie: true,
    transport: true,
  },
  view: 'cards',
};

const timetablesReducer = (state = initialState, action) => {
  switch (action.type) {
    case TIMETABLES_LOAD_SUCCESS: {
      return formatTimetablesData(state, action.timetables);
    }
    case TIMETABLES_LOAD_ERROR: {
      return initialState;
    }
    case TIMETABLES_SET_POSITIONS: {
      return update(state, {
        positions: {
          [action.position]: { $set: action.value },
        },
      });
    }
    case TIMETABLES_SET_VIEW: {
      return update(state, { view: { $set: action.view } });
    }
    default:
      return state;
  }
};

export { timetablesReducer };
