import { useMutation, useQueries } from '@tanstack/react-query';
import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import dayjs from 'dayjs';
import FileSaver from 'file-saver';
import { isNumber } from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { Form, Stack } from 'react-bootstrap';
import { cashRegisterApi } from 'services/apiRequests/cash-register.api';
import { _onlyNumbersRegExp } from 'services/consts/regexp';
import { CashRegisterStoryType } from 'services/enums/CashRegister.enum';
import {
  ICashRegister,
  ICashRegisterStory,
} from 'services/interfaces/cash-register/CashRegister.interface';
import { ISettings } from 'services/interfaces/settings/Settings.interface';

interface IProps {
  cashRegister: ICashRegister;
  closeModal: () => void;
  refetchCashRegisterData: () => Promise<any>;
  settings: ISettings;
}

const PayOutMoneyModal: FC<IProps> = ({
  cashRegister,
  refetchCashRegisterData,
  closeModal,
  settings,
}) => {
  const alert = useAlert();
  const [value, setValue] = useState<number>(0);
  const [info, setInfo] = useState<string>('');
  const [description, setDescription] = useState<string>('');

  const [cashRegisterStory] = useQueries({
    queries: [
      {
        queryKey: ['get-cash-register-story', description],
        queryFn: () =>
          cashRegisterApi.getCashRegisterStory({
            dateFrom: dayjs(cashRegister.lastPayOut).toDate(),
            dateTo: dayjs().toDate(),
          }),
        onError: (err: any) => {
          console.log(err, err.response);
          alert.error('Błąd');
        },
        enabled: cashRegister && description === 'Raport',
      },
    ],
  });

  useEffect(() => {
    if (
      description === 'Raport' &&
      cashRegisterStory.data?.data.cashRegisterStory.length
    ) {
      const value = cashRegisterStory.data.data.cashRegisterStory
        .filter(
          (story) =>
            story.description !== 'Zasilenie kasy' &&
            story.type === CashRegisterStoryType.IN,
        )
        .reduce((acc, story) => {
          if (story.type === CashRegisterStoryType.IN)
            return (acc += story.value);
          if (story.type === CashRegisterStoryType.OUT)
            return (acc -= story.value);
          return acc;
        }, 0);
      setValue(value);
    }
  }, [cashRegisterStory.data, description]);

  const { mutate: handlePayOutModal, isLoading } = useMutation(
    async () => {
      const cashRegisterValue = cashRegister.value - value;
      if (isNumber(value) && isNumber(cashRegisterValue)) {
        const storyItem: Partial<ICashRegisterStory> = {
          type: CashRegisterStoryType.OUT,
          value,
          description,
          info,
          cashRegisterValue,
          date: dayjs().toDate(),
        };
        if (description === 'Raport') {
          return await cashRegisterApi.payOutMoneyWithReport(
            value,
            storyItem,
          );
        } else {
          return await cashRegisterApi.payOutMoney(value, storyItem);
        }
      }
    },
    {
      onSuccess: async (data: any) => {
        if (description === 'Raport') {
          FileSaver.saveAs(
            new Blob([data.data], {
              type: 'application/pdf;charset=utf-8',
            }),
            `Raport - ${dayjs().format('DD-MM-YYYY_kk-mm')}.pdf`,
          );
        }
        await refetchCashRegisterData();
        alert.success(`Z kasy wypłacono: ${value}zł`);
        closeModal();
      },
      onError: () => {
        alert.error('Błąd!');
      },
    },
  );

  const handleValue = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    if (!value.match(_onlyNumbersRegExp)) return;
    setValue(+value);
  };

  return (
    <ModalWrapper
      title="Wypłata z kasy"
      closeModal={closeModal}
      onOk={handlePayOutModal}
      okButtonDisabled={
        !value || !description || value > cashRegister.value
      }
      isLoading={cashRegisterStory.isFetching || isLoading}
    >
      <Stack gap={3} className="mb-2">
        <Stack gap={3} direction="horizontal">
          <div>Podaj wartość do wypłaty: </div>
          <Form.Control
            type="text"
            placeholder="Kwota"
            value={value}
            className="w-25"
            onChange={handleValue}
          />
        </Stack>
        <Form.Control
          as="select"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          disabled={!settings}
        >
          <option value={''}>Typ wypłaty</option>
          <option value="Raport" style={{ fontWeight: 'bold' }}>
            Raport
          </option>
          {settings.cashRegister.withdrawalDesc.map((desc: string) => (
            <option value={desc} key={desc}>
              {desc}
            </option>
          ))}
        </Form.Control>
        <Form.Control
          type="text"
          placeholder="Dodatkowe informacje"
          value={info}
          onChange={(e) => setInfo(e.target.value)}
        />
        <small>
          <div>Aktualna wartość kasy: {cashRegister.value}zł</div>
          <div>
            Wartość kasy po wypłacie: {cashRegister.value - value}
            zł
          </div>
        </small>
      </Stack>
    </ModalWrapper>
  );
};

export default PayOutMoneyModal;
