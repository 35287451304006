/* eslint-disable consistent-return */
import Axios from 'axios';

export const updateOrder = async (type, orderId, body, onEnd, onErr) => {
  try {
    const url = `/api/orders/${type}/${orderId}`;
    const res = await Axios.put(url, body);
    const { order } = res.data;

    sessionStorage.setItem('lastEditedOrder', orderId);
    if (res.status === 200) onEnd(order);
  } catch (error) {
    console.log('ERROR_UPDATE_ORDER: ', error, error.response);
    if (
      error.response &&
      error.response.status === 501 &&
      error.response.data.message
    )
      return onErr(error.response.data.message);
    if (
      error.response &&
      error.response.data &&
      error.response.data.msg === 'na produkcji'
    ) {
      return onErr(error.response.data.msg);
    }
    return onErr();
  }
};
