import React from 'react';
import styled from 'styled-components';
import { Modal, Button } from 'react-bootstrap';
import FlexRow from 'PageTemplates/FlexRowTemplate';
import './style.scss';

const StyledTitle = styled.h3`
  color: ${({ variant, theme }) => {
    if (variant === 'success') return theme.blowGreen;
    if (variant === 'danger') return theme.blowDanger;
  }};
`;

const ModalContainer = (props) => {
  const {
    title = '',
    closeModal,
    children,
    footer = null,
    variant = 'success',
    size = 'xs',
    onHide = null,
    onOk = () => {},
    okButtonText = 'Zatwierdź',
    disabled = false,
    style = {},
    ...rest
  } = props;
  return (
    <div>
      <Modal
        show
        onHide={onHide || closeModal}
        size={size}
        style={style}
        {...rest}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <StyledTitle variant={variant}>{title}</StyledTitle>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {children}
          <FlexRow justify="flex-end">
            {footer || (
              <>
                <hr />
                <Button
                  variant="success"
                  onClick={onOk}
                  disabled={disabled}
                >
                  {okButtonText}
                </Button>
                <Button variant="danger" onClick={closeModal}>
                  Anuluj
                </Button>
              </>
            )}
          </FlexRow>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ModalContainer;
