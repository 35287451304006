export const getOthersArray = (arr) =>
  arr.map((item) => ({
    name: item.name,
    type: item.type,
    quantity: '',
  }));

export const getPaintsArr = (arr) =>
  arr.map((order) => ({
    ...order,
    quantity: '',
    producer: order.paintMaker.toLowerCase(),
  }));
