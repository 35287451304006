import React from 'react';
import { Form, Button } from 'react-bootstrap';
import { StyledH6 } from 'components/header';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import {
  setOrdersFilters,
  clearOrdersFilters,
  resetOrdersSort,
} from 'services/store/actions/orders';
import { paintTypes } from 'services/utils/const';
import { firstLetterToUpperCase } from 'services/utils/string';

const StyledWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-bottom: 20px;
  div {
    width: 18%;
    margin: 5px;
    text-align: center;
    div {
      width: 100%;
      margin: 0;
    }
  }
  @media (max-width: 600px) {
    flex-direction: column;
    div {
      width: 100%;
      margin: 5px auto;
    }
  }
`;
const Filters = () => {
  const dispatch = useDispatch();
  const orders = useSelector((state) => state.orders.orders);
  const customers = useSelector((state) => state.orders.ordersCustomers);
  const colors = useSelector((state) => state.orders.ordersColors);
  const filters = useSelector((state) => state.orders.filters.employee);

  const handleFilter = (name, field, value) =>
    dispatch(setOrdersFilters(name, field, value));
  const clearFilters = () => {
    dispatch(clearOrdersFilters('employee'));
    dispatch(resetOrdersSort('employee'));
  };
  return (
    <div>
      {' '}
      <StyledWrapper>
        <CustomersSelect
          name="Klient"
          list={customers}
          value={filters.customer}
          handleChange={handleFilter}
          disabled={!orders}
        />
        <Select
          name="Kolor"
          listName="employee"
          fieldName="color"
          list={colors}
          value={filters.color}
          handleChange={handleFilter}
          disabled={!orders}
        />
        <Select
          name="Matowość"
          listName="employee"
          fieldName="paintType"
          list={paintTypes}
          value={filters.paintType}
          handleChange={handleFilter}
          disabled={!orders}
        />
        <Button variant="warning" onClick={clearFilters}>
          Widok domyślny
        </Button>
      </StyledWrapper>
    </div>
  );
};

export default Filters;
const Option = styled.option`
  background-color: ${({ theme, status }) => {
    if (status) return theme.positions[status];
    return 'white';
  }};
  font-weight: bold;
`;
const Select = ({
  name,
  list,
  listName,
  fieldName,
  value,
  handleChange,
  disabled,
  as = 'select',
}) => (
  <div>
    <StyledH6 style={{ color: 'black' }}>{name}</StyledH6>
    <Form.Control
      as={as}
      onChange={(e) => handleChange(listName, fieldName, e.target.value)}
      value={value}
      disabled={disabled}
    >
      {as === 'select' ? (
        <>
          <option value="" />
          {list &&
            list
              .sort((a, b) => `${a}`.localeCompare(b))
              .map((item) => (
                <Option value={item} key={item} status={item}>
                  {typeof item === 'string'
                    ? firstLetterToUpperCase(item)
                    : item}
                </Option>
              ))}
        </>
      ) : null}
    </Form.Control>
  </div>
);

const CustomersSelect = ({
  name,
  list,
  value,
  handleChange,
  disabled,
}) => (
  <div>
    <StyledH6 style={{ color: 'black' }}>{name}</StyledH6>
    <Form.Control
      as="select"
      onChange={(e) =>
        handleChange('employee', 'customer', e.target.value)
      }
      value={value}
      disabled={disabled}
    >
      <>
        <option value="" />
        {list &&
          list
            .sort((a, b) => `${a.company}`.localeCompare(b.company))
            .map((item) => (
              <Option value={item._id} key={item._id} status={item._id}>
                {firstLetterToUpperCase(
                  `${item.company}${
                    item.firstname
                      ? ` - ${item.firstname[0].toUpperCase()}`
                      : ''
                  }`,
                )}
              </Option>
            ))}
      </>
    </Form.Control>
  </div>
);
