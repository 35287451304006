import axios, { AxiosResponse } from 'axios';
import { CashRegisterStoryType } from 'services/enums/CashRegister.enum';
import { OrderPaymentStatus } from 'services/enums/Orders.enum';
import {
  ICashRegister,
  ICashRegisterStory,
} from 'services/interfaces/cash-register/CashRegister.interface';
import {
  ISearchParams,
  ISearchResult,
} from 'services/interfaces/search.interface';
import setAuthToken from 'services/utils/setAuthToken';

interface ISearchCashRegisterStoryParams extends ISearchParams {
  customer?: string;
  description?: string;
  type?: CashRegisterStoryType;
  dateFrom: Date;
  dateTo: Date;
}
interface ISettleOrdersBody {
  value: number;
  orders: {
    order: {
      _id: string;
      paymentStatus: OrderPaymentStatus;
    };
    payment: number;
  }[];
  storyItem: Partial<ICashRegisterStory>;
}

export const cashRegisterApi = {
  getCashRegister: async (): Promise<AxiosResponse<ICashRegister>> => {
    setAuthToken();
    return axios.get(`/api/cash-register`);
  },
  getCashRegisterStory: async (
    params: ISearchCashRegisterStoryParams,
  ): Promise<
    AxiosResponse<{ cashRegisterStory: ICashRegisterStory[] }>
  > => {
    setAuthToken();
    return axios.get(`/api/cash-register/story`, { params });
  },
  getCashRegisterStoryPaginated: async (
    params: ISearchCashRegisterStoryParams,
  ): Promise<AxiosResponse<ISearchResult<ICashRegisterStory>>> => {
    setAuthToken();
    return axios.get(`/api/cash-register/story/paginated`, { params });
  },
  depositMoney: async (
    deposit: number,
    storyItem: Partial<ICashRegisterStory>,
  ): Promise<AxiosResponse<{ cashRegister: ICashRegister }>> => {
    setAuthToken();
    return await axios.put(`/api/cash-register/deposit`, {
      deposit,
      storyItem,
    });
  },
  payOutMoney: async (
    payOutValue: number,
    storyItem: Partial<ICashRegisterStory>,
  ): Promise<
    AxiosResponse<{
      cashRegister: ICashRegister;
      storItem: ICashRegisterStory;
    }>
  > => {
    setAuthToken();
    return await axios.put(`/api/cash-register/pay-out`, {
      payOutValue,
      storyItem,
    });
  },
  payOutMoneyWithReport: async (
    payOutValue: number,
    storyItem: Partial<ICashRegisterStory>,
  ): Promise<AxiosResponse<any>> => {
    setAuthToken();
    return await axios({
      url: `/api/cash-register/pay-out`,
      method: 'PUT',
      responseType: 'arraybuffer',
      data: { payOutValue, storyItem },
    });
  },

  settleOrders: async (body: ISettleOrdersBody) => {
    setAuthToken();
    return axios.put(`/api/cash-register/settle-orders`, body);
  },
};
