import React from 'react';
import Modal from 'PageTemplates/ModalTemplate';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router';

const AccountCreatedModal = ({ closeModal }) => {
  const history = useHistory();

  const handleClose = () => {
    history.push('/login');
    closeModal();
  };
  return (
    <Modal
      closeModal={closeModal}
      title="Konto utworzone pomyślnie"
      footer={
        <Button variant="success" onClick={handleClose}>
          Zakończ
        </Button>
      }
    >
      <p>
        Dziękujemy za założenie konta. Proszę oczekiwać na zatwierdzenie
        przez administratora.
      </p>
      <br />
      <h4 style={{ fontWeight: 'bold' }}>Dane kontaktowe:</h4>
      <p>
        tel.: <strong>796 999 540</strong>
        <br />
        E-mail: <strong>biuro@mebleblow.pl</strong>
      </p>
    </Modal>
  );
};

export default AccountCreatedModal;
