import { useQueries } from '@tanstack/react-query';
import { PageHeader, StyledH3 } from 'components/header';
import { useModal } from 'hooks/useModal';
import PageTemplate from 'PageTemplates/PageTemplate';
import React, { useState } from 'react';
import dayjs from 'dayjs';
import { useAlert } from 'react-alert';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { cashRegisterApi } from 'services/apiRequests/cash-register.api';
import { customersApi } from 'services/apiRequests/customers.api';
import { CashRegisterStoryType } from 'services/enums/CashRegister.enum';
import { settingsApi } from 'services/apiRequests/settings.api';
import Spinner from 'components/spinner';
import CustomDateRangePicker from 'components/DatePicker/CustomDateRangePicker';
import { _cashRegisterStoryTypes } from 'services/consts/cash-register.const';
import { AxiosResponse } from 'axios';
import {
  ICashRegister,
  ICashRegisterStory,
} from 'services/interfaces/cash-register/CashRegister.interface';
import CashRegisterStoryList from './components/CashRegisterStoryList';
import { useHistory } from 'react-router';
import OrdersInCashList from './components/OrdersInCashList/OrdersInCashList';
import DepositMoneyModal from './components/DepositMoney.modal';
import PayOutMoneyModal from './components/PayOutMoney.modal';
import SettleOrdersModal from './components/SettleOrders.modal/SettleOrders.modal';
import FlexRow from 'PageTemplates/FlexRowTemplate';
import usePagination from 'hooks/usePagination';
import { ISearchResult } from 'services/interfaces/search.interface';

const CashRegisterPage = () => {
  const alert = useAlert();
  const history = useHistory();
  const [dateFrom, setDateFrom] = useState<Date>(
    dayjs().subtract(1, 'day').startOf('day').toDate(),
  );
  const [dateTo, setDateTo] = useState<Date>(
    dayjs().endOf('day').toDate(),
  );
  const [storyType, setStoryType] = useState<
    CashRegisterStoryType | undefined
  >(undefined);
  const [customer, setCustomer] = useState<string | undefined>(undefined);
  const [description, setDescription] = useState<string | undefined>(
    undefined,
  );

  const { modal, closeModal, showModal } = useModal({
    depositMoney: false,
    payOutMoney: false,
    settleOrders: false,
  });

  const [cashRegister, cashRegisterStory, customers, settings] =
    useQueries({
      queries: [
        {
          queryKey: ['get-cash-register'],
          queryFn: () => cashRegisterApi.getCashRegister(),
          onError: (err: any) => {
            console.log(err, err.response);
            alert.error('Błąd');
          },
          onSuccess(data: AxiosResponse<ICashRegister>) {
            if (data.data.lastPayOut) {
              setDateFrom(dayjs(data.data.lastPayOut).toDate());
            }
          },
        },
        {
          queryKey: [
            'get-cash-register-story',
            dateFrom,
            dateTo,
            customer,
            description,
            storyType,
          ],
          queryFn: () =>
            cashRegisterApi.getCashRegisterStory({
              dateFrom,
              dateTo,
              type: storyType,
              customer,
              description,
            }),
          onError: (err: any) => {
            console.log(err, err.response);
            alert.error('Błąd');
          },
          enabled: !!dateFrom && !!dateTo,
        },
        {
          queryKey: ['get-customers'],
          queryFn: () => customersApi.getCustomers(),
          onError: (err: any) => {
            console.log(err, err.response);
            alert.error('Błąd');
          },
        },
        {
          queryKey: ['get-settings'],
          queryFn: () => settingsApi.getSettings(),
          onError: (err: any) => {
            console.log(err, err.response);
            alert.error('Błąd');
          },
        },
      ],
    });

  const refetchCashRegisterData = async () => {
    await cashRegister.refetch();
    await cashRegisterStory.refetch();
  };

  const handleDateChange = (dates: [Date, Date]) => {
    const [start, end] = dates;
    setDateFrom(start);
    setDateTo(end);
  };

  const handleRedirectToStory = (id: string) => {
    history.push(`/blow/${id}`);
  };
  const handleRedirectToOrder = (id: string) => {
    history.push(`/order/${id}`);
  };

  // TODO Settle orders modal
  return (
    <PageTemplate>
      {(cashRegister.isLoading ||
        (cashRegisterStory.isLoading &&
          cashRegisterStory.fetchStatus !== 'idle') ||
        customers.isLoading) && <Spinner />}
      <PageHeader
        title={`Kasa${
          cashRegister.data ? `: ${cashRegister.data.data.value}zł` : ''
        }`}
        extra={[
          <Button
            key={0}
            variant="outline-primary"
            onClick={() => showModal('depositMoney')}
          >
            Zasil kasę
          </Button>,
          <Button
            key={1}
            variant="success"
            onClick={() => showModal('settleOrders')}
          >
            Rozlicz zamówienia
          </Button>,
          <Button
            key={2}
            variant="warning"
            onClick={() => showModal('payOutMoney')}
          >
            Wypłać
          </Button>,
        ]}
      />
      <hr />
      <Row className="justify-content-between">
        <div>
          <StyledH3>Historia</StyledH3>
        </div>
        <div>
          Ostatnie rozliczenie:{' '}
          <strong>
            {cashRegister.data?.data.lastPayOut
              ? dayjs(cashRegister.data.data.lastPayOut).format(
                  'DD.MM.YYYYr.',
                )
              : '-'}
          </strong>
        </div>
      </Row>
      <Row className="justify-content-between">
        <Col sm={2}>
          <Row>
            <Col sm={12}>Zakres dat:</Col>
            <Col sm={12}>
              <CustomDateRangePicker
                selectsRange={true}
                startDate={dateFrom}
                endDate={dateTo}
                onChange={handleDateChange}
                dateFormat="dd.MM.yyyy"
                locale="pl"
              />
            </Col>
          </Row>
        </Col>
        <Col sm={2}>
          <Row>
            <Col sm={12}>Typ</Col>
            <Col sm={12}>
              <Form.Control
                as="select"
                value={storyType}
                onChange={(e) =>
                  setStoryType(e.target.value as CashRegisterStoryType)
                }
              >
                <option value={undefined}></option>
                {_cashRegisterStoryTypes.map((type) => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))}
              </Form.Control>
            </Col>
          </Row>
        </Col>
        <Col sm={2}>
          <Row>
            <Col sm={12}>Opis</Col>
            <Col sm={12}>
              <Form.Control
                as="select"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              >
                <option value={undefined}></option>
                <option
                  disabled
                  value={undefined}
                  style={{
                    fontWeight: 'bold',
                    color: 'black',
                  }}
                >
                  Wpłaty
                </option>
                {settings.data?.data.settings.cashRegister.depositDesc.map(
                  (item) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ),
                )}
                <option
                  disabled
                  value={undefined}
                  style={{ fontWeight: 'bold', color: 'lightgray' }}
                >
                  ---------------------------------
                </option>
                <option
                  disabled
                  value={undefined}
                  style={{
                    fontWeight: 'bold',
                    color: 'black',
                  }}
                >
                  Wypłaty
                </option>
                {settings.data?.data.settings.cashRegister.withdrawalDesc.map(
                  (item) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ),
                )}
              </Form.Control>
            </Col>
          </Row>
        </Col>
        <Col sm={2}>
          <Row>
            <Col sm={12}>Klient</Col>
            <Col sm={12}>
              <Form.Control
                as="select"
                value={customer}
                onChange={(e) => setCustomer(e.target.value)}
              >
                <option value={undefined}></option>
                {customers.data?.data.customers.map((customer) => (
                  <option key={customer._id} value={customer._id}>
                    {customer.company} [{customer.firstname}]
                  </option>
                ))}
              </Form.Control>
            </Col>
          </Row>
        </Col>
      </Row>
      <CashRegisterStoryList
        cashRegisterStory={
          cashRegisterStory.data?.data.cashRegisterStory || []
        }
        handleRedirectToStory={handleRedirectToStory}
      />

      <hr />
      <StyledH3>Zamówienia w kasie</StyledH3>
      <OrdersInCashList
        orders={cashRegister.data?.data.ordersInCash || []}
        handleRedirect={handleRedirectToOrder}
      />

      {/* NOTE Modals */}
      {modal.depositMoney && cashRegister.data && settings.data && (
        <DepositMoneyModal
          cashRegister={cashRegister.data.data}
          closeModal={closeModal}
          refetchCashRegisterData={refetchCashRegisterData}
          settings={settings.data.data.settings}
        />
      )}
      {modal.payOutMoney && cashRegister.data && settings.data && (
        <PayOutMoneyModal
          cashRegister={cashRegister.data.data}
          closeModal={closeModal}
          refetchCashRegisterData={refetchCashRegisterData}
          settings={settings.data.data.settings}
        />
      )}
      {/* FIXME REFACTOR MODAL */}
      {modal.settleOrders && customers.data && cashRegister.data && (
        <SettleOrdersModal
          cashRegister={cashRegister.data.data}
          closeModal={closeModal}
          refetchCashRegisterData={refetchCashRegisterData}
          customers={customers.data?.data.customers}
        />
      )}
    </PageTemplate>
  );
};

export default CashRegisterPage;
