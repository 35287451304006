export const theme = {
  blowGreen: '#73a52e',
  blowGreenLight: '#cde7a9',
  blowPrimary: '#a4cfff',
  blowPrimaryLight: '#b1cff1',
  blowSuccess: '#85eb6a',
  blowSuccessLight: '#b9faa7',
  blowInfo: '#31b0c4',
  blowInfoLight: '#45c5d9',
  blowWarning: '#ffbd40',
  blowWarningLight: '#fcd7a0',
  blowDanger: '#fa3e54',
  blowDangerLight: '#e7bcba',
  blowDark: '#343a40',
  blowLightGrey: '#f5f5f5',
  blowPurple: '#e165b9',
  positions: {
    blow: '#73a52e',
    piła: '#9e9e9e',
    obróbka: '#fc8403',
    surówka: '#ffbd40',
    podkład: '#f5f5f5',
    szlifiernia: '#33cec3',
    lakiernia: '#a4cfff',
    polernia: '#fa3e54',
    pakowanie: '#85eb6a',
    zakończone: '#e165b9',
    transport: 'gray',
  },
  items: {
    lost: 'rgba(231, 188, 186, 0.8)',
    toCorrect: 'rgba(204, 247, 179, 0.8)',
    long: 'rgba(255, 153, 0, 0.5)',
    notIncludedToPrice: 'rgba(177, 207, 241, 0.8)',
  },
};
