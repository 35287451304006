import React, { FC } from 'react';
import cells from 'components/ordersList/components/cells';
import { Table } from 'react-bootstrap';
import { ICashRegisterOrder } from 'services/interfaces/cash-register/CashRegister.interface';
import { IOrder } from 'services/interfaces/orders/Orders.interface';
import OrdersInCashListRow from './OrdersInCashListRow';

interface IProps {
  orders: ICashRegisterOrder[];
  handleRedirect: (id: string) => void;
}

// TODO CHANGE TO ORDERS LIST
const OrdersInCashList: FC<IProps> = ({ orders, handleRedirect }) => {
  return (
    <Table responsive="lg" bordered hover size="sm">
      <thead>
        <tr>
          <th>Lp</th>
          <th>Klient</th>
          <th>Nr</th>
          <th>Nazwa</th>
          <th>Kolor</th>
          <th>Matowość</th>
          <th>Elementy</th>
          <th>PP</th>
          <th>PL</th>
          <th>Dł. el. niest</th>
          <th>Typ</th>
          <th>Data</th>
          <th>Data realizacji</th>
          <th>
            Cena <small>(zaliczka)</small>
          </th>
          <th>W kasie</th>
          <th>Wpłacono wcześniej</th>
          <th>Status płatności</th>
        </tr>
      </thead>
      <tbody>
        {orders.map(({ order, value }, i) => {
          return (
            <OrdersInCashListRow
              key={(order as IOrder)._id}
              index={i}
              order={order as IOrder}
              cashRegisterStoryValue={value}
              handleRedirect={() => handleRedirect((order as IOrder)._id)}
            />
          );
        })}
      </tbody>
    </Table>
  );
};

export default OrdersInCashList;
