import React from 'react';
import { firstLetterToUpperCase } from 'services/utils/string';

const PaintType = ({ order }) => {
  const { paintType, transparent } = order;
  return (
    <td
      style={paintType === 'połysk' ? { backgroundColor: '#0080FF' } : {}}
    >
      {firstLetterToUpperCase(paintType)} {transparent && '(+B)'}
    </td>
  );
};

export default PaintType;
