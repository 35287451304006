import React from 'react';
import ModalContainer from 'PageTemplates/ModalTemplate';
import { Table } from 'react-bootstrap';
import { dateToStringWithHour } from 'services/utils/date';

const UpdateHistory = ({ order, closeModal }) => {
  return (
    <ModalContainer
      title="Historia zmian"
      closeModal={closeModal}
      size="xl"
      footer={<div />}
    >
      <Table
        responsive
        size="sm"
        style={{ textAlign: 'center' }}
        striped
        hover
      >
        <thead>
          <tr>
            <th>Kiedy</th>
            <th>Kto</th>
            <th>Co</th>
            <th>Opis</th>
          </tr>
        </thead>
        <tbody>
          {order.updateHistory.map((item, index) => {
            return (
              <tr key={index}>
                <td>{dateToStringWithHour(item.date)}</td>
                <td>{item.user}</td>
                <td>{item.key}</td>
                <td>
                  {item.desc ? item.desc : `z ${item.from} na ${item.to}`}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </ModalContainer>
  );
};

export default UpdateHistory;
