export const flatString = (string) =>
  string.toLowerCase().replace(/[\s-]/g, '');

export const firstLetterToUpperCase = (string) => {
  if (string) return string.charAt(0).toUpperCase() + string.slice(1);
  return null;
};

export const getUserName = (user) =>
  `${user.company}${user.firstname ? `.${user.firstname[0]}` : ''}`;
