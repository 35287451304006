import React from 'react';
import { Card, Button, Form } from 'react-bootstrap';
import { domain } from 'services/utils/const';
import FlexRow from 'PageTemplates/FlexRowTemplate';

const ImageCard = ({ image, index, setAsPrimary, remove }) => {
  return (
    <Card style={{ width: '30%' }}>
      <Card.Img
        variant="top"
        style={{ height: 100, width: 'auto' }}
        src={image._id ? `${domain}/${image.path}` : image.path}
      />
      <Card.Body style={{ padding: 3 }}>
        <Form.Check
          id={`Primary_ID_${index}`}
          checked={image.isPrimary}
          label="Zdjęcie główne"
          onChange={() => setAsPrimary(index)}
        />

        <FlexRow justify="center">
          <Button
            variant="outline-danger"
            size="sm"
            onClick={() => remove(index)}
          >
            Usuń
          </Button>
        </FlexRow>
      </Card.Body>
    </Card>
  );
};

export default ImageCard;
