/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import Modal from 'PageTemplates/ModalTemplate';
import { ReactSortable } from 'react-sortablejs';
import styled from 'styled-components';
import { lighten } from 'polished';
import { dateToString } from 'services/utils/date';
import { firstLetterToUpperCase } from 'services/utils/string';
import { weekDays } from 'services/utils/const';
import { useDispatch } from 'react-redux';
import { useAlert } from 'react-alert';
import { setSpinner } from 'services/store/actions/view';
import { updateDayTimetable } from 'services/apiRequests/timetable/update';
import { getAllTimetables } from 'services/store/actions/timetables';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

const Container = styled.div`
  padding: 10px;
  background-color: ${({ position, theme }) => {
    if (position === 'szlifiernia' || position === 'transport')
      return lighten(0.4, theme.positions[position]);
    if (
      position === 'pakowanie' ||
      position === 'surówka' ||
      position === 'podkład'
    )
      return lighten(0.3, theme.positions[position]);
    if (position === 'polernia')
      return lighten(0.35, theme.positions[position]);
    if (position === 'lakiernia')
      return lighten(0.15, theme.positions[position]);
    return '#f0f0f0';
  }};
`;
const Bar = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid gray;
  margin-bottom: 10px;
`;
const Wrapper = styled.div`
  padding: 5px;
  border-bottom: 1px solid lightgray;
  display: flex;
  justify-content: space-between;
`;

const DayEdit = ({ closeModal, day }) => {
  const dispatch = useDispatch();
  const alert = useAlert();

  const dayName = weekDays[new Date(day.date).getDay()];
  const [list, setList] = useState([]);
  const [removed, setRemoved] = useState([]);

  useEffect(() => {
    if (day) {
      const newList = day.orders.map((order, i) => ({
        id: i,
        ...order,
      }));
      setList(newList);
    }
  }, [day]);

  const handleSubmit = async () => {
    dispatch(setSpinner(true));
    await updateDayTimetable(
      { orders: list, removed },
      day._id,
      () => {
        dispatch(
          getAllTimetables(
            () => {
              dispatch(setSpinner(false));
              closeModal();
            },
            () => {
              dispatch(setSpinner(false));
              alert.error('Błąd!');
            },
          ),
        );
      },
      () => {
        dispatch(setSpinner(false));
        alert.error('Błąd!');
      },
    );
  };

  const handleRemoveOrder = async (id, orderId) => {
    const newList = list.filter((item) => item.id !== id);
    setList(newList);
    if (!removed.includes(orderId)) setRemoved([...removed, orderId]);
  };

  return (
    <Modal
      title="Edycja dnia"
      size="xl"
      closeModal={closeModal}
      onOk={handleSubmit}
    >
      <Container position={day.position}>
        <Bar>
          <span>{dateToString(day.date)}</span>
          <span className={day.position} style={{ fontSize: 20 }}>
            {firstLetterToUpperCase(day.position)}
          </span>
          <strong>{firstLetterToUpperCase(dayName)}</strong>
        </Bar>
        <Wrapper style={{ fontWeight: 'bold' }}>
          <Item value="Lp" />
          <Item value="Klient" />
          <Item value="Nr" />
          <Item value="Kolor" />
          <Item value="Matowość" />
          <Item value="Elementy" />
          <Item value="Akcja" />
          <Item value="Usuń" />
        </Wrapper>
        <ReactSortable animation={150} list={list} setList={setList}>
          {list
            .filter((item) => item.order)
            .map((item, i) => {
              const { actions } = item;
              let action = '-';

              if (actions.all) action = 'Wszystko';
              else if (actions.left) action = 'Lewe';
              else if (actions.corrections) action = 'Poprawki';
              else if (actions.losts) action = 'Braki';

              return (
                <Wrapper key={item.id} style={{ cursor: 'all-scroll' }}>
                  <Item value={i + 1} />
                  <Item
                    value={item && item.order && item.order.user.company}
                  />
                  <Item value={item && item.order && item.order.number} />
                  <Item value={item && item.order && item.order.color} />
                  <Item
                    value={item && item.order && item.order.paintType}
                  />
                  <Item
                    value={item && item.order && item.order.elements}
                  />
                  <Item value={action} />
                  <Item
                    value={
                      <FontAwesomeIcon
                        icon={faTrash}
                        style={{ color: 'red' }}
                      />
                    }
                    onclick={() => {
                      return handleRemoveOrder(item.id, item.order._id);
                    }}
                  />
                </Wrapper>
              );
            })}
        </ReactSortable>
      </Container>
    </Modal>
  );
};

export default DayEdit;

const Item = ({ value, onclick = null }) => (
  <div
    style={{
      width: 150,
      textAlign: 'center',
      cursor: onclick && 'pointer',
    }}
    onClick={onclick}
  >
    {value}
  </div>
);
