import React, { FC, useMemo } from 'react';
import { IOrder } from 'services/interfaces/orders/Orders.interface';
import { ordersUtils } from 'services/utils/orders/orders.utils';

interface IProps {
  order: IOrder;
}

const OrderCustomElementsNumber: FC<IProps> = ({ order }) => {
  const customItemsCount = useMemo(
    () => ordersUtils.elements.getCustomItemsCount(order.customItems),
    [order.customItems],
  );

  return (
    <td title="Długość i ilość elemnetów niestandardowych">
      {order.customItemsLength?.toFixed(2)}
      <strong>
        <sub>{!!customItemsCount && customItemsCount}</sub>
      </strong>
    </td>
  );
};

export default OrderCustomElementsNumber;
