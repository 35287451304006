import { useQueries } from '@tanstack/react-query';
import { IOrderCheckboxOnChange } from 'components/ordersList/Cells/OrderCheckboxCell';
import OrdersList from 'components/ordersList/OrdersList';
import React, { FC } from 'react';
import { filterNotFullyPaidOrders } from 'scenes/CashRegister/utils/filterNotFullyPaidOrders';
import { ordersApi } from 'services/apiRequests/orders.api';
import {
  OrderPaymentStatus,
  OrderStatus,
} from 'services/enums/Orders.enum';
import { IOrder } from 'services/interfaces/orders/Orders.interface';

interface IProps {
  customer: string | undefined;
  handlePickOrder: (event: IOrderCheckboxOnChange) => void;
  isOrderPicked: (order: IOrder) => boolean;
}

const PickOrdersStep: FC<IProps> = ({
  customer,
  handlePickOrder,
  isOrderPicked,
}) => {
  const [orders] = useQueries({
    queries: [
      {
        queryKey: ['get-orders', customer],
        queryFn: async () => {
          const res = await ordersApi.getOrdersWithParams({
            status: [OrderStatus.FINISH, OrderStatus.PICKED].join(', '),
            user: customer,
            isPaid: false,
            paymentStatus: [
              OrderPaymentStatus.NOT_PAID,
              OrderPaymentStatus.HALF_PAID,
              OrderPaymentStatus.TRANSFER,
            ].join(', '),
          });
          const orders = filterNotFullyPaidOrders(res.data.orders);
          return orders;
        },
        enabled: !!customer,
        refetchInterval: false,
        refetchOnWindowFocus: false,
        initialData: [],
      },
    ],
  });

  return (
    <div>
      {customer && orders.data && (
        <OrdersList
          orders={orders.data}
          onCheck={handlePickOrder}
          isChecked={isOrderPicked}
          columns={{
            status: true,
            price: true,
            paymentStatusDisplay: true,
            paymentsList: true,
            checkbox: true,
          }}
        />
      )}
    </div>
  );
};

export default PickOrdersStep;
