import React from 'react';
import { useSelector } from 'react-redux';
import { UserPermission } from 'services/interfaces/users/Users.interface';

// FIXME change any type
const useUser = () => {
  const user = useSelector((state: any) => state.auth.user);
  const isAdmin = () => {
    if (user?.permission === UserPermission.ADMIN) return true;
    return false;
  };
  const isSuperAdmin = () => {
    if (!!user?.isBoss && isAdmin()) return true;
    return false;
  };

  return { user, isSuperAdmin, isAdmin };
};

export default useUser;
