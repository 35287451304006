import React, { FC } from 'react';
import { IOrder } from 'services/interfaces/orders/Orders.interface';
import { IUser } from 'services/interfaces/users/Users.interface';
import { ordersUtils } from 'services/utils/orders/orders.utils';

interface IProps {
  order: IOrder;
}

const OrderCustomerNameCell: FC<IProps> = ({ order }) => {
  const customerName = ordersUtils.data.getOrderCustomerName(order);
  return <td>{customerName}</td>;
};

export default OrderCustomerNameCell;
