import React from 'react';
import Modal from 'PageTemplates/ModalTemplate';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useAlert } from 'react-alert';
import { setSpinner } from 'services/store/actions/view';
import { customerPermRemove } from 'services/apiRequests/customers/remove';
import { useHistory } from 'react-router';

const RemoveUser = ({ userId, closeModal }) => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const history = useHistory();

  const handleRemoveUser = async () => {
    dispatch(setSpinner(true));
    await customerPermRemove(
      userId,
      () => {
        dispatch(setSpinner(false));
        history.push('/customers');
        alert.success('Użytkownik usunięty');
      },
      () => {
        alert.error('Błąd!');
        dispatch(setSpinner(false));
      },
    );
  };

  return (
    <Modal
      title="Usunięcie użytkownika"
      variant="danger"
      closeModal={closeModal}
      footer={
        <>
          <Button variant="success" onClick={handleRemoveUser}>
            Tak
          </Button>
          <Button variant="danger" onClick={closeModal}>
            Nie
          </Button>
        </>
      }
    >
      Czy na pewno chcesz usunąć użytkownika i jego zamówienia? Ten proces
      będzie nieodwracalny
    </Modal>
  );
};

export default RemoveUser;
