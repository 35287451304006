import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import ModalContainer from 'PageTemplates/ModalTemplate';
import { useDispatch } from 'react-redux';
import { setSpinner } from 'services/store/actions/view';
import { updateOrder } from 'services/apiRequests/orders/update';
import { setOrder } from 'services/store/actions/orders';
import { orderStatuses } from 'services/utils/const';
import FlexRow from 'PageTemplates/FlexRowTemplate';
import { firstLetterToUpperCase } from 'services/utils/string';
import { useAlert } from 'react-alert';

const Status = ({ order, closeModal }) => {
  const dispatch = useDispatch();
  const alert = useAlert();

  const [status, setStatus] = useState([]);

  const handleStatus = (e) => {
    const { checked, value } = e.target;
    if (checked) return setStatus(status.concat(value));
    return setStatus(status.filter((state) => state !== value));
  };

  const handleSubmit = async () => {
    dispatch(setSpinner(true));
    await updateOrder(
      'status',
      order._id,
      { status },
      (order) => {
        dispatch(setOrder(order));
        dispatch(setSpinner(false));
        alert.success('Zaktualizowano status');
        closeModal();
      },
      () => {
        dispatch(setSpinner(false));
        alert.error('Błąd!');
        closeModal();
      },
    );
  };
  return (
    <ModalContainer
      title="Status"
      closeModal={closeModal}
      footer={
        <>
          <Button variant="success" onClick={handleSubmit}>
            Zmień
          </Button>
          <Button variant="danger" onClick={closeModal}>
            Anuluj
          </Button>
        </>
      }
    >
      <p>
        Aktualny status zamówienia to:{' '}
        <strong>{order.status ? order.status : order.status}</strong>
        , zmień na: <br />
        {status.map((status) => (
          <span
            key={status}
            className={status}
            style={{ fontWeight: 'bold' }}
          >
            {firstLetterToUpperCase(status)}{' '}
          </span>
        ))}
      </p>
      <FlexRow direction="column" wrap>
        {orderStatuses.map((state) => (
          <Form.Group key={state} controlId={state} style={{ margin: 0 }}>
            <Form.Check type="checkbox">
              <Form.Check.Input
                type="checkbox"
                checked={status.includes(state)}
                value={state}
                onChange={handleStatus}
              />
              <Form.Check.Label>
                <span className={state} style={{ fontWeight: 'bold' }}>
                  {firstLetterToUpperCase(state)}
                </span>
              </Form.Check.Label>
            </Form.Check>
          </Form.Group>
        ))}
      </FlexRow>
    </ModalContainer>
  );
};

export default Status;
