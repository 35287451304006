import React from 'react';

const Surface = ({ order, surface }) => {
  let elements = 0;
  if (order.items) {
    elements = order.items.reduce((elements, item) => {
      if (item[surface]) elements += item.quantity;
      return elements;
    }, 0);
  }

  return (
    <td
      style={
        surface === 'surfaceLeft' &&
        order.surfaceLeft &&
        order.leftSidesFlag
          ? { fontWeight: 'bold' }
          : {}
      }
    >
      {order[surface] ? order[surface].toFixed(2) : null}
      {elements !== 0 && (
        <strong>
          <sub> {elements}</sub>
        </strong>
      )}
    </td>
  );
};

export default Surface;
