import React from 'react';
import ModalContainer from 'PageTemplates/ModalTemplate';
import { Table } from 'react-bootstrap';
import {
  dateToStringWithHour,
  getHoursMinutesString,
} from 'services/utils/date';

const ProductionHistory = ({ order, closeModal }) => {
  return (
    <ModalContainer
      title="Historia produkcji"
      closeModal={closeModal}
      footer={<div />}
      size="xl"
    >
      <Table responsive="lg" size="sm" style={{ textAlign: 'center' }}>
        <thead>
          <tr>
            <th>Stanowisko</th>
            <th>Kto</th>
            <th>Opis</th>
            <th>Kiedy</th>
            <th>Czas</th>
          </tr>
        </thead>
        <tbody>
          {order.productionHistory.map((item, index) => {
            return (
              <tr key={index}>
                <td>
                  <span
                    className={item.position}
                    style={{ fontWeight: 'bold' }}
                  >
                    {item.position}
                  </span>
                </td>
                <td>
                  {item.employees.map((item) =>
                    typeof item === 'string' ? (
                      <span key={item}>{item}, </span>
                    ) : (
                      '?'
                    ),
                  )}
                </td>
                <td>{item.desc}</td>
                <td>{dateToStringWithHour(item.date)}</td>
                <td>
                  {item.time ? getHoursMinutesString(item.time) : '-'}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </ModalContainer>
  );
};

export default ProductionHistory;
