import Axios from 'axios';

export const getAllCustomersStats = async (dates, onEnd, onErr) => {
  try {
    const res = await Axios.post(`/api/statistics/allcustomers`, {
      ...dates,
    });
    const { data } = res.data;

    onEnd(data);
  } catch (error) {
    console.log('ERROR_GET_STATS: ', error.response);
    onErr();
  }
};
