import React from 'react';
import withContext from 'hoc/withContext';

const Color = ({ order, permissionContext }) => {
  return (
    <td
      style={
        order.isBlackBase && permissionContext !== 'user'
          ? { backgroundColor: 'black', color: 'white' }
          : {}
      }
    >
      {order.color.toUpperCase()} {order.coloredWater && '(WB)'}
    </td>
  );
};

export default withContext(Color);
