import Axios from 'axios';
import FileSaver from 'file-saver';
import { dateToString } from 'services/utils/date';

export const getInvoiceFile = async (invoiceID, path, onEnd, onError) => {
  try {
    const res = await Axios({
      url: `/api/files/invoice/${invoiceID}`,
      method: 'GET',
      responseType: 'arraybuffer',
    });
    FileSaver.saveAs(new Blob([res.data]), path.split('/').pop());
    return onEnd();
  } catch (err) {
    console.log(err.response);
    onError();
  }
};
export const getCuttingItemsCSV = async (
  cuttingPackageId,
  onEnd,
  onError,
) => {
  try {
    const res = await Axios({
      url: `/api/files/cutting-items-csv/${cuttingPackageId}`,
      method: 'GET',
      responseType: 'arraybuffer',
    });
    FileSaver.saveAs(
      new Blob([res.data], { type: 'application/csv' }),
      `ciecie_CSV_${dateToString(new Date())}.csv`,
    );
    return onEnd();
  } catch (err) {
    console.log(err.response);
    onError();
  }
};
export const getCuttingItemsList = async (
  cuttingPackageId,
  onEnd,
  onError,
) => {
  try {
    const res = await Axios({
      url: `/api/files/cutting-items-list/${cuttingPackageId}`,
      method: 'GET',
      responseType: 'arraybuffer',
    });
    FileSaver.saveAs(
      new Blob([res.data], { type: 'application/xlsx' }),
      `ciecie_lista_elementow_${dateToString(new Date())}.xlsx`,
    );
    return onEnd();
  } catch (err) {
    console.log(err.response);
    onError();
  }
};
export const getOrderLabel = async (order, onEnd, onError) => {
  try {
    const res = await Axios({
      url: `/api/files/${order._id}/label`,
      method: 'GET',
      responseType: 'arraybuffer',
    });
    FileSaver.saveAs(
      new Blob([res.data], { type: 'image/png' }),
      `${order.user.company}-${order.user.firstname[0]}_${order.number}[NAKLEJKA].png`,
    );
    return onEnd();
  } catch (err) {
    console.log(err.response);
    onError();
  }
};

export const getOrderFiles = async (order, onEnd, onErr) => {
  try {
    console.log('GET FILES');
    const res = await Axios({
      url: `/api/files/${order._id}/files`,
      method: 'GET',
      responseType: 'arraybuffer',
    });

    FileSaver.saveAs(
      new Blob([res.data], { type: 'application/zip' }),
      `${order.user.company}${
        order.user.firstname ? `-${order.user.firstname[0]}` : ''
      }_${order.number}.zip`,
    );
    return onEnd();
  } catch (error) {
    console.log(error.response || error);
    onErr();
  }
};

export const getProductionList = async (data, onEnd, onError) => {
  const date = new Date();
  try {
    const res = await Axios({
      url: `/api/files/productionlist`,
      method: 'POST',
      responseType: 'arraybuffer',
      data,
    });
    FileSaver.saveAs(
      new Blob([res.data], { type: 'application/xlsx' }),
      `Lista_produkcyjna - ${dateToString(date)}.xlsx`,
    );
    return onEnd();
  } catch (err) {
    console.log(err.response);
    onError();
  }
};

export const getPaintOrder = async (orderId, onEnd, onError) => {
  try {
    const res = await Axios({
      url: `/api/files/paintorder/${orderId}`,
      method: 'GET',
      responseType: 'arraybuffer',
    });
    FileSaver.saveAs(
      new Blob([res.data], { type: 'application/xlsx' }),
      `lakiery.xlsx`,
    );
    return onEnd();
  } catch (err) {
    console.log(err.response);
    onError();
  }
};

export const getImportDataTemplate = async (onEnd, onError) => {
  try {
    const res = await Axios({
      url: `/api/files/sheettemplate`,
      method: 'GET',
      responseType: 'arraybuffer',
    });
    FileSaver.saveAs(
      new Blob([res.data], { type: 'application/xlsx' }),
      `importDataTemplate.xlsx`,
    );
    return onEnd();
  } catch (err) {
    console.log(err.response);
    onError();
  }
};

export const getPickUpReport = async (
  order,
  pickedUpElements,
  onEnd,
  onError,
) => {
  try {
    const res = await Axios({
      url: `/api/files/pickupreport/${order._id}`,
      method: 'POST',
      responseType: 'arraybuffer',
      data: pickedUpElements,
    });
    FileSaver.saveAs(
      new Blob([res.data], { type: 'application/pdf;charset=utf-8' }),
      `${order.user.company}${
        order.user.firstname ? `-${order.user.firstname[0]}` : ''
      }-${order.number}[PROTOKÓŁ ODBIORU].pdf`,
    );
    return onEnd();
  } catch (err) {
    console.log(err.response);
    onError();
  }
};
