import dayjs from 'dayjs';
import useHasPermission from 'hooks/useHasPermission';
import React, { FC, useMemo } from 'react';
import { Permissions } from 'services/enums/Permissions.enum';
import { IOrder } from 'services/interfaces/orders/Orders.interface';
import { ordersUtils } from 'services/utils/orders/orders.utils';

interface IProps {
  order: IOrder;
}

const OrderTypeCell: FC<IProps> = ({ order }) => {
  const { hasPermission } = useHasPermission();
  const type = useMemo(
    () => ordersUtils.data.getOrderType(order),
    [order],
  );
  const { style, title } = getCellData(
    order,
    hasPermission([Permissions.ADMIN, Permissions.EMPLOYEE]),
  );

  return (
    <td style={style} title={title}>
      {type}
    </td>
  );
};

export default OrderTypeCell;

const getCellData = (order: IOrder, hasPermission: boolean) => {
  const result = {
    style: { color: 'black', fontWeight: 'bold' },
    title: '',
  };
  if (!hasPermission || !order.dateSendToCNC) return result;
  else if (order.dateBackFromCNC) {
    result.title = 'Odebrane z CNC';
    result.style.color = 'green';
  } else if (dayjs().diff(dayjs(order.dateSendToCNC), 'days') > 7) {
    result.title = 'Opóźnienie w CNC';
    result.style.color = 'red';
  } else if (dayjs().diff(dayjs(order.dateSendToCNC), 'days') < 7) {
    result.title = `Wysłano do CNC ${dayjs().diff(
      dayjs(order.dateSendToCNC),
      'days',
    )} dni temu`;
  }

  return result;
};
