import React from 'react';
import withContext from 'hoc/withContext';
import { dateToString } from 'services/utils/date';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { isDeadlinePassed } from 'services/utils/orders';

const FinishDate = ({ date, permissionContext }) => {
  const orderFinishDate = new Date(date);
  const isDeadline = isDeadlinePassed(orderFinishDate);
  return (
    <td>
      {dateToString(orderFinishDate)}{' '}
      {permissionContext !== 'user' && isDeadline && (
        <FontAwesomeIcon
          style={{ fontWeight: 'bold' }}
          icon={faExclamationCircle}
        />
      )}
    </td>
  );
};

export default withContext(FinishDate);
