import React, { useState } from 'react';
import Modal from 'PageTemplates/ModalTemplate';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import MessagesList from 'components/Messages/MessagesList';
import Message from 'components/Messages/Message';
import NewMessage from 'components/Messages/NewMessage';

const initView = {
  message: null,
  newMessage: null,
};

const Messages = ({ closeModal }) => {
  const messages = useSelector((state) => state.messages.messages);
  const user = useSelector((state) => state.auth.user);

  const [view, setView] = useState(initView);

  const handleView = (key, value) =>
    setView({ ...initView, [key]: value });
  return (
    <Modal
      title="Wiadomości"
      closeModal={closeModal}
      footer={
        <>
          <Button onClick={() => handleView('newMessage', true)}>
            Nowa wiadomość
          </Button>
          <Button variant="danger" onClick={closeModal}>
            Anuluj
          </Button>
        </>
      }
    >
      {view.newMessage ? (
        <NewMessage goBack={() => handleView('newMesasage', null)} />
      ) : view.message ? (
        <Message
          message={view.message}
          closeMessage={() => handleView('message', null)}
        />
      ) : (
        <MessagesList
          messages={messages}
          user={user}
          setMessage={(message) => handleView('message', message)}
        />
      )}
    </Modal>
  );
};

export default Messages;
