import React, { useEffect, useState } from 'react';
import PageTemplate from 'PageTemplates/PageTemplate';
import { PageHeader } from 'components/header';
import { useDispatch, useSelector } from 'react-redux';
import {
  getGlobalSettings,
  updateGlobalSettings,
} from 'services/store/actions/settings';
import { useAlert } from 'react-alert';
import { Tabs, Tab } from 'react-bootstrap';
import { setSpinner } from 'services/store/actions/view';
import Terms from './components/Terms';
import Producers from './components/Producers';
import Prices from '../../../components/Prices';
import Contact from './components/Contact';
import BlackBaseColors from './components/BlackBaseColors';
import PaintOrders from './components/PaintOrders';
import PositionLimits from './components/PositionsLimits';
import PaintSecondGroup from './components/PaintSecondGroup';
import CashRegister from './components/CashRegister';

const GlobalSettings = () => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const settings = useSelector((state) => state.settings);

  const [isLoaded, setIsLoaded] = useState(false);
  const [key, setKey] = useState(0);

  useEffect(() => {
    dispatch(
      getGlobalSettings(
        () => {
          setIsLoaded(true);
        },
        () => {
          alert.error('Błąd!');
          setIsLoaded(false);
        },
      ),
    );
  }, []);

  const handleSubmit = async (body, disableEdit, message) => {
    dispatch(setSpinner(true));
    dispatch(
      updateGlobalSettings(
        body,
        () => {
          alert.success(message);
          disableEdit();
          dispatch(setSpinner(false));
        },
        () => {
          alert.error('Błąd!');
          dispatch(setSpinner(false));
        },
      ),
    );
  };

  return (
    <PageTemplate>
      <PageHeader title="Ustawienia" />
      {isLoaded && (
        <Tabs
          id="settingsTabs"
          activeKey={key}
          onSelect={(key) => setKey(key)}
        >
          <Tab eventKey={0} title="Ceny">
            <Prices prices={settings.prices} handleSubmit={handleSubmit} />
          </Tab>
          <Tab eventKey={1} title="Terminy">
            <Terms
              terms={settings.realizationDates}
              handleSubmit={handleSubmit}
            />
          </Tab>
          <Tab eventKey={2} title="Limity stanowisk">
            <PositionLimits
              terms={settings.productionLimits}
              handleSubmit={handleSubmit}
            />
          </Tab>
          <Tab eventKey={3} title="Producenci">
            <Producers
              producers={settings.paintProducers}
              handleSubmit={handleSubmit}
            />
          </Tab>
          <Tab eventKey={4} title="Zamawianie lakierów">
            <PaintOrders
              paintOrders={settings.paintOrders}
              handleSubmit={handleSubmit}
            />
          </Tab>
          <Tab eventKey={5} title="Czarny podkład">
            <BlackBaseColors
              blackBaseColors={settings.blackBaseColors}
              handleSubmit={handleSubmit}
            />
          </Tab>
          <Tab eventKey={6} title="2 grupa cenowa połysków">
            <PaintSecondGroup
              paintSecondGroup={settings.paintSecondGroup}
              handleSubmit={handleSubmit}
            />
          </Tab>
          <Tab eventKey={7} title="Kasa">
            <CashRegister
              cashRegister={settings.cashRegister}
              handleSubmit={handleSubmit}
            />
          </Tab>
          <Tab eventKey={10} title="Kontakt">
            <Contact
              contact={settings.contact}
              handleSubmit={handleSubmit}
            />
          </Tab>
        </Tabs>
      )}
    </PageTemplate>
  );
};

export default GlobalSettings;
