import moment from 'moment';

export const setFinishDate = (state, terms) => {
  const { veneer, milling, gloss, semiGloss } = terms;
  const { finishDateExtendedByUser } = state;
  let term = semiGloss;
  if (state.millings.length) term = milling;
  else if (state.veneer) term = veneer;
  else if (state.paintType === 'połysk') term = gloss;

  const newDate = new Date();
  const finishDate = newDate.setDate(newDate.getDate() + term);

  if (finishDateExtendedByUser) {
    const userFinishDate = moment(state.finishDate);
    const newFinishDate = moment(finishDate);
    if (userFinishDate.diff(newFinishDate) > 0) return { ...state };
  }

  return {
    ...state,
    finishDate: new Date(finishDate),
    finishDateExtendedByUser: false,
  };
};
