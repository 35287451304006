import React, { useState } from 'react';
import { StyledH3 } from 'components/header';
import TextInput from 'components/FormInputs/TextInput';
import { Form, Button } from 'react-bootstrap';
import { Formik } from 'formik';
import FlexRow from 'PageTemplates/FlexRowTemplate';
import styled from 'styled-components';
import * as yup from 'yup';

const Wrapper = styled.div`
  max-width: 500px;
`;

const PositionLimits = ({ terms, handleSubmit }) => {
  const [isEdit, setIsEdit] = useState(false);
  return (
    <div>
      <Formik
        validationSchema={schema}
        // ONSUBMIT REQUEST
        onSubmit={async (values) => {
          await handleSubmit(
            { productionLimits: values },
            () => setIsEdit(false),
            'Zaktualizowano limity dzienne',
          );
        }}
        initialValues={{
          ...terms,
        }}
        render={(props) => {
          return (
            <Form noValidate onSubmit={props.handleSubmit}>
              <Wrapper>
                <StyledH3>Limity dzienne [m2]</StyledH3>
                <TextInput
                  {...props}
                  name="surówka"
                  label="Surówka"
                  disabled={!isEdit}
                  required
                />
                <TextInput
                  {...props}
                  name="podkład"
                  label="Podkład"
                  disabled={!isEdit}
                  required
                />
                <TextInput
                  {...props}
                  name="szlifiernia"
                  label="Szlifiernia"
                  disabled={!isEdit}
                  required
                />
                <TextInput
                  {...props}
                  name="lakiernia"
                  label="Lakiernia"
                  disabled={!isEdit}
                  required
                />
                <TextInput
                  {...props}
                  name="polernia"
                  label="Polernia"
                  disabled={!isEdit}
                  required
                />
                <TextInput
                  {...props}
                  name="pakowanie"
                  label="Pakowanie [szt]"
                  disabled={!isEdit}
                  required
                />

                <FlexRow justify="flex-end">
                  <Button
                    variant="success"
                    type="submit"
                    disabled={!isEdit}
                  >
                    Zatwierdź
                  </Button>
                  <Button
                    variant={isEdit ? 'outline-secondary' : 'secondary'}
                    onClick={() => setIsEdit(!isEdit)}
                  >
                    {isEdit ? 'Zablokuj edycję' : 'Edytuj'}
                  </Button>
                </FlexRow>
              </Wrapper>
            </Form>
          );
        }}
      />
    </div>
  );
};

export default PositionLimits;

const schema = yup.object({
  surówka: yup
    .number()
    .typeError('wartość musi być liczbą')
    .positive('wartość nie może być ujemna')
    .required('wymagane'),
  podkład: yup
    .number()
    .typeError('wartość musi być liczbą')
    .positive('wartość nie może być ujemna')
    .required('wymagane'),
  szlifiernia: yup
    .number()
    .typeError('wartość musi być liczbą')
    .positive('wartość nie może być ujemna')
    .required('wymagane'),
  lakiernia: yup
    .number()
    .typeError('wartość musi być liczbą')
    .positive('wartość nie może być ujemna')
    .required('wymagane'),
  polernia: yup
    .number()
    .typeError('wartość musi być liczbą')
    .positive('wartość nie może być ujemna')
    .required('wymagane'),
  pakowanie: yup
    .number()
    .typeError('wartość musi być liczbą')
    .positive('wartość nie może być ujemna')
    .required('wymagane'),
});
