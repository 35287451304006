import moment from 'moment';
import { InvoicePaymentStatus } from 'services/enums/Invoices.enum';
import { IInvoice } from 'services/interfaces/invoices/Invoices.interface';

export const isInvoiceTerminated = (invoice: IInvoice) => {
  const isTerminated =
    moment(invoice.paymentTo).diff(moment(), 'hours') < 0;
  if (invoice.status !== InvoicePaymentStatus.PAID && isTerminated)
    return true;
  return false;
};
