import React from 'react';
import * as Sentry from '@sentry/browser';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './theme/index.scss';
import 'react-datepicker/dist/react-datepicker.css';
import pl from 'date-fns/locale/pl';
import { registerLocale } from 'react-datepicker';
import { Provider } from 'react-redux';
import {
  transitions,
  positions,
  Provider as AlertProvider,
} from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';
import App from './App';
import store from './services/store';

registerLocale('pl', pl);

Sentry.init({
  dsn: 'https://8a9e62f5ac1e498a9d3b37fb819f58ba@sentry.io/1854720',
});

const alertOptions = {
  position: positions.TOP_RIGHT,
  timeout: 5000,
  offset: '10px',
  transition: transitions.FADE,
};

ReactDOM.render(
  <Provider store={store}>
    <AlertProvider template={AlertTemplate} {...alertOptions}>
      <App />
    </AlertProvider>
  </Provider>,
  document.getElementById('root'),
);
