import update from 'immutability-helper';
import {
  ORDERS_LOAD_SUCCESS,
  ORDERS_LOAD_ERROR,
  ORDERS_CLEAR_LIST,
  ORDERS_SET_ENDED_FILTERS,
  ORDERS_CLEAR_FILTERS,
  ORDERS_UPDATE_ORDER_IN_LIST,
  ORDERS_SET_SORT,
  ORDERS_SET_ORDER,
  ORDERS_PRODUCTION_LOAD_SUCCESS,
  ORDERS_PRODUCTION_LOAD_ERROR,
  ORDERS_ORDER_LOAD_ERROR,
  ORDERS_RESET_SORT,
  ORDERS_SET_TIME_TO_PAINT,
} from '../types/orders';
import {
  getColorsFromOrders,
  getCustomersFromOrders,
} from './utils/orders';

const today = new Date();
const dateFrom = new Date(new Date().setDate(new Date().getDate() - 28));

const initialState = {
  // ORDERS LISTS LISTS
  orders: null,
  newOrders: null,
  endedOrders: null,
  productionOrders: null,

  // PRODUCTION DATA
  timeOnPosition: 0,

  //  SINGLE ORDER DATA
  order: null,

  //  ORDERS LISTS FILTERs DATA
  ordersCustomers: [],
  ordersCustomersProduction: [],
  ordersColors: [],

  //  SORT ORDERS
  sort: {
    new: {
      sortBy: 'date',
      direction: 'descend',
    },
    ended: {
      sortBy: 'pickUpDate',
      direction: 'descend',
    },
    production: {
      sortBy: 'status',
      direction: 'ascend',
    },
    customer: {
      sortBy: 'date',
      direction: 'descend',
    },
    employee: {
      sortBy: 'deadline',
      direction: 'descend',
    },
    paints: {
      sortBy: 'color',
      direction: 'descend',
    },
    default: {
      sortBy: null,
      direction: null,
    },
  },

  //  ORDERS FILTERS DATA
  filters: {
    ended: {
      user: '',
      number: '',
      name: '',
      color: '',
      dateFrom: new Date(new Date().setDate(1)),
      dateTo: new Date(),
    },
    customer: {
      number: '',
      name: '',
      color: '',
      dateFrom,
      dateTo: today,
    },
    production: {
      color: '',
      customer: '',
      status: '',
      paintType: '',
      name: '',
    },
    employee: {
      customer: '',
      color: '',
      paintType: '',
    },
  },
};

const ordersReducer = (state = initialState, action) => {
  switch (action.type) {
    case ORDERS_SET_TIME_TO_PAINT: {
      return { ...state, timeOnPosition: action.value };
    }
    case ORDERS_SET_ORDER: {
      return { ...state, order: action.order };
    }
    case ORDERS_ORDER_LOAD_ERROR: {
      return { ...state, order: null };
    }
    case ORDERS_LOAD_SUCCESS: {
      const { orders } = action;
      const colors = getColorsFromOrders(orders);
      const customers = getCustomersFromOrders(orders);
      return update(state, {
        [action.list || 'orders']: { $set: orders },
        ordersColors: { $set: colors },
        ordersCustomers: {
          $set: customers.sort((a, b) => `${a}`.localeCompare(b)),
        },
      });
    }
    case ORDERS_LOAD_ERROR: {
      return { ...state, orders: null };
    }
    case ORDERS_PRODUCTION_LOAD_SUCCESS: {
      const { orders } = action;
      const colors = getColorsFromOrders(orders);
      const customers = getCustomersFromOrders(orders);

      return update(state, {
        productionOrders: { $set: orders },
        ordersColors: { $set: colors },
        ordersCustomersProduction: {
          $set: customers.sort((a, b) => `${a}`.localeCompare(b)),
        },
      });
    }
    case ORDERS_PRODUCTION_LOAD_ERROR: {
      return { ...state, productionOrders: null };
    }
    case ORDERS_CLEAR_LIST: {
      return { ...state, orders: null };
    }
    case ORDERS_SET_ENDED_FILTERS: {
      return update(state, {
        filters: {
          [action.list]: { [action.field]: { $set: action.value } },
        },
      });
    }
    case ORDERS_CLEAR_FILTERS: {
      return update(state, {
        filters: {
          [action.filtersName]: {
            $set: initialState.filters[action.filtersName],
          },
        },
      });
      //  return { ...state, filters: };
    }
    case ORDERS_UPDATE_ORDER_IN_LIST: {
      const index = state[action.list].findIndex(
        (order) => order._id === action.order._id,
      );
      return update(state, {
        [action.list]: {
          [index]: { $set: action.order },
        },
      });
    }
    case ORDERS_SET_SORT: {
      return update(state, {
        sort: {
          [action.listName]: {
            sortBy: { $set: action.sortBy },
            direction: { $set: action.direction },
          },
        },
      });
    }
    case ORDERS_RESET_SORT: {
      return update(state, {
        sort: {
          [action.listName]: { $set: initialState.sort[action.listName] },
        },
      });
    }
    default:
      return state;
  }
};

export { ordersReducer };
