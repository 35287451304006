import { data } from './orderData.utils';
import { dates } from './orderDates.utils';
import { elements } from './orderElements.utils';
import { payments } from './orderPayments.utils';
import { styles } from './orderStyles.utils';

export const ordersUtils = {
  elements,
  dates,
  data,
  payments,
  styles,
};
