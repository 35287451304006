import {
  MESSAGES_LOAD_ERROR,
  MESSAGES_LOAD_SUCCESS,
  MESSAGES_BANNER_LOAD,
} from '../types/messages';

const initialState = {
  messages: [],
  banner: null,
};

const messagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case MESSAGES_LOAD_SUCCESS: {
      return { ...state, messages: action.messages };
    }
    case MESSAGES_BANNER_LOAD: {
      return { ...state, banner: action.banner };
    }
    case MESSAGES_LOAD_ERROR: {
      return { ...state, messages: [] };
    }
    default:
      return state;
  }
};

export { messagesReducer };
