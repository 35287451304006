import React from 'react';
import styled from 'styled-components';
import { Table } from 'react-bootstrap';
import ListRow from './ListRow';
import withContext from 'hoc/withContext';

const Th = styled.th`
  width: ${({ width }) => (width ? `${width}px` : 'auto')};
  background-color: ${({ primary, secondary, theme }) => {
    if (primary) return theme.blowGreen;
    if (secondary) return theme.blowWarning;
  }};
  ${({ small }) => (small ? 'font-weight:normal;font-size:12px' : '')}
`;

const CustomItemsList = ({ order, permissionContext }) => {
  if (order.customItems.length)
    return (
      <Table responsive="xl" bordered hover size="sm">
        <thead>
          <tr>
            <Th width="50" rowSpan={2}>
              <small>Materiał BLOW</small>
            </Th>
            <Th width="50" rowSpan={2}>
              Lp
            </Th>
            <th rowSpan="2" style={{ width: 100 }}>
              Typ
            </th>
            <th rowSpan="2" style={{ width: 80 }}>
              Długość
            </th>
            <th rowSpan="2" style={{ width: 80 }}>
              Szerokość
            </th>
            <Th width="50" rowSpan="4">
              Grubość płyty
              <br />
              <small>[mm]</small>
            </Th>
            <Th width="50" rowSpan="4">
              Ilość
            </Th>
            <th colSpan="2" style={{ width: 100 }}>
              Lakierowane strony
            </th>

            <th rowSpan="2" style={{ width: 600 }}>
              Uwagi
            </th>
            <th rowSpan="2" style={{ width: 100 }}>
              Długość
              <br />
              <small>[m.b.]</small>
            </th>
            {permissionContext !== 'user' && (
              <Th width={50} rowSpan="2">
                <small>Brak</small>
              </Th>
            )}
            {permissionContext !== 'user' && (
              <Th width={50} rowSpan="2">
                <small>Popr.</small>
              </Th>
            )}
          </tr>
          <tr>
            <Th width={50} primary>
              P
            </Th>
            <Th width={50} secondary>
              L
            </Th>
          </tr>
        </thead>
        <tbody>
          {order.customItems.map((item, index) =>
            item !== null ? (
              <ListRow
                item={item}
                order={order}
                index={index}
                key={item._id || Math.random()}
              />
            ) : null,
          )}
        </tbody>
      </Table>
    );
  return 'Brak elementów niestandardowych';
};

export default withContext(CustomItemsList);
