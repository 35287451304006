export enum OrderType {
  COMPLAINT = 'Reklamacja (wina BLOW)',
  CORRECTION = 'Poprawa (wina klienta)',
  ADDITIONAL_ORDER = 'Domówienie',
  CUSTOMER_MATERIAL = 'Materiał klienta',
  ELEMENTS_LIST = 'Lista elementów',
}
export enum OrderPaintType {
  SEMIGLOSS = 'półmat',
  MAT = 'mat',
  GLOSS = 'połysk',
}
export enum OrderPaintStyle {
  SINGLE_SIDE = 'jednostronne',
  BOTH_SIDES = 'dwustronne',
  GLOSS_SEMIGLOSS = 'prawa - połysk / lewa - półmat',
}
export enum OrderMechanicalBy {
  BLOW = 'blow',
  CUSTOMER = 'customer',
}
export enum OrderStatus {
  SEND = 'wysłane',
  SAW = 'piła',
  MACHINING = 'obróbka',
  RAW = 'surówka',
  BASE = 'podkład',
  GRINDERY = 'szlifiernia',
  PAINT = 'lakiernia',
  POLISH = 'polernia',
  PACKING = 'pakowanie',
  FINISH = 'zakończone',
  PICKED = 'odebrane',
}
export enum OrderPaymentStatus {
  PAID = 'Opłacone',
  HALF_PAID = 'Opłacone częściowo',
  NOT_PAID = 'Nieopłacone',
  TRANSFER = 'Przelew',
}

// --------------------------------------------------------------------------
// NOTE ITEMS
// --------------------------------------------------------------------------
export enum OrderItemType {
  FLAT = 'gładki',
  MILLED = 'frez',
  GLASS_CASE = 'witryna',
  FLAT_CNC = 'gładki CNC',
  GROOVED = 'ryflowany',
}
export enum OrderItemRightSideEdge {
  R1 = 'r1',
  R2 = 'r2',
  CHAMFERING = 'gierunek',
  EMPTY = '-',
}
export enum OrderItemLeftSideEdge {
  R1 = 'r1',
  R2 = 'r2',
  HOLE_2 = '2 otw',
  HOLE_3 = '3 otw',
  HOLE_4 = '4 otw',
  HOLE_5 = '5 otw',
  HOLE_6 = '6 otw',
  HOLE_7 = '7 otw',
  CHAMFERING = 'gierunek',
  FELC = 'felc',
  NUT = 'nut',
  EMPTY = '-',
}
export enum OrderItemCuttingStatus {
  TO_CUT = 'do wycięcia',
  SUSPENDED = 'wstrzymany',
  SKIPPED = 'pominięty',
  READY = 'gotowy',
}
export enum OrderItemSurfaces {
  LEFT = 'surfaceLeft',
  RIGHT = 'surfaceRigth',
}
export enum OrderTypes {
  VENEER = 'fornir',
  CNC = 'CNC',
}
// --------------------------------------------------------------------------
// NOTE CUSTOM ITEMS
// --------------------------------------------------------------------------
export enum OrderCustomItemType {
  PILASTER = 'pilaster',
  CROWN = 'korona',
}
