import withContext from 'hoc/withContext';
import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import Modal from 'PageTemplates/ModalTemplate';
import { setBannerReaded } from 'services/apiRequests/messages/update';
import { setSpinner } from 'services/store/actions/view';
import { domain } from 'services/utils/const';

const BannerDisplay = ({ closeModal, banner }) => {
  const dispatch = useDispatch();
  const [messageReaded, setMessageReaded] = useState(false);

  const handleSetReaded = async () => {
    dispatch(setSpinner(true));
    await setBannerReaded(
      () => {
        dispatch(setSpinner(false));
        closeModal();
      },
      () => {
        dispatch(setSpinner(false));
        alert.error('Błąd!');
      },
    );
  };

  return (
    <Modal
      title="INFO"
      closeModal={() => {}}
      onOk={handleSetReaded}
      disabled={!messageReaded}
      backdrop="static"
    >
      <div dangerouslySetInnerHTML={{ __html: banner.message }} />
      <div style={{ textAlign: 'center' }}>
        {banner.imgPath && <img src={`${banner.imgPath}`} height={300} />}
      </div>
      <hr />
      <strong>
        <Form.Group controlId="formBasicCheckbox">
          <Form.Check
            type="checkbox"
            label="Przeczytałem"
            checked={messageReaded}
            onChange={(e) => setMessageReaded(e.target.checked)}
          />
        </Form.Group>
      </strong>
    </Modal>
  );
};

export default withContext(BannerDisplay);
