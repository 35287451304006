import axios from 'axios';
import { PageHeader, StyledH3, StyledH6 } from 'components/header';
import React, { useCallback, useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { setSpinner } from 'services/store/actions/view';
import { dateToString } from 'services/utils/date';
import { firstLetterToUpperCase } from 'services/utils/string';
import PageTemplate from 'PageTemplates/PageTemplate';
import OrdersInCashList from 'scenes/CashRegister/components/OrdersInCashList/OrdersInCashList';

const CashRegisterStoryPreview = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const alert = useAlert();
  const { id } = useParams();
  const [storyItem, setStoryItem] = useState(null);

  const getStoryItem = useCallback(async () => {
    try {
      dispatch(setSpinner(true));
      const res = await axios.get(`/api/cash-register/story/${id}`);
      setStoryItem(res.data.cashRegisterStoryItem);
    } catch (error) {
      console.log(error, error.response);
      alert.error('Błąd!');
    } finally {
      dispatch(setSpinner(false));
    }
  }, [id]);

  useEffect(() => {
    getStoryItem();
  }, []);

  const handleRedirectToOrder = (id) => history.push(`/order/${id}`);

  return (
    <PageTemplate>
      {storyItem && (
        <>
          <PageHeader
            title={`${firstLetterToUpperCase(storyItem.type)}: ${
              storyItem.description
            }`}
            extra={[
              <StyledH6 key={1}>
                Data: {dateToString(storyItem.date)}
              </StyledH6>,
            ]}
          />
          <StyledH3>Wartość: {storyItem.value}zł</StyledH3>
          <StyledH6>Stan kasy: {storyItem.cashRegisterValue}zł</StyledH6>
          <div>
            <span>{storyItem.info}</span>
          </div>
          <hr />
          <OrdersInCashList
            orders={storyItem.orders}
            handleRedirect={handleRedirectToOrder}
          />
        </>
      )}
    </PageTemplate>
  );
};

export default CashRegisterStoryPreview;
