/* eslint-disable no-shadow */
import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import ModalContainer from 'PageTemplates/ModalTemplate';
import { useDispatch } from 'react-redux';
import { setSpinner } from 'services/store/actions/view';
import { updateOrder } from 'services/apiRequests/orders/update';
import { setOrder } from 'services/store/actions/orders';
import { useAlert } from 'react-alert';

const AdvancePayment = ({ order, closeModal }) => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const [advancePayment, setAdvancePayment] = useState('');

  const handleInput = (e) => {
    const { value } = e.target;
    const numbers = /^[\d|.|,]*$/;
    if (!value.match(numbers)) return;
    setAdvancePayment(value.replace(',', '.'));
  };
  const handleSubmit = async () => {
    dispatch(setSpinner(true));

    await updateOrder(
      'advance-payment',
      order._id,
      { advancePayment },
      (order) => {
        dispatch(setOrder(order));
        dispatch(setSpinner(false));
        alert.success('Zaliczka zatwierdzona');
        closeModal();
      },
      () => {
        dispatch(setSpinner(false));
        alert.error('błąd!');
        closeModal();
      },
    );
  };

  return (
    <ModalContainer
      title="Cena zamówienia"
      closeModal={closeModal}
      footer={
        <>
          <Button variant="success" onClick={handleSubmit}>
            Zatwierdź
          </Button>
          <Button variant="danger" onClick={closeModal}>
            Anuluj
          </Button>
        </>
      }
    >
      Wartość zaliczki:
      <Form.Control
        type="text"
        placeholder="kwota"
        style={{ width: '30%', display: 'inline' }}
        value={advancePayment}
        onChange={handleInput}
      />
    </ModalContainer>
  );
};

export default AdvancePayment;
