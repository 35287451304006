import {
  CATALOG_LOAD_SUCCESS,
  CATALOG_LOAD_ERROR,
  CATALOG_ADD_TO_LIST,
  CATALOG_REMOVE_FROM_LIST,
  CATALOG_UPDATE_IN_LIST,
} from '../types/catalog';
import axios from 'axios';
import setAuthToken from 'services/utils/setAuthToken';
import { setSpinner } from './view';

export const catalogLoadSuccess = (
  fronts,
  glassCases,
  handles,
  pilasters,
  crowns,
) => ({
  type: CATALOG_LOAD_SUCCESS,
  fronts,
  glassCases,
  handles,
  pilasters,
  crowns,
});

export const catalogLoadError = () => ({
  type: CATALOG_LOAD_ERROR,
});

export const catalogAddToList = (listName, item) => ({
  type: CATALOG_ADD_TO_LIST,
  listName,
  item,
});

export const catalogRemoveFromList = (listName, id) => ({
  type: CATALOG_REMOVE_FROM_LIST,
  listName,
  id,
});

// FIXME UPDATE IN LIST NOT BY INDEX, findIndex by id
export const catalogUpdateInList = (listName, index, item) => ({
  type: CATALOG_UPDATE_IN_LIST,
  listName,
  index,
  item,
});

// THUNK
export const getCatalog = (onEnd, onError) => async (dispatch) => {
  if (localStorage.token) setAuthToken(localStorage.token);
  try {
    const res = await axios.get(`/api/catalog/`);
    const { fronts, glassCases, handles, pilasters, crowns } = res.data;

    dispatch(
      catalogLoadSuccess(fronts, glassCases, handles, pilasters, crowns),
    );
    if (onEnd) onEnd();
  } catch (error) {
    console.log(`THUNK_CATALOG: `, error, error.response);
    dispatch(catalogLoadError());
    if (onError) onError();
    if (error.response && error.response.status === 401)
      window.location.reload();
  }
  dispatch(setSpinner(false));
};

export const createFront =
  (frontData, onEnd, onError) => async (dispatch) => {
    if (localStorage.token) setAuthToken(localStorage.token);
    try {
      const body = new FormData();
      frontData.photos.forEach((photo) =>
        body.append('file', photo.file, photo.file.name),
      );

      frontData.photos = frontData.photos.map((photo) => ({
        ...photo,
        file: undefined,
        path: '',
      }));
      body.append('frontData', JSON.stringify(frontData));

      const res = await axios.post(`/api/catalog/`, body);
      const { newFront } = res.data;

      dispatch(catalogAddToList(`${frontData.type}s`, newFront));
      if (onEnd) onEnd();
    } catch (error) {
      console.log(`THUNK_CATALOG: `, error, error.response);
      if (onError) onError();
      if (error.response && error.response.status === 401)
        window.location.reload();
    }
    dispatch(setSpinner(false));
  };

export const updateFront =
  (millingData, onEnd, onError) => async (dispatch) => {
    if (localStorage.token) setAuthToken(localStorage.token);
    try {
      const body = new FormData();
      millingData.photos.forEach((photo) => {
        if (photo.file)
          return body.append('file', photo.file, photo.file.name);
      });

      millingData.photos = millingData.photos.map((photo) => {
        if (!photo._id)
          return {
            ...photo,
            file: undefined,
            path: '',
          };
        return photo;
      });
      body.append('millingData', JSON.stringify(millingData));

      await axios.put(`/api/catalog/${millingData._id}`, body);

      if (onEnd) onEnd();
    } catch (error) {
      console.log(`THUNK_CATALOG: `, error, error.response);
      if (onError) onError();
      if (error.response && error.response.status === 401)
        window.location.reload();
    }
    dispatch(setSpinner(false));
  };

export const removeMillingPhoto =
  (millingId, photoId, onEnd, onError) => async (dispatch) => {
    if (localStorage.token) setAuthToken(localStorage.token);
    try {
      await axios.patch(`/api/catalog/${millingId}/${photoId}`);

      if (onEnd) onEnd();
    } catch (error) {
      console.log(`THUNK_CATALOG: `, error, error.response);
      if (onError) onError();
      if (error.response && error.response.status === 401)
        window.location.reload();
    }
    dispatch(setSpinner(false));
  };

export const removeFront =
  (frontData, onEnd, onError) => async (dispatch) => {
    if (localStorage.token) setAuthToken(localStorage.token);
    try {
      await axios.delete(`/api/catalog/${frontData._id}`);

      dispatch(catalogRemoveFromList(`${frontData.type}s`, frontData._id));
      if (onEnd) onEnd();
    } catch (error) {
      console.log(`THUNK_CATALOG: `, error, error.response);
      if (onError) onError();
      if (error.response && error.response.status === 401)
        window.location.reload();
    }
    dispatch(setSpinner(false));
  };
