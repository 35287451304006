import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import {
  OrderItemCuttingStatus,
  OrderItemType,
} from 'services/enums/Orders.enum';
import { ICuttingPackage } from 'services/interfaces/cutting-package/CuttingPackage.interface';
import {
  IOrder,
  IOrderItem,
} from 'services/interfaces/orders/Orders.interface';
import PackageListRow from './PackageListRow';

interface ISummary {
  toCutFlat: number;
  toCutCNC: number;
  suspended: number;
  skipped: number;
  readyFlat: number;
  readyCNC: number;
}

interface IProps {
  packages: ICuttingPackage[];
}

const CuttingPackagesList: React.FC<IProps> = ({ packages }) => {
  const [summary, setSummary] = useState({
    toCutFlat: 0,
    toCutCNC: 0,
    suspended: 0,
    skipped: 0,
    readyFlat: 0,
    readyCNC: 0,
  });

  useEffect(() => {
    if (packages) {
      const summary = packages
        .reduce((acc: IOrder[], curr: ICuttingPackage) => {
          return [...acc, ...(curr.orders as IOrder[])];
        }, [])
        .reduce((acc: IOrderItem[], curr: IOrder) => {
          return [...acc, ...curr.items];
        }, [])
        .reduce(
          (acc: ISummary, curr: IOrderItem) => {
            if (
              curr.cuttingStatus === OrderItemCuttingStatus.TO_CUT &&
              curr.type === OrderItemType.FLAT
            )
              return { ...acc, toCutFlat: acc.toCutFlat + curr.quantity };
            if (
              curr.cuttingStatus === OrderItemCuttingStatus.TO_CUT &&
              [
                OrderItemType.FLAT_CNC,
                OrderItemType.GLASS_CASE,
                OrderItemType.MILLED,
                OrderItemType.GROOVED,
              ].includes(curr.type)
            )
              return { ...acc, toCutCNC: acc.toCutCNC + curr.quantity };
            if (curr.cuttingStatus === OrderItemCuttingStatus.SUSPENDED)
              return { ...acc, suspended: acc.suspended + curr.quantity };
            if (curr.cuttingStatus === OrderItemCuttingStatus.SKIPPED)
              return { ...acc, skipped: acc.skipped + curr.quantity };
            if (
              curr.cuttingStatus === OrderItemCuttingStatus.READY &&
              curr.type === OrderItemType.FLAT
            )
              return { ...acc, readyFlat: acc.readyFlat + curr.quantity };
            if (
              curr.cuttingStatus === OrderItemCuttingStatus.READY &&
              [
                OrderItemType.FLAT_CNC,
                OrderItemType.GLASS_CASE,
                OrderItemType.MILLED,
                OrderItemType.GROOVED,
              ].includes(curr.type)
            )
              return { ...acc, readyCNC: acc.readyCNC + curr.quantity };

            return acc;
          },
          {
            toCutFlat: 0,
            toCutCNC: 0,
            suspended: 0,
            skipped: 0,
            readyFlat: 0,
            readyCNC: 0,
          },
        );
      setSummary(summary);
    }
  }, [packages]);

  return (
    <div>
      <Table responsive="lg" bordered hover size="sm">
        <thead>
          <tr>
            <th>Lp.</th>
            <th>Nazwa</th>
            <th>Utworzone przez</th>
            <th>Ilość zamówień</th>
            <th>Zamówienia</th>
            <th>
              Elem. do wycięcia
              <div>
                <small style={{ fontWeight: 'normal' }}>
                  [Gładkie/CNC]
                </small>
              </div>
            </th>
            <th>Elem. wstrzymane</th>
            <th>Elem. pominięte</th>
            <th>
              Elem. gotowe
              <div>
                <small style={{ fontWeight: 'normal' }}>
                  [Gładkie/CNC]
                </small>
              </div>
            </th>
            <th>Data utworzenia</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {packages?.map((cuttingPackage, i) => (
            <PackageListRow
              key={cuttingPackage._id}
              index={i}
              cuttingPackage={cuttingPackage}
            />
          ))}
          <tr>
            <td
              colSpan={5}
              style={{
                height: '50px',
                fontSize: 18,
                textAlign: 'right',
              }}
            >
              <strong>Suma:</strong>
            </td>
            <td>
              {summary.toCutFlat}/{summary.toCutCNC}
            </td>
            <td>{summary.suspended}</td>
            <td>{summary.skipped}</td>
            <td>
              {summary.readyFlat}/{summary.readyCNC}
            </td>
            <td colSpan={2}></td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

export default CuttingPackagesList;
