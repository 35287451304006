import FileSaver from 'file-saver';
import { orderItemCuttingStatus } from 'services/utils/const';
import { dateToString } from 'services/utils/date';

export const isMachningRequired = (item) => {
  if (
    item.milledHandle ||
    item.milledPartHandle ||
    [
      '2 otw',
      '3 otw',
      '4 otw',
      '5 otw',
      '6 otw',
      '7 otw',
      'nut',
      'felc',
      'gierunek',
    ].includes(item.w1L) ||
    [
      '2 otw',
      '3 otw',
      '4 otw',
      '5 otw',
      '6 otw',
      '7 otw',
      'nut',
      'felc',
      'gierunek',
    ].includes(item.w2L) ||
    [
      '2 otw',
      '3 otw',
      '4 otw',
      '5 otw',
      '6 otw',
      '7 otw',
      'nut',
      'felc',
      'gierunek',
    ].includes(item.h1L) ||
    [
      '2 otw',
      '3 otw',
      '4 otw',
      '5 otw',
      '6 otw',
      '7 otw',
      'nut',
      'felc',
      'gierunek',
    ].includes(item.h2L)
  )
    return true;
  return false;
};

export const createCSVPackageFile = (pcg) => {
  const { orders, name } = pcg;
  const today = new Date();
  const date = dateToString(today);
  const items = orders
    .reduce((allItems, order) => {
      return [
        ...allItems,
        ...order.items.map((item, index) => ({
          ...item,
          numberInOrder: index + 1,
          orderName: `${item.isMachiningRequired ? '[O]_' : ''}${
            order.userName
          } - ${order.number}`,
          orderNumber: order.number,
        })),
      ];
    }, [])
    .filter(
      (item) => item.cuttingStatus === orderItemCuttingStatus.TO_CUT,
    );

  const J = `//J;JobGroup;Date;Number;Name;Description;Price;Discount;Weight;Term;Order;CustomerCode;CustomerShortName;CustomerName;CustomerAddress;CustomerPostalCode;CustomerCity;CustomerState;DeliveryName;DeliveryAddress;DeliveryPostalCode;DeliveryCity;DeliveryState;CustomerPerson;CustomerTelephone;CustomerEmail;CustomerWeb;CustomerBankAccount;CustomerCompanyID;CustomerTaxID;CustomerVATID;Documented;Produced\r`;
  const P = `//P;Number;Code;Name;Description;Count;Image;Price;FixedPrice;TotalPrice;Weight;TotalWeight\r`;
  const I = `//I;Number;FullNumber;Type;Code;Name;Description;Price;Weight;Count;Length;Width;Thickness;Grain;MaterialCode;LayerCount;GroupType;GroupNumber;GroupPosition;GroupPieces;CornerLengthLT;CornerWidthLT;CornerTypeLT;CornerLengthRT;CornerWidthRT;CornerTypeRT;CornerLengthRB;CornerWidthRB;CornerTypeRB;CornerLengthLB;CornerWidthLB;CornerTypeLB;EdgeCodeT;EdgeCodeR;EdgeCodeB;EdgeCodeL;EdgeCodeLT;EdgeCodeRT;EdgeCodeRB;EdgeCodeLB;Accessory1Code;Accessory1Quantity;Accessory2Code;Accessory2Quantity;CustomData1;CustomData2;CustomData3;CustomData4;CustomData5;CustomData6;CustomData7;CustomData8;CustomData9;CustomData10\r`;

  const dataJ = `J;- Glowna grupa -;${date};-;${name};;-;0;-;${date};;-;${name};${name};;;;;;;;;;;;;;;;;;0;0\r`;
  const dataP = `P;2;;${name};;;;;-;;;\r`;
  const dataI = items.map(
    (item, index) =>
      `I;${index + 1};${item.orderNumber}.${index + 1};1;;${
        item.orderName
      } [${item.numberInOrder}];;;;${item.quantity};${item.height};${
        item.width
      };${item.thickness};-;MZ${
        item.thickness
      };1;0;0;0;0;0;0;0;0;0;0;0;0;0;0;0;0;;;;;;;;;;0;;0;;;;;;;;;;\r`,
  );
  const CSV = `${J}${P}${I}${dataJ}${dataP}${dataI.join('')}`;

  FileSaver.saveAs(
    new Blob([CSV], { type: 'text/csv;charset=windows-1252;' }),
    `ciecie_CSV_${date}.csv`,
  );
};
