import React from 'react';

const Name = ({ name }) => {
  const getName = () => {
    if (name && name.length > 35) return `${name.slice(0, 35)}...`;
    return name;
  };
  return <td title={name}>{name ? getName() : null}</td>;
};

export default Name;
