import React, { FC } from 'react';
import { OrderItemSurfaces } from 'services/enums/Orders.enum';
import { IOrder } from 'services/interfaces/orders/Orders.interface';
import { OrdersListCells } from './Cells';
import { IOrderListProps } from './OrdersList';
import OrdersListTableRow from './OrdersListTableRow';

interface IProps extends Omit<IOrderListProps, 'orders'> {
  order: IOrder;
  index: number;
}

const OrdersListRow: FC<IProps> = ({
  order,
  onRowClick,
  onCheck,
  isChecked,
  columns,
  index,
}) => {
  return (
    <OrdersListTableRow onRowClick={onRowClick}>
      {columns.checkbox && onCheck && isChecked && (
        <OrdersListCells.checkbox
          order={order}
          onCheck={onCheck}
          isChecked={isChecked}
        />
      )}
      {columns.lp && <td>{index + 1}</td>}
      {columns.customer && <OrdersListCells.customerName order={order} />}
      {columns.number && <OrdersListCells.orderNumber order={order} />}
      {columns.name && <OrdersListCells.orderName order={order} />}
      {columns.color && <OrdersListCells.color order={order} />}
      {columns.paintType && <OrdersListCells.paintType order={order} />}
      {columns.elements && <OrdersListCells.elements order={order} />}
      {columns.surfaceRight && (
        <OrdersListCells.surface
          surfaceType={OrderItemSurfaces.RIGHT}
          surfaceValue={order.surfaceRight}
          order={order}
        />
      )}
      {columns.surfaceLeft && (
        <OrdersListCells.surface
          surfaceType={OrderItemSurfaces.LEFT}
          surfaceValue={order.surfaceLeft}
          order={order}
        />
      )}
      {columns.elements && (
        <OrdersListCells.customElements order={order} />
      )}
      {columns.type && <OrdersListCells.orderType order={order} />}
      {columns.date && <OrdersListCells.date order={order} />}
      {columns.finishDate && <OrdersListCells.finishDate order={order} />}
      {columns.status && <OrdersListCells.orderStatus order={order} />}
      {columns.price && <OrdersListCells.price order={order} />}
      {columns.paymentsList && (
        <OrdersListCells.paymentsList order={order} />
      )}
      {columns.paymentStatusDisplay && (
        <OrdersListCells.paymentStatusDisplay order={order} />
      )}
    </OrdersListTableRow>
  );
};

export default OrdersListRow;
