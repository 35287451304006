import React, { useState, useEffect } from 'react';
import PageTemplate from 'PageTemplates/PageTemplate';
import { PageHeader } from 'components/header';
import SidebarTemplate from 'PageTemplates/SidebarTemplate';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import {
  calcNewOrderValues,
  setNewOrderField,
  setNewOrderFinisDate,
} from 'services/store/actions/newOrder';
import { getGlobalSettings } from 'services/store/actions/settings';
import { useAlert } from 'react-alert';
import { useHistory, useParams, Redirect } from 'react-router';
import withContext from 'hoc/withContext';
import { validateItems } from '../utils/validateItems';
import ItemsErrors from '../components/Modals/ItemsErrors';
import OrderForm from '../components/OrderForm';
import ChangeType from '../components/Modals/ChangeType';
import CustomerOrderType from './components/CustomerOrderType';
import Sidebar from '../components/Sidebar';

const initModals = {
  changeType: false,
  customerChangeType: false,
};

const EditOrder = ({ permissionContext }) => {
  const alert = useAlert();
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();
  const newOrder = useSelector((state) => state.newOrder);
  const realizationDates = useSelector(
    (state) => state.settings.realizationDates,
  );
  const paintSecondGroup = useSelector(
    (state) => state.settings.paintSecondGroup,
  );

  const [errors, setErrors] = useState([]);
  const [modals, setModals] = useState(initModals);

  useEffect(() => {
    dispatch(
      getGlobalSettings(
        () => {},
        () => {
          alert.error('Błąd pobierania ustawień!');
        },
      ),
    );
  }, []);

  useEffect(() => {
    if (newOrder.color && paintSecondGroup) {
      const isExist = paintSecondGroup.find(
        (item) => item.toLowerCase() === newOrder.color.toLowerCase(),
      );
      dispatch(setNewOrderField('glossSecondGroup', !!isExist));
    }
  }, [newOrder.color, paintSecondGroup]);

  useEffect(() => {
    if (newOrder.user?.realizationDates && newOrder.user?.isVip)
      dispatch(setNewOrderField('terms', newOrder.user.realizationDates));
    else dispatch(setNewOrderField('terms', realizationDates));
  }, [newOrder.user, realizationDates]);

  // set order finish date
  useEffect(() => {
    if (
      newOrder.terms &&
      newOrder.terms.veneer &&
      newOrder.terms.milling &&
      newOrder.terms.gloss &&
      newOrder.terms.semiGloss
    )
      dispatch(setNewOrderFinisDate(newOrder.terms));
  }, [
    newOrder.terms,
    newOrder.color,
    newOrder.paintType,
    newOrder.millings,
    newOrder.veneer,
  ]);

  const handleModal = (key) => setModals({ ...modals, [key]: true });
  const closeModal = () => setModals(initModals);
  const handleNext = () => {
    const isErrors = validateItems(newOrder, setErrors);
    if (!isErrors) {
      dispatch(calcNewOrderValues());
      history.push('/editorder/summary');
    }
  };

  if (!newOrder.user) return <Redirect to={`/order/${params.id}`} />;

  return (
    <PageTemplate>
      <SidebarTemplate Component={<Sidebar />}>
        <PageHeader
          title="Edycja zamówienia"
          extra={[
            permissionContext === 'admin' && (
              <Button key={0} onClick={() => handleModal('changeType')}>
                Zmień typ
              </Button>
            ),
            permissionContext === 'user' &&
              ['Nowe zamówienie', 'Materiał klienta'].includes(
                newOrder.orderType,
              ) && (
                <Button
                  key={0}
                  onClick={() => handleModal('customerChangeType')}
                >
                  Zmień typ
                </Button>
              ),
          ]}
        />
        <OrderForm order={newOrder} handleNext={handleNext} />
      </SidebarTemplate>
      {/* MODALS */}
      {errors.length ? (
        <ItemsErrors errors={errors} closeModal={() => setErrors([])} />
      ) : null}
      {modals.changeType && (
        <ChangeType order={newOrder} closeModal={closeModal} />
      )}
      {modals.customerChangeType && (
        <CustomerOrderType order={newOrder} closeModal={closeModal} />
      )}
    </PageTemplate>
  );
};

export default withContext(EditOrder);
