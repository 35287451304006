/* eslint-disable no-use-before-define */
/* eslint-disable prefer-destructuring */
import { isOrderWetForPosition } from './orders';

export const sortMethod = {
  string: (a, b, key, direction) => {
    if (key === 'company') {
      //   For orders list
      a = a.user[key];
      b = b.user[key];
    } else if (key === 'status') {
      a = a.status[0];
      b = b.status[0];
    } else if (key === 'customerCompany') {
      //   For customers list
      a = a.company;
      b = b.company;
    } else {
      a = a[key];
      b = b[key];
    }

    if (direction === 'ascend') return a.localeCompare(b);
    if (direction === 'descend') return b.localeCompare(a);
  },

  date: (a, b, key, direction) => {
    const dateA = new Date(a[key]);
    const dateB = new Date(b[key]);

    if (direction === 'ascend') return dateA - dateB;
    if (direction === 'descend') return dateB - dateA;
  },

  number: (a, b, key, direction) => {
    if (key === 'number') {
      let a1;
      let b1;
      if (a[key].includes('.')) a1 = a[key].split('.')[0];
      else a1 = a[key];
      if (b[key].includes('.')) b1 = b[key].split('.')[0];
      else b1 = b[key];

      if (direction === 'ascend') return a1 - b1;
      if (direction === 'descend') return b1 - a1;
    }

    if (direction === 'ascend') return a[key] - b[key];
    if (direction === 'descend') return b[key] - a[key];
  },

  deadline: (a, b, key, direction, position) =>
    sortByDeadlineForPosition(a, b, position),

  getSortMethod: (key) => {
    const strings = [
      'company',
      'customerCompany',
      'status',
      'color',
      'paintType',
      'surname',
      'firstname',
    ];
    const numbers = ['number', 'ordersNumber'];
    const dates = ['date', 'finishDate', 'pickUpDate', 'addDate'];

    if (key === 'default') return key;
    if (strings.includes(key)) return 'string';
    if (numbers.includes(key)) return 'number';
    if (dates.includes(key)) return 'date';
    if (key === 'deadline') return 'deadline';
  },
};

const sortByDeadlineForPosition = (a, b, position) => {
  // sort orders by deadline, but wet orders go to end of list
  if (isOrderWetForPosition(a, position)) return 1;
  if (isOrderWetForPosition(b, position)) return -1;

  if (a.priority || b.priority) return b.priority - a.priority;

  const dateA = new Date(a.finishDate);
  const dateB = new Date(b.finishDate);

  if (
    position !== 'polernia' &&
    position !== 'pakowanie' &&
    a.paintType === 'połysk'
  ) {
    dateA.setDate(dateA.getDate() - 4);
    dateB.setDate(dateB.getDate() - 4);
  }

  if (position === 'pakowanie') {
    if (a.status.includes('zakończone') && a.status.includes('pakowanie'))
      return -1;
    if (b.status.includes('zakończone') && b.status.includes('pakowanie'))
      return 1;
    if (a.status.includes('zakończone') && !a.status.includes('pakowanie'))
      return 1;
    if (b.status.includes('zakończone') && !b.status.includes('pakowanie'))
      return -1;
    if (a.status[0] === 'pakowanie') return dateA - dateB;
    if (b.status[0] === 'pakowanie') return dateA - dateB;
  }
  return dateA - dateB;
};
