import React from 'react';
import styled from 'styled-components';

const StyledSelect = styled.select`
  height: 28px;
  width: 100%;
  border-radius: 3px;
  border: 1px solid lightgray;
  &:focus {
    background-color: ${({ theme }) => theme.blowPrimaryLight};
  }
`;
const CustomItemThickness = ({ item, handleChange }) => {
  let options = [19, 22];

  return (
    <StyledSelect
      name="thickness"
      value={item.thickness}
      onChange={handleChange}
      required
    >
      {options.map((item) => (
        <option value={item} key={item}>
          {item}
        </option>
      ))}
    </StyledSelect>
  );
};

export default CustomItemThickness;
