import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEdit,
  faExclamation,
  faComments,
  faClock,
  faTint,
  faTruck,
  faUndo,
  faCheck,
  faExclamationCircle,
  faCalendarAlt,
} from '@fortawesome/free-solid-svg-icons';
import { faClock as faClockRegular } from '@fortawesome/free-regular-svg-icons';
import withContext from 'hoc/withContext';

const HalfGriding = styled.div`
  width: 30px;
  height: 20px;
  display: inline-block;
  background-color: #fff3cb;
  border: 1px solid rgba(0, 0, 0, 0.1);
`;
const StyledSpan = styled.span`
  background-color: ${({ theme, type }) => theme.items[type]};
`;

const Legend = ({ permissionContext }) => {
  if (permissionContext === 'user')
    return <Item icon={faTruck} desc="Materiał powierzony" />;
  if (permissionContext === 'admin')
    return (
      <>
        <Item icon={faCalendarAlt} desc="Zamówienie zaplanowane" />
        <Item icon={faExclamation} desc="Priorytet" />
        <Item icon={faTruck} desc="Materiał powierzony" />
        <Item icon={faComments} desc="Zamówienie zawiera komentarze" />
        <Item icon={faClock} desc="Zamówienie w trakcie wykonywania" />
        <Item icon={faTint} desc="Zamówienie mokre" />
        <Item
          icon={faExclamationCircle}
          desc="Przekroczony data realizacji"
        />
        <Item
          icon={faClockRegular}
          style={{ color: 'red' }}
          desc=">12h od szlifowania"
        />
        <Item
          customIcon={<StyledSpan type="lost">1</StyledSpan>}
          desc="Elementy brakujące"
        />
        <Item
          customIcon={<StyledSpan type="toCorrect">1</StyledSpan>}
          desc="Elementy do poprawy"
        />
        <Item icon={faEdit} desc="Ostatnio edytowane zamówienie" />
      </>
    );
  return (
    <>
      <Item icon={faExclamation} desc="Priorytet" />
      <Item icon={faTruck} desc="Materiał powierzony" />{' '}
      <Item icon={faComments} desc="Zamówienie zawiera komentarze" />
      <Item icon={faUndo} desc="Zamówienie wróciło za szlifiernię" />
      <Item customIcon={<HalfGriding />} desc="Zamówienie do przeszlifu" />
      <Item
        icon={faClockRegular}
        style={{ color: 'red' }}
        desc=">12h od szlifowania"
      />
      <Item
        icon={faTint}
        style={{ opacity: 0.5 }}
        desc="Zamówienie mokre"
      />
      <Item icon={faCheck} desc="Gotowe do odbioru" />
    </>
  );
};

export default withContext(Legend);

const Item = ({ icon, desc, style, customIcon }) => (
  <div>
    {customIcon || <FontAwesomeIcon icon={icon} style={style} />}
    <span> - {desc}</span>
  </div>
);
