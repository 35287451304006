import React from 'react';
import Modal from 'PageTemplates/ModalTemplate';
import { Button } from 'react-bootstrap';
import { Formik, Form } from 'formik';
import TextInput from 'components/FormInputs/TextInput';
import * as yup from 'yup';

const AddAddress = ({ closeModal, addresses, setFieldValue }) => {
  return (
    <Modal
      title="Dodaj numer telefonu"
      closeModal={closeModal}
      footer={
        <>
          <Button variant="success" type="submit" form="AddAddressModal">
            Dodaj
          </Button>
          <Button variant="danger" onClick={closeModal}>
            Anuluj
          </Button>
        </>
      }
    >
      <Formik
        validationSchema={schema}
        // ONSUBMIT REQUEST
        onSubmit={async (values) => {
          const newAddresses = addresses.concat(values);
          setFieldValue('addresses', newAddresses);
          closeModal();
        }}
        initialValues={{
          name: '',
          city: '',
          postcode: '',
          street: '',
          description: '',
        }}
        render={(props) => {
          return (
            <Form
              noValidate
              onSubmit={props.handleSubmit}
              id="AddAddressModal"
            >
              <TextInput {...props} name="name" label="Nazwa" required />
              <TextInput
                {...props}
                name="city"
                label="Miejscowość"
                required
              />
              <TextInput
                {...props}
                name="postcode"
                label="Kod pocztowy"
                required
              />
              <TextInput {...props} name="street" label="Ulica" required />
              <TextInput
                {...props}
                type="textarea"
                name="description"
                label="Opis"
                required
              />
            </Form>
          );
        }}
      />
    </Modal>
  );
};

export default AddAddress;

const schema = yup.object({
  name: yup.string().required('Nazwa jest wymagana'),
  city: yup.string().required('Miejscowość jest wymagana'),
  street: yup.string().required('Ulica jest wymagana'),
  postcode: yup.string().matches(/^\d{2}-\d{3}$/, 'Nieprawidłowy format'),
});
