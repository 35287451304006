import { useMutation } from '@tanstack/react-query';
import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import { IOrderCheckboxOnChange } from 'components/ordersList/Cells/OrderCheckboxCell';
import Spinner from 'components/spinner';
import dayjs from 'dayjs';
import { isNumber } from 'lodash';
import { FC, useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { Col, Form, Row } from 'react-bootstrap';
import { cashRegisterApi } from 'services/apiRequests/cash-register.api';
import { CashRegisterStoryType } from 'services/enums/CashRegister.enum';
import {
  ICashRegister,
  ICashRegisterStory,
} from 'services/interfaces/cash-register/CashRegister.interface';
import { IOrder } from 'services/interfaces/orders/Orders.interface';
import { IUser } from 'services/interfaces/users/Users.interface';
import { ordersUtils } from 'services/utils/orders/orders.utils';
import { parseStringToNumber } from 'services/utils/parseStringToNumber';
import PickOrdersStep from './PickOrdersStep';
import SettleOrdersStep from './SettleOrdersStep';

enum SettleOrdersSteps {
  PICK_ORDERS = 'pick-orders',
  SETTLE_ORDERS = 'settle-orders',
}

export interface ISettleOrderPayment {
  order: IOrder;
  payment: number;
}

interface IProps {
  cashRegister: ICashRegister;
  closeModal: () => void;
  refetchCashRegisterData: () => Promise<any>;
  customers: IUser[];
}

const SettleOrdersModal: FC<IProps> = ({
  cashRegister,
  refetchCashRegisterData,
  closeModal,
  customers,
}) => {
  const alert = useAlert();
  const [step, setStep] = useState<SettleOrdersSteps>(
    SettleOrdersSteps.PICK_ORDERS,
  );
  const [customer, setCustomer] = useState<string>('');
  const [pickedOrders, setPickedOrders] = useState<ISettleOrderPayment[]>(
    [],
  );
  const [pickedOrdersValue, setPickedOrdersValue] = useState<number>(0);
  const [value, setValue] = useState<number>(0);
  const [info, setInfo] = useState<string>('');

  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    if (pickedOrders.length) {
      const pickedOrdersValue = pickedOrders.reduce((acc, o) => {
        return (acc += o.payment);
      }, 0);

      setPickedOrdersValue(pickedOrdersValue);
    }
  }, [pickedOrders]);

  useEffect(() => {
    if (pickedOrdersValue > value)
      setErrorMessage(
        'Suma podanych wpłat do zamówień nie odpowiadaja pełnej kwocie wpłaty',
      );
    else setErrorMessage('');
  }, [pickedOrdersValue, value]);

  const handleCustomer = (customer: string) => {
    setCustomer(customer);
    setPickedOrders([]);
  };

  const handlePickOrder = (event: IOrderCheckboxOnChange) => {
    if (event.target.checked)
      setPickedOrders([
        ...pickedOrders,
        { order: event.order, payment: 0 },
      ]);
    else
      setPickedOrders(
        pickedOrders.filter((item) => item.order._id !== event.order._id),
      );
  };

  const isOrderPicked = (order: IOrder) => {
    const isPicked = pickedOrders.find(
      (item) => item.order._id === order._id,
    );
    return !!isPicked;
  };

  const updatePickedOrder = (order: IOrder) => {
    const newPicked = pickedOrders.map((pickedOrder) => {
      if (pickedOrder.order._id === order._id)
        return { ...pickedOrder, order };
      return pickedOrder;
    });
    setPickedOrders(newPicked);
  };
  const updatePickedOrderPayment = (order: IOrder, value: string) => {
    const paymentValue = parseStringToNumber(value);
    const newPickedOrders = pickedOrders.map((item) => {
      if (item.order._id !== order._id) return item;

      const orderPrice = ordersUtils.payments.getOrderPrice(
        item.order,
        true,
      );
      const orderPaymentsValue =
        ordersUtils.payments.getPaymentsValue(order);
      const paymentStatus = ordersUtils.payments.getPaymentStatus(
        orderPrice,
        paymentValue + orderPaymentsValue,
      );

      return {
        ...item,
        order: { ...item.order, paymentStatus },
        payment: paymentValue,
      };
    });

    setPickedOrders(newPickedOrders);
  };

  const isOkButtonDisabled = () => {
    if (step === SettleOrdersSteps.PICK_ORDERS)
      if (!customer || !pickedOrders.length) return true;
    if (step === SettleOrdersSteps.SETTLE_ORDERS)
      if (!value || pickedOrders.some((p) => !p.payment)) return true;
    return false;
  };

  const { mutate: settleOrders, isLoading } = useMutation(
    async () => {
      const cashRegisterValue = pickedOrdersValue + cashRegister.value;
      if (isNumber(value) && isNumber(cashRegisterValue)) {
        const orders = pickedOrders.map((item) => ({
          order: item.order._id,
          value: item.payment,
        }));
        const storyItem: Partial<ICashRegisterStory> = {
          type: CashRegisterStoryType.IN,
          value: pickedOrdersValue,
          description: 'Rozliczenie zamówień',
          info,
          cashRegisterValue,
          date: dayjs().toDate(),
          customer,
          orders,
        };
        return cashRegisterApi.settleOrders({
          value: pickedOrdersValue,
          orders: pickedOrders.map((item) => ({
            order: {
              _id: item.order._id,
              paymentStatus: item.order.paymentStatus,
            },
            payment: item.payment,
          })),
          storyItem,
        });
      }
    },
    {
      onSuccess: async () => {
        refetchCashRegisterData();
        alert.success(
          `Rozliczono zamówienia kwotą: ${pickedOrdersValue}zł`,
        );
        closeModal();
      },
      onError: (error) => {
        console.log(error);
        alert.error('Błąd!');
      },
    },
  );

  const handleOkButton = () => {
    if (step === SettleOrdersSteps.PICK_ORDERS)
      setStep(SettleOrdersSteps.SETTLE_ORDERS);
    else settleOrders();
  };

  return (
    <ModalWrapper
      title="Rozliczenie zamówień"
      closeModal={closeModal}
      onOk={handleOkButton}
      okButtonText={
        step === SettleOrdersSteps.PICK_ORDERS ? 'Dalej' : 'Zatwierdź'
      }
      okButtonDisabled={isOkButtonDisabled()}
      dialogClassName="mybootstrapmodal"
    >
      {isLoading && <Spinner />}
      <Row>
        <Col sm={3}>
          <Form.Control
            as="select"
            value={customer}
            onChange={(e) => handleCustomer(e.target.value)}
            disabled={step === SettleOrdersSteps.SETTLE_ORDERS}
          >
            <option value={''}>Klient</option>
            {customers.map((customer: IUser) => (
              <option key={customer._id} value={customer._id}>
                {customer.company} [{customer.firstname}]
              </option>
            ))}
          </Form.Control>
        </Col>
      </Row>
      <div title="Wartość po odjęciu zaliczki i wcześniejszych wpłat">
        <div>
          <small>
            Wartość wpłat{' '}
            <strong>
              {pickedOrdersValue}
              zł.
            </strong>
          </small>
        </div>
      </div>
      <hr />
      {step === SettleOrdersSteps.PICK_ORDERS && (
        <PickOrdersStep
          customer={customer}
          handlePickOrder={handlePickOrder}
          isOrderPicked={isOrderPicked}
        />
      )}
      {step === SettleOrdersSteps.SETTLE_ORDERS && (
        <SettleOrdersStep
          value={value}
          setValue={setValue}
          info={info}
          setInfo={setInfo}
          pickedOrders={pickedOrders}
          errorMessage={errorMessage}
          updatePickedOrder={updatePickedOrder}
          updatePickedOrderPayment={updatePickedOrderPayment}
        />
      )}
      <hr />
      <small>
        <div>
          Aktualna wartość kasy: <strong>{cashRegister.value}</strong>zł
        </div>
        <div>
          Wartość kasy po zasileniu:{' '}
          <strong> {cashRegister.value + pickedOrdersValue}</strong>
          zł
        </div>
      </small>
    </ModalWrapper>
  );
};

export default SettleOrdersModal;
