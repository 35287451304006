import React from 'react';
import { Button } from 'react-bootstrap';
import ModalContainer from 'PageTemplates/ModalTemplate';
import { useDispatch } from 'react-redux';
import { setSpinner } from 'services/store/actions/view';
import { updateOrder } from 'services/apiRequests/orders/update';
import { setOrder } from 'services/store/actions/orders';
import { useAlert } from 'react-alert';

const BackFromCNC = ({ order, closeModal }) => {
  const dispatch = useDispatch();
  const alert = useAlert();

  const handleSubmit = async () => {
    dispatch(setSpinner(true));
    await updateOrder(
      'back-from-cnc',
      order._id,
      { dateBackFromCNC: new Date() },
      (order) => {
        dispatch(setOrder(order));
        dispatch(setSpinner(false));
        alert.success('Zatwierdzono odbiór z CNC');
        closeModal();
      },
      () => {
        dispatch(setSpinner(false));
        alert.error('Błąd!');
        closeModal();
      },
    );
  };
  return (
    <ModalContainer
      title="Zatwierdzenie odbioru z CNC"
      closeModal={closeModal}
      footer={
        <>
          <Button variant="success" onClick={handleSubmit}>
            Zatwierdź
          </Button>
          <Button variant="danger" onClick={closeModal}>
            Anuluj
          </Button>
        </>
      }
    >
      Wybierz <b>Zatwierdź</b>, aby potwierdzić odbiór elementów
      frezowanych.
    </ModalContainer>
  );
};

export default BackFromCNC;
