import { useSelector } from 'react-redux';
import { Permissions } from 'services/enums/Permissions.enum';

const useHasPermission = () => {
  const user = useSelector((state: any) => state.auth.user);
  const hasPermission = (permissions: Permissions[]) => {
    if (!user) return false;
    if (!user.permission) return false;
    return permissions.includes(user.permission);
  };

  return { hasPermission };
};

export default useHasPermission;
