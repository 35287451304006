/* eslint-disable no-shadow */
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import ModalContainer from 'PageTemplates/ModalTemplate';
import { useDispatch } from 'react-redux';
import { dateToString } from 'services/utils/date';
import { useAlert } from 'react-alert';
import DatePicker from 'components/DatePicker';
import { setNewOrderField } from 'services/store/actions/newOrder';

const ChangeFinishDateByUser = ({ order, closeModal }) => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const date = order.finishDate;

  const [finishDate, setFinishDate] = useState(new Date(date));

  const handleSubmit = async () => {
    Promise.resolve().then(() => {
      dispatch(setNewOrderField('finishDate', finishDate));
      dispatch(setNewOrderField('finishDateExtendedByUser', true));
      alert.success('Zmieniłeś datę realizacji');
      closeModal();
    });
  };
  return (
    <ModalContainer
      title="Data realizacji"
      closeModal={closeModal}
      footer={
        <>
          <Button variant="success" onClick={handleSubmit}>
            Zmień
          </Button>
          <Button variant="danger" onClick={closeModal}>
            Anuluj
          </Button>
        </>
      }
    >
      Aktualna data realizacji zamówienia to:{' '}
      <span style={{ fontWeight: 'bold', fontSize: 18 }}>
        {dateToString(date)}
      </span>
      <br />
      Chcesz ją zmienić na:{' '}
      <DatePicker
        style={{ width: '50%', display: 'inline' }}
        value={finishDate}
        minDate={new Date(date)}
        onChange={(date) => {
          setFinishDate(date);
        }}
      />
      <br />
      <small>Możesz jedynie wydłużyć czas realizacji.</small>
    </ModalContainer>
  );
};

export default ChangeFinishDateByUser;
