import React, { useState, useRef } from 'react';
import Fade from 'react-reveal/Fade';
import FlexRow from 'PageTemplates/FlexRowTemplate';
import {
  Form,
  OverlayTrigger,
  Popover,
  Button,
  FormControl,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestion } from '@fortawesome/free-solid-svg-icons';
import List from './List';
import { StyledH3 } from 'components/header';
import { useDispatch } from 'react-redux';
import { addNewOrderItem } from 'services/store/actions/newOrder';

const Items = ({ setImportDimensions }) => {
  const scrollTo = useRef(null);
  const dispatch = useDispatch();

  const [newItems, setNewItems] = useState('');
  const [tabIndex, setTabIndex] = useState(0);

  const scrollToBottom = () =>
    setTimeout(
      () => scrollTo.current.scrollIntoView({ behavior: 'smooth' }),
      200,
    );

  const handleNewItems = ({ target: { value } }) => {
    const numbers = /^(\s*|\d+)$/;
    if (!value.match(numbers)) return;
    setNewItems(value);
  };

  const handleAddItems = () => {
    if (!newItems) {
      dispatch(addNewOrderItem());
      scrollToBottom();
    } else {
      for (let i = 0; i < newItems; i++) {
        dispatch(addNewOrderItem());
      }
    }
    setNewItems('');
  };

  return (
    <Fade left>
      <>
        <StyledH3>Elementy</StyledH3>
        <FlexRow justify="space-between">
          <FlexRow justify="flex-start">
            <Form.Check
              custom
              type="checkbox"
              onChange={(e) => setTabIndex(e.target.checked ? -1 : 0)}
              checked={tabIndex}
              id="fastWriteCheckbox"
              label="Szybkie uzupełnianie"
            />{' '}
            <OverlayTrigger
              trigger="click"
              placement="right"
              overlay={FastWriteInfo}
            >
              <FontAwesomeIcon
                icon={faQuestion}
                style={{
                  opacity: 0.5,
                  margin: 3,
                  cursor: 'pointer',
                }}
              />
            </OverlayTrigger>
          </FlexRow>
          <Button
            size="sm"
            onClick={() => setImportDimensions(true)}
            variant="outline-primary"
          >
            Import wymiarów
          </Button>
        </FlexRow>
        <List tabIndex={tabIndex} />
        <FlexRow justify="flex-end">
          <FormControl
            placeholder="liczba pozycji"
            value={newItems}
            onChange={handleNewItems}
            style={{ width: 200 }}
          />
          <Button
            variant="outline-primary"
            onClick={handleAddItems}
            style={{ margin: 0 }}
          >
            Dodaj
          </Button>
        </FlexRow>

        <div ref={scrollTo} />
      </>
    </Fade>
  );
};

export default Items;

const FastWriteInfo = (
  <Popover id="popover-basic">
    <Popover.Title as="h3">Szybkie uzupełnianie</Popover.Title>
    <Popover.Content>
      Jeżeli opcja jest zaznaczona, przycisk TAB zmienia pozycję kursora
      tylko pomiędzy okienkami{' '}
      <strong>wysokości, szerokości i ilości</strong> elementów, pomijając
      inne
    </Popover.Content>
  </Popover>
);
