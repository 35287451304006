import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Form, Button, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from 'components/DatePicker';
import { getCustomers } from 'services/store/actions/customers';
import FlexRow from 'PageTemplates/FlexRowTemplate';

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: no-wrap;
  button {
    margin: 0;
  }
  input,
  select {
    width: 150px;
    margin-right: 5px;
  }
  @media (max-width: 600px) {
    flex-direction: column;
    text-align: center;
    width: 100%;
    margin: 5px auto;
    button {
      width: 100%;
      margin: 5px auto;
    }
    input,
    select {
      width: 90%;
      margin: 2px auto;
    }
  }
`;

const Filters = ({
  isEndedLoading,
  filters,
  handleFilter,
  handleInput,
  handleClearFilters,
}) => {
  const dispatch = useDispatch();
  const customers = useSelector((state) => state.customers.customers);

  useEffect(() => {
    dispatch(
      getCustomers(
        () => {},
        () => {},
      ),
    );
  }, []);

  return (
    <div>
      <Row>
        {isEndedLoading && (
          <Spinner
            animation="border"
            variant="success"
            style={{ marginRight: 5 }}
          />
        )}
        <Form.Control
          as="select"
          onChange={(e) => handleFilter('user', e.target.value)}
          value={filters.user}
        >
          <option value="">Klient</option>
          {customers &&
            customers
              .sort((a, b) => a.company.localeCompare(b.company))
              .map((item) => (
                <option key={item._id} value={item._id}>
                  {item.company}
                  {item.firstname ? ` - ${item.firstname[0]}` : ''}
                </option>
              ))}
        </Form.Control>
        <Form.Control
          type="text"
          onChange={(e) => handleInput('number', e.target.value)}
          placeholder="Numer"
        />
        <Form.Control
          type="text"
          onChange={(e) => handleInput('color', e.target.value)}
          placeholder="Kolor"
        />
        <Form.Control
          type="text"
          onChange={(e) => handleInput('name', e.target.value)}
          placeholder="Nazwa"
        />
        <FlexRow justify="flex-start" buttonFormat={false}>
          <div>
            {' '}
            Od{' '}
            <DatePicker
              value={filters.dateFrom}
              onChange={(date) => handleFilter('dateFrom', date)}
            />
          </div>
          <div>
            Do{' '}
            <DatePicker
              value={filters.dateTo}
              onChange={(date) => handleFilter('dateTo', date)}
            />
          </div>
        </FlexRow>
        <Button size="sm" onClick={handleClearFilters}>
          Wyczyść
        </Button>
      </Row>
    </div>
  );
};

export default Filters;
