import React, { FC } from 'react';
import { Table } from 'react-bootstrap';
import { CashRegisterStoryType } from 'services/enums/CashRegister.enum';
import { ICashRegisterStory } from 'services/interfaces/cash-register/CashRegister.interface';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { IUser } from 'services/interfaces/users/Users.interface';

const Type = styled.strong<{ type: CashRegisterStoryType }>`
  color: ${({ type }) =>
    type === CashRegisterStoryType.IN ? 'green' : 'orange'};
  font-size: 12px;
`;

interface IProps {
  cashRegisterStory: ICashRegisterStory[];
  handleRedirectToStory: (id: string) => void;
}

const CashRegisterStoryList: FC<IProps> = ({
  cashRegisterStory,
  handleRedirectToStory,
}) => {
  const summary = cashRegisterStory.reduce(
    (acc: number, curr: ICashRegisterStory) => {
      if (curr.type === CashRegisterStoryType.IN)
        return (acc += curr.value);
      if (curr.type === CashRegisterStoryType.OUT)
        return (acc -= curr.value);
      return acc;
    },
    0,
  );
  return (
    <Table hover bordered striped size="sm" className="mt-2">
      <thead>
        <tr>
          <th>Data</th>
          <th>Użytkownik</th>
          <th>Typ</th>
          <th>
            <div>Opis</div>
            <div>
              <small>(Dodatkowe informacje)</small>
            </div>
          </th>
          <th>Wartość</th>
          <th>Stan kasy &apos;po&apos;</th>
          <th>Klient</th>
          <th>Ilość zamówień</th>
        </tr>
      </thead>
      <tbody>
        {/* TODO wydzielić komponent z elementem listy */}
        {cashRegisterStory.map((storyItem) => (
          <tr
            key={storyItem._id}
            style={{ cursor: 'pointer' }}
            onClick={() => handleRedirectToStory(storyItem._id)}
          >
            <td>{dayjs(storyItem.date).format('DD.MM.YYYY, HH:mm')}</td>
            <td>
              {(storyItem.user as IUser)?.firstname}{' '}
              {(storyItem.user as IUser)?.surname}
            </td>
            <td>
              <Type type={storyItem.type}>
                {storyItem.type.toUpperCase()}
              </Type>
            </td>
            <td>
              <div>
                <span>{storyItem.description}</span>
              </div>{' '}
              <div>
                <small>
                  {storyItem.info ? `(${storyItem.info})` : ''}
                </small>
              </div>
            </td>
            <td>{storyItem.value}zł</td>
            <td>
              <strong>{storyItem.cashRegisterValue}zł</strong>
            </td>
            <td>
              {storyItem.customer && (
                <strong>{(storyItem.customer as IUser).company}</strong>
              )}
            </td>
            <td>
              {storyItem.orders.length ? (
                <strong>{storyItem.orders.length}</strong>
              ) : null}
            </td>
          </tr>
        ))}
        <tr>
          <td colSpan={8}></td>
        </tr>
        <tr>
          <td colSpan={3}></td>
          <th>BILANS</th>
          <th>{summary} zł</th>
          <td colSpan={3}></td>
        </tr>
      </tbody>
    </Table>
  );
};

export default CashRegisterStoryList;
