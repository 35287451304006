import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenSquare } from '@fortawesome/free-solid-svg-icons';
import withContext from 'hoc/withContext';

const StyledIcon = styled(FontAwesomeIcon)`
  color: black;
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.blowPrimary};
  }
`;
const P = styled.p`
  margin: 0;
`;
const Small = styled.small`
  font-weight: bold;
`;

const PaintProducers = ({ order, setModal, permissionContext }) => {
  if (permissionContext !== 'user')
    return (
      <div style={{ textAlign: 'right' }}>
        <P>
          <StyledIcon
            icon={faPenSquare}
            title="Zmień producenta"
            onClick={() => setModal('_paintProducers')}
          />
          <Small> Podkład</Small>
          <small> - {order.paintMakerBase || '-'}</small>
        </P>
        <P>
          <StyledIcon
            icon={faPenSquare}
            title="Zmień producenta"
            onClick={() => setModal('_paintProducers')}
          />
          <Small> Lakier</Small>
          <small> - {order.paintMaker || '-'}</small>
        </P>
      </div>
    );
  return null;
};

export default withContext(PaintProducers);
