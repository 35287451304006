/* eslint-disable consistent-return */
import Axios from 'axios';

export const removeMessage = async (messageId, onEnd, onErr) => {
  try {
    await Axios.delete(`/api/messages/${messageId}`);

    return onEnd();
  } catch (error) {
    console.log(error);
    onErr();
  }
};
