/* eslint-disable no-shadow */
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import ModalContainer from 'PageTemplates/ModalTemplate';
import { useDispatch } from 'react-redux';
import { setSpinner } from 'services/store/actions/view';
import { updateOrder } from 'services/apiRequests/orders/update';
import { setOrder } from 'services/store/actions/orders';
import { dateToString } from 'services/utils/date';
import { useAlert } from 'react-alert';
import DatePicker from 'components/DatePicker';

const RealizationDate = ({ order, closeModal }) => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const date = order.finishDate;

  const [finishDate, setFinishDate] = useState(new Date(date));

  const handleSubmit = async () => {
    dispatch(setSpinner(true));
    await updateOrder(
      'finishdate',
      order._id,
      { finishDate },
      (order) => {
        dispatch(setOrder(order));
        dispatch(setSpinner(false));
        alert.success('Zaktualizowano datę realizacji');
        closeModal();
      },
      () => {
        dispatch(setSpinner(false));
        alert.error('Błąd!');
        closeModal();
      },
    );
  };
  return (
    <ModalContainer
      title="Data realizacji"
      closeModal={closeModal}
      footer={
        <>
          <Button variant="success" onClick={handleSubmit}>
            Zmień
          </Button>
          <Button variant="danger" onClick={closeModal}>
            Anuluj
          </Button>
        </>
      }
    >
      Aktualna data realizacji zamówienia to:{' '}
      <span style={{ fontWeight: 'bold', fontSize: 18 }}>
        {dateToString(date)}
      </span>
      <br />
      Chcesz ją zmienić na:{' '}
      <DatePicker
        style={{ width: '50%', display: 'inline' }}
        value={finishDate}
        onChange={(date) => {
          setFinishDate(date);
        }}
      />
      <br />
      <small>
        Data na produkcji: <strong>-3 dni</strong>
      </small>
    </ModalContainer>
  );
};

export default RealizationDate;
