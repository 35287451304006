import React from 'react';
import styled from 'styled-components';

const StyledParagraph = styled.p`
  font-size: 20px;
  text-align: ${({ align }) => align || 'justify'};
  color: white;
  @media (max-width: 600px) {
    font-size: 16px;
  }
`;
const StyledStrong = styled.strong`
  cursor: pointer;
  color: ${({ theme }) => theme.blowDark};
  &:hover {
    text-decoration: underline;
  }
`;

const About = ({ setTitle }) => {
  return (
    <div>
      <StyledParagraph>
        Jesteśmy firmą specjalizującą się w kompleksowej produkcji i
        lakierowaniu komponentów meblowych. Siedziba firmy mieści się pod
        Warszawą, jednak ofertę kierujemy do odbiorców w całym kraju.
        Naszym głównym atutem jest wykwalifikowana kadra, która przez lata
        zdobywała doświadczenie. Oferujemy możliwość wyboru spośród
        gotowych produktów lub projekt indywidualny dostosowany do potrzeb
        Klienta.
      </StyledParagraph>
      <StyledParagraph align="right">
        W razie jakichkolwiek pytań -{' '}
        <StyledStrong onClick={() => setTitle('Kontakt')}>
          skontaktuj się z nami.
        </StyledStrong>
      </StyledParagraph>
    </div>
  );
};

export default About;
