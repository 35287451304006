import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { firstLetterToUpperCase } from 'services/utils/string';

const Span = styled.span`
  font-weight: bold;
  color: ${({ theme, status, listName }) =>
    listName !== 'production' && theme.positions[status]};
  text-shadow: ${({ status, listName }) =>
    listName !== 'production' && status === 'podkład'
      ? ' 1px 1px 3px rgb(75, 75, 75)'
      : 'none'};
`;

const Status = ({ order, position, listName }) => {
  return (
    <td>
      {order.status &&
        order.status.map((status) => (
          <Span key={status} status={status} listName={listName}>
            {firstLetterToUpperCase(status)}{' '}
            {position === 'pakowanie' && order.isReadyToPickUp && (
              <span>
                <FontAwesomeIcon
                  style={{ color: '#e165b9' }}
                  icon={faCheck}
                  title="Do odbioru"
                />
              </span>
            )}
          </Span>
        ))}
    </td>
  );
};

export default Status;
