import React from 'react';
import { StyledH1, StyledH6 } from 'components/header';
import FlexRow from 'PageTemplates/FlexRowTemplate';
import DatePicker from 'components/DatePicker';
import { useSelector, useDispatch } from 'react-redux';
import { setOrdersFilters } from 'services/store/actions/orders';
import OrdersList from 'components/ordersList/static';
import { Spinner } from 'react-bootstrap';

const Orders = ({ isLoading, updateInList }) => {
  const dispatch = useDispatch();
  const filters = useSelector((state) => state.orders.filters.customer);
  const orders = useSelector((state) => state.orders.orders);

  const handleFilter = (key, value) =>
    dispatch(setOrdersFilters('customer', key, value));

  return (
    <div>
      <FlexRow justify="space-between">
        <StyledH1>Zamówienia</StyledH1>
        <FlexRow justify="space-between">
          {isLoading && <Spinner animation="border" variant="success" />}
          <div>
            {' '}
            Od{' '}
            <DatePicker
              value={filters.dateFrom}
              onChange={(e) => handleFilter('dateFrom', e)}
              disabled={!orders}
            />{' '}
          </div>
          <div>
            Do{' '}
            <DatePicker
              value={filters.dateTo}
              onChange={(e) => handleFilter('dateTo', e)}
              disabled={!orders}
            />
          </div>
        </FlexRow>
      </FlexRow>

      {orders ? (
        <OrdersList
          isLoading={isLoading}
          orders={orders}
          listName="customer"
          paymentStatus
          pickUpDate
          status
          fv
          price
          updateInList={updateInList}
        />
      ) : (
        <StyledH6>Brak zamówień</StyledH6>
      )}
    </div>
  );
};

export default Orders;
