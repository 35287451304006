import React, { FC } from 'react';
import { OrderItemSurfaces } from 'services/enums/Orders.enum';
import {
  IOrder,
  IOrderItem,
} from 'services/interfaces/orders/Orders.interface';
import { ordersUtils } from 'services/utils/orders/orders.utils';

interface IProps {
  surfaceValue: number;
  order: IOrder;
  surfaceType: OrderItemSurfaces;
}

const OrderSurfaceCell: FC<IProps> = ({
  order,
  surfaceType,
  surfaceValue,
}) => {
  const elementsCount =
    ordersUtils.elements.getElementsWithPaintedSpecificSideCount(
      order.items,
      surfaceType,
    );

  const style = {};
  return (
    <td style={style}>
      {surfaceValue?.toFixed(2)}{' '}
      <strong>
        <sub>{!!elementsCount && elementsCount}</sub>
      </strong>
    </td>
  );
};

export default OrderSurfaceCell;
