import React, { FC, useMemo } from 'react';
import { IOrder } from 'services/interfaces/orders/Orders.interface';
import { ordersUtils } from 'services/utils/orders/orders.utils';

interface IProps {
  order: IOrder;
}

const OrderPriceCell: FC<IProps> = ({ order }) => {
  const orderPrice = useMemo(
    () => ordersUtils.payments.getOrderPrice(order, true),
    [order],
  );
  return (
    <td>
      {orderPrice}{' '}
      {!!order.advancePayment && <small>(+{order.advancePayment})</small>}
    </td>
  );
};

export default OrderPriceCell;
