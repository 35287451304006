import Axios from 'axios';
import { getFileExtension } from 'services/utils/files';
import setAuthToken from 'services/utils/setAuthToken';

export const sendMessage = async (body, onEnd, onErr) => {
  try {
    await Axios.post(`/api/messages/`, body);

    return onEnd();
  } catch (error) {
    console.log(error);
    onErr();
  }
};
export const sendCustomerMessage = async (msgData, onEnd, onErr, img) => {
  try {
    const body = new FormData();
    body.append('message', JSON.stringify(msgData));

    if (img) {
      const ext = getFileExtension(img.name);
      body.append('files', img, `img.${ext}`);
    }

    const res = await Axios.post(`/api/messages/customer`, body);
    const { newMessage } = res.data;
    return onEnd(newMessage);
  } catch (error) {
    console.log(error);
    onErr();
  }
};
export const createBannerMessage = async (message, img, onEnd, onErr) => {
  if (localStorage.token) setAuthToken(localStorage.token);
  try {
    const body = new FormData();
    body.append('message', JSON.stringify(message));

    if (img) {
      const ext = getFileExtension(img.name);
      body.append('files', img, `img.${ext}`);
    }

    const res = await Axios.post(`/api/messages/banner`, body);

    const { newBanner } = res.data;
    return onEnd(newBanner);
  } catch (error) {
    console.log(error.response);
    onErr();
  }
};
