/* eslint-disable no-console */
import { getHingeHolesPrice } from './hingeHoles';
import { getBackMillingPrice } from './backMilling';
import { getChamferingPrice } from './chamfering';
import { getHandlesPrice } from './handles';
import { getItemsPrice } from './items';
import { getUnderGlassPrice } from './underGlass';
import { getCustomItemsPrice } from './customItems';

export const calcOrderPrice = (state, prices) => {
  let paintPrice = 0;
  let customItemsPaintPrice = 0;
  let transparentPaintPrice = 0;
  let handlePrice = 0;
  let chamferingPrice = 0;
  let backMillingPrice = 0;
  let hingeHolesPrice = 0;
  let underGlassPrice = 0;

  console.clear();
  console.log('');
  console.log(`----=====OBLICZANIE CENY ZAMÓWIENIA=====----`);
  console.log('');
  /** ========================================
   * OTWORY
   * ======================================== */
  hingeHolesPrice = getHingeHolesPrice(state, prices);
  console.log(
    `CENA WIERCENIA ${
      state.hingeHoles || 0
    }mb OTWORÓW: ${hingeHolesPrice}zł`,
  );
  console.log('--');

  /** ========================================
   * GIEROWANIE
   * ======================================== */
  chamferingPrice = getChamferingPrice(state, prices);
  console.log(
    `CENA ${state.chamfering || 0}mb GIEROWANIA: ${chamferingPrice}zł`,
  );
  console.log('--');
  /** ========================================
   * FREZOWANIE POD PLECY
   * ======================================== */
  backMillingPrice = getBackMillingPrice(state, prices);
  console.log(
    `CENA ${
      state.backMilling || 0
    }mb FREZOWANIA POD PLECY: ${backMillingPrice}zł`,
  );
  console.log('--');

  /** ========================================
   * UCHWYTY
   * ======================================== */
  handlePrice = getHandlesPrice(state, prices);
  console.log(
    `CENA ${state.milledHandle || 0}mb i ${
      state.milledPartHandle || 0
    }szt UCHWYTÓW FREZOWANYCH: ${handlePrice}zł`,
  );

  /** ========================================
   * PODFREZOWANIE POD SZKLO
   * ======================================== */
  underGlassPrice = getUnderGlassPrice(state, prices);
  console.log(`CENA FREZOWANIA POD SZKLO: ${underGlassPrice}zł`);

  /** ========================================
   * LAKIEROWANIE
   * ======================================== */
  console.log(' ');
  console.log('====LAKIEROWANIE====');
  console.log(
    `typ | materiał | lakierowanie | typ lakierowania | powierzchnia | cena`,
  );
  console.log('');

  const itemsData = getItemsPrice(state, prices);
  paintPrice = itemsData.itemsPrice;
  const { items } = itemsData;

  console.log(' ');
  console.log(
    `CENA ${state.surfaceRight}PL i ${state.surfaceLeft}PP m2 LAKIEROWANIA: ${paintPrice}zł`,
  );
  // Wodoodporny mdf
  let waterproofMdfPrice = 0;
  if (state.isWaterproofMdf) {
    const surface = (state.surfaceRight || 0) + (state.surfaceLeft || 0);
    waterproofMdfPrice =
      surface * prices.paint.companyMaterial.waterproofMdf;
    console.log(
      `+ wodoodporny mdf (${surface}m2): ${waterproofMdfPrice}zł`,
    );
  }

  console.log(' ');
  console.log('--Elementy niestandardowe');
  console.log(`typ | materiał | lakierowanie | długość | cena`);
  console.log('');

  const customItemsData = getCustomItemsPrice(state, prices);
  customItemsPaintPrice = customItemsData.customItemsPrice;
  const { customItems } = customItemsData;

  console.log('====LAKIEROWANIE====');
  console.log(' ');

  /** ========================================
   * LAKIEROWANIE
   * ======================================== */
  let extraColorPrice = 0;
  if (
    ['dorabiany wg. próbki', 'dorabiany ciemny wg. próbki'].includes(
      state.color.toLowerCase(),
    )
  ) {
    extraColorPrice = prices.paint.extraColor;
    console.log(`LAKIER DORABIANY: + ${extraColorPrice}`);
  }

  //  SUMMARY
  let price =
    hingeHolesPrice +
    chamferingPrice +
    backMillingPrice +
    handlePrice +
    transparentPaintPrice +
    paintPrice +
    customItemsPaintPrice +
    underGlassPrice +
    extraColorPrice +
    waterproofMdfPrice;

  if (!price) price = 0;
  if (state.orderType.toLowerCase() === 'reklamacja (wina blow)') {
    console.log('---===R E K L A M A C J A===---');
    price = 0;
  }

  if (price) price = Math.round(price);

  console.log(`CENA: ${price}`);
  console.log('');
  console.log('---===KONIEC===---');

  return { ...state, items, price, customItems };
};
