/* eslint-disable no-param-reassign */
import React from 'react';
import styled from 'styled-components';
import { Table } from 'react-bootstrap';
import withContext from 'hoc/withContext';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getOrderPrice } from 'services/utils/orders';

const Th = styled.th`
  width: 150px;
  background-color: ${({ primary, secondary, theme }) => {
    if (primary) return theme.blowGreen;
    if (secondary) return theme.blowWarning;
    return 'white';
  }}!important;
`;
const Td = styled.td`
  background-color: ${({ primary, secondary, theme }) => {
    if (primary) return theme.blowGreenLight;
    if (secondary) return theme.blowWarningLight;
    return 'white';
  }}!important;
`;

const Summary = ({ order, permissionContext }) => {
  const getItemsQuantity = (key) =>
    order.items.reduce((elements, item) => {
      if (item[key]) elements += item.quantity;
      return elements;
    }, 0);
  return (
    <Table responsive striped bordered hover size="sm">
      <thead>
        <tr>
          <th rowSpan={2}>
            Ilość elementów:
            <br />
            <small>(szt)</small>
          </th>
          <th colSpan={2}>
            Powierzchnia lakierowania:
            <br />
            <small>
              (m<sup>2</sup>)
            </small>
          </th>
          {order.customItemsLength ? (
            <th rowSpan={2}>
              Długość el. niestandardowych:
              <br />
              <small>(m.b.)</small>
            </th>
          ) : null}
          {permissionContext !== 'employee' &&
          ['Materiał klienta', 'Poprawa (wina klienta)'].includes(
            order.orderType,
          ) ? (
            <th rowSpan={2}>
              Powierzchnia krawędzi* <br />
              <small>
                (m<sup>2</sup>)
              </small>
            </th>
          ) : null}
          <th rowSpan={2}>
            Uchwyt frezowany:
            <br />
            <small>(mb)</small>
          </th>
          {order.milledPartHandle ? (
            <th rowSpan={2}>
              Uchwyt częściowy:
              <br />
              <small>(szt)</small>
            </th>
          ) : null}
          {order.chamfering ? (
            <th rowSpan={2}>
              Gierowanie:
              <br />
              <small>(mb)</small>
            </th>
          ) : null}
          {order.backMilling ? (
            <th rowSpan={2}>
              Frezowanie pod plecy:
              <br />
              <small>(mb)</small>
            </th>
          ) : null}
          <th rowSpan={2}>
            Ilość otworów:
            <br />
            <small>(szt)</small>
          </th>
          {permissionContext !== 'employee' &&
            permissionContext !== 'display' && (
              <>
                {order.manHours && order.manHours.hours ? (
                  <th rowSpan={2}>
                    Prace stolarskie:
                    <br />
                    <small>(h)</small>
                  </th>
                ) : null}
                {order.ventilations && order.ventilations.quantity ? (
                  <th rowSpan={2}>
                    Kratki wentylacyjne:
                    <br />
                    <small>(szt)</small>
                  </th>
                ) : null}
                <th rowSpan={2}>
                  Przewidywana cena <sub>netto</sub>**:
                  <br />
                  <small>(zł)</small>
                </th>
              </>
            )}
        </tr>
        <tr>
          <Th primary>PL</Th>
          <Th secondary>PP</Th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{order.elements}</td>
          <Td primary>
            {order.surfaceRight && (
              <>
                {order.surfaceRight.toFixed(3)}
                <sub>
                  <strong> {getItemsQuantity('surfaceRight')}</strong>
                </sub>
              </>
            )}
          </Td>
          <Td secondary>
            {order.surfaceLeft && (
              <>
                {order.surfaceLeft.toFixed(3)}
                <sub>
                  <strong> {getItemsQuantity('surfaceLeft')}</strong>
                </sub>
              </>
            )}
          </Td>
          {order.customItemsLength ? (
            <td>{order.customItemsLength.toFixed(2)}</td>
          ) : null}
          {permissionContext !== 'employee' &&
            ['Materiał klienta', 'Poprawa (wina klienta)'].includes(
              order.orderType,
            ) && <td rowSpan={2}>{order.edgesSurface.toFixed(2)}</td>}
          <td>{order.milledHandle && order.milledHandle.toFixed(2)}</td>
          {order.milledPartHandle ? (
            <td>{order.milledPartHandle && order.milledPartHandle}</td>
          ) : null}
          {order.chamfering ? (
            <td>{order.chamfering && order.chamfering.toFixed(2)}</td>
          ) : null}
          {order.backMilling ? (
            <td>{order.backMilling && order.backMilling.toFixed(2)}</td>
          ) : null}
          <td>{order.hingesHoles}</td>
          {permissionContext !== 'employee' &&
            permissionContext !== 'display' && (
              <>
                {order.manHours && order.manHours.hours ? (
                  <td>{order.manHours.hours}</td>
                ) : null}
                {order.ventilations && order.ventilations.quantity ? (
                  <td>{order.ventilations.quantity}</td>
                ) : null}
                <td>
                  <div>
                    {getOrderPrice(order)}{' '}
                    {permissionContext === 'admin' &&
                      order.isPriceBlocked && (
                        <FontAwesomeIcon
                          title="Cena zablokowana"
                          icon={faLock}
                        />
                      )}
                  </div>
                  <div>
                    <small>
                      {order.advancePayment
                        ? `Zaliczka: ${order.advancePayment}zł`
                        : ''}
                    </small>
                  </div>
                </td>
              </>
            )}
        </tr>
      </tbody>
    </Table>
  );
};

export default withContext(Summary);
