/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from 'react';
import Modal from 'PageTemplates/ModalTemplate';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomers } from 'services/store/actions/customers';
import { useAlert } from 'react-alert';
import { Form, Button } from 'react-bootstrap';
import { orderTypes, reasonsOfComplaint } from 'services/utils/const';
import { getAllCustomerOrders } from 'services/apiRequests/orders/get';
import {
  setNewOrderField,
  setNewOrderManyFields,
} from 'services/store/actions/newOrder';
import { setSpinner } from 'services/store/actions/view';
import { firstLetterToUpperCase } from 'services/utils/string';
import { substractSimilarObjects } from 'services/utils/object';

// NEW ORDER -> ADMIN

const initData = {
  user: undefined,
  userName: undefined,
  orderType: undefined,
  number: undefined,
  reasonOfComplaint: undefined,
};

const OrderAs = ({ closeModal }) => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const customers = useSelector((state) => state.customers.customers);
  const realizationDates = useSelector(
    (state) => state.settings.realizationDates,
  );

  const [step, setStep] = useState(0);
  const [data, setData] = useState(initData);
  const [userOrders, setUserOrders] = useState(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  useEffect(() => {
    dispatch(
      getCustomers(
        () => {},
        () => {
          alert.error('Błąd pobierania klientów');
        },
      ),
    );
  }, []);

  useEffect(() => {
    //  set user name
    if (customers) {
      if (data.user)
        handleData(
          'userName',
          `${data.user.company} - ${data.user.firstname}`,
        );
      else {
        handleData('userName', undefined);
        setUserOrders([]);
      }
    }
  }, [data.user]);

  useEffect(() => {
    if (data.user) {
      dispatch(setSpinner(true));
      getAllCustomerOrders(
        data.user._id,
        (orders) => {
          dispatch(setSpinner(false));
          setUserOrders(orders);
        },
        () => {
          dispatch(setSpinner(false));
          alert.error('Błąd pobierania zamówień klienta');
        },
      );
    }
  }, [data.user]);

  useEffect(() => {
    setIsButtonDisabled(false);
    if (step === 0) {
      if (!data.user || !data.userName || (data.user && !userOrders))
        return setIsButtonDisabled(true);
    } else if (step === 1) {
      if (!data.orderType) {
        return setIsButtonDisabled(true);
      }
      if (
        data.orderType &&
        data.orderType.toLowerCase() === 'reklamacja (wina blow)' &&
        !data.reasonOfComplaint
      ) {
        return setIsButtonDisabled(true);
      }
    } else if (step === 2) {
      if (!data.number) return setIsButtonDisabled(true);
    }
  }, [data, userOrders, step]);

  const handleData = (key, value) => setData({ ...data, [key]: value });
  const handleNext = () => {
    if (step === 0) setStep(step + 1);
    else if (step === 1) {
      if (
        [
          'Reklamacja (wina BLOW)',
          'Poprawa (wina klienta)',
          'Domówienie',
        ].includes(data.orderType)
      )
        setStep(step + 1);
      else handleSubmit();
    } else handleSubmit();
  };

  const handleSubmit = () => {
    const fields = { ...data };

    if (data.number) fields.number = `${data.number}.${data.orderType[0]}`;
    else if (!data.number) fields.number = data.user.currentFreeOrderId;
    dispatch(setNewOrderField('userPrices', data.user.discounts));
    if (realizationDates && data.user.realizationDates) {
      const terms = substractSimilarObjects(
        realizationDates,
        data.user.realizationDates,
      );
      dispatch(setNewOrderField('terms', terms));
    }

    userOrders.forEach((item) => {
      if (item.number === data.number) {
        fields.name = item.name;
        fields.handles = item.handles;
        fields.millings = item.millings;
        fields.veneer = item.veneer;
        fields.glassCase = item.glassCase;
        fields.color = item.color;
        fields.paintType = item.paintType;
        fields.paintStyle = item.paintStyle;
        fields.isNut = item.isNut;
        fields.isFelc = item.isFelc;
        fields.isChamfering = item.isChamfering;
        fields.colorHex = item.colorHex;
        fields.transparent = item.transparent;
        fields.motherOrder = item._id;
      }
    });
    dispatch(setNewOrderManyFields({ ...fields }));
    closeModal();
  };

  return (
    <Modal
      title="Zamów jako"
      closeModal={closeModal}
      backdrop="static"
      footer={
        <>
          <Button
            variant="success"
            onClick={handleNext}
            disabled={isButtonDisabled}
          >
            Dalej
          </Button>
          <Button variant="danger" onClick={closeModal}>
            Anuluj
          </Button>
        </>
      }
    >
      {step === 0 ? (
        <CustomerCheck customers={customers} handleData={handleData} />
      ) : step === 1 ? (
        <OrderTypeCheck
          orderType={data.orderType}
          reasonOfComplaint={data.reasonOfComplaint}
          handleData={handleData}
        />
      ) : (
        <OrderNumberCheck
          orders={userOrders}
          number={data.number}
          handleData={handleData}
        />
      )}
    </Modal>
  );
};

export default OrderAs;

const OrderNumberCheck = ({ orders, number, handleData }) => {
  return (
    <>
      <strong>Wybierz numer zamówienia</strong>
      <Form.Control
        as="select"
        onChange={(e) => handleData('number', e.target.value)}
        value={number}
      >
        <option value="" />
        {orders.map((order) => {
          if (!order.number.includes('.')) {
            return (
              <option key={order._id} value={order.number}>
                {order.number}
              </option>
            );
          }
          return null;
        })}
      </Form.Control>
    </>
  );
};

const OrderTypeCheck = ({ reasonOfComplaint, orderType, handleData }) => {
  return (
    <>
      <strong>Wybierz typ zamówienia</strong>
      <Form.Group
        onChange={(e) => handleData('orderType', e.target.value)}
        value={orderType}
      >
        {orderTypes.map((item) => (
          <Form.Check
            custom
            key={item}
            value={item}
            type="radio"
            label={item}
            id={item}
            name="orderType"
          />
        ))}
      </Form.Group>

      {orderType && orderType.toLowerCase() === 'reklamacja (wina blow)' && (
        <>
          <strong>Przyczyna reklamacji</strong>
          <Form.Group
            onChange={(e) =>
              handleData('reasonOfComplaint', e.target.value)
            }
            value={reasonOfComplaint}
          >
            {reasonsOfComplaint.map((item) => (
              <Form.Check
                custom
                key={item}
                value={item}
                type="radio"
                label={firstLetterToUpperCase(item)}
                id={item}
                name="reasonOfComplaint"
              />
            ))}
          </Form.Group>
        </>
      )}
    </>
  );
};

const CustomerCheck = ({ customers, handleData }) => {
  if (customers)
    return (
      <>
        <strong>Wybierz klienta</strong>
        <Form.Control
          as="select"
          onChange={(e) => {
            handleData('user', JSON.parse(e.target.value));
          }}
        >
          <option value={undefined} />
          {customers
            .sort((a, b) => {
              return a.company.localeCompare(b.company);
            })
            .map((item) => (
              <option value={JSON.stringify(item)} key={item._id}>
                {item.company} - {item.firstname[0]}
              </option>
            ))}
        </Form.Control>
      </>
    );
  return null;
};
