import React from 'react';
import ModalContainer from 'PageTemplates/ModalTemplate';
import { Table } from 'react-bootstrap';
import { dateToStringWithHour, dateToString } from 'services/utils/date';

const PlanHistory = ({ order, closeModal }) => {
  const checkIsDelay = (item) => {
    let result = false;
    if (item.done) {
      const datePlaned = new Date(item.planedOn);
      const dateDone = new Date(item.done);
      if (dateDone.getTime() - datePlaned.getTime() > 86400000)
        result = true;
    }
    return result;
  };
  return (
    <ModalContainer
      title="Historia planowania"
      closeModal={closeModal}
      size="xl"
    >
      <Table responsive="lg" size="sm" style={{ textAlign: 'center' }}>
        <thead>
          <tr>
            <th>Stanowisko</th>
            <th>Zaplanowano na</th>
            <th>Wykonano</th>
          </tr>
        </thead>
        <tbody>
          {order.productionPlan
            .sort((a, b) => new Date(a.planedOn) - new Date(b.planedOn))
            .map((item) => (
              <tr
                key={item.position + item.planedOn}
                style={
                  checkIsDelay(item) ? { backgroundColor: '#fde8e8' } : {}
                }
              >
                <td>
                  <span
                    className={item.position}
                    style={{ fontWeight: 'bold' }}
                  >
                    {item.position}
                  </span>
                </td>
                <td>{dateToString(item.planedOn)}</td>
                <td>
                  {item.done ? dateToStringWithHour(item.done) : '-'}
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </ModalContainer>
  );
};

export default PlanHistory;
