import Axios from 'axios';

export const changeCuttingPackageStatus = async (
  cuttingPackageId,
  status,
  onEnd,
  onError,
) => {
  try {
    await Axios.put(`/api/cutting/${cuttingPackageId}/status`, { status });

    onEnd();
  } catch (error) {
    console.log(error.response);
    onError();
  }
};
