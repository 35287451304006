import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import {
  dateToString,
  getArrayOfDatesFromTo,
  dateToStringWithoutYear,
  getDayName,
} from 'services/utils/date';
import { Table } from 'react-bootstrap';
import { StyledH3, StyledH1 } from 'components/header';
import { firstLetterToUpperCase } from 'services/utils/string';
import {
  findByDateInStats,
  getSummaryByItemType,
  findCorrectionsByDateInStats,
  getCorrectionsSummary,
  getAverageValue,
} from '../utils';

const Header = styled.h1`
  font-size: 64px;
  letter-spacing: 10px;
`;
const Wrapper = styled.div`
  text-align: center;
`;
const TableWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
`;

const PositionStatsDatesRange = ({ dates: { from, to }, position }) => {
  const statistics = useSelector((state) => state.statistics.stats);
  const [days, setDays] = useState([]);
  const [sum, setSum] = useState(null);
  const [corrections, setCorrections] = useState(null);
  const [average, setAverage] = useState(null);

  useEffect(() => {
    if (position && statistics) {
      const days = getArrayOfDatesFromTo(from, to);
      setDays(days);
    }
  }, [from, to, statistics]);

  useEffect(() => {
    if (statistics) {
      const sum = getSummaryByItemType(statistics);
      setSum(sum);

      const corrections = getCorrectionsSummary(statistics);
      setCorrections(corrections);

      const ave = getAverageValue(statistics);
      setAverage(ave);
    }
  }, [statistics]);

  if (statistics && days.length && sum)
    return (
      <div>
        <Wrapper>
          <Header className={position}>{position.toUpperCase()}</Header>
          <small>
            Dane pochodzą z zamówień wykonanych między{' '}
            <strong>{dateToString(from)}</strong> a{' '}
            <strong>{dateToString(to)}</strong>
          </small>
          <hr />
          <span>
            W tym okresie na stanowisku,{' '}
            <i style={{ textDecoration: 'underline' }}>
              w dniach roboczych
            </i>
            , średnio wykonywano:{' '}
            <strong>{average ? average.toFixed(2) : '-'}</strong> m
            <sup>2</sup>
            /dzień
          </span>
        </Wrapper>
        <hr />
        {statistics && (
          <>
            <StyledH3>Liczby</StyledH3>
            <StatsTables stats={statistics} days={days} sum={sum} />
          </>
        )}
        {corrections && (
          <>
            <StyledH3>Poprawki</StyledH3>
            <CorrectionsTable corrections={corrections} />
          </>
        )}
      </div>
    );
  return null;
};

export default PositionStatsDatesRange;

const CorrectionsTable = ({ corrections }) => {
  return (
    <div style={{ maxWidth: 500 }}>
      <Table responsive="lg" bordered hover striped size="sm">
        <tbody>
          {Object.keys(corrections).map((key) => (
            <React.Fragment key={key}>
              <tr>
                <th rowSpan={2}>{firstLetterToUpperCase(key)}</th>
                <td>Elementy</td>
                <td>{corrections[key].elements}</td>
              </tr>
              <tr>
                <td>Powierzchnia</td>
                <td>{corrections[key].surface.toFixed(2)}</td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

const Th = styled.th`
  width: ${({ width }) => width || 'fit-content'};
  color: ${({ dayName }) =>
    dayName === 'niedziela'
      ? 'red'
      : dayName === 'sobota'
      ? 'green'
      : 'black'};
`;
const Td = styled.td`
  width: ${({ width }) => width || 'fit-content'};
`;

const StatsTables = ({ stats, days, sum }) => {
  return (
    <TableWrapper>
      <Table responsive="lg" bordered hover striped size="sm">
        <tbody>
          <tr>
            <Th colSpan={2}>Dni</Th>
            {days.map((day) => (
              <Th width={20} key={day} dayName={getDayName(day)}>
                {dateToStringWithoutYear(day)}
              </Th>
            ))}
            <th colSpan={3}>SUMA</th>
          </tr>
          <tr>
            <Th width={80} rowSpan={2}>
              Płaskie
            </Th>
            <Th width={150}>Jednostronne</Th>
            <DaysCells days={days} stats={stats} field="flatOneSide" />
            <Th width={40}>
              {sum.flatOneSide ? sum.flatOneSide.toFixed(2) : 0}
            </Th>
            <Th width={80} rowSpan={2}>
              <StyledH3>
                {sum.flatSummary ? sum.flatSummary.toFixed(2) : 0}
              </StyledH3>
            </Th>
            <Th width={80} rowSpan={5}>
              <StyledH1>
                {sum.summary ? sum.summary.toFixed(2) : 0}
              </StyledH1>
            </Th>
          </tr>
          <tr>
            <Th width={150}>Obustronne</Th>
            <DaysCells days={days} stats={stats} field="flatBothSides" />
            <Th width={40}>
              {sum.flatBothSides ? sum.flatBothSides.toFixed(2) : 0}
            </Th>
          </tr>
          <tr>
            <Th width={80} rowSpan={2}>
              CNC
            </Th>
            <Th width={150}>Jednostronne</Th>

            <DaysCells days={days} stats={stats} field="cncOneSide" />

            <Th width={40}>
              {sum.cncOneSide ? sum.cncOneSide.toFixed(2) : 0}
            </Th>
            <Th width={80} rowSpan={2}>
              <StyledH3>
                {sum.cncSummary ? sum.cncSummary.toFixed(2) : 0}
              </StyledH3>
            </Th>
          </tr>
          <tr>
            <Th width={150}>Obustronne</Th>

            <DaysCells days={days} stats={stats} field="cncBothSides" />

            <Th width={40}>
              {sum.cncBothSides ? sum.cncBothSides.toFixed(2) : 0}
            </Th>
          </tr>
          <tr>
            <th colSpan={2}>SUMA</th>
            <DaysCells days={days} stats={stats} field="sum" />
            <td colSpan={2} />
          </tr>
          <tr>
            <th rowSpan={2}>Elementy</th>
            <th>Płaskie</th>
            <DaysCells days={days} stats={stats} field="flatElements" />
            <td>
              <strong>{Math.ceil(sum.flatElements)}</strong>
            </td>
            <td rowSpan={2} colSpan={2}>
              <StyledH3>{Math.ceil(sum.elements)}</StyledH3>
            </td>
          </tr>
          <tr>
            <th>CNC</th>
            <DaysCells days={days} stats={stats} field="cncElements" />
            <td>
              <strong>{Math.ceil(sum.cncElements)}</strong>
            </td>
          </tr>
          <tr style={{ borderTop: 2 }}>
            <th rowSpan={2}>Poprawki</th>
            <th>Elementy</th>
            <CorrectionsDaysCells
              days={days}
              stats={stats}
              field="elements"
            />
            <th colSpan={3} style={{ color: 'red' }}>
              {sum.correctionsElements || 0}
            </th>
          </tr>
          <tr>
            <th>Powierzchnia</th>
            <CorrectionsDaysCells
              days={days}
              stats={stats}
              field="surface"
            />
            <th colSpan={3} style={{ color: 'red' }}>
              {sum.correctionsSurface
                ? sum.correctionsSurface.toFixed(2)
                : 0}
            </th>
          </tr>
        </tbody>
      </Table>
    </TableWrapper>
  );
};

const CorrectionsDaysCells = ({ days, stats, field }) =>
  days.map((day) => {
    const value = findCorrectionsByDateInStats(stats, day, field);
    return (
      <Th width={20} key={day} style={{ color: 'red' }}>
        {value}
      </Th>
    );
  });

const DaysCells = ({ days, stats, field }) =>
  days.map((day) => {
    const value = findByDateInStats(stats, day, field);

    return (
      <Td
        width={20}
        key={day}
        style={field === 'sum' ? { fontWeight: 'bold' } : {}}
      >
        {value}
      </Td>
    );
  });
