import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  
  *, *::before, *::after {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  body {
    margin: 0;
    padding: 0;
    font-family:  sans-serif;
    color:#3f454d
  }
  .invalid{
    bottom: 0;
    color: #fa3e54;
    font-size: 10px;
  }
.nav-tabs{
  .nav-item button{
    color: #73a52e;
    font-weight: 400;
    letter-spacing:3px;
    padding: 10px 40px;
  }
}
table{
  th, td{
    vertical-align:middle !important;
    text-align:center;
    padding:2px !important;
  }
}

  .blow {
    font-weight:bold;
    color: #73a52e;
   }
  .piła {
    font-weight:bold;
    color: #9e9e9e;
   }
  .obróbka {
    font-weight:bold;
    color: #fc8403;
   }
  .surówka {
    font-weight:bold;
    color: #ffbd40;
   }
   .podkład {
     font-weight:bold;
    color: #f5f5f5;
    text-shadow: 1px 1px 3px rgb(75, 75, 75);
   }
   .szlifiernia {
     font-weight:bold;
    color: #33cec3
   }
   .lakiernia {
     font-weight:bold;
    color: #a4cfff
   }
   .polernia {
     font-weight:bold;
    color: #fa3e54
   }
   .pakowanie {
     font-weight:bold;
    color: #85eb6a
   }
   .zakończone {
     font-weight:bold;
    color: #e165b9
   }
   .transport {
     font-weight:bold;
    color: gray
   }
   option{
    &.blow {
    font-weight:bold;
    background-color: #73a52e;
   }
    &.piła {
    font-weight:bold;
    background-color: #9e9e9e;
   }
    &.obróbka {
    font-weight:bold;
    background-color: #fc8403;
   }
    &.surówka {
    font-weight:bold;
    background-color: #ffbd40;
   }
   &.podkład {
     font-weight:bold;
    background-color: #f5f5f5;
   }
   &.szlifiernia {
     font-weight:bold;
    background-color: #33cec3
   }
   &.lakiernia {
     font-weight:bold;
    background-color: #a4cfff
   }
   &.polernia {
     font-weight:bold;
    background-color: #fa3e54
   }
   &.pakowanie {
     font-weight:bold;
    background-color: #85eb6a
   }
   &.zakończone {
     font-weight:bold;
    background-color: #e165b9
   }
   &.transport {
     font-weight:bold;
     background-color: gray
   }
   }

   .custom-react-datepicker{
    border-radius: 5px;
    border: 1px solid #73a52e;
    height: 36px;
    margin: 0 5px;
   }

`;

export default GlobalStyle;
