import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { sortMethod } from 'services/utils/sortMethods';
import {
  setSortEmployeesList,
  setEmployee,
} from 'services/store/actions/employees';
import { firstLetterToUpperCase } from 'services/utils/string';

const StyledTh = styled.th`
  cursor: pointer;
`;
const StyledRow = styled.tr`
  cursor: pointer;
`;

const List = ({ employees }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const sort = useSelector((state) => state.employees.sort);
  const { sortBy, direction } = sort;

  const [sortDirection, setSortDirection] = useState(direction);

  useEffect(() => {
    setSortDirection(direction === 'ascend' ? 'descend' : 'ascend');
  }, [sortBy, direction]);

  const handleSort = (by, dir) => dispatch(setSortEmployeesList(by, dir));
  const handleSetEmployee = (data) => dispatch(setEmployee(data));

  if (employees)
    return (
      <div>
        <Table responsive="lg" striped bordered hover size="sm">
          <thead>
            <tr>
              <th>LP</th>
              <StyledTh
                onClick={() => handleSort('surname', sortDirection)}
              >
                Nazwisko <FontAwesomeIcon icon={faSort} />
              </StyledTh>
              <StyledTh
                onClick={() => handleSort('firstname', sortDirection)}
              >
                Imię <FontAwesomeIcon icon={faSort} />
              </StyledTh>
              <th>Stanowisko(a) pracy</th>
            </tr>
          </thead>
          <tbody>
            {employees
              .sort((a, b) =>
                sortMethod[sortMethod.getSortMethod(sortBy)](
                  a,
                  b,
                  sortBy,
                  direction,
                ),
              )
              .map((item, index) => (
                <ListRow
                  item={item}
                  index={index}
                  history={history}
                  setEmployee={handleSetEmployee}
                  key={item._id}
                />
              ))}
          </tbody>
        </Table>
      </div>
    );
  return null;
};

export default List;

const ListRow = ({ item, index, history, setEmployee }) => {
  return (
    <StyledRow
      onClick={() => {
        setEmployee(item);
        history.push(`/employees/${item._id}`, { ...item });
      }}
    >
      <td>{index + 1}</td>
      <td>{item.surname}</td>
      <td>{item.firstname}</td>
      <td>
        {item.positions.map((position) => (
          <span key={position} className={position.toLowerCase()}>
            {firstLetterToUpperCase(position)}{' '}
          </span>
        ))}
      </td>
    </StyledRow>
  );
};
