import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tabs, Tab } from 'react-bootstrap';
import { PageHeader } from 'components/header';
import { setHomePageKey, setSpinner } from 'services/store/actions/view';
import {
  clearOrdersFilters,
  clearOrdersList,
  setOrdersFilters,
} from 'services/store/actions/orders';
import OrdersList from 'components/ordersList/static';
import { getCustomerMessages } from 'services/store/actions/auth';
import Filters from './components/Filters';
import EndedOrdersList from './components/EndedOrdersList';
import { getBanner } from 'services/store/actions/messages';
import axios from 'axios';
import { debounce } from 'lodash';

const AdminHomePage = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const key = useSelector((state) => state.view.homePageKey);
  const filters = useSelector((state) => state.orders.filters.ended);

  const [isEndedLoading, setIsEndedLoading] = useState(false);

  const [newOrders, setNewOrders] = useState([]);
  const [endedOrders, setEndedOrders] = useState([]);

  const getEndedOrders = useCallback(() => {
    setIsEndedLoading(true);
    axios
      .get(`/api/orders`, {
        params: {
          status: 'odebrane',
          pickUpDateFrom: filters.dateFrom,
          pickUpDateTo: filters.dateTo,
          user: filters.user,
          color: filters.color || undefined,
          name: filters.name || undefined,
          number: filters.number || undefined,
        },
      })
      .then((res) => {
        setEndedOrders(res.data.orders);
        setIsEndedLoading(false);
      });
  }, [filters]);
  const getNewOrders = useCallback(() => {
    dispatch(setSpinner(true));
    axios
      .get(`/api/orders`, {
        params: {
          status: 'wysłane',
        },
      })
      .then((res) => {
        setNewOrders(res.data.orders);
        dispatch(setSpinner(false));
      });
  }, [filters]);

  useEffect(() => {
    if (key === 'ended') getEndedOrders();
  }, [key, filters]);

  useEffect(() => {
    if (key === 'new') getNewOrders();
  }, [key]);

  useEffect(() => {
    if (user) {
      dispatch(getCustomerMessages(user._id));
      dispatch(
        getBanner(
          () => {},
          () => {},
        ),
      );
    }
  }, [user]);

  const handleFilter = (field, value) => {
    dispatch(setOrdersFilters('ended', field, value));
  };
  const handleInput = debounce(
    (field, text) => handleFilter(field, text),
    400,
  );
  const handleClearFilters = () => dispatch(clearOrdersFilters('ended'));
  const updateInEndedList = (order) => {
    const newList = endedOrders.map((o) => {
      if (o._id === order._id) return order;
      return o;
    });
    setEndedOrders(newList);
  };
  return (
    <div>
      <PageHeader
        title="Zamówienia"
        extra={
          key === 'ended'
            ? [
                <Filters
                  key="1"
                  filters={filters}
                  isEndedLoading={isEndedLoading}
                  handleInput={handleInput}
                  handleFilter={handleFilter}
                  handleClearFilters={handleClearFilters}
                />,
              ]
            : []
        }
        buttonFormat={false}
      />

      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(key) => {
          dispatch(clearOrdersList());
          dispatch(setHomePageKey(key));
        }}
      >
        <Tab eventKey="new" title="Nowe">
          {key === 'new' && (
            <>
              <OrdersList orders={newOrders} listName="new" summary />
              <div>
                <div
                  style={{
                    display: 'inline-block',
                    width: 40,
                    height: 20,
                    border: '1px solid gray',
                    backgroundColor: '#ffd27d',
                  }}
                />{' '}
                -<small> Klient VIP. Zaplanuj zamówienie.</small>
              </div>
            </>
          )}
        </Tab>
        <Tab eventKey="ended" title="Odebrane">
          {key === 'ended' && (
            <EndedOrdersList
              orders={endedOrders}
              listName="ended"
              pickUpDate
              paymentStatus
              summary
              fv
              price
              updateInList={updateInEndedList}
            />
          )}
        </Tab>
      </Tabs>
    </div>
  );
};

export default AdminHomePage;
