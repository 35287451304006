import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import withContext from 'hoc/withContext';
import { firstLetterToUpperCase } from 'services/utils/string';
import { orderItemCuttingStatuses } from 'services/utils/const';

const Td = styled.td`
  background-color: ${({ primary, secondary, theme }) => {
    if (primary) return theme.blowGreenLight;
    if (secondary) return theme.blowWarningLight;
  }};
  font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
`;
const Tr = styled.tr`
  background-color: ${({ cuttingStatus, theme }) => {
    if (cuttingStatus === 'do wycięcia') return theme.blowSuccessLight;
    else if (cuttingStatus === 'wstrzymany') return theme.blowDangerLight;
    else if (cuttingStatus === 'pominięty') return 'gray';
    else if (cuttingStatus === 'gotowy') return theme.blowSuccess;
    return 'inherit';
  }};
`;
const StyledSelect = styled.select`
  height: 28px;
  width: 100%;
  border-radius: 3px;
  border: 1px solid lightgray;
  &:focus {
    background-color: ${({ theme }) => theme.blowPrimaryLight};
  }
`;

const ListRow = ({
  item,
  order,
  orderIndex,
  index,
  handleItemStatus,
  handleMachining,
}) => {
  const [lost, setLost] = useState(null);
  const [toCorrect, setToCorrect] = useState(null);

  useEffect(() => {
    checkItemStatus('elementLost', item, setLost);
    checkItemStatus('elementToCorrect', item, setToCorrect);
  }, [item, order]);

  return (
    <Tr cuttingStatus={item.cuttingStatus}>
      <td>{index + 1}</td>
      <td>
        <input
          type="checkbox"
          checked={item.isMachiningRequired}
          onChange={(e) =>
            handleMachining(orderIndex, index, e.target.checked)
          }
          style={{ width: 20, height: 20 }}
        />
      </td>
      <td>
        <StyledSelect
          name="cuttingStatus"
          value={item.cuttingStatus}
          onChange={(e) => {
            handleItemStatus(orderIndex, index, e.target.value);
          }}
        >
          {orderItemCuttingStatuses.map((item) => (
            <option key={item} value={item}>
              {firstLetterToUpperCase(item)}
            </option>
          ))}
        </StyledSelect>
      </td>
      <th>
        {item.blowMaterial ? <FontAwesomeIcon icon={faCheck} /> : ''}
      </th>
      <td>{firstLetterToUpperCase(item.type)}</td>
      <Td bold>{item.height}</Td>
      <Td primary>{edgeCell(item.h1P)}</Td>
      <Td primary>{edgeCell(item.h2P)}</Td>
      <Td secondary>{edgeCell(item.h1L)}</Td>
      <Td secondary>{edgeCell(item.h2L)}</Td>
      <Td secondary>
        {item.hLPaintedEdge ? <FontAwesomeIcon icon={faCheck} /> : ''}
      </Td>
      <Td bold>{item.width}</Td>
      <Td primary>{edgeCell(item.w1P)}</Td>
      <Td primary>{edgeCell(item.w2P)}</Td>
      <Td secondary>{edgeCell(item.w1L)}</Td>
      <Td secondary>{edgeCell(item.w2L)}</Td>
      <Td secondary>
        {item.wLPaintedEdge ? <FontAwesomeIcon icon={faCheck} /> : ''}
      </Td>
      <td>{item.thickness}</td>
      <td>{item.quantity}</td>
      <Td primary>
        {item.paintRight ? <FontAwesomeIcon icon={faCheck} /> : ''}
      </Td>
      <Td secondary>
        {item.paintLeft ? <FontAwesomeIcon icon={faCheck} /> : ''}
      </Td>

      {order.isUnderGlass && (
        <Td>
          {item.underGlass ? <FontAwesomeIcon icon={faCheck} /> : ''}
        </Td>
      )}
      {order.muntinType === 'x' && <Td>{item.muntinX}</Td>}
      {order.muntinType === '+' && (
        <>
          <Td>{item.muntinH}</Td>
          <Td>{item.muntinW}</Td>
        </>
      )}

      {order.transparent && (
        <Td secondary>
          {item.transparentBothSide ? (
            <FontAwesomeIcon icon={faCheck} />
          ) : (
            ''
          )}
        </Td>
      )}
      <td>{item.comments}</td>
      <Td primary>
        {item.surfaceRight ? item.surfaceRight.toFixed(3) : ''}
      </Td>
      <Td secondary>
        {item.surfaceLeft ? item.surfaceLeft.toFixed(3) : ''}
      </Td>
      <td>{lost}</td>
      <td>{toCorrect}</td>
    </Tr>
  );
};

export default withContext(ListRow);

const edgeCell = (value) =>
  value.length < 5
    ? value.toUpperCase()
    : value.length >= 5
    ? `${firstLetterToUpperCase(value.substring(0, 4))}.`
    : firstLetterToUpperCase(value);
const checkItemStatus = (key, item, setList) => {
  if (item[key] && item[key].position) {
    const { position, quantity } = item[key];
    setList(
      <small
        style={{ cursor: 'default' }}
        title={`${position} - ${quantity}szt`}
        className={position.toLowerCase()}
      >
        {firstLetterToUpperCase(position.slice(0, 3))}_{quantity}
      </small>,
    );
  } else setList(null);
};
