import { containsOneOf } from 'services/utils/array';
import {
  veneerHandles,
  veneerExcludedHandles,
} from 'services/utils/const';
import { handleItemEdges } from './orderItems';

export const setUnderGlass = (state, isUnderGlass) => {
  if (!isUnderGlass) {
    const items = state.items.map((item) => {
      return {
        ...item,
        isUnderGlass: false,
        comments: item.comments.replace('+podfrezowanie', ' '),
      };
    });
    return { ...state, isUnderGlass: false, items };
  }

  return { ...state, isUnderGlass };
};
export const addMuntinType = (state, muntinType) => {
  if (!muntinType) {
    const items = state.items.map((item) => {
      return { ...item, muntinH: 0, muntinW: 0, muntinX: 0 };
    });
    return { ...state, muntinType: null, items };
  }

  return { ...state, muntinType };
};
// Adding veener to order is available when order has no milling/handles, or as one of handles suitable for veneer. All items right edges must be changed to '-' from 'r1/r2'.
export const addVeneer = (state, veneer) => {
  const { handles, millings, items } = state;

  if (
    containsOneOf(veneerExcludedHandles, handles) ||
    (millings.length && !millings.includes('ryflowany'))
  )
    return { ...state };

  const newItems = items.map((item) => {
    const edges = ['r1', 'r2'];
    handleItemEdges(true, false, item, (edgeValue, edgeName) => {
      if (edges.includes(edgeValue)) item[edgeName] = '-';
    });
    return item;
  });

  return { ...state, veneer, items: newItems };
};

export const setPilaster = (state, pilaster) => {
  if (pilaster) return { ...state, pilaster };
  else {
    const customItems = state.customItems.filter(
      (item) => item.type !== 'pilaster',
    );
    const customPilaster = { file: null, path: '' };
    return { ...state, pilaster, customItems, customPilaster };
  }
};

export const setCrown = (state, crown) => {
  if (crown) return { ...state, crown };
  else {
    const customItems = state.customItems.filter(
      (item) => item.type !== 'korona',
    );
    const customCrown = { file: null, path: '' };
    return { ...state, crown, customItems, customCrown };
  }
};

// Adding handle to order is avaiable when order has no veneer/color(bejca), or if has veneer/color(bejca) and handle is one of suitable to veneers.
export const addHandle = (state, handle) => {
  const { color, veneer } = state;

  if (veneer || color.toLowerCase().includes('bejca')) {
    if (veneerHandles.includes(handle.toLowerCase()))
      return pushItemToArray(state, 'handles', handle);
    return { ...state };
  }

  return pushItemToArray(state, 'handles', handle);
};

// Remove handle, removes this handle from all items
export const removeHandle = (state, handle) => {
  const isGloss = state.paintType === 'połysk';

  state.items = state.items.map((item) => {
    handleItemEdges(true, true, item, (edgeValue, edgeName) => {
      if (edgeValue === handle) {
        item[edgeName] = isGloss ? 'r2' : 'r1';
        if (handle.toLowerCase() === 'uc') item.milledPartHandle = 0;
        else item.milledHandle = 0;
      }
    });
    return item;
  });
  const handles = state.handles.filter((item) => item !== handle);

  return { ...state, handles };
};

// Adding milling to order is available then order has no veneer/color(bejca)
export const addMilling = (state, milling) => {
  if (milling && state.items.length === 1)
    if (!state.items[0].width && !state.items[0].height) {
      if (milling.symbol === 'gładki CNC') {
        state.items[0].type = 'gładki CNC';
      } else if (milling.symbol && milling.symbol !== 'ryflowany') {
        if (state.items[0].type === 'gładki') {
          state.items[0].type = 'frez';
          state.items[0].thickness = 18;
        }
      }
    }

  let items = state.items;
  if (state.items.length > 1) {
    if (milling.symbol === 'gładki CNC') {
      items = state.items.map((item) => {
        if (item.type === 'frez') item.type = 'gładki CNC';
        return item;
      });
    } else if (milling.symbol && milling.symbol !== 'ryflowany') {
      items = state.items.map((item) => {
        if (item.type === 'gładki CNC') item.type = 'frez';
        return item;
      });
    }
  }
  return {
    ...state,
    items,
    millings: [milling.symbol],
    millingPricingGroup: milling.pricingGroup || 1,
  };
};

// Adding custom milling picture to order is available then order has no veneer/color(bejca)
export const addCustomMilling = (state, file) => {
  const filename = file.name.split('/').join('_');
  console.log(filename);
  const customMilling = {
    path: filename,
    file,
  };

  return {
    ...state,
    customMilling,
    millings: ['Ze zdjęcia'],
    millingPricingGroup: 1,
  };
};
export const addCustomPilaster = (state, file) => {
  const filename = file.name.split('/').join('_');
  const customPilaster = {
    path: filename,
    file,
  };

  return {
    ...state,
    customPilaster,
    pilaster: 'Ze zdjęcia',
  };
};
export const addCustomCrown = (state, file) => {
  const filename = file.name.split('/').join('_');
  const customCrown = {
    path: filename,
    file,
  };

  return {
    ...state,
    customCrown,
    crown: 'Ze zdjęcia',
  };
};

// Remove milling, must change items type
export const removeMilling = (state) => {
  const items = state.items.map((item) => {
    if (item.type.toLowerCase() === 'frez') {
      item.type = 'gładki';
      item.muntinH = 0;
      item.muntinW = 0;
      item.muntinX = 0;
    } else if (item.type.toLowerCase() === 'gładki cnc')
      item.type = 'gładki';
    else if (item.type.toLowerCase() === 'ryflowany') item.type = 'gładki';
    return item;
  });
  let muntinType = state.muntinType;
  if (!state.glassCase) muntinType = null;

  return {
    ...state,
    items,
    millings: [],
    millingPricingGroup: 1,
    customMilling: { path: '', file: null },
    muntinType,
  };
};

// Adding glassCase to order is available then order has no veneer/color(bejca)
export const addGlassCase = (state, glassCase) => {
  const { veneer, color } = state;

  if (!glassCase || glassCase === '') {
    const items = state.items.map((item) => {
      if (item.type.toLowerCase() === 'witryna') {
        item.type = 'gładki';
        item.muntinH = 0;
        item.muntinW = 0;
        item.muntinX = 0;
        item.underGlass = false;
      }
      return item;
    });
    let muntinType = state.muntinType;
    if (!state.millings.length) muntinType = null;

    return { ...state, glassCase, items, muntinType, isUnderGlass: false };
  }
  if (
    (veneer || color.toLowerCase().includes('bejca')) &&
    glassCase.toLowerCase() !== 'w4'
  )
    return { ...state };
  return {
    ...state,
    glassCase: glassCase.symbol,
    glassCasePricingGroup: glassCase.pricingGroup || 1,
  };
};

// Adding paintStyle to order must change all items edges
export const addPaintType = (state, paintType) => {
  let { items, transparent, paintStyle, coloredWater } = state;

  if (state.items.length) {
    if (paintType === 'połysk') {
      items = state.items.map((item) => {
        handleItemEdges(true, false, item, (edgeValue, edgeName) => {
          if (edgeValue === 'r1') item[edgeName] = 'r2';
        });
        return item;
      });
      transparent = false;
      coloredWater = false;
    } else {
      if (paintStyle === 'prawa - połysk / lewa - półmat') {
        paintStyle = 'jednostronne';
      }
      items = state.items.map((item) => {
        handleItemEdges(true, false, item, (edgeValue, edgeName) => {
          if (edgeValue === 'r2') item[edgeName] = 'r1';
        });
        return item;
      });
    }
  }
  return {
    ...state,
    items,
    paintType,
    transparent,
    coloredWater,
    paintStyle,
  };
};

// Adding paintStyle to two painted sides, first item is changed to two sides paint
export const addPaintStyle = (state, paintStyle) => {
  let { paintType, transparent } = state;
  if (paintStyle === 'prawa - połysk / lewa - półmat') {
    paintType = 'połysk';
    transparent = false;
  }

  if (!paintStyle !== 'jednostronne' && state.items.length === 1)
    if (!state.items[0].width && !state.items[0].height)
      state.items[0].paintLeft = true;

  return { ...state, paintStyle, paintType, transparent };
};

// Setting felc/nut on false, must remove value from all items left edges
export const setBackMilling = (state, backMillingType, value) => {
  const name = backMillingType === 'isFelc' ? 'felc' : 'nut';
  let { items } = state;

  if (!value)
    items = state.items.map((item) => {
      handleItemEdges(false, true, item, (edgeValue, edgeName) => {
        if (edgeValue === name) item[edgeName] = '-';
      });
      return item;
    });

  return { ...state, items, [backMillingType]: value };
};
// Setting felc/nut on false, must remove value from all items left edges
export const setWaterproofMdf = (state, isWaterproofMdf) => {
  let { items } = state;
  if (!!isWaterproofMdf)
    items = state.items.map((item) => {
      item.thickness = 19;
      return item;
    });

  return { ...state, items, isWaterproofMdf };
};

// set chamfering on false, must remove value from all items edges
export const setChamfering = (state, isChamfering) => {
  let { items } = state;
  const isGloss = state.paintType === 'połysk';

  if (!isChamfering) {
    items = items.map((item) => {
      handleItemEdges(true, false, item, (value, key) => {
        if (value === 'gierunek') item[key] = isGloss ? 'r2' : 'r1';
      });
      handleItemEdges(false, true, item, (value, key) => {
        if (value === 'gierunek') item[key] = '-';
      });

      return item;
    });
  }

  return { ...state, items, isChamfering };
};

// HELPERS
const pushItemToArray = (state, key, item) => {
  if (!state[key].includes(item))
    return { ...state, [key]: [...state[key], item] };
  return { ...state };
};
