import { Button } from 'react-bootstrap';
import { PageHeader } from 'components/header';
import withContext from 'hoc/withContext';
import React, { useEffect, useState } from 'react';
import PageTemplate from 'PageTemplates/PageTemplate';
import { useDispatch, useSelector } from 'react-redux';
import { setSpinner } from 'services/store/actions/view';
import { getCatalog, removeFront } from 'services/store/actions/catalog';
import { useAlert } from 'react-alert';
import { useModal } from 'hooks/useModal';
import AddMillingModal from '../components/AddMillingModal/AddMillingModal';
import PricingGroup from '../components/MillingPricingGroup/PricingGroup';
import {
  setNewOrderCrown,
  setNewOrderCustomCrown,
} from 'services/store/actions/newOrder';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileUpload } from '@fortawesome/free-solid-svg-icons';

const StyledInputFile = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;
const StyledFileLabel = styled.label`
  color: white;
  background-color: white;
  border: 1px solid #007bff;
  color: #007bff;
  padding: 5px 30px;
  letter-spacing: 1px;
  border-radius: 5px;
  margin: 5px 5px 10px;
  cursor: pointer;
  transition: background-color 200ms ease;
  &:hover {
    background-color: #007bff;
    color: white;
  }
`;

const initModals = {
  addCrown: false,
};

const Crowns = ({ closeModal: closeNewOrderModal, permissionContext }) => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const { modal, showModal, closeModal } = useModal(initModals);
  const crowns = useSelector((state) => state.catalog.crowns);
  const newOrder = useSelector((state) => state.newOrder);
  const [crownToUpdate, setCrownToUpdate] = useState(null);

  useEffect(() => {
    if (!crowns.length) dispatch(setSpinner(true));
    dispatch(
      getCatalog(
        () => {},
        () => {
          alert.error('Błąd pobierania katalogu');
        },
      ),
    );
  }, []);

  const handleCrown = (crown) => {
    dispatch(setNewOrderCrown(crown.symbol));
    alert.success('Wybrałeś koronę');
    if (closeNewOrderModal) closeNewOrderModal();
  };

  const handleAddCustomCrown = (e) => {
    dispatch(setNewOrderCustomCrown(e.target.files[0]));
    alert.success('Wybrałeś koronę ze zdjęcia');
    if (closeNewOrderModal) closeNewOrderModal();
  };

  const handleEditCrown = (front, index) =>
    setCrownToUpdate({ ...front, index });

  const handleRemoveCrown = async (front) => {
    dispatch(setSpinner(true));
    dispatch(
      removeFront(
        front,
        () => {
          alert.success('Korona usunięty');
        },
        () => {
          alert.error('Błąd!');
        },
      ),
    );
  };

  return (
    <PageTemplate>
      <PageHeader
        title="Korony"
        extra={[
          permissionContext === 'admin' && (
            <Button key={1} onClick={() => showModal('addCrown')}>
              Dodaj nową koronę
            </Button>
          ),
          <StyledInputFile
            key="3"
            type="file"
            accept="image/*,application/pdf"
            id="file_crown"
            onChange={handleAddCustomCrown}
          />,
          <StyledFileLabel htmlFor="file_crown" key="4">
            <FontAwesomeIcon
              icon={faFileUpload}
              style={
                newOrder.customPilaster?.file ? { color: 'black' } : null
              }
            />{' '}
            Dodaj ze zdjęcia
          </StyledFileLabel>,
        ]}
      />
      <PricingGroup
        group={1}
        fronts={crowns}
        removeFront={handleRemoveCrown}
        editFront={handleEditCrown}
        handleFront={handleCrown}
      />

      {/* MODALS */}
      {(modal.addCrown || crownToUpdate) && (
        <AddMillingModal
          closeModal={() => {
            setCrownToUpdate(null);
            closeModal();
          }}
          toUpdate={crownToUpdate}
          type="crown"
        />
      )}
    </PageTemplate>
  );
};

export default withContext(Crowns);
