import React from 'react';
import Modal from 'PageTemplates/ModalTemplate';
import { Button } from 'react-bootstrap';
import { darken } from 'polished';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { setEmployeeActivePosition } from 'services/store/actions/employee';
import FlexRow from 'PageTemplates/FlexRowTemplate';
import { firstLetterToUpperCase } from 'services/utils/string';
import { clearOrdersList } from 'services/store/actions/orders';

const StyledButton = styled(Button)`
  color: black;
  background-color: ${({ theme, position }) => theme.positions[position]};
  border-color: ${({ theme, position }) =>
    position === 'Podkład' ? theme.blowDark : theme.positions[position]};
  &:hover {
    color: black;
    border-color: ${({ theme, position }) =>
      position === 'Podkład' ? theme.blowDark : theme.positions[position]};
    background-color: ${({ theme, position }) =>
      darken(0.2, theme.positions[position])};
  }
`;

const ChangePosition = ({ closeModal }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);

  // HANDLERS
  const handleClick = (position) => {
    dispatch(clearOrdersList());
    dispatch(setEmployeeActivePosition(position));
    closeModal();
  };

  return (
    <Modal
      title="Zmiana stanowiska"
      closeModal={closeModal}
      footer={
        <>
          <Button variant="danger" onClick={closeModal}>
            Anuluj
          </Button>
        </>
      }
    >
      <FlexRow direction="column">
        {user.positions &&
          user.positions.map((item) => (
            <StyledButton
              key={item}
              onClick={() => handleClick(item)}
              position={item}
            >
              {firstLetterToUpperCase(item)}
            </StyledButton>
          ))}
      </FlexRow>
    </Modal>
  );
};

export default ChangePosition;
