import Axios from 'axios';

export const updateEmployeeData = async (
  employeeId,
  body,
  onEnd,
  onErr,
) => {
  try {
    const res = await Axios.put(`/api/employees/${employeeId}`, body);

    return onEnd(res.data.employee);
  } catch (error) {
    console.log(error);
    onErr();
  }
};

export const updateWorkedHours = async (body, onEnd, onErr) => {
  try {
    const res = await Axios.put(`/api/employees/all/workedhours`, body);

    return onEnd(res.data.employees);
  } catch (error) {
    console.log(error);
    onErr();
  }
};
