import withContext from 'hoc/withContext';
import React, { useState } from 'react';
import { useAlert } from 'react-alert';
import { Form, Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import Modal from 'PageTemplates/ModalTemplate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileUpload,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import { createBannerMessage } from 'services/apiRequests/messages/post';
import { setSpinner } from 'services/store/actions/view';
import Editor, { BtnBold, BtnItalic, Toolbar } from 'react-simple-wysiwyg';

const StyledInputFile = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;
const StyledFileLabel = styled.label`
  background-color: white;
  color: #007bff;
  border: 1px solid #007bff;
  padding: 5px 30px;
  letter-spacing: 1px;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 200ms ease;
  &:hover {
    background-color: #007bff;
    color: white;
  }
`;

const BannerModal = ({ closeModal }) => {
  const dispatch = useDispatch();
  const alert = useAlert();

  const [message, setMessage] = useState('');
  const [img, setImg] = useState();

  const handleSubmit = async () => {
    dispatch(setSpinner(true));
    await createBannerMessage(
      message,
      img,
      () => {
        dispatch(setSpinner(false));
        alert.success('Banner utworzony');
        closeModal();
      },
      () => {
        dispatch(setSpinner(false));
        alert.error('Błąd!');
      },
    );
  };

  return (
    <Modal
      title="BANNER"
      closeModal={closeModal}
      onOk={handleSubmit}
      disabled={!message}
    >
      <Editor
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        containerProps={{ style: { height: 200 } }}
      >
        <Toolbar>
          <BtnBold />
          <BtnItalic />
        </Toolbar>
      </Editor>
      {!img ? (
        <>
          <StyledInputFile
            type="file"
            accept="image/*"
            id={`file_banner`}
            onChange={(e) => setImg(e.target.files[0])}
          />
          <StyledFileLabel htmlFor={`file_banner`}>
            <FontAwesomeIcon icon={faFileUpload} /> Dodaj zdjęcie
          </StyledFileLabel>
        </>
      ) : (
        <div>
          {' '}
          <Button variant="outline-danger" onClick={() => setImg()}>
            <FontAwesomeIcon
              icon={faTrashAlt}
              style={{ color: '#c01212', cursor: 'pointer' }}
            />{' '}
            Usuń zdjęcie
          </Button>
          <div>
            <small>{img && img.name}</small>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default withContext(BannerModal);
