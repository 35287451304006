import { OrderItemSurfaces } from 'services/enums/Orders.enum';
import {
  IOrder,
  IOrderCustomItem,
  IOrderItem,
} from 'services/interfaces/orders/Orders.interface';

export const elements = {
  getLostElemnetsCount: (
    items: IOrderItem[] = [],
    customItems: IOrderCustomItem[] = [],
  ): number => {
    return [...items, ...customItems].reduce((acc, item) => {
      if (item.elementLost) {
        return (acc += item.elementLost.quantity);
      }
      return acc;
    }, 0);
  },
  getElementsToCorrectCount: (
    items: IOrderItem[] = [],
    customItems: IOrderCustomItem[] = [],
  ): number => {
    return [...items, ...customItems].reduce((acc, item) => {
      if (item.elementToCorrect) {
        return (acc += item.elementToCorrect.quantity);
      }
      return acc;
    }, 0);
  },
  getElementsWithPaintedSpecificSideCount: (
    items: IOrderItem[] = [],
    paintedSide: OrderItemSurfaces,
  ): number => {
    return items.reduce((acc, item) => {
      if (paintedSide === OrderItemSurfaces.RIGHT && item.surfaceRight)
        return (acc += item.quantity);
      if (paintedSide === OrderItemSurfaces.LEFT && item.surfaceLeft)
        return (acc += item.quantity);
      return acc;
    }, 0);
  },
  getCustomItemsCount: (customItems: IOrderCustomItem[] = []): number => {
    return customItems.reduce((acc, item) => (acc += item.quantity), 0);
  },
};
