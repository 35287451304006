import { Button } from 'react-bootstrap';
import React from 'react';
import Modal from 'PageTemplates/ModalTemplate';
import { useAlert } from 'react-alert';
import { setSpinner } from 'services/store/actions/view';
import { changePermission } from 'services/apiRequests/users/update';
import { useDispatch } from 'react-redux';
import { setCustomer } from 'services/store/actions/customers';

const ChangePermission = ({ user, closeModal }) => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const perm = user.permission === 'admin' ? 'Administrator' : 'Klient';

  const handleChangePermission = async (permission) => {
    if (user) {
      if (user.isBoss)
        return alert.error(
          'Nie możesz zmienić uprawnień tego użytkownika',
        );
      dispatch(setSpinner(true));
      await changePermission(
        user._id,
        permission,
        (user) => {
          dispatch(setCustomer(user));
          alert.success('Updawnienia zmienione');
          closeModal();
        },
        () => {
          alert.error('Błąd');
        },
      );
      dispatch(setSpinner(false));
    }
  };
  return (
    <Modal
      title="Zmiana uprawnień"
      closeModal={closeModal}
      footer={
        <>
          <Button
            variant="success"
            onClick={() => handleChangePermission('admin')}
          >
            Administrator
          </Button>
          <Button
            variant="outline-success"
            onClick={() => handleChangePermission('user')}
          >
            Klient
          </Button>
        </>
      }
    >
      <div>
        Aktualne uprawnienia użytkownika
        <strong>
          {' '}
          {user.firstname || ''} {user.surname || ''}{' '}
        </strong>{' '}
        to: <strong style={{ color: 'green' }}>{perm}</strong>.
      </div>
      <br />
      <div>Chcesz je zmienić na:</div>
    </Modal>
  );
};

export default ChangePermission;
