import React from 'react';

const Gallery = () => {
  return (
    <div>
      <h4>Soon...</h4>
    </div>
  );
};

export default Gallery;
