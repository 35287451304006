import React from 'react';
import Modal from 'PageTemplates/ModalTemplate';
import { Button, Form } from 'react-bootstrap';
import { Formik } from 'formik';
import TextInput from 'components/FormInputs/TextInput';
import { schema } from '../utils/validateAddEmployee';
import { employeePositions } from 'services/utils/const';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { setSpinner } from 'services/store/actions/view';
import { createEmployee } from 'services/apiRequests/employees/post';
import { useAlert } from 'react-alert';

const AddEmployeeModal = ({ closeModal }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const alert = useAlert();

  return (
    <Modal
      title="Dodaj pracownika"
      closeModal={closeModal}
      footer={
        <>
          <Button variant="success" type="submit" form="addEmployeeForm">
            Dodaj
          </Button>
          <Button variant="danger" onClick={closeModal}>
            Anuluj
          </Button>
        </>
      }
    >
      <Formik
        validationSchema={schema}
        validate={(values) => {
          const errors = {};
          if (values.password2 !== values.password)
            errors.password2 = 'Hasła muszą być identyczne';
          if (!values.positions.length)
            errors.positions = 'Musisz wybrać stanowisko/a';
          return errors;
        }}
        // ONSUBMIT REQUEST
        onSubmit={async (values, actions) => {
          dispatch(setSpinner(true));
          await createEmployee(
            values,
            (id) => {
              dispatch(setSpinner(false));
              alert.success('Pracownik utworzony');
              history.push(`/employees/${id}`);
              closeModal();
            },
            (errors) => {
              dispatch(setSpinner(false));
              alert.error('Błąd');
              actions.setErrors(errors);
            },
          );
        }}
        initialValues={{
          firstname: '',
          surname: '',
          email: '',
          positions: [],
          password: '',
          password2: '',
        }}
      >
        {(props) => {
          const { values, errors, touched } = props;
          return (
            <Form
              noValidate
              onSubmit={props.handleSubmit}
              id="addEmployeeForm"
            >
              <TextInput
                {...props}
                name="firstname"
                label="Imię"
                placeholder="Imię"
                required
              />
              <TextInput
                {...props}
                name="surname"
                label="Nazwisko"
                placeholder="Nazwisko"
                required
              />
              <TextInput
                {...props}
                name="email"
                label="E-mail"
                placeholder="E-mail"
                required
              />
              <TextInput
                {...props}
                name="password"
                type="password"
                label="Hasło"
                placeholder="Hasło"
                required
              />
              <TextInput
                {...props}
                name="password2"
                type="password"
                label="Powtórz hasło"
                placeholder="Powtórz hasło"
                required
              />

              <Form.Group controlId="positions">
                <Form.Label style={{ fontWeight: 'bold', fontSize: 18 }}>
                  Stanowiska:
                </Form.Label>
                {employeePositions.map((item) => (
                  <Form.Check
                    key={item}
                    type="checkbox"
                    id={item}
                    name={item}
                    label={item}
                    className={item}
                    value={item}
                    onChange={(e) => {
                      if (e.target.checked)
                        values.positions.push(e.target.value);
                      else
                        values.positions = values.positions.filter(
                          (item) => item !== e.target.value,
                        );
                    }}
                  />
                ))}
                {errors.positions && touched.positions && (
                  <div className="invalid">{errors.positions}</div>
                )}
              </Form.Group>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default AddEmployeeModal;
