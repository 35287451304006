import React, { FC, ReactElement, useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { IOrder } from 'services/interfaces/orders/Orders.interface';
import { IOrderCheckboxOnChange } from './Cells/OrderCheckboxCell';
import OrdersListRow from './OrdersListRow';
import { initColumnns, IOrderListColumns } from './utils/orderListColumns';

export interface IOrderListProps {
  orders?: IOrder[];
  columns: Partial<IOrderListColumns>;
  onCheck?: (event: IOrderCheckboxOnChange) => void;
  onRowClick?: () => void;
  isChecked?: (order: IOrder) => boolean;
}

const OrdersList: FC<IOrderListProps> = ({
  orders,
  columns,
  onCheck,
  onRowClick,
  isChecked,
  children,
}) => {
  checkIsComponentWillRender(!!children, columns);

  const [sortingType, setSortingType] = useState('default');
  const [list, setList] = useState<IOrder[] | undefined>(
    orders || undefined,
  );

  const columnsToDisplay: IOrderListColumns = {
    ...initColumnns,
    ...columns,
  };

  useEffect(() => {
    if (sortingType && orders?.length) {
      setList(orders);
    }
  }, [sortingType, orders]);

  return (
    <Table responsive="lg" bordered hover size="sm">
      <thead>
        <tr>
          {columnsToDisplay.checkbox && onCheck && isChecked && (
            <th style={{ width: 30, visibility: 'hidden' }}></th>
          )}
          {columnsToDisplay.lp && <th>Lp</th>}
          {columnsToDisplay.customer && <th>Klient</th>}
          {columnsToDisplay.number && <th>Numer</th>}
          {columnsToDisplay.name && <th>Nazwa</th>}
          {columnsToDisplay.color && <th>Kolor</th>}
          {columnsToDisplay.paintType && <th>Matowość</th>}
          {columnsToDisplay.elements && <th>Elementy</th>}
          {columnsToDisplay.surfaceRight && <th>PL</th>}
          {columnsToDisplay.surfaceLeft && <th>PP</th>}
          {columnsToDisplay.customElements && (
            <th
              title="Długość elementów niestandardowych"
              style={{ width: 100 }}
            >
              Dł. el. niest.
            </th>
          )}
          {columnsToDisplay.type && <th>Typ</th>}
          {columnsToDisplay.date && <th>Data</th>}
          {columnsToDisplay.finishDate && <th>Data realizacji</th>}
          {columnsToDisplay.status && <th>Status</th>}
          {columnsToDisplay.price && (
            <th>
              Cena <small>(zaliczka)</small>
            </th>
          )}
          {columnsToDisplay.priceChange && <th>Zmiana ceny</th>}
          {columnsToDisplay.paymentValue && (
            <th style={{ width: 150 }}>Wartość wpłaty</th>
          )}
          {columnsToDisplay.paymentsList && <th>Lista wpłat</th>}
          {(columnsToDisplay.paymentStatusDisplay ||
            columnsToDisplay.paymentStatusHandle) && (
            <th style={{ width: 200 }}>Status płatności</th>
          )}
        </tr>
      </thead>
      <tbody>
        {children ||
          list?.map((order, index) => (
            <OrdersListRow
              key={order._id}
              order={order}
              index={index}
              columns={columnsToDisplay}
              onRowClick={onRowClick}
              isChecked={isChecked}
              onCheck={onCheck}
            />
          ))}
      </tbody>
    </Table>
  );
};

export default OrdersList;

const checkIsComponentWillRender = (
  children: boolean,
  columns: Partial<IOrderListColumns>,
) => {
  if (!children && (columns.priceChange || columns.paymentValue)) {
    throw 'OrdersList -> Children prop is needed!';
  }
};
