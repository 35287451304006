import Axios from 'axios';

const config = {
  headers: {
    'Content-Type': 'application/json',
  },
};

export const sendOrder = async (order, type, onEnd, onErr) => {
  try {
    const data = new FormData();
    data.append(`order`, JSON.stringify(order));

    if (order.customMilling.file) {
      const filename = order.customMilling.file.name.split('/').join('_');
      data.append('file', order.customMilling.file, filename);
    }
    if (order.customPilaster.file) {
      const filename = order.customPilaster.file.name.split('/').join('_');
      data.append('file', order.customPilaster.file, filename);
    }
    if (order.customCrown.file) {
      const filename = order.customCrown.file.name.split('/').join('_');
      data.append('file', order.customCrown.file, filename);
    }
    order.items.forEach((item) => {
      if (item.image.file) {
        const filename = item.image.file.name.split('/').join('_');
        data.append('file', item.image.file, filename);
      }
    });
    order.customItems.forEach((item) => {
      if (item.image.file) {
        const filename = item.image.file.name.split('/').join('_');
        data.append('file', item.image.file, filename);
      }
    });

    const res =
      type === 'new'
        ? await Axios.post('/api/orders/neworder', data, config)
        : await Axios.put(`/api/orders/edit/${order._id}`, data, config);
    const { _id } = res.data;

    return onEnd(_id);
  } catch (err) {
    console.log(err.response || err);
    if (
      err.response &&
      err.response.data &&
      err.response.data.msg === 'na produkcji'
    ) {
      return onErr('Zamówienie na produkcji');
    }
    return onErr();
  }
};
