import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { dateToString } from 'services/utils/date';
import { Table, Form } from 'react-bootstrap';
import styled from 'styled-components';
import { firstLetterToUpperCase } from 'services/utils/string';
import { getOrdersSchedules } from './utils';
import FlexRow from 'PageTemplates/FlexRowTemplate';

const Wrapper = styled.div`
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
`;
const Tr = styled.tr`
  &:hover {
    background-color: #ececec;
  }
`;
const Th = styled.th`
  background-color: ${({ dayName }) => {
    if (dayName === 'Sobota') return '#e6f8e2';
    if (dayName === 'Niedziela') return '#f7eded';
    return 'inherit';
  }};
`;
const Td = styled.td`
  position: 'relative';
  background-color: ${({ dayName }) => {
    if (dayName === 'Sobota') return '#e6f8e2';
    if (dayName === 'Niedziela') return '#f7eded';
    return 'inherit';
  }};
`;

const TableView = ({ timetables }) => {
  const history = useHistory();
  const [days, setDays] = useState(null);
  const [orders, setOrders] = useState(null);
  const [filteredOrders, setFilteredOrders] = useState(null);
  const [customersList, setCustomersList] = useState(null);
  const [customer, setCustomer] = useState('');

  useEffect(() => {
    if (timetables) {
      const result = getOrdersSchedules(timetables);
      setOrders(result.schedules);
      setDays(result.dates);
      setCustomersList(result.customersList);
    }
  }, [timetables]);

  useEffect(() => {
    if (orders) {
      let newFilteredOrders = [...orders];
      if (customer)
        newFilteredOrders = newFilteredOrders.filter(
          (item) => item.customerId === customer,
        );

      setFilteredOrders(newFilteredOrders);
    }
  }, [orders, customer]);

  return (
    <Wrapper>
      <Filter
        customers={customersList}
        value={customer}
        onChange={(e) => setCustomer(e.target.value)}
        disabled={
          !customersList ||
          !customersList.length ||
          !orders ||
          !orders.length
        }
      />
      <Table bordered>
        <thead>
          <tr>
            <th style={{ width: 100 }}>Zamówienie</th>
            {days &&
              days.map((day) => (
                <Th key={day.date} dayName={day.name}>
                  {dateToString(day.date)} {day.name}
                </Th>
              ))}
          </tr>
        </thead>
        <tbody>
          {filteredOrders &&
            filteredOrders.map((order) => {
              return (
                <Tr key={order.orderId}>
                  <td
                    onClick={() => history.push(`/order/${order.orderId}`)}
                    style={{ fontWeight: 'bold', cursor: 'pointer' }}
                  >
                    {order.orderName}
                  </td>
                  {order.schedule.map((item, index) => {
                    return (
                      <Td
                        key={item.positions.join('.') + index}
                        dayName={item.dayName}
                      >
                        {item.positions.map((pos) => (
                          <span key={pos} className={pos}>
                            {firstLetterToUpperCase(pos)},{' '}
                          </span>
                        ))}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
        </tbody>
      </Table>
    </Wrapper>
  );
};

export default TableView;

const Filter = ({ value, disabled, onChange, customers }) => (
  <FlexRow justify="flex-start">
    <Form.Control
      as="select"
      onChange={onChange}
      value={value}
      disabled={disabled}
      style={{ width: 200, marginBottom: 5 }}
    >
      <option value="" />
      {customers &&
        customers
          .sort((a, b) =>
            `${a.customerName}`.localeCompare(b.customerName),
          )
          .map((item) => (
            <option
              value={item.customerId}
              key={item.customerId}
              status={item}
            >
              {item.customerName}
            </option>
          ))}
    </Form.Control>
  </FlexRow>
);
