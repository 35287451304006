import React, { useState } from 'react';
import Modal from 'PageTemplates/ModalTemplate';
import { Button, Table, Form } from 'react-bootstrap';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { productionStatuses } from 'services/utils/const';
import { useDispatch, useSelector } from 'react-redux';
import { setSpinner } from 'services/store/actions/view';
import { updateOrder } from 'services/apiRequests/orders/update';
import { setOrder } from 'services/store/actions/orders';
import { useAlert } from 'react-alert';
import { firstLetterToUpperCase } from 'services/utils/string';
import withContext from 'hoc/withContext';
import { StyledH6 } from 'components/header';

const SetLostElements = ({ order, closeModal, permissionContext }) => {
  const dispatch = useDispatch();
  const alert = useAlert();

  const position = useSelector((state) => state.employee.activePosition);

  const [list, setList] = useState({
    items: order.items.map((item) => ({
      _id: item._id,
      ...item.elementLost,
    })),
    customItems: order.customItems.map((item) => ({
      _id: item._id,
      ...item.elementLost,
    })),
  });

  const handleItem = (listName, index, key, value) => {
    const newList = [...list[listName]];
    newList[index][key] = value;
    setList({ ...list, [listName]: newList });
  };

  const handleSubmit = async () => {
    dispatch(setSpinner(true));
    await updateOrder(
      'lost',
      order._id,
      { lostItems: list, position: position || 'Biuro' },
      (order) => {
        dispatch(setOrder(order));
        dispatch(setSpinner(false));
        alert.success('zaktualizowano braki');
        closeModal();
      },
      () => {
        dispatch(setSpinner(false));
        alert.error('Błąd!');
      },
    );
  };

  const isDisabled = () =>
    [...list.items, ...list.customItems].some(
      (item) =>
        (!item.position && item.quantity) ||
        (item.position && item.quantity === 0),
    );

  const clearLost = () => {
    const items = list.items.map((item) => ({
      ...item,
      position: '',
      quantity: 0,
    }));
    const customItems = list.customItems.map((item) => ({
      ...item,
      position: '',
      quantity: 0,
    }));
    setList({ items, customItems });
  };

  return (
    <Modal
      title="Brakujące elementy"
      closeModal={closeModal}
      size="lg"
      footer={
        <>
          {permissionContext === 'admin' && (
            <Button variant="outline-primary" onClick={clearLost}>
              Resetuj
            </Button>
          )}
          <Button
            variant="success"
            onClick={handleSubmit}
            disabled={isDisabled()}
          >
            Zatwierdź
          </Button>
          <Button variant="danger" onClick={closeModal}>
            Anuluj
          </Button>
        </>
      }
    >
      <StyledH6>Elementy</StyledH6>
      <Items
        items={order.items}
        list={list.items}
        listName="items"
        handleItem={handleItem}
        position={position}
        permissionContext={permissionContext}
      />{' '}
      {list.customItems.length ? (
        <>
          <StyledH6>Elementy niestandardowe</StyledH6>
          <Items
            items={order.customItems}
            list={list.customItems}
            listName="customItems"
            handleItem={handleItem}
            position={position}
            permissionContext={permissionContext}
          />
        </>
      ) : null}
    </Modal>
  );
};

export default withContext(SetLostElements);

const Th = styled.th`
  width: 20px;
`;
const TightColumn = styled.th`
  width: 65px;
  background-color: ${({ side, theme }) => {
    if (side === 'left') return theme.blowWarning;
    if (side === 'right') return theme.blowGreen;
    return null;
  }};
`;
const PrimaryColumn = styled.td`
  vertical-align: middle;
  background-color: rgba(205, 231, 169, 0.5);
`;
const SecondaryColumn = styled.td`
  vertical-align: middle;
  background-color: rgba(252, 215, 160, 0.5);
`;

const Items = (props) => {
  const { items, listName } = props;
  return (
    <Table responsive="lg" hover bordered>
      <thead>
        <tr>
          <Th>LP</Th>
          <Th>Typ</Th>
          <TightColumn>
            {listName === 'items' ? 'Wys.' : 'Dł.'}
          </TightColumn>
          <TightColumn>Szer.</TightColumn>
          <TightColumn side="right">P</TightColumn>
          <TightColumn side="left">L</TightColumn>
          <TightColumn>Ilość</TightColumn>
          <TightColumn>Stanowisko</TightColumn>
          <TightColumn>Braki</TightColumn>
          <Th>
            <small>Popr.</small>
          </Th>
        </tr>
      </thead>
      <tbody>
        {items.map((item, index) => {
          const quantityArr = [];
          for (let i = 0; i < item.quantity + 1; i++) {
            quantityArr.push(i);
          }
          return (
            <TableRow
              key={index}
              index={index}
              quantityArr={quantityArr}
              item={item}
              {...props}
            />
          );
        })}
      </tbody>
    </Table>
  );
};

const TableRow = ({
  item,
  index,
  list,
  listName,
  quantityArr,
  handleItem,
  permissionContext,
  position,
}) => {
  const isDisabled = () => {
    if (permissionContext === 'employee') {
      if (item.elementToCorrect.position) return true;
      if (
        item.elementLost.position &&
        item.elementLost.position !== position
      )
        return true;
    }
    return false;
  };
  return (
    <tr>
      <td>{index + 1}</td>
      <td>{item.type}</td>
      <td>{listName === 'items' ? item.height : item.length}</td>
      <td>{item.width}</td>
      <PrimaryColumn>
        {item.paintRight ? <FontAwesomeIcon icon={faCheck} /> : ''}
      </PrimaryColumn>
      <SecondaryColumn>
        {item.paintLeft ? <FontAwesomeIcon icon={faCheck} /> : ''}
      </SecondaryColumn>
      <td>{item.quantity}</td>
      <td>
        <Form.Control
          disabled={isDisabled()}
          style={{ padding: 3, textAlign: 'center' }}
          value={list[index].position}
          onChange={(e) =>
            handleItem(listName, index, 'position', e.target.value)
          }
          as="select"
        >
          <option value="" />
          {permissionContext === 'admin' || isDisabled() ? (
            productionStatuses.map((item) => (
              <option
                key={item}
                value={item}
                className={item}
                style={{ color: 'black' }}
              >
                {firstLetterToUpperCase(item)}
              </option>
            ))
          ) : (
            <option
              value={position}
              className={position}
              style={{ color: 'black' }}
            >
              {firstLetterToUpperCase(position)}
            </option>
          )}
        </Form.Control>
      </td>
      <td>
        <Form.Control
          disabled={isDisabled()}
          style={{ padding: 3, textAlign: 'center' }}
          value={list[index].quantity}
          onChange={(e) =>
            handleItem(
              listName,
              index,
              'quantity',
              parseInt(e.target.value),
            )
          }
          as="select"
        >
          {quantityArr.map((item) => (
            <option
              key={item}
              value={item}
              className={item}
              style={{ color: 'black' }}
            >
              {item}
            </option>
          ))}
        </Form.Control>
      </td>
      <td>
        {item.elementToCorrect.position ? (
          <small className={item.elementToCorrect.position}>
            {`${firstLetterToUpperCase(
              item.elementToCorrect.position.slice(0, 3),
            )}_${item.elementToCorrect.quantity}`}
          </small>
        ) : null}
      </td>
    </tr>
  );
};
