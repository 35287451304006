import { OrdersListCells } from 'components/ordersList/Cells';
import OrdersListTableRow from 'components/ordersList/OrdersListTableRow';
import React, { FC } from 'react';
import { OrderItemSurfaces } from 'services/enums/Orders.enum';
import { IOrder } from 'services/interfaces/orders/Orders.interface';
import { IUser } from 'services/interfaces/users/Users.interface';

interface IProps {
  index: number;
  order: IOrder;
  handleRedirect: () => void;
  cashRegisterStoryValue: number;
}

const OrdersInCashListRow: FC<IProps> = ({
  index,
  handleRedirect,
  order,
  cashRegisterStoryValue,
}) => {
  return (
    <OrdersListTableRow onRowClick={handleRedirect}>
      <td>{index + 1}</td>
      <OrdersListCells.customerName order={order} />
      <OrdersListCells.orderName order={order} />
      <OrdersListCells.orderNumber order={order} />
      <OrdersListCells.color order={order} />
      <OrdersListCells.paintType order={order} />
      <OrdersListCells.elements order={order} />
      <OrdersListCells.surface
        order={order}
        surfaceType={OrderItemSurfaces.RIGHT}
        surfaceValue={order.surfaceRight}
      />
      <OrdersListCells.surface
        order={order}
        surfaceType={OrderItemSurfaces.LEFT}
        surfaceValue={order.surfaceLeft}
      />
      <OrdersListCells.customElements order={order} />
      <OrdersListCells.orderType order={order} />
      <OrdersListCells.date order={order} />
      <OrdersListCells.finishDate order={order} />
      <OrdersListCells.price order={order} />
      <td>{cashRegisterStoryValue}</td>
      <OrdersListCells.paymentsList order={order} />
      <OrdersListCells.paymentStatusDisplay order={order} />
    </OrdersListTableRow>
  );
};

export default OrdersInCashListRow;
