import React, { useState, useEffect } from 'react';
import PageTemplate from 'PageTemplates/PageTemplate';
import { PageHeader } from 'components/header';
import { Button } from 'react-bootstrap';
import List from './components/List';
import { useDispatch, useSelector } from 'react-redux';
import { useAlert } from 'react-alert';
import { getEmployees } from 'services/store/actions/employees';
import { setSpinner } from 'services/store/actions/view';
import AddEmployeeModal from './components/AddEmployeeModal';
import WorkedHours from './components/WorkedHours';

const initModals = { addEmployee: false, workedHours: false };

const EmployeesList = () => {
  const dispatch = useDispatch();
  const alert = useAlert();

  const employees = useSelector((state) => state.employees.employees);

  const [modals, setModals] = useState({ initModals });

  const getEmployeesList = async () => {
    dispatch(
      getEmployees(
        () => {
          dispatch(setSpinner(false));
        },
        () => {
          dispatch(setSpinner(false));
          alert.error('Błąd!');
        },
      ),
    );
  };

  useEffect(() => {
    if (!employees) {
      dispatch(setSpinner(true));
      getEmployeesList();
    } else {
      getEmployeesList();
    }
  }, []);

  const handleModal = (key) => setModals({ ...modals, [key]: true });
  const closeModal = () => setModals(initModals);
  return (
    <PageTemplate>
      <PageHeader
        title="Pracownicy"
        extra={[
          <Button
            key={0}
            variant="outline-primary"
            onClick={() => handleModal('workedHours')}
            disabled={!employees}
          >
            Godziny pracy
          </Button>,
          <Button key={1} onClick={() => handleModal('addEmployee')}>
            Dodaj pracownika
          </Button>,
        ]}
      />
      <List employees={employees} />
      {/* MODALS */}
      {modals.addEmployee && <AddEmployeeModal closeModal={closeModal} />}
      {modals.workedHours && (
        <WorkedHours employees={employees} closeModal={closeModal} />
      )}
    </PageTemplate>
  );
};

export default EmployeesList;
