import React, { useEffect } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useSelector, useDispatch } from 'react-redux';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import ScrollUpButton from 'react-scroll-up-button';
import BannerDisplay from 'components/modals/Banner/BannerDisplay';
import UserNotAccepted from 'components/modals/Users/UserNotAccepted';
import Navigation from 'components/navigation';
import Spinner from 'components/spinner';
import PrivateRoute from 'hoc/PrivateRoute';
import CashRegisterStoryPreview from 'scenes/CashRegisterStoryPreview';
import Colors from 'scenes/Catalog/Colors';
import Crowns from 'scenes/Catalog/Crowns';
import ElementDetails from 'scenes/Catalog/ElementDetails';
import GlassCases from 'scenes/Catalog/GlassCases';
import Handles from 'scenes/Catalog/Handles';
import Millings from 'scenes/Catalog/Millings';
import Pilasters from 'scenes/Catalog/Pilasters';
import Veneers from 'scenes/Catalog/Veneers';
import Contact from 'scenes/Contact';
import CuttingAdminPanel from 'scenes/Cutting/CuttingPage';
import CuttingPackage from 'scenes/CuttingPackage';
import EditInvoicePage from 'scenes/EditInvoicePage/EditInvoicePage';
import HomePage from 'scenes/Home';
import InvoicePage from 'scenes/InvoicePage/InvoicePage';
import InvoicesPage from 'scenes/InvoicesPage/InvoicesPage';
import NewCuttingPackage from 'scenes/NewCuttingPackage';
import NewInvoicePage from 'scenes/NewInvoicePage/NewInvoicePage';
import OrderSummary from 'scenes/Order/FormSummary';
import Order from 'scenes/Order/View';
import EditOrder from 'scenes/OrderForm/EditOrder';
import NewOrder from 'scenes/OrderForm/NewOrder';
import PaintsOrders from 'scenes/PaintsOrders';
import PaymentsPage from 'scenes/Payments';
import Production from 'scenes/Production/';
import Regulations from 'scenes/Regulations/';
import AccountSettings from 'scenes/Settings/Account';
import GlobalSettings from 'scenes/Settings/Global';
import Sign from 'scenes/Sign';
import CustomersStatistics from 'scenes/Statistics/Customer';
import EmployeesStatistics from 'scenes/Statistics/Employee';
import ProductionStatistics from 'scenes/Statistics/Production';
import TimetablePosition from 'scenes/Timetables/Position';
import Timetable from 'scenes/Timetables/Timetable';
import UserPrices from 'scenes/UserPrices';
import Customers from 'scenes/UsersList/Customers';
import EmployeesList from 'scenes/UsersList/Employees';
import Customer from 'scenes/UsersProfiles/Customer';
import Employee from 'scenes/UsersProfiles/Employee';
import { loadUserData } from 'services/store/actions/auth';
import {
  setEmployeeActivePosition,
  setEmployeePaintRoom,
} from 'services/store/actions/employee';
import { bannerLoad } from 'services/store/actions/messages';
import setAuthToken from 'services/utils/setAuthToken';
import MainTemplate from 'PageTemplates/MainTemplate';
import CashRegisterPage from 'scenes/CashRegister/CashRegisterPage';

// IF IS TOKEN, SET TO HEADERS IN AXIOS REQUESTS
if (localStorage.token) setAuthToken(localStorage.token);

// LOAD USER DATA
const App = () => {
  const dispatch = useDispatch();
  const isSpinner = useSelector((state) => state.view.isSpinner);
  const isAuthenticated = useSelector(
    (state) => state.auth.isAuthenticated,
  );
  const user = useSelector((state) => state.auth.user);
  const banner = useSelector((state) => state.messages.banner);

  const queryClient = new QueryClient();
  //  GET USER
  useEffect(() => {
    dispatch(loadUserData());
  }, []);

  useEffect(() => {
    if (user.permission === 'employee') {
      const position = localStorage.getItem('activePosition');
      if (position && user.positions.includes(position))
        dispatch(setEmployeeActivePosition(position));
      else dispatch(setEmployeeActivePosition(user.positions[0]));

      const paintRoom = localStorage.getItem('paintRoom');
      if (paintRoom) dispatch(setEmployeePaintRoom(paintRoom));
    }
  }, [user]);

  return (
    <QueryClientProvider client={queryClient}>
      <div
        style={{
          minHeight: '100vh',
          position: 'relative',
          paddingBottom: isAuthenticated ? 50 : 0,
        }}
      >
        {isAuthenticated && user.isAccepted === false && (
          <UserNotAccepted />
        )}
        {isSpinner && <Spinner />}
        <ScrollUpButton />
        <Router>
          <MainTemplate>
            <>
              {banner && (
                <BannerDisplay
                  closeModal={() => dispatch(bannerLoad(null))}
                  banner={banner}
                />
              )}
              <Navigation />
              <Switch>
                <Route path="/login" component={Sign} />
                <Route path="/register" component={Sign} />
                <Route path="/passwordremind" component={Sign} />
                <Route exact path="/" component={HomePage} />
                <Route path="/regulations" component={Regulations} />
                <Route exact path="/order/:id" component={Order} />
                <PrivateRoute
                  permissions={['admin', 'user']}
                  path="/order/:id/edit"
                  component={EditOrder}
                />
                <PrivateRoute
                  permissions={['admin', 'user']}
                  exact
                  path="/neworder"
                  component={NewOrder}
                />
                <PrivateRoute
                  permissions={['admin', 'user']}
                  path="/neworder/summary"
                  component={OrderSummary}
                />
                <PrivateRoute
                  permissions={['admin', 'user']}
                  path="/editorder/summary"
                  component={OrderSummary}
                />
                <PrivateRoute
                  permissions={['admin', 'employee']}
                  exact
                  path="/timetable"
                  component={Timetable}
                />
                <PrivateRoute
                  permissions={['admin']}
                  exact
                  path="/cutting"
                  component={CuttingAdminPanel}
                />
                <PrivateRoute
                  permissions={['admin', 'employee']}
                  exact
                  path="/cutting/package/:id"
                  component={CuttingPackage}
                />
                <PrivateRoute
                  permissions={['admin']}
                  exact
                  path="/cutting/new"
                  component={NewCuttingPackage}
                />

                <PrivateRoute
                  permissions={['admin', 'employee']}
                  exact
                  path="/:position/timetable/"
                  component={TimetablePosition}
                />
                <PrivateRoute
                  permissions={['admin']}
                  exact
                  path="/production"
                  component={Production}
                />
                <PrivateRoute
                  permissions={['admin', 'employee']}
                  exact
                  path="/paints"
                  component={PaintsOrders}
                />
                <PrivateRoute
                  permissions={['admin']}
                  path="/statistics/production"
                  component={ProductionStatistics}
                />
                <PrivateRoute
                  permissions={['admin']}
                  path="/statistics/employees"
                  component={EmployeesStatistics}
                />
                <PrivateRoute
                  permissions={['admin', 'user']}
                  path="/statistics/customers"
                  component={CustomersStatistics}
                />
                <PrivateRoute
                  permissions={['admin']}
                  exact
                  path="/customers"
                  component={Customers}
                />
                <PrivateRoute
                  permissions={['admin']}
                  exact
                  path="/payments"
                  component={PaymentsPage}
                />
                <PrivateRoute
                  permissions={['admin']}
                  path="/customers/:customerId"
                  component={Customer}
                />
                <PrivateRoute
                  permissions={['admin', 'user']}
                  path="/prices"
                  component={UserPrices}
                />
                <PrivateRoute
                  permissions={['user']}
                  path="/contact"
                  component={Contact}
                />
                <PrivateRoute
                  permissions={['admin']}
                  exact
                  path="/employees"
                  component={EmployeesList}
                />
                <PrivateRoute
                  permissions={['admin']}
                  path="/employees/:employeeId"
                  component={Employee}
                />
                <Route path="/catalog/colors" component={Colors} />
                <Route path="/catalog/veneers" component={Veneers} />
                <Route path="/catalog/handles" component={Handles} />
                <Route path="/catalog/millings" component={Millings} />
                <Route path="/catalog/glassCases" component={GlassCases} />
                <Route path="/catalog/pilasters" component={Pilasters} />
                <Route path="/catalog/crowns" component={Crowns} />
                <Route
                  path="/catalog/element/:name"
                  exact
                  component={ElementDetails}
                />
                <PrivateRoute
                  permissions={['admin']}
                  path="/blow"
                  exact
                  component={CashRegisterPage}
                />
                <PrivateRoute
                  permissions={['admin']}
                  path="/fv"
                  exact
                  component={InvoicesPage}
                />
                <PrivateRoute
                  permissions={['admin', 'user']}
                  path="/fv/:id"
                  exact
                  component={InvoicePage}
                />
                <PrivateRoute
                  permissions={['admin']}
                  path="/new-fv"
                  exact
                  component={NewInvoicePage}
                />
                <PrivateRoute
                  permissions={['admin']}
                  path="/edit-fv/:id"
                  exact
                  component={EditInvoicePage}
                />
                <PrivateRoute
                  permissions={['admin']}
                  path="/blow/:id"
                  exact
                  component={CashRegisterStoryPreview}
                />
                <PrivateRoute
                  permissions={['admin']}
                  path="/settings"
                  exact
                  component={GlobalSettings}
                />
                <Route
                  path="/settings/account"
                  exact
                  component={AccountSettings}
                />
              </Switch>
            </>
          </MainTemplate>
        </Router>
      </div>
    </QueryClientProvider>
  );
};

export default App;
