/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useDispatch } from 'react-redux';
import {
  setEditedOrder,
  setNewOrderField,
} from 'services/store/actions/newOrder';
import { useHistory } from 'react-router';
import withContext from 'hoc/withContext';
import { setSpinner } from 'services/store/actions/view';
import {
  getOrderFiles,
  getOrderLabel,
} from 'services/apiRequests/files/get';
import { useAlert } from 'react-alert';
import AdminButtons from './AdminButtons';
import EmployeeButtons from './EmployeeButtons';
import CustomerButtons from './CustomerButtons';

const Buttons = ({ permissionContext, ...props }) => {
  const { order } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const alert = useAlert();

  const editOrder = () => {
    dispatch(setEditedOrder(order));
    dispatch(setNewOrderField('userPrices', order.user.discounts));
    history.push(`/order/${order._id}/edit`);
  };

  const getFiles = async () => {
    dispatch(setSpinner(true));
    await getOrderFiles(
      order,
      () => dispatch(setSpinner(false)),
      () => {
        dispatch(setSpinner(false));
        alert.error('Błąd!');
      },
    );
  };

  const getLabel = async () => {
    dispatch(setSpinner(true));
    await getOrderLabel(
      order,
      () => dispatch(setSpinner(false)),
      () => {
        dispatch(setSpinner(false));
        alert.error('Błąd!');
      },
    );
  };

  if (permissionContext === 'admin')
    return (
      <AdminButtons
        {...props}
        editOrder={editOrder}
        getFiles={getFiles}
        getLabel={getLabel}
      />
    );
  if (permissionContext === 'employee')
    return (
      <EmployeeButtons
        {...props}
        getFiles={getFiles}
        getLabel={getLabel}
      />
    );
  if (permissionContext === 'user')
    return (
      <CustomerButtons
        {...props}
        editOrder={editOrder}
        getFiles={getFiles}
      />
    );
  return null;
};

export default withContext(Buttons);
