import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import Fade from 'react-reveal/Fade';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import {
  handleNewOrderInput,
  removeNewOrderItem,
} from 'services/store/actions/newOrder';
import { setItemModelEdge } from 'services/store/actions/view';
import ItemType from './Cells/ItemType';
import Input from './Cells/Input';
import RightSideEdge from './Cells/RightSideEdge';
import LeftSideEdge from './Cells/LeftSideEdge';
import Thickness from './Cells/Thickness';
import ImageCell from './Cells/Image';
import MuntinQuantity from './Cells/Muntin';

const Td = styled.td`
  background-color: ${({ primary, secondary, theme }) => {
    if (primary) return theme.blowGreenLight;
    if (secondary) return theme.blowWarningLight;
  }}!important;
`;

const OrderItemsRow = ({ item, index, tabIndex, order }) => {
  const dispatch = useDispatch();
  const [rowClassName, setRowClassName] = useState('');

  const handleItem = (value, type, isNumber = false) => {
    dispatch(handleNewOrderInput(index, type, value, isNumber));
  };

  const handleFocus = (leftSide, rightSide) => {
    setRowClassName('focusedRow');
    if (leftSide) dispatch(setItemModelEdge('left', leftSide));
    if (rightSide) dispatch(setItemModelEdge('right', rightSide));
  };

  const handleBlur = () => {
    setRowClassName('');
    dispatch(setItemModelEdge('left', ''));
    dispatch(setItemModelEdge('right', ''));
  };
  return (
    <>
      <Fade bottom>
        <tr className={rowClassName}>
          {[
            'Materiał klienta',
            'Poprawa (wina klienta)',
            'Domówienie',
          ].includes(order.orderType) && (
            <td>
              <input
                tabIndex={tabIndex}
                type="checkbox"
                name="blowMaterial"
                checked={item.blowMaterial}
                onChange={({ target: { checked } }) =>
                  handleItem(checked, 'blowMaterial')
                }
              />
            </td>
          )}
          <td>{index + 1}</td>
          <td>
            <ItemType
              value={item.type}
              handleFocus={() => handleFocus()}
              handleBlur={() => handleBlur()}
              handleChange={({ target: { value } }) =>
                handleItem(value, 'type')
              }
              tabIndex={tabIndex}
            />
          </td>
          {/* 
    |--------------------------------------------------
    |
    | HEIGHT
    |
    |--------------------------------------------------
    */}
          <td>
            <Input
              autoComplete="off"
              name="height"
              value={item.height}
              onChange={({ target: { value } }) =>
                handleItem(value, 'height', true)
              }
              onFocus={() =>
                handleFocus('rightEdge leftEdge', 'rightEdge leftEdge')
              }
              onBlur={() => handleBlur()}
              focus
            />
          </td>
          <Td primary>
            {/* rightSideLeftEdge */}
            <RightSideEdge
              name="h1P"
              value={item.h1P}
              handleChange={({ target: { value } }) =>
                handleItem(value, 'h1P')
              }
              handleFocus={() =>
                handleFocus(null, 'leftEdge leftAnimation')
              }
              handleBlur={() => handleBlur()}
              tabIndex={tabIndex}
            />
          </Td>
          <Td primary>
            {/* rightSideRightEdge */}
            <RightSideEdge
              name="h2P"
              value={item.h2P}
              handleChange={({ target: { value } }) =>
                handleItem(value, 'h2P')
              }
              handleFocus={() =>
                handleFocus(null, 'rightEdge rightAnimation')
              }
              handleBlur={() => handleBlur()}
              tabIndex={tabIndex}
            />
          </Td>
          <Td secondary>
            {/* leftSideLeftEdge */}
            <LeftSideEdge
              name="h1L"
              value={item.h1L}
              handleChange={({ target: { value } }) =>
                handleItem(value, 'h1L')
              }
              handleFocus={() =>
                handleFocus('leftEdge leftAnimation', null)
              }
              handleBlur={() => handleBlur()}
              tabIndex={tabIndex}
            />
          </Td>
          <Td secondary>
            {/* leftSideRightEdge */}
            <LeftSideEdge
              name="h2L"
              value={item.h2L}
              handleChange={({ target: { value } }) =>
                handleItem(value, 'h2L')
              }
              handleFocus={() =>
                handleFocus('rightEdge rightAnimation', null)
              }
              handleBlur={() => handleBlur()}
              tabIndex={tabIndex}
            />
          </Td>
          <Td secondary>
            <input
              tabIndex={tabIndex}
              type="checkbox"
              name="hLPaintedEdge"
              checked={item.hLPaintedEdge}
              onChange={(e) =>
                handleItem(e.target.checked, 'hLPaintedEdge')
              }
            />
          </Td>
          {/* 
     |--------------------------------------------------
     |
     | WIDTH
     |
     |--------------------------------------------------
     */}
          <td>
            <Input
              autoComplete="off"
              name="width"
              value={item.width}
              onChange={({ target: { value } }) =>
                handleItem(value, 'width', true)
              }
              onFocus={() =>
                handleFocus('topEdge bottomEdge', 'topEdge bottomEdge')
              }
              onBlur={() => handleBlur()}
              focus
            />
          </td>
          <Td primary>
            {/* rigthSideTopEdge */}
            <RightSideEdge
              name="w1P"
              value={item.w1P}
              handleChange={({ target: { value } }) =>
                handleItem(value, 'w1P')
              }
              handleFocus={() => handleFocus(null, 'topEdge topAnimation')}
              handleBlur={() => handleBlur()}
              tabIndex={tabIndex}
            />
          </Td>
          <Td primary>
            {/* rightSideBottomEdge */}
            <RightSideEdge
              name="w2P"
              value={item.w2P}
              handleChange={({ target: { value } }) =>
                handleItem(value, 'w2P')
              }
              handleFocus={() =>
                handleFocus(null, 'bottomEdge bottomAnimation')
              }
              handleBlur={() => handleBlur()}
              tabIndex={tabIndex}
            />
          </Td>
          <Td secondary>
            {/* leftSideTopEdge */}
            <LeftSideEdge
              name="w1L"
              value={item.w1L}
              handleChange={({ target: { value } }) =>
                handleItem(value, 'w1L')
              }
              handleFocus={() => handleFocus('topEdge topAnimation', null)}
              handleBlur={() => handleBlur()}
              tabIndex={tabIndex}
            />
          </Td>
          <Td secondary>
            {/* leftSideBottomEdge */}
            <LeftSideEdge
              name="w2L"
              value={item.w2L}
              handleChange={({ target: { value } }) =>
                handleItem(value, 'w2L')
              }
              handleFocus={() =>
                handleFocus('bottomEdge bottomAnimation', null)
              }
              handleBlur={() => handleBlur()}
              tabIndex={tabIndex}
            />
          </Td>
          <Td secondary>
            <input
              tabIndex={tabIndex}
              type="checkbox"
              name="wLPaintedEdge"
              checked={item.wLPaintedEdge}
              onChange={(e) =>
                handleItem(e.target.checked, 'wLPaintedEdge')
              }
            />
          </Td>
          {/* 
     |--------------------------------------------------
     |
     | OTHERS
     |
     |--------------------------------------------------
     */}
          <td>
            <Thickness
              item={item}
              handleChange={({ target: { value } }) =>
                handleItem(value, 'thickness')
              }
              handleFocus={() => handleFocus()}
              handleBlur={() => handleBlur()}
              tabIndex={tabIndex}
            />
          </td>
          <td>
            <Input
              autoComplete="off"
              name="quantity"
              value={item.quantity}
              onChange={({ target: { value } }) =>
                handleItem(value, 'quantity', true)
              }
              onFocus={() => handleFocus()}
              onBlur={() => handleBlur()}
              focus
            />
          </td>
          <Td primary>
            <input
              tabIndex={tabIndex}
              type="checkbox"
              name="paintRight"
              checked={item.paintRight}
              onChange={(e) => handleItem(e.target.checked, 'paintRight')}
            />
          </Td>
          <Td secondary>
            <input
              tabIndex={tabIndex}
              type="checkbox"
              name="paintLeft"
              checked={item.paintLeft}
              onChange={(e) => handleItem(e.target.checked, 'paintLeft')}
            />
          </Td>
          {order.isUnderGlass && (
            <th>
              <input
                name="underglass"
                type="checkbox"
                checked={item.underGlass}
                tabIndex={tabIndex}
                onChange={(e) =>
                  handleItem(e.target.checked, 'underGlass')
                }
                disabled={item.type !== 'witryna'}
              />
            </th>
          )}
          {order.muntinType === 'x' && (
            <th>
              <MuntinQuantity
                name="muntinX"
                item={item}
                value={item.muntinX}
                tabIndex={tabIndex}
                onChange={(e) =>
                  handleItem(e.target.value, 'muntinX', true)
                }
              />
            </th>
          )}
          {order.muntinType === '+' && (
            <>
              <th>
                <MuntinQuantity
                  name="muntinH"
                  item={item}
                  value={item.muntinH}
                  tabIndex={tabIndex}
                  onChange={(e) =>
                    handleItem(e.target.value, 'muntinH', true)
                  }
                />
              </th>
              <th>
                <MuntinQuantity
                  name="muntinW"
                  item={item}
                  value={item.muntinW}
                  tabIndex={tabIndex}
                  onChange={(e) =>
                    handleItem(e.target.value, 'muntinW', true)
                  }
                />
              </th>
            </>
          )}
          {order.coloredWater && (
            <Td secondary>
              <input
                tabIndex={tabIndex}
                type="checkbox"
                name="coloredWaterBothSide"
                checked={item.coloredWaterBothSide}
                onChange={(e) =>
                  handleItem(e.target.checked, 'coloredWaterBothSide')
                }
              />
            </Td>
          )}
          {order.transparent && (
            <Td secondary>
              <input
                tabIndex={tabIndex}
                type="checkbox"
                name="transparentBothSide"
                checked={item.transparentBothSide}
                onChange={(e) =>
                  handleItem(e.target.checked, 'transparentBothSide')
                }
              />
            </Td>
          )}
          <ImageCell item={item} index={index} tabIndex={tabIndex} />
          <td>
            <Input
              tabIndex={tabIndex}
              autoComplete="off"
              name="comments"
              value={item.comments}
              onChange={({ target: { value } }) =>
                handleItem(value, 'comments')
              }
              onFocus={() => handleFocus()}
              onBlur={() => handleBlur()}
              focus
            />
          </td>
          <td
            onClick={() => dispatch(removeNewOrderItem(index))}
            style={{ color: '#c01212', cursor: 'pointer' }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </td>
        </tr>
      </Fade>
    </>
  );
};

export default OrderItemsRow;
