import React from 'react';
import styled from 'styled-components';
import DayCard from './DayCard';
import { firstLetterToUpperCase } from 'services/utils/string';

const Wrapper = styled.div`
  margin-bottom: 20px;
  width: 260px;
  @media (max-width: 600px) {
    width: 100vw;
    margin: 10px 0;
  }
`;
const Heading = styled.h1`
  font-weight: bold;
  letter-spacing: 3px;
  color: ${({ theme, pos }) => theme.positions[pos]};
  text-shadow: ${({ pos }) =>
    pos === 'podkład' ? '2px 2px 5px rgb(48, 48, 48)' : 'none'};
  text-align: center;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const PositionTimetable = ({ position, timetable, handleModal }) => {
  return (
    <Wrapper>
      <Heading pos={position}>{firstLetterToUpperCase(position)}</Heading>
      <Column>
        {timetable.map((day) => (
          <DayCard day={day} key={day._id} handleModal={handleModal} />
        ))}
      </Column>
    </Wrapper>
  );
};

export default PositionTimetable;
