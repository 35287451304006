import CuttingPackagesList from 'components/cuttingPackagesList/CuttingPackagesList';
import React from 'react';

const CuttingEmployeeView = ({ cuttingPackages }) => {
  return (
    <div>
      <CuttingPackagesList packages={cuttingPackages} />
    </div>
  );
};

export default CuttingEmployeeView;
