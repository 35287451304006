import React, { useState } from 'react';
import Modal from 'PageTemplates/ModalTemplate';
import FlexRow from 'PageTemplates/FlexRowTemplate';
import styled from 'styled-components';
import { ExcelRenderer } from 'react-excel-renderer';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileUpload } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from 'react-redux';
import { importDataFromFile } from 'services/store/actions/newOrder';
import { setSpinner } from 'services/store/actions/view';
import { getImportDataTemplate } from 'services/apiRequests/files/get';

const StyledInputFile = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;
const StyledFileLabel = styled.label`
  text-align: center;
  color: white;
  font-size: 16px;
  background-color: #007bff;
  border: 1px solid #007bff;
  padding: 5px 20px;
  margin: 5px;
  letter-spacing: 1px;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 200ms ease;
  &:hover {
    background-color: white;
    color: #007bff;
  }
`;

const ImportDataFromFile = ({ closeModal }) => {
  const dispatch = useDispatch();

  const [dataFile, setDataFile] = useState(undefined);
  const [fileName, setFileName] = useState('');
  const [items, setItems] = useState([]);
  const [errors, setErrors] = useState('');

  const getSheetTemplate = async () => {
    dispatch(setSpinner(true));
    await getImportDataTemplate(
      () => dispatch(setSpinner(false)),
      () => dispatch(setSpinner(false)),
    );
  };
  const handleFile = (e) => {
    const file = e.target.files[0];
    if (!file) {
      setErrors('Brak pliku');
      return console.log('No file');
    }
    if (
      !(
        file.type === 'application/vnd.ms-excel' ||
        file.type ===
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      )
    ) {
      setErrors('Nieprawidłowy format pliku');
      return console.log('Wrong file');
    }
    ExcelRenderer(file, (err, res) => {
      if (err) {
        console.log(err);
      } else {
        console.log(res);
        setErrors('');
        setDataFile(file);
        setFileName(file.name);
        setItems(res.rows.slice(15, 115));
      }
    });
  };

  const handleImportData = () => {
    dispatch(importDataFromFile(items));
    closeModal();
  };

  return (
    <Modal
      title="Import wymiarów"
      closeModal={closeModal}
      onOk={handleImportData}
      disabled={!dataFile || errors}
    >
      <p>
        Z arkusza pobrane zostaną wartości takie jak:
        <strong>
          wysokość, szerokość, grubość, ilość elementów i uwagi
        </strong>
        .
      </p>
      <FlexRow direction="column">
        <Button variant="outline-primary" onClick={getSheetTemplate}>
          {' '}
          Pobierz wzór akusza
        </Button>
        <StyledInputFile
          onChange={(e) => handleFile(e)}
          type="file"
          name="sheet"
          multiple={false}
          id="importDataSheet"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
        />
        <StyledFileLabel htmlFor="importDataSheet">
          <FontAwesomeIcon
            icon={faFileUpload}
            style={dataFile ? { color: 'black' } : null}
          />
          Wybierz plik z dysku
        </StyledFileLabel>
        <small>{fileName}</small>
        <small style={{ color: 'red' }}>{errors}</small>
      </FlexRow>
    </Modal>
  );
};

export default ImportDataFromFile;
