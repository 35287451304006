import React, { useState } from 'react';
import { useAlert } from 'react-alert';
import { StyledH3 } from 'components/header';
import { useDispatch, useSelector } from 'react-redux';
import { setSpinner } from 'services/store/actions/view';
import { Formik } from 'formik';
import { schema } from './utils/validate';
import { Form, Button } from 'react-bootstrap';
import FlexRow from 'PageTemplates/FlexRowTemplate';
import TextInput from 'components/FormInputs/TextInput';
import { updateEmployeeData } from 'services/apiRequests/employees/update';
import { loadUserData } from 'services/store/actions/auth';

const EmployeeData = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const user = useSelector((state) => state.auth.user);
  const [isEdit, setIsEdit] = useState(false);
  return (
    <div>
      <Formik
        validationSchema={schema}
        // ONSUBMIT REQUEST
        onSubmit={async (values) => {
          dispatch(setSpinner(true));
          await updateEmployeeData(
            user._id,
            { ...values },
            () => {
              dispatch(loadUserData());
              alert.success('dane zaktualizowane');
              setIsEdit(false);
            },
            () => {
              alert.error('błąd');
            },
          );
          dispatch(setSpinner(false));
        }}
        initialValues={{
          firstname: user.firstname,
          surname: user.surname,
          email: user.email,
        }}
        render={(props) => {
          const { handleSubmit } = props;
          return (
            <Form noValidate onSubmit={handleSubmit}>
              <FlexRow justify="flex-start" wrap>
                <div>
                  <StyledH3>Dane użytkownika</StyledH3>
                  <TextInput
                    {...props}
                    name="firstname"
                    label="Imię"
                    placeholder="Imię"
                    disabled={!isEdit}
                    required
                  />
                  <TextInput
                    {...props}
                    name="surname"
                    label="Nazwisko"
                    placeholder="Nazwisko"
                    disabled={!isEdit}
                    required
                  />
                  <TextInput
                    {...props}
                    name="email"
                    label="E-mail"
                    placeholder="E-mail"
                    disabled={!isEdit}
                    required
                  />

                  <FlexRow justify="flex-end" wrap>
                    <Button
                      variant="success"
                      type="submit"
                      disabled={!isEdit}
                    >
                      Zatwiedź
                    </Button>
                    <Button
                      variant={isEdit ? 'outline-secondary' : 'secondary'}
                      onClick={() => setIsEdit(!isEdit)}
                    >
                      {isEdit ? 'Zablokuj edycję' : 'Edytuj'}
                    </Button>
                  </FlexRow>
                </div>
              </FlexRow>
            </Form>
          );
        }}
      />
    </div>
  );
};

export default EmployeeData;
