import React from 'react';
import Modal from 'PageTemplates/ModalTemplate';
import styled from 'styled-components';
import { Button } from 'react-bootstrap';

const StyledParagraph = styled.p`
  font-weight: bold;
  margin-bottom: 5px;
`;

const ItemsErrors = ({ errors, closeModal }) => {
  return (
    <Modal
      title="Błąd"
      variant="danger"
      closeModal={closeModal}
      footer={
        <Button variant="danger" onClick={closeModal}>
          Zamknij
        </Button>
      }
    >
      {errors.map((item) => (
        <StyledParagraph key={item}>{item}</StyledParagraph>
      ))}
    </Modal>
  );
};

export default ItemsErrors;
