/* eslint-disable consistent-return */
import Axios from 'axios';

export const setMessageReaded = async (messageId, onEnd, onErr) => {
  try {
    await Axios.put(`/api/messages/${messageId}`);

    return onEnd();
  } catch (error) {
    console.log(error);
    onErr();
  }
};

export const setBannerReaded = async (onEnd, onErr) => {
  try {
    await Axios.put(`/api/messages/banner/readed`);

    return onEnd();
  } catch (error) {
    console.log(error);
    onErr();
  }
};
