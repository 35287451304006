import React from 'react';
import { Button } from 'react-bootstrap';
import ModalContainer from 'PageTemplates/ModalTemplate';
import { useDispatch } from 'react-redux';
import { setSpinner } from 'services/store/actions/view';
import { updateOrder } from 'services/apiRequests/orders/update';
import { setOrder } from 'services/store/actions/orders';
import { useAlert } from 'react-alert';
import { orderPaymentStatus } from 'services/utils/const';

const PaymentStatus = ({ order, closeModal }) => {
  const dispatch = useDispatch();
  const alert = useAlert();

  const handleSubmit = async (paymentStatus) => {
    dispatch(setSpinner(true));
    await updateOrder(
      'paymentstatus',
      order._id,
      { paymentStatus },
      (order) => {
        dispatch(setOrder(order));
        dispatch(setSpinner(false));
        alert.success('Zaktualizowano status płatności');
        closeModal();
      },
      () => {
        dispatch(setSpinner(false));
        alert.error('błąd!');
        closeModal();
      },
    );
  };
  return (
    <ModalContainer
      title="Status płatności"
      closeModal={closeModal}
      footer={
        <div style={{ width: '100%' }}>
          <Button
            block
            variant="success"
            onClick={() => handleSubmit(orderPaymentStatus.PAID)}
          >
            {orderPaymentStatus.PAID}
          </Button>
          <Button
            block
            variant="warning"
            onClick={() => handleSubmit(orderPaymentStatus.HALF_PAID)}
          >
            {orderPaymentStatus.HALF_PAID}
          </Button>
          <Button
            block
            variant="outline-warning"
            onClick={() => handleSubmit(orderPaymentStatus.TRANSFER)}
          >
            {orderPaymentStatus.HALF_PAID}
          </Button>
          <Button
            block
            variant="danger"
            onClick={() => handleSubmit(orderPaymentStatus.NOT_PAID)}
          >
            {orderPaymentStatus.NOT_PAID}
          </Button>
        </div>
      }
    >
      <p>
        Zamówienie{' '}
        <strong>
          {order.user.company} - {order.user.firstname} - {order.number}
        </strong>{' '}
        jest:
      </p>
    </ModalContainer>
  );
};

export default PaymentStatus;
