import React from 'react';

const CustomItemsLength = ({ order }) => {
  let customItems = 0;
  if (order.customItems) {
    customItems = order.customItems.reduce(
      (acc, item) => (acc += item.quantity),
      0,
    );
  }

  return (
    <td title="Długość i ilość elementów niestandardowych">
      {order.customItemsLength ? order.customItemsLength.toFixed(2) : null}
      {customItems !== 0 && (
        <strong>
          <sub> {customItems}</sub>
        </strong>
      )}
    </td>
  );
};

export default CustomItemsLength;
