import React from 'react';
import Fade from 'react-reveal/Fade';
import { firstLetterToUpperCase } from 'services/utils/string';
import styled from 'styled-components';
import FlexRow from 'PageTemplates/FlexRowTemplate';
import { dateToString } from 'services/utils/date';
import { Button } from 'react-bootstrap';
import { useModal } from 'hooks/useModal';
import ChangeFinishDateByUser from './Modals/ChangeFinishDateByUser';

const ItemWrapper = styled.div`
  text-align: center;
`;

const OrderProperties = ({ order }) => {
  const { modal, showModal, closeModal } = useModal({
    setFinishDate: false,
  });

  return (
    <Fade right>
      <>
        <FlexRow justify="space-between">
          <Item name="nazwa klienta" value={order.userName || '-'} />
          <Item
            name="numer"
            value={
              typeof order.number === 'string'
                ? order.number.toUpperCase()
                : order.number || '-'
            }
          />
          <Item
            name="Typ zamówienia"
            value={firstLetterToUpperCase(order.orderType)}
          />
          <Item name="data" value={dateToString(order.date)} />
          <Item
            name="przewidywana data realizacji"
            value={order.finishDate ? dateToString(order.finishDate) : '-'}
            onClick={() => showModal('setFinishDate')}
          />
        </FlexRow>
        {/* MODALS */}
        {modal.setFinishDate && (
          <ChangeFinishDateByUser order={order} closeModal={closeModal} />
        )}
      </>
    </Fade>
  );
};

export default OrderProperties;

const Item = ({ name, value, onClick }) => (
  <ItemWrapper>
    <div>
      <strong>{firstLetterToUpperCase(name)}</strong>
    </div>
    <div>{value}</div>
    {onClick && (
      <Button
        variant="info"
        style={{
          padding: '3px 10px',
          fontSize: 12,
        }}
        onClick={onClick}
      >
        Zmień
      </Button>
    )}
  </ItemWrapper>
);
