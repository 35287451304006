import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import useHasPermission from 'hooks/useHasPermission';
import React, { FC, useMemo } from 'react';
import { Permissions } from 'services/enums/Permissions.enum';
import { IOrder } from 'services/interfaces/orders/Orders.interface';
import { ordersUtils } from 'services/utils/orders/orders.utils';

interface IProps {
  order: IOrder;
}

const OrderFinishDateCell: FC<IProps> = ({ order }) => {
  const { hasPermission } = useHasPermission();
  const isDeadlinePassed = useMemo(
    () => ordersUtils.dates.isDeadlinePassrd(order.finishDate),
    [order.finishDate],
  );

  return (
    <td>
      {dayjs(order.finishDate).format('DD.MM.YYYY r.')}{' '}
      {hasPermission([Permissions.ADMIN, Permissions.EMPLOYEE]) &&
        isDeadlinePassed && (
          <FontAwesomeIcon
            style={{ fontWeight: 'bold' }}
            icon={faExclamationCircle}
          />
        )}
    </td>
  );
};

export default OrderFinishDateCell;
