export const validateItems = (newOrder, setErrors) => {
  const errors = [];

  newOrder.items.forEach((item, index) => {
    if (!item.height) errors.push(`Element nr ${index + 1}: wysokość`);
    if (!item.width) errors.push(`Element nr ${index + 1}: szerokość`);
    if (!item.quantity) errors.push(`Element nr ${index + 1}: ilość`);
  });
  newOrder.customItems.forEach((item, index) => {
    if (!item.length)
      errors.push(`Element niestandardowy ${index + 1}: długość`);
    if (!item.width)
      errors.push(`Element niestandardowy ${index + 1}: szerokość`);
    if (!item.quantity)
      errors.push(`Element niestandardowy ${index + 1}: ilość`);
  });
  if (errors.length) {
    setErrors(errors);
    return true;
  }
  return false;
};
