import Axios from 'axios';

export const updateCustomerData = async (body, onEnd, onErr) => {
  try {
    const res = await Axios.put('/api/customers/', body);

    return onEnd(res.data.user);
  } catch (error) {
    console.log(error);
    onErr();
  }
};

export const updateCustomerDataByAdmin = async (
  customerId,
  body,
  onEnd,
  onErr,
) => {
  try {
    const res = await Axios.put(`/api/customers/${customerId}`, body);

    return onEnd(res.data.customer);
  } catch (error) {
    console.log(error);
    onErr();
  }
};
