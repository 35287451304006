import withContext from 'hoc/withContext';
import React, { useState, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import styled from 'styled-components';

const StyledWrapper = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
`;

const StyledCardWrapper = styled(Card)`
  width: fit-content;
  margin: 0 auto;
  h3 {
    color: ${({ theme }) => theme.blowGreen};
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const StyledCard = styled(Card)`
  margin: 5px 5px;
  padding: 3px 8px;
  transition: transform 0.2s ease-in-out;
  &:hover {
    transform: scale(1.1);
    background-color: ${({ theme }) => theme.blowLightGrey};
  }
`;

const StyledCardText = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Summary = ({ orders, permissionContext }) => {
  const [summary, setSummary] = useState();
  useEffect(() => {
    let PP = 0;
    let PL = 0;
    let CNC = 0;
    let glossPL = 0;
    let glossPP = 0;
    let semiGlossPL = 0;
    let semiGlossPP = 0;
    let price = 0;

    let elements = 0;
    if (orders)
      orders.forEach((item) => {
        if (item.price) price += parseFloat(item.price);
        if (item.manHours && item.manHours.price)
          price += parseFloat(item.manHours.price);
        if (item.ventilations && item.ventilations.price)
          price += parseFloat(item.ventilations.price);
        if (item.surfaceCNC) CNC += parseFloat(item.surfaceCNC);
        if (item.surfaceLeft) PP += parseFloat(item.surfaceLeft);
        if (item.surfaceRight) PL += parseFloat(item.surfaceRight);
        if (item.elements) elements += parseInt(item.elements, 10);
        if (item.paintType === 'połysk') {
          if (item.surfaceRight) glossPL += parseFloat(item.surfaceRight);
          if (item.surfaceLeft) glossPP += parseFloat(item.surfaceLeft);
        }
        if (item.paintType !== 'połysk') {
          if (item.surfaceRight)
            semiGlossPL += parseFloat(item.surfaceRight);
          if (item.surfaceLeft)
            semiGlossPP += parseFloat(item.surfaceLeft);
        }
      });
    setSummary({
      PL,
      PP,
      CNC,
      elements,
      glossPL,
      glossPP,
      semiGlossPL,
      semiGlossPP,
      price,
    });
  }, [orders]);
  return (
    <>
      {summary && (
        <StyledWrapper>
          <StyledCardWrapper>
            <Card.Header as="h3">Podsumowanie</Card.Header>
            <Card.Body style={{ padding: 0 }}>
              <StyledCardText>
                {permissionContext === 'admin' && (
                  <Item
                    title="Cena"
                    value={summary.price.toFixed(2)}
                    color="rgb(34,139,34)"
                    unit="zł"
                  />
                )}
                <Item
                  title="Połysk PL"
                  value={summary.glossPL.toFixed(3)}
                  color="rgb(1, 126, 243)"
                />
                <Item
                  title="Połysk PP"
                  value={summary.glossPP.toFixed(3)}
                  color="rgb(1, 126, 243)"
                />
                <Item
                  title="Półmat/mat PL"
                  value={summary.semiGlossPL.toFixed(3)}
                  color="rgb(88, 88, 88)"
                />
                <Item
                  title="Półmat/mat PP"
                  value={summary.semiGlossPP.toFixed(3)}
                  color="rgb(88, 88, 88)"
                />
                <Item
                  title="CNC"
                  value={summary.CNC.toFixed(3)}
                  color="rgb(243, 158, 1)"
                />
              </StyledCardText>

              <Card.Title>Ogółem</Card.Title>
              <StyledCardText>
                <Item
                  title="PL"
                  value={summary.PL.toFixed(3)}
                  color="rgb(146, 36, 180)"
                />
                <Item
                  title="PP"
                  value={summary.PP.toFixed(3)}
                  color="rgb(146, 36, 180)"
                />
                <Item
                  title="SUMA"
                  value={(summary.PL + summary.PP).toFixed(3)}
                  color="rgb(146, 36, 180)"
                />
                <Item
                  title="Elementy"
                  value={summary.elements}
                  unit="szt"
                  color="rgb(146, 36, 180)"
                />
              </StyledCardText>
            </Card.Body>
            <small>Podsumowanie aktualnie wyświetlanej listy</small>
          </StyledCardWrapper>
        </StyledWrapper>
      )}
    </>
  );
};

export default withContext(Summary);

const Item = ({ title, value, unit, color }) => (
  <StyledCard>
    <Card.Title style={{ color }}>{title}</Card.Title>
    <Card.Text>
      {value}
      <small>
        {unit || (
          <>
            m<sup>2</sup>
          </>
        )}
      </small>
    </Card.Text>
  </StyledCard>
);
