import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import CuttingPackageStatus from 'components/CuttingPackageStatus/CuttingPackageStatus';
import { orderItemCuttingStatus } from 'services/utils/const';
import { dateToString } from 'services/utils/date';
import { ICuttingPackage } from 'services/interfaces/cutting-package/CuttingPackage.interface';
import {
  IOrder,
  IOrderItem,
} from 'services/interfaces/orders/Orders.interface';
import { IUser } from 'services/interfaces/users/Users.interface';
import { OrderItemCuttingStatus } from 'services/enums/Orders.enum';

const Tr = styled.tr`
  cursor: pointer;
`;

interface IProps {
  cuttingPackage: ICuttingPackage;
  index: number;
}

const PackageListRow: React.FC<IProps> = ({ cuttingPackage, index }) => {
  const history = useHistory();

  const getElementsWithStatusQty = useCallback(
    (status: OrderItemCuttingStatus) => {
      const qty = (cuttingPackage.orders as IOrder[])
        .reduce((acc: IOrderItem[], order: IOrder) => {
          return [...acc, ...order.items];
        }, [])
        .reduce((qty: number, item: IOrderItem) => {
          if (item.cuttingStatus === status) return (qty += item.quantity);
          return qty;
        }, 0);
      return qty;
    },
    [cuttingPackage],
  );
  const getOrdersNames = useCallback(() => {
    const names = (cuttingPackage.orders as IOrder[]).reduce(
      (acc, order) => {
        const company = (order.user as IUser).company;
        const firstname = (order.user as IUser).firstname;
        const number = order.number;

        return `${acc}${company.slice(0, 25)}${
          firstname ? ` ${firstname[0]}` : ''
        } - ${number}, `;
      },
      '',
    );
    return names;
  }, [cuttingPackage]);

  return (
    <Tr
      onClick={() =>
        history.push(`/cutting/package/${cuttingPackage._id}`)
      }
    >
      <td>{index + 1}</td>
      <td>{cuttingPackage.name}</td>
      <td>
        {(cuttingPackage.createdBy as IUser)?.firstname}{' '}
        {(cuttingPackage.createdBy as IUser)?.surname}
      </td>
      <td>{cuttingPackage.orders.length}</td>
      <td>{getOrdersNames()}</td>
      <td>{getElementsWithStatusQty(OrderItemCuttingStatus.TO_CUT)}</td>
      <td>{getElementsWithStatusQty(OrderItemCuttingStatus.SUSPENDED)}</td>
      <td>{getElementsWithStatusQty(OrderItemCuttingStatus.SKIPPED)}</td>
      <td>{getElementsWithStatusQty(OrderItemCuttingStatus.READY)}</td>
      <td>{dateToString(cuttingPackage.createdAt)}</td>
      <td>{<CuttingPackageStatus status={cuttingPackage.status} />}</td>
    </Tr>
  );
};

export default PackageListRow;
