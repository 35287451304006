import React, { FC } from 'react';
import { IOrder } from 'services/interfaces/orders/Orders.interface';

interface IProps {
  order: IOrder;
}

const OrderPaymentsListCell: FC<IProps> = ({ order }) => {
  return <td>{order.payments.map((payment) => `${payment} zł, `)}</td>;
};

export default OrderPaymentsListCell;
