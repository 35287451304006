import { faSort } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cells from 'components/ordersList/components/cells';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button, Form, Table } from 'react-bootstrap';
import { orderStatuses, paintTypes } from 'services/utils/const';
import { sortMethod } from 'services/utils/sortMethods';
import { firstLetterToUpperCase } from 'services/utils/string';
import styled from 'styled-components';
import FlexRow from 'PageTemplates/FlexRowTemplate';

const Wrapper = styled.div`
  td {
    padding: 0 !important;
  }
`;

const initFilters = {
  status: '',
  color: '',
  paintType: '',
};

const ProductionList = ({ orders, handleAdd }) => {
  const [list, setList] = useState([]);
  const [colors, setColors] = useState([]);
  const [filters, setFilters] = useState(initFilters);
  const [sort, setSort] = useState({
    direction: 'ascend',
    key: 'finishDate',
  });

  useEffect(() => {
    if (orders && orders.length) {
      if (!colors.length) {
        const colors = orders
          .map((order) => order.color)
          .reduce((acc, col) => {
            if (acc.includes(col)) return acc;
            return [...acc, col];
          }, [])
          .sort((a, b) => `${a}`.localeCompare(b));
        setColors(colors);
      }
      let newList = [...orders];
      if (filters.status)
        newList = newList.filter((order) =>
          order.status.includes(filters.status),
        );
      if (filters.color)
        newList = newList.filter((order) => order.color === filters.color);
      if (filters.paintType)
        newList = newList.filter(
          (order) => order.paintType === filters.paintType,
        );

      setList(newList);
    }
  }, [orders, filters]);

  const handleSort = (key) => {
    const direction = sort.direction === 'ascend' ? 'descend' : 'ascend';
    setSort({ key, direction });
  };
  const handleFilter = (key, value) =>
    setFilters((prev) => ({ ...prev, [key]: value }));

  return (
    <Wrapper
      style={{
        height: 350,
        overflowY: 'auto',
        fontSize: 14,
        marginTop: 10,
      }}
    >
      <FlexRow justify="space-around">
        <Form.Control
          style={{ width: 250, margin: '10px 0' }}
          as="select"
          onChange={(e) => handleFilter('status', e.target.value)}
          value={filters.status}
        >
          <>
            <option value="" style={{ color: 'lightgray' }}>
              Status
            </option>
            {orderStatuses.map((item) => (
              <Option value={item} key={item} status={item}>
                {firstLetterToUpperCase(item)}
              </Option>
            ))}
          </>
        </Form.Control>
        <Form.Control
          style={{ width: 250, margin: '10px 0' }}
          as="select"
          onChange={(e) => handleFilter('color', e.target.value)}
          value={filters.color}
        >
          <>
            <option value="" style={{ color: 'lightgray' }}>
              Kolor
            </option>
            {colors.map((item) => (
              <option
                value={item}
                key={item}
                style={{ fontWeight: 'bold' }}
              >
                {item}
              </option>
            ))}
          </>
        </Form.Control>
        <Form.Control
          style={{ width: 250, margin: '10px 0' }}
          as="select"
          onChange={(e) => handleFilter('paintType', e.target.value)}
          value={filters.paintType}
        >
          <>
            <option value="" style={{ color: 'lightgray' }}>
              Matowość
            </option>
            {paintTypes.map((item) => (
              <option
                value={item}
                key={item}
                style={{ fontWeight: 'bold' }}
              >
                {firstLetterToUpperCase(item)}
              </option>
            ))}
          </>
        </Form.Control>
        <Button variant="warning" onClick={() => setFilters(initFilters)}>
          Wyczyść
        </Button>
      </FlexRow>
      <Table responsive="lg" bordered hover size="sm">
        <thead>
          <tr>
            <th>LP</th>
            <th onClick={() => handleSort('company')}>
              Klient
              <FontAwesomeIcon icon={faSort} />
            </th>
            <th>Nr</th>
            <th>Nazwa</th>
            <th onClick={() => handleSort('color')}>
              Kolor
              <FontAwesomeIcon icon={faSort} />
            </th>
            <th onClick={() => handleSort('paintType')}>
              Matowosć
              <FontAwesomeIcon icon={faSort} />
            </th>
            <th>Elementy</th>
            <th>PL</th>
            <th>PP</th>
            <th>Typ</th>
            <th>Data</th>
            <th onClick={() => handleSort('finishDate')}>
              Data realizacji
              <FontAwesomeIcon icon={faSort} />
            </th>
            <th>Status</th>
            <th>Dni do lakierowania</th>
            <th style={{ width: 20 }}>Wsz.</th>
            <th style={{ width: 20 }}>Lew.</th>
            <th style={{ width: 20 }}>Popr.</th>
            <th style={{ width: 20 }}>Brak.</th>
            <th style={{ width: 20 }}>+</th>
          </tr>
        </thead>
        <tbody>
          {list && list.length
            ? list
                .sort((a, b) => {
                  return sortMethod[sortMethod.getSortMethod(sort.key)](
                    a,
                    b,
                    sort.key,
                    sort.direction,
                  );
                })
                .map((order, index) => (
                  <TableRow
                    key={order._id}
                    order={order}
                    index={index}
                    handleAdd={handleAdd}
                  />
                ))
            : null}
        </tbody>
      </Table>
    </Wrapper>
  );
};

export default ProductionList;
const Option = styled.option`
  background-color: ${({ theme, status }) => {
    if (status) return theme.positions[status];
    return 'white';
  }};
  font-weight: bold;
`;

const TableRow = ({ order, index, handleAdd }) => {
  const [actions, setActions] = useState({
    all: true,
    left: false,
    corrections: false,
    losts: false,
  });

  const handleAction = (e, action) => {
    const newActions = {
      all: false,
      left: false,
      corrections: false,
      losts: false,
      [action]: e.target.checked,
    };
    setActions(newActions);
  };

  const daysToPainting = (() => {
    const { productionPlan } = order;
    if (productionPlan) {
      const paintingDay = productionPlan.find(
        (plan) => plan.position === 'lakiernia',
      );
      if (paintingDay && !paintingDay.done) {
        const today = moment();
        const planedOn = moment(paintingDay.planedOn);
        return Math.round(today.diff(planedOn, 'hours') / 24);
      }
    }
    return null;
  })();

  return (
    <tr key={order._id}>
      <td>{index + 1}</td>
      <cells.Customer user={order.user} />
      <td>{order.number.toUpperCase()}</td>
      <cells.Name name={order.name} />
      <cells.Color order={order} />
      <cells.PaintType order={order} />
      <cells.Elements order={order} />
      <cells.Surface order={order} surface="surfaceRight" />
      <cells.Surface order={order} surface="surfaceLeft" />
      <cells.Type order={order} />
      <cells.Date date={order.date} />
      <cells.FinishDate date={order.finishDate} />
      <cells.Status order={order} />
      <td
        style={{
          fontWeight: 'bold',
          color: daysToPainting > 0 ? 'green' : 'red',
        }}
      >{`${daysToPainting > 0 ? '+' : ''}${daysToPainting || ''}`}</td>
      <td>
        <Form.Check
          type="radio"
          checked={actions.all}
          onChange={(e) => handleAction(e, 'all')}
        />
      </td>
      <td>
        <Form.Check
          type="radio"
          checked={actions.left}
          onChange={(e) => handleAction(e, 'left')}
        />
      </td>
      <td>
        <Form.Check
          type="radio"
          checked={actions.corrections}
          onChange={(e) => handleAction(e, 'corrections')}
        />
      </td>
      <td>
        <Form.Check
          type="radio"
          checked={actions.losts}
          onChange={(e) => handleAction(e, 'losts')}
        />
      </td>
      <td>
        <Button size="sm" onClick={() => handleAdd(order, actions, index)}>
          Dodaj
        </Button>
      </td>
    </tr>
  );
};
