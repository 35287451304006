import React from 'react';
import withContext from 'hoc/withContext';
import styled from 'styled-components';

const LostSpan = styled.span`
  background-color: ${({ theme }) => theme.items.lost};
`;
const ToCorrectSpan = styled.span`
  background-color: ${({ theme }) => theme.items.toCorrect};
`;

const Elements = ({
  order: { elements, items, customItems },
  permissionContext,
}) => {
  const lost = items
    ? items
        .concat(customItems || [])
        .reduce(
          (acc, item) =>
            (acc += (item.elementLost && item.elementLost.quantity) || 0),
          0,
        )
    : null;
  const toCorrect = items
    ? items
        .concat(customItems || [])
        .reduce(
          (acc, item) =>
            (acc +=
              (item.elementToCorrect && item.elementToCorrect.quantity) ||
              0),
          0,
        )
    : null;
  return (
    <td>
      {elements ? elements : '-'}
      {permissionContext !== 'user' ? (
        <>
          {lost ? <LostSpan>/{lost}</LostSpan> : null}
          {toCorrect ? <ToCorrectSpan>/{toCorrect}</ToCorrectSpan> : null}
        </>
      ) : null}
    </td>
  );
};

export default withContext(Elements);
