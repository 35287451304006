// OTHERS
export const NEWORDER_SET_FIELD = 'NEWORDER_SET_FIELD';
export const NEWORDER_SET_MANY_FIELDS = 'NEWORDER_SET_MANY_FIELDS';
export const NEWORDER_CLEAR_ORDER = 'NEWORDER_CLEAR_ORDER';
export const NEWORDER_SET_ORDER = 'NEWORDER_SET_ORDER';
export const NEWORDER_SET_ORDER_TYPE = 'NEWORDER_SET_ORDER_TYPE';

// ITEMS
export const NEWORDER_ADD_ITEM = 'NEWORDER_ADD_ITEM';
export const NEWORDER_ADD_CUSTOM_ITEM = 'NEWORDER_ADD_CUSTOM_ITEM';
export const NEWORDER_REMOVE_ITEM = 'NEWORDER_REMOVE_ITEM';
export const NEWORDER_REMOVE_CUSTOM_ITEM = 'NEWORDER_REMOVE_CUSTOM_ITEM';
export const NEWORDER_HANDLE_INPUT = 'NEWORDER_HANDLE_INPUT';
export const NEWORDER_HANDLE_CUSTOM_ITEM_INPUT =
  'NEWORDER_HANDLE_CUSTOM_ITEM_INPUT';
export const NEWORDER_HANDLE_NUMBER_INPUT = 'NEWORDER_HANDLE_NUMBER_INPUT';
export const NEWORDER_ADD_ITEM_IMAGE = 'NEWORDER_ADD_ITEM_IMAGE';
export const NEWORDER_ADD_CUSTOM_ITEM_IMAGE =
  'NEWORDER_ADD_CUSTOM_ITEM_IMAGE';
export const NEWORDER_REMOVE_ITEM_IMAGE = 'NEWORDER_REMOVE_ITEM_IMAGE';
export const NEWORDER_REMOVE_CUSTOM_ITEM_IMAGE =
  'NEWORDER_REMOVE_CUSTOM_ITEM_IMAGE';
export const NEWORDER_IMPORT_DATA_FROM_FILE =
  'NEWORDER_IMPORT_DATA_FROM_FILE';

// PROPERTIES
export const NEWORDER_SET_NAME = 'NEWORDER_SET_NAME';
export const NEWORDER_SET_COMMENTS = 'NEWORDER_SET_COMMENTS';
export const NEWORDER_SET_NUMBER = 'NEWORDER_SET_NUMBER';

// DATES
export const NEWORDER_SET_FINISHDATE = 'NEWORDER_SET_FINISHDATE';

// VALUES
export const NEWORDER_CALC_ORDER_VALUES = 'NEWORDER_CALC_ORDER_VALUES';
export const NEWORDER_CALD_PRICE = 'NEWORDER_CALD_PRICE';

// DATA
export const NEWORDER_SET_COLOR = 'NEWORDER_SET_COLOR';
export const NEWORDER_SET_PAINTTYPE = 'NEWORDER_SET_PAINTTYPE';
export const NEWORDER_SET_PAINTSTYLE = 'NEWORDER_SET_PAINTSTYLE';
export const NEWORDER_SET_VENEER = 'NEWORDER_SET_VENEER';
export const NEWORDER_SET_HANDLE = 'NEWORDER_SET_HANDLE';
export const NEWORDER_SET_PILASTER = 'NEWORDER_SET_PILASTER';
export const NEWORDER_SET_CROWN = 'NEWORDER_SET_CROWN';
export const NEWORDER_REMOVE_HANDLE = 'NEWORDER_REMOVE_HANDLE';
export const NEWORDER_SET_MILLING = 'NEWORDER_SET_MILLING';
export const NEWORDER_REMOVE_MILLING = 'NEWORDER_REMOVE_MILLING';
export const NEWORDER_SET_CUSTOMMILLING = 'NEWORDER_SET_CUSTOMMILLING';
export const NEWORDER_SET_CUSTOM_PILASTER = 'NEWORDER_SET_CUSTOM_PILASTER';
export const NEWORDER_SET_CUSTOM_CROWN = 'NEWORDER_SET_CUSTOM_CROWN';
export const NEWORDER_SET_GLASSCASE = 'NEWORDER_SET_GLASSCASE';
export const NEWORDER_SET_CHAMFERING = 'NEWORDER_SET_CHAMFERING';
export const NEWORDER_SET_NUT = 'NEWORDER_SET_NUT';
export const NEWORDER_SET_FELC = 'NEWORDER_SET_FELC';
export const NEWORDER_SET_MUNTIN_TYPE = 'NEWORDER_SET_MUNTIN_TYPE';
export const NEWORDER_SET_UNDER_GLASS = 'NEWORDER_SET_UNDER_GLASS';
export const NEWORDER_SET_WATERPROOF_MDF = 'NEWORDER_SET_WATERPROOF_MDF';
