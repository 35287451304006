import React from 'react';
import Modal from 'PageTemplates/ModalTemplate';
import { Button, Form } from 'react-bootstrap';
import styled from 'styled-components';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { setSpinner } from 'services/store/actions/view';
import { customerAccountRemove } from 'services/apiRequests/customers/remove';
import { logOutUser } from 'services/store/actions/auth';
import TextInput from 'components/FormInputs/TextInput';
import { useAlert } from 'react-alert';
import withContext from 'hoc/withContext';
import { employeeAccountRemove } from 'services/apiRequests/employees/remove';

const Paragraph = styled.p`
  color: red;
  line-height: 1;
  opacity: 0.5;
`;

const RemoveUser = ({ closeModal, permissionContext }) => {
  const dispatch = useDispatch();
  const alert = useAlert();

  const onEnd = () => {
    dispatch(setSpinner(false));
    alert.error('Konto usunięte');
    dispatch(logOutUser());
  };
  const onErr = (message, actions) => {
    dispatch(setSpinner(false));
    alert.error('Błąd!');
    if (message) actions.setErrors(message);
  };

  const handleRemoveUser = async (values, actions) => {
    dispatch(setSpinner(true));
    if (permissionContext === 'user')
      return await customerAccountRemove(
        values,
        () => onEnd(),
        (msg) => onErr(msg, actions),
      );
    else if (permissionContext === 'employee')
      return await employeeAccountRemove(
        values,
        () => onEnd(),
        (msg) => onErr(msg, actions),
      );
  };
  return (
    <Modal
      title="Usuwanie konta"
      closeModal={closeModal}
      footer={
        <>
          <Button variant="danger" type="submit" form="accountRemoveForm">
            Usuń
          </Button>
          <Button onClick={closeModal}>Anuluj</Button>
        </>
      }
      variant="danger"
    >
      <Paragraph>
        Usunięcie konta powoduje bezpowrotne i nieodwracalne usunięcie
        danych osobowych użytkownika z bazy.
      </Paragraph>

      <Formik
        validationSchema={yup.object({
          email: yup
            .string()
            .email('Format adresu email jest nieprawidłowy')
            .required('Wymagane'),
          password: yup.string().required('Wymagane'),
        })}
        // ONSUBMIT REQUEST
        onSubmit={handleRemoveUser}
        initialValues={{
          email: '',
          password: '',
        }}
        render={(props) => {
          return (
            <Form
              noValidate
              onSubmit={props.handleSubmit}
              id="accountRemoveForm"
            >
              <TextInput
                {...props}
                type="email"
                name="email"
                label="E-mail"
                required
              />
              <TextInput
                {...props}
                type="password"
                name="password"
                label="Hasło"
                required
              />
            </Form>
          );
        }}
      />
    </Modal>
  );
};

export default withContext(RemoveUser);
