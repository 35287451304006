import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PageHeader } from 'components/header';
import PageTemplate from 'PageTemplates/PageTemplate';
import { Button } from 'react-bootstrap';
import { Redirect, useHistory } from 'react-router';
import {
  cuttingPackageSetField,
  cuttingPackageSetItemMachning,
  cuttingPackageSetItemStatus,
} from 'services/store/actions/newCuttingPackage';
import PackageOrder from './components/PackageOrder';
import FlexRow from 'PageTemplates/FlexRowTemplate';
import { useModal } from 'hooks/useModal';
import ConfirmationModal from './components/ConfirmationModal';
import { setSpinner } from 'services/store/actions/view';
import axios from 'axios';
import { useAlert } from 'react-alert';

const NewCuttingPackage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const alert = useAlert();
  const user = useSelector((state) => state.auth.user);
  const cuttingPackage = useSelector((state) => state.newCuttingPackage);
  const { modal, showModal, closeModal } = useModal({ confirm: false });

  useEffect(() => {
    dispatch(cuttingPackageSetField('createdBy', user._id));
  }, []);

  const handleItemStatus = (orderIndex, itemIndex, status) => {
    dispatch(cuttingPackageSetItemStatus(orderIndex, itemIndex, status));
  };
  const handleItemMachning = (orderIndex, itemIndex, value) => {
    dispatch(cuttingPackageSetItemMachning(orderIndex, itemIndex, value));
  };
  const handleRemoveOrderFromPackage = (orderId) => {
    const newOrders = cuttingPackage.orders.filter(
      (order) => order._id !== orderId,
    );
    dispatch(cuttingPackageSetField('orders', newOrders));
  };

  const handleSubmit = async () => {
    try {
      dispatch(setSpinner(true));
      const newCuttingPackage = {
        ...cuttingPackage,
        orders: cuttingPackage.orders.map((order) => {
          const result = {
            _id: order._id,
            cuttingPackage: order.cuttingPackage,
            updateHistory: order.updateHistory,
            items: order.items.map((item) => ({
              _id: item._id,
              cuttingStatus: item.cuttingStatus,
              cuttingPackage: item.cuttingPackage,
              isMachiningRequired: item.isMachiningRequired,
            })),
          };
          return result;
        }),
      };
      await axios.post(`/api/cutting`, {
        cuttingPackage: newCuttingPackage,
      });
      dispatch(setSpinner(false));
      closeModal();
      alert.success('Utworzono nowy pakiet cięcia');
      history.push(`/cutting`);
    } catch (error) {
      console.log(error, error?.response);
      alert.error(error?.response?.data?.message || 'Błąd!');
      dispatch(setSpinner(false));
    }
  };

  if (cuttingPackage.orders.length)
    return (
      <PageTemplate>
        <PageHeader title="Nowy pakiet cięcia" extra={[]} />
        {cuttingPackage.orders.map((order, index) => {
          return (
            <PackageOrder
              key={order._id}
              order={order}
              handleRemoveOrderFromPackage={() =>
                handleRemoveOrderFromPackage(order._id)
              }
              handleItemStatus={handleItemStatus}
              handleMachining={handleItemMachning}
              orderIndex={index}
            />
          );
        })}
        <FlexRow justify="flex-end">
          <Button
            size="lg"
            variant="success"
            onClick={() => showModal('confirm')}
          >
            Utwórz pakiet
          </Button>
        </FlexRow>
        {/* MODALS */}
        {modal.confirm && (
          <ConfirmationModal
            closeModal={closeModal}
            handleSubmit={handleSubmit}
          />
        )}
      </PageTemplate>
    );
  return <Redirect to={'/cutting'} />;
};

export default NewCuttingPackage;
