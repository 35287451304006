import React from 'react';
import { Container } from 'react-bootstrap';

const Regulations = () => (
  <Container>
    <div className="regulations text-muted">
      <h5>REGULAMIN</h5>
      <ol>
        <li>Postanowienia ogólne</li>
        <li>Definicje</li>
        <li>Rodzaj i zakres usług elektronicznych</li>
        <li>
          Warunki świadczenia i zawierania umów o świadczenie usług
          elektronicznych
        </li>
        <li>
          Warunki rozwiązania umów o świadczenie usług elektronicznych
        </li>
        <li>Tryb postępowania reklamacyjnego</li>
        <li>Własność intelektualna</li>
        <li>Postanowienia końcowe</li>
      </ol>
      <h6>POSTANOWIENIA OGÓLNE</h6>
      <ol>
        <li>
          Strona zamowfronty.pl działa na zasadach określonych w niniejszym
          Regulaminie.
        </li>
        <li>
          Regulamin określa rodzaje i zakres usług świadczonych drogą
          elektroniczną przez Stronę zamowfronty.pl, zasady świadczenia
          tych usług, warunki zawierania i rozwiązywania umów o świadczenie
          usług drogą elektroniczną, a także tryb postępowania
          reklamacyjnego.
        </li>
        <li>
          Regulamin określa rodzaje i zakres usług świadczonych drogą
          elektroniczną przez Stronę zamowfronty.pl, zasady świadczenia
          tych usług, warunki zawierania i rozwiązywania umów o świadczenie
          usług drogą elektroniczną, a także tryb postępowania
          reklamacyjnego.
        </li>
        <li>
          Każdy Usługobiorca z chwilą podjęcia czynności zmierzających do
          korzystania z Usług Elektronicznych Strony zamowfronty.pl,
          zobowiązany jest do przestrzegania postanowień niniejszego
          Regulaminu.
        </li>
        <li>
          W sprawach nieuregulowanych w niniejszym Regulaminie mają
          zastosowanie przepisy
          <ol>
            <li>
              Ustawy o świadczeniu usług drogą elektroniczną z dnia 18
              lipca 2002 r. (Dz. U. Nr 144, poz. 1204 ze zm.),
            </li>
            <li>
              Ustawy o prawach konsumenta z dnia 30 maja 2014 r. (Dz. U.
              2014 poz. 827),
            </li>
            <li>
              Ustawy Kodeks cywilny z dnia 23 kwietnia 1964 r. (Dz. U. nr
              16, poz. 93 ze zm.) oraz inne właściwe przepisy prawa
              polskiego.
            </li>
          </ol>
        </li>
      </ol>
      <h6>DEFINICJE</h6>
      <ol>
        <li>
          FORMULARZ KONTAKTOWY – formularz dostępny na Stronie
          zamowfronty.pl umożliwiający Usługobiorcy bezpośredni kontakt z
          Usługodawcą.
        </li>
        <li>REGULAMIN - niniejszy regulamin Strony.</li>
        <li>
          USŁUGODAWCA – Kamil Kępa wykonujący/a działalność gospodarczą pod
          firmą BLOW Meble, adres siedziby: 05-820 Piastów, ul.
          Piłsudskiego 56/42, adres do doręczeń: 05-850 Ożarów Mazowiecki
          Duchnice, ul. Ożarowska 83 budynek G, NIP: 534 225 05 99, REGON:
          144019841, wpisaną do Centralnej Ewidencji i Informacji o
          Działalności Gospodarczej, adres poczty elektronicznej:
          biuro@mebleblow.pl, tel. 505 412 112.
        </li>
        <li>
          USŁUGOBIORCA – osoba fizyczna, osoba prawna albo jednostka
          organizacyjna nieposiadająca osobowości prawnej, której ustawa
          przyznaje zdolność prawną korzystająca z Usługi Elektronicznej.
        </li>
        <li>
          USŁUGA ELEKTRONICZNA – usługa świadczona drogą elektroniczną
          przez Usługodawcę na rzecz Usługobiorcy za pośrednictwem Strony.
        </li>
      </ol>
      <h6>RODZAJ I ZAKRES USŁUG ELEKTRONICZNYCH</h6>
      <ol>
        <li>
          Usługodawca umożliwia za pośrednictwem Strony korzystanie z Usług
          Elektronicznych takich jak:{' '}
          <ol>
            <li>korzystanie z Formularza Kontaktowego,</li>
          </ol>
        </li>
        <li>
          Świadczenie Usług Elektronicznych na rzecz Usługobiorców odbywa
          się na warunkach określonych w Regulaminie.
        </li>
      </ol>
      <h6>
        WARUNKI ŚWIADCZENIA I ZAWIERANIA UMÓW O ŚWIADCZENIE USŁUG
        ELEKTRONICZNYCH
      </h6>
      <ol>
        <li>
          Świadczenie Usług Elektronicznych określonych w rozdziale III
          pkt. 1 Regulaminu przez Usługodawcę jest nieodpłatne.
        </li>
        <li>
          Okres na jaki umowa zostaje zawarta:
          <ol>
            <li>
              umowa o świadczenie Usługi Elektronicznej polegającej na
              umożliwieniu wysłania wiadomości za pośrednictwem Formularza
              Kontaktowego zawierana jest na czas oznaczony i ulega
              rozwiązaniu z chwilą wysłania wiadomości albo zaprzestania
              jej wysyłania przez Usługobiorcę.
            </li>
          </ol>
        </li>
        <li>
          Wymagania techniczne niezbędne do współpracy z systemem
          teleinformatycznym, którym posługuje się Usługodawca:
          <ol>
            <li>komputer z dostępem do Internetu,</li>
            <li>dostęp do poczty elektronicznej,</li>
            <li>przeglądarka internetowa,</li>
            <li>
              włączenie w przeglądarce internetowej Cookies oraz
              Javascript.
            </li>
          </ol>
        </li>
        <li>
          Usługobiorca zobowiązany jest do korzystania ze Strony w sposób
          zgodny z prawem i dobrymi obyczajami mając na uwadze poszanowanie
          dóbr osobistych i praw własności intelektualnej osób trzecich.
        </li>
        <li>
          Usługobiorca zobowiązany jest do wprowadzania danych zgodnych ze
          stanem faktycznym.
        </li>
        <li>
          Usługobiorcę obowiązuje zakaz dostarczania treści o charakterze
          bezprawnym.
        </li>
      </ol>
      <h6>TRYB POSTĘPOWANIA REKLAMACYJNEGO</h6>
      <ol>
        <li>
          Reklamacje związane ze świadczeniem Usług Elektronicznych przez
          Usługodawcę:
          <ol>
            <li>
              Reklamacje związane ze świadczeniem Usług Elektronicznych za
              pośrednictwem Strony Usługobiorca może składać za
              pośrednictwem poczty elektronicznej na adres:
              biuro@mebleblow.pl
            </li>
            <li>
              {' '}
              W powyższej wiadomości e-mail, należy podać jak najwięcej
              informacji i okoliczności dotyczących przedmiotu reklamacji,
              w szczególności rodzaj i datę wystąpienia nieprawidłowości
              oraz dane kontaktowe. Podane informacje znacznie ułatwią i
              przyspieszą rozpatrzenie reklamacji przez Usługodawcę.
            </li>
            <li>
              Rozpatrzenie reklamacji przez Usługodawcę następuje
              niezwłocznie, nie później niż w terminie 14 dni.
            </li>
            <li>
              Odpowiedź Usługodawcy w sprawie reklamacji jest wysyłana na
              adres e-mail Usługobiorcy podany w zgłoszeniu reklamacyjnym
              lub w inny podany przez Usługobiorcę sposób.
            </li>
          </ol>
        </li>
      </ol>
      <h6>WŁASNOŚĆ INTELEKTUALNA</h6>
      <ol>
        <li>
          Wszystkie treści zamieszczone na stronie internetowej pod adresem
          zamowfronty.pl korzystają z ochrony prawno autorskiej i są
          własnością zamowfronty.pl Usługobiorca ponosi pełną
          odpowiedzialność za szkodę wyrządzoną Usługodawcy, będącą
          następstwem użycia jakiejkolwiek zawartości strony
          zamowfronty.pl, bez zgody Usługodawcy.
        </li>
        <li>
          Jakiekolwiek wykorzystanie przez kogokolwiek, bez wyraźnej
          pisemnej zgody Usługodawcy, któregokolwiek z elementów
          składających się na treść oraz zawartość strony zamowfronty.pl
          stanowi naruszenie prawa autorskiego przysługującego Usługodawcy
          i skutkuje odpowiedzialnością cywilnoprawną oraz karną.
        </li>
      </ol>
      <h6>POSTANOWIENIA KOŃCOWE</h6>
      <ol>
        <li>
          Umowy zawierane za pośrednictwem Strony zawierane są zgodnie z
          prawem polskim.
        </li>
        <li>
          W przypadku niezgodności jakiejkolwiek części Regulaminu z
          obowiązującym prawem, w miejsce zakwestionowanego przepisu
          Regulaminu zastosowanie mają właściwe przepisy prawa polskiego.
        </li>
      </ol>
    </div>
  </Container>
);

export default Regulations;
