import React, { useEffect, useState } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import { getOrdersChartData } from 'scenes/Statistics/utils/chartData';
import FlexRow from 'PageTemplates/FlexRowTemplate';

const Chart = ({ orders }) => {
  const [values, setValues] = useState(null);
  useEffect(() => {
    if (orders) {
      const newValues = getOrdersChartData(orders);

      setValues(newValues);
    }
  }, [orders]);
  console.log(values);
  if (values)
    return (
      <FlexRow justify="space-around">
        <div style={{ width: '48%', textAlign: 'center' }}>
          <Bar data={values.surface} />
        </div>
        <div style={{ width: '48%', textAlign: 'center' }}>
          <Line data={values.price} />
        </div>
      </FlexRow>
    );
  return null;
};

export default Chart;
