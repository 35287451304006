/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { dateToString } from 'services/utils/date';
import FlexRow from 'PageTemplates/FlexRowTemplate';
import {
  getStatsFromOrders,
  getBestCustomers,
} from 'scenes/Statistics/utils';
import { Table } from 'react-bootstrap';
import Chart from './Chart';

const Header = styled.h1`
  color: ${({ position, theme }) => theme.positions[position]};
  font-size: 64px;
  letter-spacing: 10px;
`;
const Wrapper = styled.div`
  text-align: center;
`;
const Title = styled.h2`
  color: ${({ color, theme }) => theme[color]};
  font-size: 35px;
  letter-spacing: 5px;
  font-weight: bold;
`;
const Info = styled.span`
  font-size: 25px;
`;

const CompanyStats = ({ dates: { from, to }, position }) => {
  const orders = useSelector((state) => state.orders.endedOrders);

  const [values, setValues] = useState(null);
  const [customers, setCustomers] = useState(null);

  useEffect(() => {
    if (orders) {
      const obj = getStatsFromOrders(orders);
      setValues(obj);
      const customers = getBestCustomers(orders);
      setCustomers(customers);
    }
  }, [orders, from, to]);

  if (values)
    return (
      <div>
        <Wrapper>
          <Header position={position}>{position.toUpperCase()}</Header>
          <small>
            Dane pochodzą z zamówień odebranych między{' '}
            <strong>{dateToString(from)}</strong> a{' '}
            <strong>{dateToString(to)}</strong>
          </small>
        </Wrapper>
        <hr />
        <Wrapper>
          <Title color="blowPrimary">Sprzedaliśmy</Title>
          <Info>
            <strong>{values.summarySurface.toFixed(2)}</strong>
            <small>
              m<sup>2</sup>
            </small>{' '}
            za kwotę{' '}
            <strong style={{ color: 'green' }}>
              {values.price.toFixed(2)}
            </strong>
            <small>zł</small>
          </Info>
        </Wrapper>
        <hr />
        <Chart orders={orders} />
        <hr />
        <FlexRow justify="space-around">
          <Wrapper>
            <Title color="blowWarning">Wykonaliśmy</Title>
            <Item value={values.surfaces.cnc} text="elementów CNC" />

            <hr />
            <Item
              value={values.surfaces.PL}
              text="elementów jednostronnych"
            />
            <Item
              value={values.surfaces.PP}
              text="elementów dwustronnych"
            />
            <hr />
            <Item
              value={values.surfaces.veneer}
              text="elementów fornirowanych"
            />
          </Wrapper>
          <Wrapper>
            <Title color="blowWarning">Polakierowaliśmy</Title>
            <Item value={values.surfaces.gloss} text="połysku" />
            <Item value={values.surfaces.semiGloss} text="półmatu" />
          </Wrapper>
          <Wrapper>
            <Title color="blowWarning">Wyfrezowaliśmy</Title>
            <Item
              value={values.other.handles}
              unit="mb"
              text="uchtytów frezowanych"
            />
            <Item
              value={values.other.partHandles}
              unit="szt"
              text="uchwytów częściowych"
            />
            <Item
              value={values.other.hingeHoles}
              unit="szt"
              text="otworów pod zawiasy"
            />
          </Wrapper>
        </FlexRow>
        <hr />
        <Wrapper>
          <Title color="blowInfo">Poprawiliśmy</Title>

          <Item
            value={values.reclamations.surfaces.company}
            color="red"
            text="z winy BLOW"
          />
          <Item
            value={values.reclamations.surfaces.customer}
            text="z winy klienta"
          />
        </Wrapper>
        <hr />
        <Wrapper>
          <Item
            value={
              (values.customerMaterialSurface / values.summarySurface) *
              100
            }
            unit="%"
            text="powierzchni zamówień wykonaliśmy z materiału powierzonego"
          />
        </Wrapper>
        <hr />
        <Wrapper>
          <Title color="blowPurple">Średni czas realizacji</Title>
          <Item
            value={values.realizationTimes.gloss}
            unit="dni"
            text="dla zamówień gładkich w połysku"
          />
          <Item
            value={values.realizationTimes.semiGloss}
            unit="dni"
            text="dla zamówień gładkich w półmacie"
          />
          <Item
            value={values.realizationTimes.cnc}
            unit="dni"
            text="dla zamówień CNC"
          />
          <Item
            value={values.realizationTimes.veneer}
            unit="dni"
            text="dla zamówień fornirowanych"
          />
        </Wrapper>
        <hr />
        <Wrapper>
          <Title color="blowPrimary">Najaktywniejsi klienci</Title>
          {customers && <BestCustomers customers={customers} />}
        </Wrapper>
      </div>
    );
  return null;
};

export default CompanyStats;

const Item = ({ value, unit = 'm2', text, color = 'black' }) => {
  return (
    <div>
      <Info>
        <strong style={{ color }}>
          {value && !['szt', 'dni'].includes(unit)
            ? value.toFixed(2)
            : value || 0}
        </strong>
        <small>
          {unit === 'm2' ? (
            <span>
              m<sup>2</sup>
            </span>
          ) : (
            unit
          )}
        </small>{' '}
        {text}
      </Info>
    </div>
  );
};

const BestCustomers = ({ customers }) => {
  return (
    <Table responsive="lg" bordered hover size="sm">
      <thead>
        <tr>
          <th>Lp.</th>
          <th>Klient</th>
          <th>Ilosć zamówień</th>
          <th>
            Powierzchnia{' '}
            <small>
              m<sup>2</sup>
            </small>
          </th>
          <th>
            Kwota <small>zł</small>
          </th>
        </tr>
      </thead>
      <tbody>
        {customers.map((item, i) => (
          <tr key={item.customer}>
            <td>{i + 1}</td>
            <td>{item.customerName}</td>
            <td>{item.orders}</td>
            <td>{item.surface.toFixed(2)}</td>
            <td>{item.price.toFixed(2)}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
