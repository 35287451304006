import React, { useState } from 'react';
import { StyledH3 } from 'components/header';
import TextInput from 'components/FormInputs/TextInput';
import { Form, Button } from 'react-bootstrap';
import { Formik } from 'formik';
import FlexRow from 'PageTemplates/FlexRowTemplate';
import styled from 'styled-components';
import * as yup from 'yup';

const Wrapper = styled.div`
  max-width: 500px;
`;

const Terms = ({ terms, handleSubmit }) => {
  const [isEdit, setIsEdit] = useState(false);
  return (
    <div>
      <Formik
        validationSchema={schema}
        // ONSUBMIT REQUEST
        onSubmit={async (values) => {
          await handleSubmit(
            { realizationDates: values },
            () => setIsEdit(false),
            'Zaktualizowano terminy realizacji',
          );
        }}
        initialValues={{
          ...terms,
        }}
        render={(props) => {
          return (
            <Form noValidate onSubmit={props.handleSubmit}>
              <Wrapper>
                <StyledH3>Terminy realizacji</StyledH3>
                <TextInput
                  {...props}
                  name="veneer"
                  label="Fornir"
                  disabled={!isEdit}
                  required
                />
                <TextInput
                  {...props}
                  name="milling"
                  label="CNC"
                  disabled={!isEdit}
                  required
                />
                <TextInput
                  {...props}
                  name="gloss"
                  label="Połysk"
                  disabled={!isEdit}
                  required
                />
                <TextInput
                  {...props}
                  name="semiGloss"
                  label="Półmat / mat"
                  disabled={!isEdit}
                  required
                />

                <FlexRow justify="flex-end">
                  <Button
                    variant="success"
                    type="submit"
                    disabled={!isEdit}
                  >
                    Zatwierdź
                  </Button>
                  <Button
                    variant={isEdit ? 'outline-secondary' : 'secondary'}
                    onClick={() => setIsEdit(!isEdit)}
                  >
                    {isEdit ? 'Zablokuj edycję' : 'Edytuj'}
                  </Button>
                </FlexRow>
              </Wrapper>
            </Form>
          );
        }}
      />
    </div>
  );
};

export default Terms;

const schema = yup.object({
  veneer: yup
    .number()
    .typeError('wartość musi być liczbą')
    .positive('wartość nie może być ujemna')
    .required('wymagane'),
  milling: yup
    .number()
    .typeError('wartość musi być liczbą')
    .positive('wartość nie może być ujemna')
    .required('wymagane'),
  gloss: yup
    .number()
    .typeError('wartość musi być liczbą')
    .positive('wartość nie może być ujemna')
    .required('wymagane'),
  semiGloss: yup
    .number()
    .typeError('wartość musi być liczbą')
    .positive('wartość nie może być ujemna')
    .required('wymagane'),
});
