import React from 'react';
import Modal from 'PageTemplates/ModalTemplate';
import OrdersList from 'components/ordersList/static';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { getOrder } from 'services/store/actions/orders';
import { setSpinner } from 'services/store/actions/view';
import { useAlert } from 'react-alert';

const OrdersInProduction = ({ orders, closeModal }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const alert = useAlert();

  const handleClick = (order) => {
    dispatch(setSpinner(true));
    dispatch(
      getOrder(
        order._id,
        () => {
          dispatch(setSpinner(false));
        },
        () => {
          dispatch(setSpinner(false));
          alert.error('Błąd!');
        },
      ),
    );
    closeModal();
    return history.push(`/order/${order._id}`);
  };
  return (
    <Modal
      title="Zamówienia na produkcji"
      closeModal={closeModal}
      size="xl"
    >
      <OrdersList
        orders={orders}
        status
        listName="production"
        handleClickRow={handleClick}
      />
    </Modal>
  );
};

export default OrdersInProduction;
