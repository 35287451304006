import React, { useState } from 'react';
import Modal from 'PageTemplates/ModalTemplate';
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useAlert } from 'react-alert';
import { setSpinner } from 'services/store/actions/view';
import { getPickUpReport } from 'services/apiRequests/files/get';
import { getOrder } from 'services/store/actions/orders';

const PickUpReport = ({ order, closeModal }) => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const [pickedUpElements, setPickedUpElements] = useState(
    order.pickedUpElements
      ? order.elements - order.pickedUpElements
      : order.elements,
  );

  const position = useSelector((stats) => stats.employee.activePosition);

  const handleInput = (e) => {
    const numbers = /^(\s*|\d+)$/;
    if (!e.target.value.match(numbers)) return;
    setPickedUpElements(e.target.value ? parseInt(e.target.value) : 0);
  };

  const handleSubmit = async () => {
    dispatch(setSpinner(true));
    await getPickUpReport(
      order,
      { pickedUpElements, position: position || 'Biuro' },
      () => {
        dispatch(
          getOrder(
            order._id,
            () => {
              dispatch(setSpinner(false));
              closeModal();
            },
            () => {
              dispatch(setSpinner(false));
              alert.error('Błąd');
            },
          ),
        );
      },
      () => {
        dispatch(setSpinner(false));
        alert.error('Błąd');
      },
    );
  };
  return (
    <Modal
      title="Protokół odbioru"
      closeModal={closeModal}
      onOk={handleSubmit}
      disabled={!pickedUpElements}
    >
      <p>
        {order.pickedUpElements
          ? `Odebrano już ${order.pickedUpElements} z ${order.elements} elementów zamówienia.`
          : `Zamówienie zawiera 
            ${order.elements}
            element/ów.`}{' '}
        Podaj ilość odbieranych elementów:
      </p>
      <Form.Control
        type="text"
        value={pickedUpElements}
        style={{ marginBottom: 10 }}
        onChange={handleInput}
        placeholder="Ilość odebranych elementów"
      />
    </Modal>
  );
};

export default PickUpReport;
