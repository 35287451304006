import LoadingButton from 'components/Buttons/LoadingButton';
import { StyledH3 } from 'components/header';
import React, { FC } from 'react';
import { Button, Modal, ModalProps, Row, Spinner } from 'react-bootstrap';
import styled from 'styled-components';

const SpinnerWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: white;
  opacity: 0.7;
  z-index: 999;
  div {
    position: absolute;
    top: 30%;
    left: 45%;
  }
`;

interface IProps extends ModalProps {
  title: string;
  closeModal: () => void;
  onOk: (attr?: any) => void;
  okButtonDisabled?: boolean;
  okButtonText?: string;
  isLoading?: boolean;
}

const ModalWrapper: FC<IProps> = ({
  title,
  closeModal,
  children,
  onOk,
  okButtonText = 'Zatwierdź',
  okButtonDisabled = false,
  isLoading = false,
  ...props
}) => {
  return (
    <Modal show onHide={closeModal} {...props}>
      <Modal.Header closeButton>
        <Modal.Title>
          <StyledH3>{title}</StyledH3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="position-relative">
        {isLoading && (
          <SpinnerWrapper>
            <div>
              <Spinner variant="success" />
            </div>
          </SpinnerWrapper>
        )}
        {children}
        <Modal.Footer className="jusify-content-end">
          <Button
            variant="success"
            disabled={okButtonDisabled}
            onClick={onOk}
          >
            {okButtonText}
          </Button>
          <Button variant="danger" onClick={closeModal}>
            Anuluj
          </Button>
        </Modal.Footer>
      </Modal.Body>
    </Modal>
  );
};

export default ModalWrapper;
