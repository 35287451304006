import React, { useEffect, useState } from 'react';
import PageTemplate from 'PageTemplates/PageTemplate';
import { PageHeader, StyledH3, StyledH6 } from 'components/header';
import { useDispatch, useSelector } from 'react-redux';
import { getGlobalSettings } from 'services/store/actions/settings';
import { useAlert } from 'react-alert';
import { setSpinner } from 'services/store/actions/view';
import { substractSimilarObjects } from 'services/utils/object';
import styled from 'styled-components';
import FlexRow from 'PageTemplates/FlexRowTemplate';

const Wrapper = styled.div`
  margin-right: 30px;
`;

const UserPrices = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const globalPrices = useSelector((state) => state.settings.prices);
  const user = useSelector((state) => state.auth.user);

  const [prices, setPrices] = useState(null);

  useEffect(() => {
    dispatch(setSpinner(true));
    dispatch(
      getGlobalSettings(
        () => {
          dispatch(setSpinner(false));
        },
        () => {
          dispatch(setSpinner(false));
          alert.error('Błąd!');
        },
      ),
    );
  }, []);
  useEffect(() => {
    if (user.discounts && globalPrices) {
      const userPrices = substractSimilarObjects(
        globalPrices,
        user.discounts,
      );
      setPrices(userPrices);
    }
  }, [user.discounts, globalPrices]);
  console.log(user.discounts, globalPrices);
  return (
    <PageTemplate>
      <PageHeader title="Cennik netto" />
      {prices && (
        <div>
          <FlexRow justify="flex-start">
            <div>
              <StyledH3>Materiał BLOW</StyledH3>
              <FlexRow justify="space-between">
                <Wrapper>
                  <StyledH6>Połysk</StyledH6>
                  <Price
                    name="Gładki - Jednostronny"
                    value={prices.paint.companyMaterial.gloss.oneSide}
                    unit="m2"
                  />
                  <Price
                    name="Gładki - Dwustronny"
                    value={prices.paint.companyMaterial.gloss.twoSides}
                    unit="m2"
                  />
                  <Price
                    name="2 grupa cenowa"
                    value={prices.paint.glossSecondGroup}
                    unit="m2"
                  />
                  <StyledH6>Półmat/mat - lewa strona</StyledH6>
                  <Price
                    name="Gładki"
                    value={prices.paint.companyMaterial.leftSide}
                    unit="m2"
                  />
                  <StyledH6>Półmat/mat</StyledH6>
                  <Price
                    name="Gładki - Jednostronny"
                    value={prices.paint.companyMaterial.semiGloss.oneSide}
                    unit="m2"
                  />
                  <StyledH6>Półmat/mat - lewa strona</StyledH6>
                  <Price
                    name="Gładki - Jednostronny"
                    value={prices.paint.companyMaterial.leftSide}
                    unit="m2"
                  />
                  <StyledH6>CNC</StyledH6>
                  <Price
                    name="Ryflowany"
                    value={
                      prices.paint.companyMaterial.semiGloss.groovedOneSide
                    }
                    unit="m2"
                  />
                  <Price
                    name="CNC - 1 grupa"
                    value={prices.paint.companyMaterial.cnc[1]}
                    unit="m2"
                  />
                  <Price
                    name="CNC - 2 grupa"
                    value={prices.paint.companyMaterial.cnc[2]}
                    unit="m2"
                  />
                  <Price
                    name="CNC - 3 grupa"
                    value={prices.paint.companyMaterial.cnc[3]}
                    unit="m2"
                  />
                  <Price
                    name="CNC - 4 grupa"
                    value={prices.paint.companyMaterial.cnc[4]}
                    unit="m2"
                  />
                  <Price
                    name="CNC - gładki"
                    value={prices.paint.companyMaterial.cnc.flat}
                    unit="m2"
                  />
                  <StyledH6>Witryny</StyledH6>
                  <Price
                    name="Witryna - 1 grupa"
                    value={prices.paint.companyMaterial.glassCase[1]}
                    unit="m2"
                  />
                  <Price
                    name="Witryna - 2 grupa"
                    value={prices.paint.companyMaterial.glassCase[2]}
                    unit="m2"
                  />
                  <Price
                    name="Witryna - 3 grupa"
                    value={prices.paint.companyMaterial.glassCase[3]}
                    unit="m2"
                  />
                  <Price
                    name="Witryna - 4 grupa"
                    value={prices.paint.companyMaterial.glassCase[4]}
                    unit="m2"
                  />
                  <StyledH6>Pilastry</StyledH6>
                  <Price
                    name="Pilastry - jednostronne"
                    value={prices.paint.companyMaterial.pilasters.oneSide}
                    unit="m.b."
                  />
                  <Price
                    name="Pilastry - dwustronne"
                    value={prices.paint.companyMaterial.pilasters.twoSides}
                    unit="m.b."
                  />
                  <StyledH6>Szpros</StyledH6>
                  <Price
                    name="Szpros"
                    value={prices.paint.muntin}
                    unit="szt"
                  />
                  <StyledH6>Długie elementy</StyledH6>
                  <Price
                    name="Wys./Szer. >= 2800mm"
                    value={`+ ${prices.paint.companyMaterial.longElement}`}
                    unit="m2"
                  />
                </Wrapper>
                <Wrapper>
                  <StyledH6>Kolor + bezbarwny</StyledH6>
                  <Price
                    name="Bezbarwny - Jednostronny"
                    value={`+ ${prices.paint.transparent.oneSide}`}
                    unit="m2"
                  />
                  <StyledH6>Woda barwiona</StyledH6>
                  <Price
                    name="Woda barwiona - Jednostronna"
                    value={`+ ${prices.paint.coloredWater}`}
                    unit="m2"
                  />
                  <StyledH6>Dorabiany</StyledH6>
                  <Price
                    name="Dorabiany lakier"
                    value={`+ ${prices.paint.extraColor}`}
                    unit="do zam"
                  />
                  <StyledH6>Obróbka elementów</StyledH6>
                  <Price
                    name="Uchwyt frez + lak."
                    value={prices.services.handle}
                    unit="mb"
                  />
                  <Price
                    name="Uchwyt frez. częściowy + lak."
                    value={prices.services.partHandle}
                    unit="szt"
                  />
                  <Price
                    name="Frezowanie uchwytu"
                    value={prices.services.millingHandle}
                    unit="mb"
                  />
                  <Price
                    name="Otw. pod zawias"
                    value={prices.services.hingeHole}
                    unit="szt"
                  />
                  <Price
                    name="Gierowanie"
                    value={prices.services.chamfering}
                    unit="mb"
                  />
                  <Price
                    name="Frezowanie pod plecy"
                    value={prices.services.backMilling}
                    unit="mb"
                  />
                  <Price
                    name="Roboczogodziny"
                    value={prices.services.manHour}
                    unit="h"
                  />{' '}
                  <Price
                    name="Kratki wentylacyjne"
                    value={prices.services.ventilations}
                    unit="szt"
                  />
                  <Price
                    name="Podfrezowanie pod szkło"
                    value={prices.services.underGlass}
                    unit="szt"
                  />
                  <StyledH6>Pogrubienia</StyledH6>
                  <Price
                    name="22mm"
                    value={`+ ${prices.bold[22]}`}
                    unit="m2"
                  />
                  <Price
                    name="25mm"
                    value={`+ ${prices.bold[25]}`}
                    unit="m2"
                  />
                  <Price
                    name="28mm"
                    value={`+ ${prices.bold[28]}`}
                    unit="m2"
                  />
                  <Price
                    name="30mm"
                    value={`+ ${prices.bold[30]}`}
                    unit="m2"
                  />
                  <Price
                    name="38mm"
                    value={`+ ${prices.bold[38]}`}
                    unit="m2"
                  />
                  <StyledH6>Wodoodporny MDF</StyledH6>
                  <Price
                    name="Zielony MDF"
                    value={`+ ${prices.paint.companyMaterial.waterproofMdf}`}
                    unit="m2"
                  />
                </Wrapper>
              </FlexRow>
            </div>
            <div>
              <StyledH3>Materiał Klienta</StyledH3>
              <FlexRow justify="flex-start">
                <div>
                  <StyledH6>Połysk</StyledH6>
                  <Price
                    name="Gładki - Jednostronny"
                    value={prices.paint.customerMaterial.gloss.oneSide}
                    unit="m2"
                  />
                  <Price
                    name="Gładki - Dwustronny"
                    value={prices.paint.customerMaterial.gloss.twoSides}
                    unit="m2"
                  />
                  <StyledH6>Półmat/mat</StyledH6>
                  <Price
                    name="Gładki - Jednostronny"
                    value={prices.paint.customerMaterial.semiGloss.oneSide}
                    unit="m2"
                  />
                  <StyledH6>Półmat/mat - lewa strona</StyledH6>
                  <Price
                    name="Gładki - Jednostronny"
                    value={prices.paint.customerMaterial.leftSide}
                    unit="m2"
                  />
                  <StyledH6>CNC</StyledH6>
                  <Price
                    name="Ryflowany"
                    value={
                      prices.paint.customerMaterial.semiGloss
                        .groovedOneSide
                    }
                    unit="m2"
                  />
                  <Price
                    name="CNC - 1 grupa"
                    value={prices.paint.customerMaterial.cnc[1]}
                    unit="m2"
                  />
                  <Price
                    name="CNC - 2 grupa"
                    value={prices.paint.customerMaterial.cnc[2]}
                    unit="m2"
                  />
                  <Price
                    name="CNC - 3 grupa"
                    value={prices.paint.customerMaterial.cnc[3]}
                    unit="m2"
                  />
                  <Price
                    name="CNC - 4 grupa"
                    value={prices.paint.customerMaterial.cnc[4]}
                    unit="m2"
                  />
                  <Price
                    name="CNC - gładki"
                    value={prices.paint.customerMaterial.cnc.flat}
                    unit="m2"
                  />
                  <StyledH6>Witryny</StyledH6>
                  <Price
                    name="Witryna - 1 grupa"
                    value={prices.paint.customerMaterial.glassCase[1]}
                    unit="m2"
                  />
                  <Price
                    name="Witryna - 2 grupa"
                    value={prices.paint.customerMaterial.glassCase[2]}
                    unit="m2"
                  />
                  <Price
                    name="Witryna - 3 grupa"
                    value={prices.paint.customerMaterial.glassCase[3]}
                    unit="m2"
                  />
                  <Price
                    name="Witryna - 4 grupa"
                    value={prices.paint.customerMaterial.glassCase[4]}
                    unit="m2"
                  />
                  <StyledH6>Pilastry</StyledH6>
                  <Price
                    name="Pilastry - jednostronne"
                    value={prices.paint.customerMaterial.pilasters.oneSide}
                    unit="m.b."
                  />
                  <Price
                    name="Pilastry - dwustronne"
                    value={
                      prices.paint.customerMaterial.pilasters.twoSides
                    }
                    unit="m.b."
                  />
                  <StyledH6>Długie elementy</StyledH6>
                  <Price
                    name="Wys./Szer. >= 2800mm"
                    value={`+ ${prices.paint.customerMaterial.longElement}`}
                    unit="m2"
                  />
                </div>
                <div>
                  <StyledH6>Fornir</StyledH6>
                  <Price
                    name="Fornir [Bezbarwny] - Jednostronny"
                    value={prices.paint.veneer.colorlessOneSide}
                    unit="m2"
                  />
                  <Price
                    name="Fornir [Bezbarwny] - Dwustronny"
                    value={prices.paint.veneer.colorlessTwoSides}
                    unit="m2"
                  />
                  <Price
                    name="Fornir [Bejca] - Jednostronny"
                    value={prices.paint.veneer.mordantOneSide}
                    unit="m2"
                  />
                  <Price
                    name="Fornir [Bejca] - Dwustronny"
                    value={prices.paint.veneer.mordantTwoSides}
                    unit="m2"
                  />
                  <Price
                    name="Ryflowany [Bezbarwny] - Jednostronny"
                    value={prices.paint.veneer.groovedColorlessOneSide}
                    unit="m2"
                  />
                  <Price
                    name="Ryflowany [Bezbarwny] - Dwustronny"
                    value={prices.paint.veneer.groovedColorlessTwoSides}
                    unit="m2"
                  />
                  <Price
                    name="Ryflowany [Bejca] - Jednostronny"
                    value={prices.paint.veneer.groovedMordantOneSide}
                    unit="m2"
                  />
                  <Price
                    name="Ryflowany [Bejca] - Dwustronny"
                    value={prices.paint.veneer.groovedMordantTwoSides}
                    unit="m2"
                  />
                  <StyledH6>Inne</StyledH6>
                  <Price
                    name="Lakierowanie uchwytu"
                    value={prices.services.paintHandle}
                    unit="mb"
                  />
                  <Price
                    name="Lakierowanie uchwytu częściowego"
                    value={prices.services.paintPartHandle}
                    unit="szt"
                  />
                </div>
              </FlexRow>
            </div>
          </FlexRow>
        </div>
      )}
    </PageTemplate>
  );
};

export default UserPrices;

const Price = ({ name, value, unit }) => {
  let currentUnit;
  if (unit === 'szt') currentUnit = 'szt.';
  else if (unit === 'mb') currentUnit = 'm.b.';
  else if (unit === 'h') currentUnit = 'h';
  else if (unit === 'do zam') currentUnit = 'do zam';
  else if (unit === 'm2')
    currentUnit = (
      <span>
        m<sup>2</sup>
      </span>
    );
  return (
    <div style={{ margin: '0 5px' }}>
      <p>
        {name}:
        <span>
          <strong> {value}</strong> <small>zł/{currentUnit}</small>
        </span>
      </p>
    </div>
  );
};
