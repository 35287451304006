import { StyledH3 } from 'components/header';
import React from 'react';
import FlexRow from 'PageTemplates/FlexRowTemplate';
import withContext from 'hoc/withContext';
import Front from './Front';

const PricingGroup = ({
  group,
  fronts,
  permissionContext,
  removeFront,
  editFront,
  handleFront,
}) => {
  if (fronts && fronts.length)
    return (
      <div>
        <StyledH3>{`${group} grupa cenowa`}</StyledH3>
        <FlexRow justify="flex-start" wrap>
          {fronts && fronts.length
            ? fronts.map((front) => {
                return (
                  <Front
                    key={front._id}
                    front={front}
                    permission={permissionContext}
                    removeFront={removeFront}
                    editFront={editFront}
                    handleFront={handleFront}
                  >
                    {front.symbol}
                  </Front>
                );
              })
            : null}
        </FlexRow>
      </div>
    );
  return null;
};

export default withContext(PricingGroup);
