import React, { useState } from 'react';
import Modal from 'PageTemplates/ModalTemplate';
import { Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useAlert } from 'react-alert';
import { setSpinner } from 'services/store/actions/view';
import { updateEmployeeData } from 'services/apiRequests/employees/update';
import { setEmployee } from 'services/store/actions/employees';

const EmployeePermissions = ({ closeModal, employee }) => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const [isManager, setIsManager] = useState(employee.isManager);
  const handleChange = (value) => setIsManager(value);

  const handleSubmit = async () => {
    dispatch(setSpinner(true));

    await updateEmployeeData(
      employee._id,
      { isManager },
      (employee) => {
        dispatch(setSpinner(false));
        alert.success(`Zaktualizowano uprawnienia`);
        dispatch(setEmployee(employee));
        closeModal();
      },
      () => {
        dispatch(setSpinner(false));
        alert.error(`Błąd!`);
      },
    );
  };
  return (
    <Modal
      title="Zmiana uprawnień"
      closeModal={closeModal}
      onOk={handleSubmit}
      disabled={typeof isManager !== 'boolean'}
    >
      <small>
        Kierownik, poza uprawnieniami pracownika, ma możliwość korzystania
        z modułu służącego do zamawiania lakierów, jak również ma wgląd do
        pełnego planu produkcji, bez możliwości edycji.
      </small>
      <Form.Group>
        <Form.Check
          type="radio"
          checked={!isManager}
          onChange={() => handleChange(false)}
          label="Pracownik"
          id="Pracownik"
        />
        <Form.Check
          type="radio"
          checked={isManager}
          onChange={() => handleChange(true)}
          label="Kierownik"
          id="Kierownik"
        />
      </Form.Group>
    </Modal>
  );
};

export default EmployeePermissions;
