import * as yup from 'yup';

export const schema = yup.object({
  email: yup
    .string()
    .email('Format adresu email jest nieprawidłowy')
    .required('Email jest wymagany'),
  name: yup.string().required('Imię i nazwisko jest wymagane'),
  message: yup
    .string()
    .min(10, 'Wiadomość musi składać się z min. 10 znaków')
    .required('Wiadomość jest wymagana'),
});
