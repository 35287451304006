import React from 'react';
import styled from 'styled-components';
import FlexRow from 'PageTemplates/FlexRowTemplate';

export const PageHeader = ({ title, extra = [], color = null }) => {
  return (
    <FlexRow justify="space-between">
      <StyledH1 color={color}>{title}</StyledH1>
      <FlexRow justify="space-between">
        {extra.map((item) =>
          item ? <div key={item.key}>{item}</div> : null,
        )}
      </FlexRow>
    </FlexRow>
  );
};

export const StyledH1 = styled.h1`
  color: ${({ theme, color }) => color || theme.blowGreen};
  letter-spacing: 3px;
  margin-bottom: 20px;
`;
export const StyledH3 = styled.h3`
  color: ${({ theme, color }) => color || theme.blowInfo};
  letter-spacing: 3px;
  margin-bottom: 10px;
`;
export const StyledH6 = styled.h6`
  color: ${({ theme, color }) => color || theme.blowPrimary};
  font-weight: bold;
`;
