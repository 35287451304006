import React, { useCallback, useEffect, useState } from 'react';
import {
  faFileUpload,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { Formik } from 'formik';
import * as moment from 'moment';
import { useAlert } from 'react-alert';
import { Button, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import DatePicker from 'components/DatePicker';
import { PageHeader } from 'components/header';
import OrdersList from 'components/ordersList/static';
import { getCustomers } from 'services/store/actions/customers';
import { setSpinner } from 'services/store/actions/view';
import { getOrderPrice } from 'services/utils/orders';
import FlexRow from 'PageTemplates/FlexRowTemplate';
import PageTemplate from 'PageTemplates/PageTemplate';
import { newFvValidate } from './utils/validate';

const StyledFormGroup = styled(Form.Group)`
  margin-bottom: 5px;
  max-width: 300px;
`;

const StyledInputFile = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;
const StyledFileLabel = styled.label`
  background-color: white;
  color: #007bff;
  border: 1px solid #007bff;
  padding: 5px 30px;
  letter-spacing: 1px;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 200ms ease;
  &:hover {
    background-color: #007bff;
    color: white;
  }
`;

const initValues = {
  paymentTo: moment().add(2, 'week').toDate(),
  createdAt: moment().toDate(),
  number: '',
};

const NewInvoicePage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const alert = useAlert();
  const customers = useSelector((state) => state.customers.customers);

  const [customer, setCustomer] = useState(undefined);
  const [price, setPrice] = useState(0);
  const [priceGross, setPriceGross] = useState(0);
  const [file, setFile] = useState(null);

  const [customerOrders, setCustomerOrders] = useState([]);
  const [pickedOrders, setPickedOrders] = useState([]);

  const getCustomerOrders = useCallback(async () => {
    if (customer) {
      try {
        dispatch(setSpinner(true));
        setPickedOrders([]);
        const res = await axios.get(
          `/api/orders/customer/${customer}/to-fv`,
          { params: { onlyWithoutInvoice: true } },
        );

        setCustomerOrders(res.data);
      } catch (error) {
        console.log(error);
        alert.error('Błąd');
      } finally {
        dispatch(setSpinner(false));
      }
    }
  }, [customer]);

  useEffect(() => {
    dispatch(
      getCustomers(
        () => {},
        () => {},
      ),
    );
  }, []);

  useEffect(() => {
    getCustomerOrders();
  }, [getCustomerOrders]);

  useEffect(() => {
    const orders = customerOrders.filter((order) =>
      pickedOrders.includes(order._id),
    );
    const newPrice = orders.reduce((acc, order) => {
      const orderPrice = getOrderPrice(order);
      return (acc += +orderPrice);
    }, 0);

    setPrice(newPrice);
  }, [pickedOrders]);

  const handlePickOrder = (e) => {
    let { checked, value } = e.target;
    const obj = JSON.parse(value);
    value = obj._id;
    if (checked) {
      if (!pickedOrders.includes(value))
        setPickedOrders(pickedOrders.concat(value));
    } else {
      const newList = pickedOrders.filter((item) => item !== value);
      setPickedOrders(newList);
    }
  };
  const createInvoice = async (values) => {
    try {
      dispatch(setSpinner(true));
      const data = new FormData();

      const invoice = {
        ...values,
        price,
        orders: pickedOrders,
        customer,
        priceGross,
      };
      data.append(`invoice`, JSON.stringify(invoice));
      if (file) {
        const filename = file.name.split('/').join('_');
        data.append('file', file, filename);
      }

      await axios.post(`/api/transfers`, data);
      dispatch(setSpinner(false));
      alert.success('Przypisano zamówienia do faktury');
      history.push(`/fv`);
    } catch (error) {
      console.log(error, error?.response);
      alert.error(error?.response?.data?.message || 'Błąd!');
      dispatch(setSpinner(false));
    }
  };

  return (
    <PageTemplate>
      <PageHeader title="Nowa faktura" />
      <Formik
        validationSchema={newFvValidate}
        // ONSUBMIT REQUEST
        onSubmit={createInvoice}
        initialValues={initValues}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          setFieldValue,
          values,
          touched,
          errors,
          isValid,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <FlexRow justify="space-between">
              <StyledFormGroup controlId="numberId">
                <strong>Numer FV</strong>
                <Form.Control
                  required
                  type="text"
                  name="number"
                  value={values.number}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Numer FV*"
                  className={
                    errors.number && touched.number && 'is-invalid'
                  }
                />
                {errors.number && touched.number && (
                  <div className="invalid">{errors.number}</div>
                )}
              </StyledFormGroup>
              <StyledFormGroup controlId="priceId">
                <strong>Data wystawienia FV</strong>
                <DatePicker
                  value={values.createdAt}
                  onChange={(date) => setFieldValue('createdAt', date)}
                />
                {errors.createdAt && touched.createdAt && (
                  <div className="invalid">{errors.createdAt}</div>
                )}
              </StyledFormGroup>
              <StyledFormGroup controlId="priceId">
                <strong>Termin płatności</strong>
                <DatePicker
                  value={values.paymentTo}
                  onChange={(date) => setFieldValue('paymentTo', date)}
                />
                {errors.paymentTo && touched.paymentTo && (
                  <div className="invalid">{errors.paymentTo}</div>
                )}
              </StyledFormGroup>

              <div>
                <div>
                  <strong>Faktura</strong>
                </div>
                <div>
                  {!file ? (
                    <>
                      <StyledInputFile
                        type="file"
                        id={`file_banner`}
                        onChange={(e) => setFile(e.target.files[0])}
                      />
                      <StyledFileLabel htmlFor={`file_banner`}>
                        <FontAwesomeIcon icon={faFileUpload} /> Dodaj plik
                      </StyledFileLabel>
                    </>
                  ) : (
                    <div>
                      <Button
                        variant="outline-danger"
                        onClick={() => setFile()}
                      >
                        <FontAwesomeIcon
                          icon={faTrashAlt}
                          style={{ color: '#c01212', cursor: 'pointer' }}
                        />
                        Usuń plik
                      </Button>
                      <div>
                        <small>{file && file.name}</small>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </FlexRow>
            <hr />
            <FlexRow justify="space-between">
              <StyledFormGroup>
                <strong>Klient</strong>
                <Form.Control
                  as="select"
                  onChange={(e) => setCustomer(e.target.value)}
                  value={customer}
                >
                  <option value="">Klient</option>
                  {customers &&
                    customers
                      .sort((a, b) => a.company.localeCompare(b.company))
                      .map((item) => (
                        <option key={item._id} value={item._id}>
                          {item.company}
                          {item.firstname ? ` - ${item.firstname[0]}` : ''}
                        </option>
                      ))}
                </Form.Control>
                {!customer && !isValid ? (
                  <div className="invalid">Wybierz klienta</div>
                ) : (
                  ''
                )}
                <small
                  style={{
                    color: 'lightgray',
                  }}
                >
                  Pobrane są zamówienia nieopłacone i nieuwzględnione
                  wcześniej w FV
                </small>
              </StyledFormGroup>
              <div style={{ textAlign: 'right' }}>
                <small>
                  Liczba wybranych zamówień: {pickedOrders.length}
                </small>
                {!pickedOrders.length || !isValid ? (
                  <div className="invalid">Wybierz zamówienia</div>
                ) : (
                  ''
                )}
              </div>
            </FlexRow>
            <OrdersList
              orders={customerOrders}
              listName="customer"
              paymentStatus
              pickUpDate
              status
              fv
              checkbox
              onCheck={handlePickOrder}
              redirect={false}
              price
            />
            <hr />
            <FlexRow justify="flex-end">
              <StyledFormGroup>
                <div style={{ marginBottom: 10, textAlign: 'right' }}>
                  <small>
                    Liczba wybranych zamówień: {pickedOrders.length}
                  </small>
                  {!pickedOrders.length && !isValid ? (
                    <div className="invalid">Wybierz zamówienia</div>
                  ) : (
                    ''
                  )}
                </div>
                <div>
                  <strong>Cena</strong>
                  <Form.Control
                    type="text"
                    placeholder="Cena"
                    value={price}
                    onChange={(e) => {
                      const { value } = e.target;
                      const numbers = /^[\d|.|,]*$/;
                      if (!value.match(numbers)) return;
                      setPrice(value.replace(',', '.'));
                    }}
                  />
                  {!price && pickedOrders.length ? (
                    <div className="invalid">Wartość jest wymagana</div>
                  ) : (
                    ''
                  )}
                </div>
                <div>
                  <strong>Cena brutto</strong>
                  <Form.Control
                    type="text"
                    placeholder="Cena brutto"
                    value={priceGross}
                    onChange={(e) => {
                      const { value } = e.target;
                      const numbers = /^[\d|.|,]*$/;
                      if (!value.match(numbers)) return;
                      setPriceGross(value.replace(',', '.'));
                    }}
                  />
                </div>
              </StyledFormGroup>
            </FlexRow>
            <Button
              disabled={
                !price || !customer || !pickedOrders.length || !isValid
              }
              block
              type="submit"
              variant="success"
            >
              Zapisz
            </Button>
          </Form>
        )}
      </Formik>
    </PageTemplate>
  );
};

export default NewInvoicePage;
