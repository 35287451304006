import Axios from 'axios';
import setAuthToken from 'services/utils/setAuthToken';

export const getPaintOrdersByDate = async (from, to, onEnd, onError) => {
  if (localStorage.token) setAuthToken(localStorage.token);

  try {
    const res = await Axios.post('/api/paints/getorders', { from, to });
    const { orders } = res.data;

    onEnd();
    return orders;
  } catch (error) {
    onError();
    if (error.response && error.response.status === 401)
      window.location.reload(true);
  }
};
