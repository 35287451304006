import React from 'react';
import Modal from 'PageTemplates/ModalTemplate';

const ConfirmationModal = ({
  title = null,
  handleSubmit,
  description,
  closeModal,
}) => {
  return (
    <Modal
      title={title || 'Czy chcesz wykonać akcję?'}
      closeModal={closeModal}
      onOk={handleSubmit}
      backdrop="static"
    >
      {description && (
        <>
          {description}
          <hr />
        </>
      )}
    </Modal>
  );
};

export default ConfirmationModal;
