import Axios from 'axios';

export const planOrder = async (body, onEnd, onErr) => {
  try {
    await Axios.put(`/api/timetables/`, body);

    sessionStorage.setItem('lastEditedOrder', body.orderId);
    return onEnd();
  } catch (error) {
    console.log(error);
    onErr();
  }
};

export const editOrderPlan = async (body, orderId, onEnd, onErr) => {
  try {
    await Axios.put(`/api/timetables/edit/${orderId}`, body);

    sessionStorage.setItem('lastEditedOrder', orderId);
    return onEnd();
  } catch (error) {
    console.log(error);
    onErr();
  }
};

export const updateDayTimetable = async (body, dayId, onEnd, onErr) => {
  console.log(body);
  try {
    await Axios.put(`/api/timetables/day/${dayId}`, body);

    return onEnd();
  } catch (error) {
    console.log(error);
    onErr();
  }
};

export const clearOrderSchedule = async (orderId, onEnd, onErr) => {
  try {
    const res = await Axios.put(
      `/api/timetables/removeorderschedule/${orderId}`,
    );
    const { order } = res.data;

    return onEnd(order);
  } catch (error) {
    console.log(error);
    onErr();
  }
};
