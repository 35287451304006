import React from 'react';
import { CuttingPackageStatus as CuttingPackageStatusEnum } from 'services/enums/CuttingPackage.enum';
import { firstLetterToUpperCase } from 'services/utils/string';
import styled from 'styled-components';

const Span = styled.span<{
  status: CuttingPackageStatusEnum;
  size?: number;
}>`
  font-weight: bold;
  font-size: ${({ size }) => `${size}` || 16}px;
  color: ${({ status }) => {
    if (status === CuttingPackageStatusEnum.PENDING) return 'gray';
    else if (status === CuttingPackageStatusEnum.IN_PROGRESS)
      return 'lightblue';
    else if (status === CuttingPackageStatusEnum.NOT_COMPLETE)
      return 'red';
    else if (status === CuttingPackageStatusEnum.READY) return 'green';
    return 'black';
  }};
`;

interface IProps {
  status: CuttingPackageStatusEnum;
  size?: number;
}

const CuttingPackageStatus: React.FC<IProps> = ({ status, size }) => {
  return (
    <Span status={status} size={size}>
      {firstLetterToUpperCase(status)}
    </Span>
  );
};

export default CuttingPackageStatus;
