import React, { FC } from 'react';
import { IOrder } from 'services/interfaces/orders/Orders.interface';

interface IProps {
  order: IOrder;
}

const OrderNumberCell: FC<IProps> = ({ order }) => {
  return <td>{order.number.toUpperCase()}</td>;
};

export default OrderNumberCell;
