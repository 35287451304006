import React from 'react';
import Fade from 'react-reveal/Fade';
import FlexRow from 'PageTemplates/FlexRowTemplate';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import {
  setNewOrderName,
  setNewOrderComments,
} from 'services/store/actions/newOrder';
import { Form } from 'react-bootstrap';

const StyledWrapper = styled.div`
  text-align: center;
  margin: 0 5px;
  width: 45%;
  input,
  textarea {
    text-align: center;
  }
  @media (max-width: 600px) {
    width: 100%;
  }
`;

const OrderInfo = ({ order }) => {
  const dispatch = useDispatch();

  return (
    <Fade right>
      <FlexRow justify="space-between">
        <StyledWrapper>
          <strong>Nazwa zamówienia</strong>
          <Form.Control
            type="text"
            value={order.name}
            placeholder="Nazwa zamówienia"
            onChange={(e) => dispatch(setNewOrderName(e.target.value))}
          />
        </StyledWrapper>
        <StyledWrapper>
          <strong>Uwagi do zamówienia</strong>
          <Form.Control
            as="textarea"
            value={order.comments}
            placeholder="Uwagi dotyczące całego zamówienia"
            onChange={(e) => dispatch(setNewOrderComments(e.target.value))}
          />
        </StyledWrapper>
      </FlexRow>
    </Fade>
  );
};

export default OrderInfo;
