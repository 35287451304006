import { IOrder } from 'services/interfaces/orders/Orders.interface';
import { ordersUtils } from 'services/utils/orders/orders.utils';

// TODO Move to order utils
export const filterNotFullyPaidOrders = (orders: IOrder[]) => {
  if (!orders) return [];

  return orders.filter((order) => {
    const price = ordersUtils.payments.getOrderPrice(order, true);
    const paymentsValue = ordersUtils.payments.getPaymentsValue(order);

    if (price > paymentsValue) return order;
  });
};
