import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

const StyledSelect = styled.select`
  height: 28px;
  width: 100%;
  border-radius: 3px;
  border: 1px solid lightgray;
  &:focus {
    background-color: ${({ theme }) => theme.blowPrimaryLight};
  }
`;
const LeftSideEdge = ({
  handleChange,
  handleFocus,
  handleBlur,
  value,
  name,
  tabIndex,
}) => {
  const order = useSelector((state) => state.newOrder);
  const { isChamfering, isNut, isFelc, paintType } = order;

  const R = paintType === 'połysk' ? 'r2' : 'r1';
  return (
    <StyledSelect
      tabIndex={tabIndex}
      name={name}
      value={value}
      onChange={handleChange}
      onFocus={handleFocus}
      onBlur={handleBlur}
      required
    >
      <option value="-">-</option>
      <option value={R}>{R.toUpperCase()}</option>
      <option value="2 otw">2 otw.</option>
      <option value="3 otw">3 otw.</option>
      <option value="4 otw">4 otw.</option>
      <option value="5 otw">5 otw.</option>
      <option value="6 otw">6 otw.</option>
      <option value="7 otw">7 otw.</option>
      {isNut && <option value="nut">Nut</option>}
      {isFelc && <option value="felc">Felc</option>}
      {isChamfering && <option value="gierunek">Gierunek</option>}
    </StyledSelect>
  );
};

export default LeftSideEdge;
