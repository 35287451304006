import React from 'react';
import FlexRow from 'PageTemplates/FlexRowTemplate';
import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ItemWrapper = styled.div`
  text-align: center;
  position: relative;
`;

const RemoveIcon = styled(FontAwesomeIcon)`
  color: red;
  cursor: pointer;
  opacity: 0.5;
  &:hover {
    opacity: 1;
  }
`;

const Pilasters = ({ order, handleClick, handleRemove, disabled }) => {
  return (
    <ItemWrapper>
      <strong>Pilaster</strong>
      <FlexRow justify="space-between">
        {order.pilaster.toUpperCase()}
        {order.pilaster && (
          <RemoveIcon icon={faTrash} onClick={() => handleRemove()} />
        )}
      </FlexRow>
      <Button
        variant="info"
        size="sm"
        onClick={handleClick}
        disabled={disabled}
      >
        Wybierz
      </Button>
    </ItemWrapper>
  );
};

export default Pilasters;
