import React, { useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
// import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Button, Form } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { schema } from './utils/validatePasswordRemind';
import { useDispatch } from 'react-redux';
import { setSpinner } from 'services/store/actions/view';
import { passwordRemind } from 'services/apiRequests/users/update';
import ConfirmModal from './components/ConfirmModal';

const StyledContainer = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const StyledContent = styled.div`
  width: 70%;
  @media (max-width: 600px) {
    width: 95%;
  }
`;
const StyledH1 = styled.h1`
  margin-bottom: 30px;
  font-size: 50px;
  @media (max-width: 600px) {
    font-size: 35px;
    margin-bottom: 10px;
  }
`;
const StyledButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  margin: 0 !important;
  background-color: ${({ theme }) => theme.blowGreen};
  color: white;
  border: none;
  font-size: 20px;
  letter-spacing: 3px;
  padding: 10px 30px;
  transition: transform 0.2s;
  &:hover {
    transform: translateX(-10px);
  }
  @media (max-width: 600px) {
    padding: 3px 20px;
  }
`;

const PasswordRemind = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [modal, setModal] = useState(false);

  return (
    <StyledContainer>
      <StyledButton onClick={() => history.push('/login')}>
        <FontAwesomeIcon icon={faArrowLeft} /> Zaloguj
      </StyledButton>
      <StyledContent>
        <StyledH1>Przypomnij hasło</StyledH1>
        <Formik
          validationSchema={schema}
          // ONSUBMIT REQUEST
          onSubmit={async (values) => {
            dispatch(setSpinner(true));
            await passwordRemind(
              values.email,
              () => {
                dispatch(setSpinner(false));
                setModal(true);
              },
              () => {
                dispatch(setSpinner(false));
                setModal(true);
              },
            );
          }}
          initialValues={{
            email: '',
          }}
          render={({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            errors,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              {/* SPINNER */}

              <Form.Group controlId="formEmail">
                <Form.Control
                  required
                  type="email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isValid={touched.email && !errors.email}
                  placeholder="Adres email*"
                  className={errors.email && touched.email && 'is-invalid'}
                />
                {errors.email && touched.email && (
                  <div className="invalid">{errors.email}</div>
                )}
              </Form.Group>

              <div>
                <Button
                  type="submit"
                  variant="success"
                  style={{
                    margin: '5px 0',
                    padding: '10px 0',
                    letterSpacing: 5,
                    width: '100%',
                  }}
                  disabled={!values.email}
                >
                  Wyślij
                </Button>
              </div>
            </Form>
          )}
        />
      </StyledContent>
      {/* MODAL */}
      {modal && <ConfirmModal closeModal={() => setModal(false)} />}
    </StyledContainer>
  );
};

export default PasswordRemind;
