import Axios from 'axios';

export const createEmployee = async (data, onEnd, onErr) => {
  console.log('CREATE_EMPLOYEE');
  try {
    const res = await Axios.post('/api/employees/', data);

    onEnd(res.data._id);
  } catch (error) {
    const { response } = error;
    console.log('ERROR_CREATE_EMPLOYEE: ', response);
    if (response.status === 422) onErr(parseErrors(response.data.errors));
    if (response.status === 409) onErr({ email: response.data.message });
  }
};

const parseErrors = (err) => {
  const errors = {};
  Object.keys(err).forEach((key) => {
    const error = err[key];
    errors[key] = error.msg;
  });
  return errors;
};
