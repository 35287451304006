import React, { useState, useEffect } from 'react';
import Modal from 'PageTemplates/ModalTemplate';
import { useDispatch, useSelector } from 'react-redux';
import { setSpinner } from 'services/store/actions/view';
import { getProductionOrders } from 'services/store/actions/orders';
import { useAlert } from 'react-alert';
import OrdersList from 'components/ordersList/static';
import styled from 'styled-components';
import { addCustomOrders } from 'services/store/actions/paints';

const Wrapper = styled.div`
  max-height: 70vh;
  overflow-y: auto;
`;

const AddPosition = ({ closeModal }) => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const orders = useSelector((state) => state.orders.productionOrders);

  const [pickedOrders, setPickedOrders] = useState([]);

  useEffect(() => {
    if (!orders) {
      dispatch(setSpinner(true));
      dispatch(
        getProductionOrders(
          () => {
            dispatch(setSpinner(false));
          },
          () => {
            dispatch(setSpinner(false));
            alert.error('Błąd!');
          },
        ),
      );
    }
  }, []);

  const handleCheck = ({ target: { checked, value } }) => {
    const order = JSON.parse(value);
    if (checked) setPickedOrders(pickedOrders.concat(order._id));
    else {
      const newList = pickedOrders.filter(
        (item) => item._id !== order._id,
      );
      setPickedOrders(newList);
    }
  };

  const handleAddOrders = () => {
    const ordersToAdd = orders.reduce((arr, curr) => {
      if (pickedOrders.includes(curr._id)) {
        return arr.concat({
          ...curr,
          quantity: '',
          producer: curr.paintMaker.toLowerCase(),
        });
      }
      return arr;
    }, []);
    dispatch(addCustomOrders(ordersToAdd));
    alert.success('Dodano zamówienie do listy');
    closeModal();
  };

  return (
    <Modal
      title="Dodaj zamówienie"
      size="xl"
      onOk={handleAddOrders}
      disabled={!pickedOrders.length}
      closeModal={closeModal}
    >
      <Wrapper>
        <OrdersList
          orders={orders}
          listName="production"
          onCheck={handleCheck}
          redirect={false}
          checkbox
          status
        />
      </Wrapper>
    </Modal>
  );
};

export default AddPosition;
