import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import * as moment from 'moment';
import { useAlert } from 'react-alert';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useParams } from 'react-router';
import { PageHeader } from 'components/header';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import OrdersList from 'components/ordersList/static';
import { getInvoiceFile } from 'services/apiRequests/files/get';
import { setSpinner } from 'services/store/actions/view';
import { invoicePaymentStatus } from 'services/utils/const';
import FlexRow from 'PageTemplates/FlexRowTemplate';
import PageTemplate from 'PageTemplates/PageTemplate';

const InvoicePage = () => {
  //   const history = useHistory();
  const dispatch = useDispatch();
  const history = useHistory();
  const alert = useAlert();
  const { id } = useParams();

  const [invoice, setInvoice] = useState(null);
  const [deleteConfirm, setDeleteConfirm] = useState(false);

  const getInvoice = useCallback(async () => {
    try {
      dispatch(setSpinner(true));
      const res = await axios.get(`/api/transfers/${id}`);
      setInvoice(res.data);
    } catch (error) {
      console.log(error);
      alert.error('Błąd');
    } finally {
      dispatch(setSpinner(false));
    }
  }, []);

  useEffect(() => {
    getInvoice();
  }, [getInvoice]);

  const handleInvoicePaymentStatus = async () => {
    if (!invoice) return;
    try {
      dispatch(setSpinner(true));
      const status =
        invoice.status === invoicePaymentStatus.PAID
          ? invoicePaymentStatus.NOT_PAID
          : invoicePaymentStatus.PAID;
      const res = await axios.put(
        `/api/transfers/${id}/set-payment-status`,
        {
          status,
        },
      );
      setInvoice(res.data.invoice);
      alert.success(
        `Faktura i zamówienia zostały oznaczone jako ${status}`,
      );
    } catch (error) {
      console.log(error);
      alert.error('Błąd');
    } finally {
      dispatch(setSpinner(false));
    }
  };

  const getFile = async () => {
    if (invoice && invoice.filePath) dispatch(setSpinner(true));
    await getInvoiceFile(
      invoice._id,
      invoice.filePath,
      () => {
        dispatch(setSpinner(false));
      },
      () => {
        dispatch(setSpinner(false));
      },
    );
  };

  const deleteInvoice = async () => {
    try {
      dispatch(setSpinner(true));
      const res = await axios.delete(`/api/transfers/${id}`);
      dispatch(setSpinner(false));
      history.push('/fv');
    } catch (error) {
      console.log(error);
      alert.error('Błąd');
    } finally {
      dispatch(setSpinner(false));
    }
  };

  const goToEditTransfer = () => history.push(`/edit-fv/${id}`);
  console.log(invoice);
  return (
    <PageTemplate>
      <PageHeader
        title={invoice?.number ? `FV: ${invoice.number}` : 'FV'}
        extra={[
          <Button variant="secondary" key={2} onClick={goToEditTransfer}>
            Edytuj
          </Button>,
          <Button disabled={!invoice?.filePath} key={1} onClick={getFile}>
            Pobierz FV
          </Button>,
          <Button
            variant={
              invoice?.status === invoicePaymentStatus.PAID
                ? 'danger'
                : 'success'
            }
            key={0}
            onClick={handleInvoicePaymentStatus}
          >
            {invoice?.status === invoicePaymentStatus.PAID
              ? 'Nieopłacone'
              : 'Opłacone'}
          </Button>,
          <Button
            variant="danger"
            key={3}
            onClick={() => setDeleteConfirm(true)}
          >
            Usuń
          </Button>,
        ]}
      />
      <h4>{invoice?.customer?.company}</h4>
      <hr />
      <FlexRow justify="space-between">
        <div>
          <div>
            <strong>Numer FV</strong>
          </div>
          <div>{invoice?.number}</div>
        </div>
        <div>
          <div>
            <strong>Data wystawienia FV</strong>
          </div>
          <div>
            {invoice ? moment(invoice.createdAt).format('DD.MM.YYYY') : ''}
          </div>
        </div>
        <div>
          <div>
            <strong>Termin płatności</strong>
          </div>
          <div
            style={
              invoice &&
              invoice.status === invoicePaymentStatus.NOT_PAID &&
              moment(invoice?.paymentTo)?.diff(moment(), 'hours') < 0
                ? { fontWeight: 'bold', color: 'red' }
                : {}
            }
          >
            {invoice ? moment(invoice.paymentTo).format('DD.MM.YYYY') : ''}
          </div>
        </div>
        <div>
          <div>
            <strong>Wartość</strong>
          </div>
          <div
            style={{
              fontWeight: 'bold',
            }}
          >
            {invoice?.price} zł
          </div>
        </div>
        <div>
          <div>
            <strong>Wartość brutto</strong>
          </div>
          <div
            style={{
              fontWeight: 'bold',
            }}
          >
            {invoice?.priceGross} zł
          </div>
        </div>
        <div>
          <div>
            <strong>Status</strong>
          </div>
          <div
            style={{
              fontWeight: 'bold',
              color:
                invoice?.status === invoicePaymentStatus.NOT_PAID
                  ? 'red'
                  : 'green',
            }}
          >
            {invoice?.status?.toUpperCase()}
          </div>
        </div>
      </FlexRow>
      <hr />
      <OrdersList
        orders={invoice?.orders.map((order) => order.order)}
        listName="customer"
        paymentStatus
        pickUpDate
        status
        price
      />

      {deleteConfirm && (
        <ConfirmationModal
          title="Chcesz usunąć tą fakturę?"
          handleSubmit={deleteInvoice}
          closeModal={() => setDeleteConfirm(false)}
        />
      )}
    </PageTemplate>
  );
};

export default InvoicePage;
