import React from 'react';
import styled from 'styled-components';
import { Form, Button } from 'react-bootstrap';
import { Formik } from 'formik';
import { StyledH6 } from 'components/header';
import FlexRow from 'PageTemplates/FlexRowTemplate';
import { schema } from './utils/validateContactForm';

const StyledRow = styled.div`
  display: flex;
  justify-content: space-around;
`;
const StyledContactData = styled.div`
  margin-top: 20px;
  text-align: center;
  color: white;
  @media (max-width: 600px) {
    width: 45%;
    font-size: 14px;
    margin-top: 5px;
  }
`;
const StyledFormGroup = styled(Form.Group)`
  width: 45%;
  @media (max-width: 600px) {
    width: 100%;
  }
`;

const ContactForm = () => {
  return (
    <div>
      <Formik
        validationSchema={schema}
        // ONSUBMIT REQUEST
        onSubmit={async () =>
          // values, actions
          {
            //  if (values.fakeCheckbox) return;
            //  try {
            //   dispatch(setSpinner(true));
            //   await Axios({
            //    method: 'post',
            //    responseType: 'json',
            //    url: '/api/users/contactform',
            //    data: values,
            //   });
            //   dispatch(setSpinner(false));
            //   handleResult('success', 'Wiadomość wysłana');
            //   actions.setValues({
            //    email: '',
            //    name: '',
            //    message: '',
            //    fakeCheckbox: false,
            //   });
            //  } catch (err) {
            //   console.log(err.response);
            //   dispatch(setSpinner(false));
            //   handleResult('danger', 'Błąd serwera, spróbuj później');
            //   actions.setValues({
            //    email: '',
            //    name: '',
            //    message: '',
            //    fakeCheckbox: false,
            //   });
            //  }
          }
        }
        initialValues={{
          email: '',
          name: '',
          message: '',
          fakeCheckbox: false,
        }}
        render={({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          errors,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            {/* SPINNER */}
            <FlexRow justify="space-between" wrap>
              <StyledFormGroup controlId="formEmail">
                <Form.Control
                  required
                  type="email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isValid={touched.email && !errors.email}
                  placeholder="Adres email*"
                  className={errors.email && touched.email && 'is-invalid'}
                />
                {errors.email && touched.email && (
                  <div className="invalid">{errors.email}</div>
                )}
              </StyledFormGroup>
              <StyledFormGroup controlId="formName">
                <Form.Control
                  required
                  type="text"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isValid={touched.name && !errors.name}
                  placeholder="Imię i nazwisko"
                  className={errors.name && touched.name && 'is-invalid'}
                />
                {errors.name && touched.name && (
                  <div className="invalid">{errors.name}</div>
                )}
              </StyledFormGroup>
            </FlexRow>
            <Form.Group controlId="formMessage">
              <Form.Control
                style={{ height: 100 }}
                required
                as="textarea"
                name="message"
                value={values.message}
                onChange={handleChange}
                onBlur={handleBlur}
                isValid={touched.message && !errors.message}
                placeholder="Wiadomość"
                className={
                  errors.message && touched.message && 'is-invalid'
                }
              />
              {errors.message && touched.message && (
                <div className="invalid">{errors.message}</div>
              )}
            </Form.Group>

            <Form.Group controlId="formBasicFakeCheckbox">
              <Form.Check
                value={values.fakeCheckbox}
                onChange={handleChange}
                onBlur={handleBlur}
                isValid={touched.fakeCheckbox && !errors.fakeCheckbox}
                type="checkbox"
                style={{ display: 'none' }}
              />
            </Form.Group>
            <div>
              <Button
                disabled={
                  !values.email ||
                  !values.name ||
                  !values.message ||
                  values.fakeCheckbox
                }
                type="submit"
                variant="dark"
                style={{
                  margin: '5px 0',
                  padding: '10px 0',
                  letterSpacing: 5,
                  width: '100%',
                }}
              >
                Wyślij
              </Button>
            </div>
          </Form>
        )}
      />

      <StyledRow>
        <StyledContactData>
          <StyledH6>Produkcja</StyledH6>
          <p>
            Ul. Ożarowska 83 budynek G<br />
            05-850 Duchnice
            <br />
            tel. 796 999 540
            <br />
            tel. 505 412 112
            <br />
            tel. (22) 722 10 17
            <br />
            E-mail: <strong>biuro@mebleblow.pl</strong>
          </p>
        </StyledContactData>
      </StyledRow>
    </div>
  );
};

export default ContactForm;
