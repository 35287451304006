import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import withContext from 'hoc/withContext';
import { firstLetterToUpperCase } from 'services/utils/string';

const Td = styled.td`
  background-color: ${({ primary, secondary, theme }) => {
    if (primary) return theme.blowGreenLight;
    if (secondary) return theme.blowWarningLight;
  }};
  font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
`;
const Tr = styled.tr`
  background-color: ${({ type, theme }) => {
    if (type) return theme.items[type];
    return 'inherit';
  }};
`;

const ListRow = ({ item, order, index, permissionContext }) => {
  const [lost, setLost] = useState(null);
  const [toCorrect, setToCorrect] = useState(null);

  useEffect(() => {
    checkItemStatus('elementLost', item, setLost);
    checkItemStatus('elementToCorrect', item, setToCorrect);
  }, [item, order]);

  const checkItemType = () => {
    if (lost) return 'lost';
    if (toCorrect) return 'toCorrect';

    return null;
  };

  return (
    <Tr type={checkItemType()}>
      <th>
        {item.blowMaterial ? <FontAwesomeIcon icon={faCheck} /> : ''}
      </th>

      <td>{index + 1}</td>
      <td>{firstLetterToUpperCase(item.type)}</td>
      <Td bold>{item.length}</Td>
      <Td bold>{item.width}</Td>

      <td>{item.thickness}</td>
      <td>{item.quantity}</td>
      <Td primary>
        {item.paintRight ? <FontAwesomeIcon icon={faCheck} /> : ''}
      </Td>
      <Td secondary>
        {item.paintLeft ? <FontAwesomeIcon icon={faCheck} /> : ''}
      </Td>

      <td>{item.comments}</td>
      <td>
        {item.length
          ? ((item.length * item.quantity) / 1000).toFixed(3)
          : ''}
      </td>
      {permissionContext !== 'user' && (
        <>
          <td style={{ backgroundColor: 'white' }}>{lost}</td>
          <td style={{ backgroundColor: 'white' }}>{toCorrect}</td>
        </>
      )}
    </Tr>
  );
};

export default withContext(ListRow);

const checkItemStatus = (key, item, setList) => {
  if (item[key] && item[key].position) {
    const { position, quantity } = item[key];
    setList(
      <small
        style={{ cursor: 'default' }}
        title={`${position} - ${quantity}szt`}
        className={position.toLowerCase()}
      >
        {firstLetterToUpperCase(position.slice(0, 3))}_{quantity}
      </small>,
    );
  } else setList(null);
};
