import React, { FC } from 'react';
import { IOrder } from 'services/interfaces/orders/Orders.interface';

interface IProps {
  order: IOrder;
}

const OrderNameCell: FC<IProps> = ({ order }) => {
  if (!order.name) return <td></td>;
  return (
    <td title={order.name}>
      {order.name.length > 35
        ? `${order.name.slice(0, 35)}...`
        : order.name}
    </td>
  );
};

export default OrderNameCell;
