import React from 'react';
import useUser from 'hooks/useUser';
import { getOrderPrice } from 'services/utils/orders';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useAlert } from 'react-alert';

// FIXME to tsx
const Price = ({ order }) => {
  const alert = useAlert();
  const { isSuperAdmin } = useUser();
  const priceString = getOrderPrice(order).toString().split('.').shift();

  const showOnCopyMessage = () => {
    if (order) {
      return alert.success(`Skopiowano: ${priceString}`);
    }
    return null;
  };
  if (isSuperAdmin())
    return (
      <CopyToClipboard
        text={priceString}
        onCopy={() => showOnCopyMessage()}
        data-isnotclickable="1"
      >
        <td data-isnotclickable="1" title="Kopiuj cenę">
          {getOrderPrice(order, true)}{' '}
          <small>
            {order.advancePayment ? `(+${order.advancePayment})` : null}
          </small>
        </td>
      </CopyToClipboard>
    );

  return (
    <td data-isnotclickable="1">
      {getOrderPrice(order, true)}{' '}
      <small>
        {order.advancePayment ? `(+${order.advancePayment})` : null}
      </small>
    </td>
  );
};

export default Price;
