import React from 'react';
import { useAlert } from 'react-alert';
import { useDispatch } from 'react-redux';
import { changeCuttingPackageStatus } from 'services/apiRequests/cutting/update';
import { setSpinner } from 'services/store/actions/view';
import { cuttingPackageStatus } from 'services/utils/const';
import Modal from 'PageTemplates/ModalTemplate';

const ChangeEmployeeStatusModal = ({ cuttingPackage, closeModal }) => {
  const dispatch = useDispatch();
  const alert = useAlert();

  const handleSubmit = async () => {
    dispatch(setSpinner(true));
    await changeCuttingPackageStatus(
      cuttingPackage._id,
      cuttingPackage.status === cuttingPackageStatus.IN_PROGRESS
        ? cuttingPackageStatus.READY
        : cuttingPackageStatus.IN_PROGRESS,
      () => {
        dispatch(setSpinner(false));
        alert.success('Status zmieniony');
        window.location.reload();
        closeModal();
      },
      () => {
        dispatch(setSpinner(false));
        alert.error('Błąd!');
      },
    );
  };
  return (
    <Modal
      title={'Zmiana statusu'}
      closeModal={closeModal}
      onOk={handleSubmit}
      backdrop="static"
    >
      {cuttingPackage.status === cuttingPackageStatus.IN_PROGRESS
        ? 'Chcesz zakończyć cięcie?'
        : 'Chcesz rozpocząć cięcie?'}
    </Modal>
  );
};

export default ChangeEmployeeStatusModal;
