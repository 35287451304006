import React, { useEffect, useState } from 'react';
import PageTemplate from 'PageTemplates/PageTemplate';
import { PageHeader } from 'components/header';
import { Button, Spinner } from 'react-bootstrap';
import { getProductionOrders } from 'services/store/actions/orders';
import { useDispatch, useSelector } from 'react-redux';
import OrdersList from 'components/ordersList/static';
import { setSpinner } from 'services/store/actions/view';
import { useAlert } from 'react-alert';
import { getAllMessages } from 'services/store/actions/messages';
import { isUnreadedMessageInArray } from 'services/utils/messages';
import styled from 'styled-components';
import { BlinkingMessageAnimation } from 'theme/animations';
import Messages from './components/Messages';
import Filters from './components/Filters';
import ProductionListGenerate from './components/ProductionList';

const MessageButton = styled.button`
  padding: 6px 12px;
  border: 1px solid ${({ theme }) => theme.blowDark};
  border-radius: 5px;
  animation: ${({ ismessage }) =>
      ismessage ? BlinkingMessageAnimation : 'none'}
    2s infinite;
`;

const initModals = {
  messages: false,
  generateList: false,
};

const Production = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const user = useSelector((state) => state.auth.user);
  const orders = useSelector((state) => state.orders.productionOrders);
  const timeOnPosition = useSelector(
    (state) => state.orders.timeOnPosition,
  );
  const messages = useSelector((state) => state.messages.messages);
  const filters = useSelector((state) => state.orders.filters.production);

  const [isLoading, setIsLoading] = useState(false);
  const [List, setList] = useState([]);
  const [modals, setModals] = useState(initModals);

  const [isUnreadedMessage, setIsUnreadedMessage] = useState(false);

  const getData = () => {
    dispatch(
      getAllMessages(
        () => {},
        () => {
          alert.error('Błąd pobierania wiadomości');
        },
      ),
    );
    dispatch(
      getProductionOrders(
        () => {
          dispatch(setSpinner(false));
          setIsLoading(false);
        },
        () => {
          dispatch(setSpinner(false));
          setIsLoading(false);
          alert.error('Błąd pobierania zamówień');
        },
      ),
    );
  };

  useEffect(() => {
    if (!orders) {
      dispatch(setSpinner(true));
      getData();
    } else {
      setIsLoading(true);
      getData();
    }

    const interval = setInterval(() => {
      setIsLoading(true);
      getData();
    }, 60000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (user._id && messages) {
      const isNewMessage = isUnreadedMessageInArray(user, messages);
      setIsUnreadedMessage(isNewMessage);
    }
  }, [user, messages]);

  useEffect(() => {
    let filteredOrders = orders;
    if (orders) {
      if (filters.customer)
        filteredOrders = filteredOrders.filter(
          (item) => item.user._id === filters.customer,
        );

      if (filters.color)
        filteredOrders = filteredOrders.filter((item) =>
          item.color.includes(filters.color),
        );

      if (filters.status)
        filteredOrders = filteredOrders.filter((item) =>
          item.status.includes(filters.status),
        );

      if (filters.paintType)
        filteredOrders = filteredOrders.filter(
          (item) => item.paintType === filters.paintType,
        );

      if (filters.name)
        filteredOrders = filteredOrders.filter((item) =>
          item.name.includes(filters.name),
        );
    }
    setList(filteredOrders);
  }, [orders, filters]);

  const handleModal = (key) => setModals({ ...modals, [key]: true });
  const closeModal = () => setModals(initModals);

  return (
    <PageTemplate>
      <PageHeader
        title="Produkcja"
        extra={[
          <>
            {isLoading && <Spinner animation="border" variant="success" />}
          </>,
          <MessageButton
            ismessage={isUnreadedMessage}
            variant="outline-dark"
            key={1}
            onClick={() => handleModal('messages')}
          >
            Wiadomości
          </MessageButton>,
          <Button key={2} onClick={() => handleModal('generateList')}>
            Stwórz listę
          </Button>,
        ]}
      />
      <Filters />
      <br />
      {timeOnPosition &&
      filters.status &&
      timeOnPosition[filters.status.toLowerCase()] ? (
        <small>
          Przewidywany czas oczekiwania na lakierowanie:{' '}
          <strong>
            {timeOnPosition[filters.status.toLowerCase()].toFixed(2)}
          </strong>{' '}
          dni
        </small>
      ) : null}
      <OrdersList
        orders={List}
        listName="production"
        edited
        status
        lastOperation
        legend
        summary
      />
      {/* MODALS */}
      {modals.messages && <Messages closeModal={closeModal} />}
      {modals.generateList && (
        <ProductionListGenerate orders={List} closeModal={closeModal} />
      )}
    </PageTemplate>
  );
};

export default Production;
