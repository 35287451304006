import { employeePositions as positions } from 'services/utils/const';

export const formatTimetablesData = (state, timetables) => {
  const obj = {};

  positions.forEach((position) => {
    let arr = [];

    timetables.forEach((timetable) => {
      if (timetable.position === position) arr.push(timetable);
    });

    arr = arr.sort((a, b) => {
      const A = new Date(a.date);
      const B = new Date(b.date);
      return A - B;
    });

    obj[position] = arr;
    return;
  });

  return { ...state, timetables: obj };
};
