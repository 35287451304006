/* eslint-disable no-unused-expressions */
import React, { useState } from 'react';
import { employeePositions } from 'services/utils/const';
import { Form, Button } from 'react-bootstrap';
import { firstLetterToUpperCase } from 'services/utils/string';
import FlexRow from 'PageTemplates/FlexRowTemplate';
import { useDispatch, useSelector } from 'react-redux';
import { useAlert } from 'react-alert';
import { setSpinner } from 'services/store/actions/view';
import { sendMessage } from 'services/apiRequests/messages/post';
import {
  getAllMessages,
  getMessagesForPosition,
} from 'services/store/actions/messages';
import withContext from 'hoc/withContext';

const NewMessage = ({ permissionContext, goBack }) => {
  const dispatch = useDispatch();
  const alert = useAlert();

  const position = useSelector((state) => state.employee.activePosition);

  const [message, setMessage] = useState('');
  const [positions, setPositions] = useState([]);

  const handleChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      if (!positions.includes(value))
        setPositions(positions.concat(value));
    } else if (positions.includes(value)) {
      const newPositions = positions.filter((item) => item !== value);
      setPositions(newPositions);
    }
  };

  const handleSendMessage = async () => {
    dispatch(setSpinner(true));
    await sendMessage(
      { message, positions },
      () => {
        permissionContext === 'admin'
          ? dispatch(
              getAllMessages(
                () => {
                  dispatch(setSpinner(false));
                },
                () => {
                  dispatch(setSpinner(false));
                  alert.error('Błąd!');
                },
              ),
            )
          : dispatch(
              getMessagesForPosition(
                position,
                () => {
                  dispatch(setSpinner(false));
                  goBack();
                },
                () => {
                  dispatch(setSpinner(false));
                  alert.error('Błąd!');
                },
              ),
            );
      },
      () => {
        dispatch(setSpinner(false));
        alert.error('Błąd!');
      },
    );
  };

  return (
    <div>
      <Form.Control
        as="textarea"
        placeholder="Treść nowej wiadomości..."
        style={{ height: '100px', marginBottom: 10 }}
        value={message}
        onChange={(e) => {
          setMessage(e.target.value);
        }}
      />
      <FlexRow justify="space-between">
        <div>
          <strong>Do kogo?</strong>
          <Form.Group>
            <Form.Check
              style={{ marginBottom: 5 }}
              id="Biuro"
              key="Biuro"
              value="Biuro"
              type="checkbox"
              label="Biuro"
              name="Position"
              onChange={handleChange}
            />
            {employeePositions.map((item) => {
              return (
                <Form.Check
                  style={{ marginBottom: 5 }}
                  id={item}
                  key={item}
                  value={item}
                  type="checkbox"
                  label={firstLetterToUpperCase(item)}
                  name="Position"
                  onChange={handleChange}
                />
              );
            })}
          </Form.Group>
        </div>
        <div>
          <Button onClick={handleSendMessage}>Wyślij</Button>
        </div>
      </FlexRow>
    </div>
  );
};

export default withContext(NewMessage);
