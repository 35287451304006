import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

const StyledSelect = styled.select`
  height: 28px;
  width: 100%;
  border-radius: 3px;
  border: 1px solid lightgray;
  &:focus {
    background-color: ${({ theme }) => theme.blowPrimaryLight};
  }
`;
const RightSideEdge = ({
  handleChange,
  handleFocus,
  handleBlur,
  value,
  name,
  tabIndex,
}) => {
  const order = useSelector((state) => state.newOrder);
  const { handles, isChamfering, paintType } = order;

  const R = paintType === 'połysk' ? 'r2' : 'r1';

  return (
    <StyledSelect
      tabIndex={tabIndex}
      name={name}
      value={value}
      onChange={handleChange}
      onFocus={handleFocus}
      onBlur={handleBlur}
      required
    >
      <option value={R}>{R.toUpperCase()}</option>
      {handles.map((handle) => (
        <option value={handle} key={handle}>
          {handle.toUpperCase()}
        </option>
      ))}
      {isChamfering && <option value="gierunek">Gierunek</option>}
      <option value="-">-</option>
    </StyledSelect>
  );
};

export default RightSideEdge;
