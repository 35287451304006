/* eslint-disable no-shadow */
import React, { useState } from 'react';
import ModalContainer from 'PageTemplates/ModalTemplate';
import { useDispatch } from 'react-redux';
import { setSpinner } from 'services/store/actions/view';
import { getOrder } from 'services/store/actions/orders';
import { useAlert } from 'react-alert';
import { dateToString } from 'services/utils/date';
import { editOrderPlan } from 'services/apiRequests/timetable/update';
import DatePicker from 'components/DatePicker';

const TransportDate = ({ order, closeModal }) => {
  const maxDate = new Date().getTime() + 2246400000;
  const dispatch = useDispatch();
  const alert = useAlert();

  const [date, setDate] = useState(order.transportDate || new Date());

  const handleSubmit = async () => {
    dispatch(setSpinner(true));
    await editOrderPlan(
      { schedule: [{ date, position: 'transport' }] },
      order._id,
      () => {
        dispatch(
          getOrder(
            order._id,
            () => {
              dispatch(setSpinner(false));
              closeModal();
            },
            () => {
              dispatch(setSpinner(false));
              alert.error('Błąd!');
            },
          ),
        );
      },
      () => {
        dispatch(setSpinner(false));
        alert.error('Błąd!');
      },
    );
  };
  return (
    <ModalContainer
      title="Data transportu"
      closeModal={closeModal}
      onOk={handleSubmit}
    >
      <div>
        Aktualna data transportu zamówienia to:{' '}
        <span style={{ fontWeight: 'bold', fontSize: 18 }}>
          {order.transportDate ? dateToString(order.transportDate) : '-'}
        </span>
        <br />
        Chcesz ją zmienić na:{' '}
        <DatePicker
          value={date}
          maxDate={maxDate}
          onChange={(date) => setDate(date)}
        />
      </div>
    </ModalContainer>
  );
};

export default TransportDate;
