import { useMutation } from '@tanstack/react-query';
import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import dayjs from 'dayjs';
import { isNumber } from 'lodash';
import React, { FC, useState } from 'react';
import { useAlert } from 'react-alert';
import { Form, Stack } from 'react-bootstrap';
import { cashRegisterApi } from 'services/apiRequests/cash-register.api';
import { _onlyNumbersRegExp } from 'services/consts/regexp';
import { CashRegisterStoryType } from 'services/enums/CashRegister.enum';
import {
  ICashRegister,
  ICashRegisterStory,
} from 'services/interfaces/cash-register/CashRegister.interface';
import { ISettings } from 'services/interfaces/settings/Settings.interface';

interface IProps {
  cashRegister: ICashRegister;
  closeModal: () => void;
  refetchCashRegisterData: () => Promise<any>;
  settings: ISettings;
}

const DepositMoneyModal: FC<IProps> = ({
  cashRegister,
  closeModal,
  refetchCashRegisterData,
  settings,
}) => {
  const alert = useAlert();
  const [value, setValue] = useState<number>(0);
  const [info, setInfo] = useState<string>('');
  const [description, setDescription] = useState<string>('');

  const { mutate: handleDepositMoney, isLoading } = useMutation(
    async () => {
      const cashRegisterValue = value + cashRegister.value;
      if (isNumber(value) && isNumber(cashRegisterValue)) {
        const storyItem: Partial<ICashRegisterStory> = {
          type: CashRegisterStoryType.IN,
          value,
          description,
          info,
          cashRegisterValue,
          date: dayjs().toDate(),
        };
        await cashRegisterApi.depositMoney(value, storyItem);
      }
    },
    {
      onSuccess: async () => {
        await refetchCashRegisterData();
        alert.success(`Zasilono kasę kwotą: ${value}zł`);
        closeModal();
      },
      onError: () => {
        alert.error('Błąd!');
      },
    },
  );

  const handleValue = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    if (!value.match(_onlyNumbersRegExp)) return;
    setValue(+value);
  };

  return (
    <ModalWrapper
      title="Zasilenie kasy"
      closeModal={closeModal}
      onOk={handleDepositMoney}
      okButtonDisabled={!value || isLoading || !description}
      isLoading={isLoading}
    >
      {' '}
      <Stack gap={3} className="mb-2">
        <Stack gap={3} direction="horizontal">
          <div>Podaj wartość: </div>
          <Form.Control
            type="text"
            placeholder="Kwota"
            value={value}
            onChange={handleValue}
            className="w-25"
          />
        </Stack>
        <Form.Control
          as="select"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          disabled={!settings}
        >
          <option value={''}>Typ wpłaty</option>
          {settings.cashRegister.depositDesc.map((desc: string) => (
            <option value={desc} key={desc}>
              {desc}
            </option>
          ))}
        </Form.Control>
        <Form.Control
          type="text"
          placeholder="Dodatkowe informacje"
          value={info}
          onChange={(e) => setInfo(e.target.value)}
        />
        <small>
          <div>Aktualna wartość kasy: {cashRegister.value}zł</div>
          <div>
            Wartość kasy po zasileniu: {cashRegister.value + value}zł
          </div>
        </small>
      </Stack>
    </ModalWrapper>
  );
};

export default DepositMoneyModal;
