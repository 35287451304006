import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileUpload,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from 'react-redux';
import {
  addNewOrderCustomItemImage,
  removeNewOrderCustomItemImage,
} from 'services/store/actions/newOrder';

const StyledInputFile = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;
const StyledLabelFile = styled.label`
  cursor: pointer;
  margin: 0;
`;

const CustomItemImageCell = ({ index, item }) => {
  const dispatch = useDispatch();

  return (
    <div>
      {!item.image.file && !item.image.path ? (
        <>
          <StyledInputFile
            type="file"
            accept="image/*,application/pdf"
            id={`custom_file${index}`}
            onChange={(e) => {
              console.log('CUSTOM');
              return dispatch(
                addNewOrderCustomItemImage(index, e.target.files[0]),
              );
            }}
          />
          <StyledLabelFile htmlFor={`custom_file${index}`}>
            <FontAwesomeIcon icon={faFileUpload} title="Dodaj rysunek" />
          </StyledLabelFile>
        </>
      ) : (
        <FontAwesomeIcon
          icon={faTrashAlt}
          style={{ color: '#c01212', cursor: 'pointer' }}
          title="Usuń rysunek"
          onClick={() => dispatch(removeNewOrderCustomItemImage(index))}
        />
      )}
    </div>
  );
};

export default CustomItemImageCell;
