import React from 'react';
import { StyledH1 } from 'components/header';
import styled from 'styled-components';
import withContext from 'hoc/withContext';
import FlexRow from 'PageTemplates/FlexRowTemplate';
import { Table, OverlayTrigger, Button } from 'react-bootstrap';
import { dateToString } from 'services/utils/date';
import { firstLetterToUpperCase } from 'services/utils/string';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFill, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router';
import { setOrder } from 'services/store/actions/orders';
import { setSpinner } from 'services/store/actions/view';
import { useAlert } from 'react-alert';
import { useDispatch } from 'react-redux';
import { updateOrder } from 'services/apiRequests/orders/update';
import moment from 'moment';

const StyledSpan = styled.span`
  font-size: 20px;
`;
const StyledH5 = styled.h5`
  color: ${({ theme }) => theme.blowInfo};
  letter-spacing: 2px;
  margin-bottom: 10px;
`;
const StyledTable = styled(Table)`
  max-width: 500px;
  margin: 10px;
  thead {
    tr {
      th,
      td {
        text-align: left;
      }
    }
  }
  @media (max-width: 600px) {
    max-width: 90%;
    margin: 10px auto;
  }
`;
const Icon = styled(FontAwesomeIcon)`
  cursor: pointer;
  margin-left: 10px;
  opacity: 0.5;
  &:hover {
    opacity: 1;
  }
`;

const Data = ({ order, permissionContext }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const alert = useAlert();
  const orderFinishDate = new Date(order.finishDate);

  const setBlackBase = async () => {
    dispatch(setSpinner(true));
    await updateOrder(
      'blackbase',
      order._id,
      { isBlackBase: !order.isBlackBase },
      (order) => {
        dispatch(setOrder(order));
        dispatch(setSpinner(false));
      },
      () => {
        dispatch(setSpinner(false));
        alert.error('Błąd!');
      },
    );
  };

  return (
    <div>
      <Header order={order} />
      {order.motherOrder && (
        <Button
          size="sm"
          style={{ padding: '3px 5px' }}
          variant="outline-secondary"
          onClick={() => {
            window.location.pathname = `order/${order.motherOrder}`;
          }}
        >
          Przejdź do zamówienia głównego
        </Button>
      )}
      <FlexRow justify="center" wrap>
        <StyledTable striped bordered hover size="sm">
          <thead>
            {order.veneer ? (
              <Row title="Fornir" value={order.veneer} />
            ) : null}
            <Row
              title="Kolor"
              value={`${order.color.toUpperCase()}${
                order.coloredWater ? ' (WB)' : ''
              }`}
              valueStyle={
                permissionContext === 'admin' && order.isBlackBase
                  ? { backgroundColor: 'black', color: 'white' }
                  : {}
              }
              extra={
                <div style={{ position: 'relative' }}>
                  {order.colorHex && (
                    <OverlayTrigger
                      overlay={() => (
                        <div
                          style={{
                            position: 'absolute',
                            top: 0,
                            width: 400,
                            height: 200,
                            borderRadius: 5,
                            backgroundColor: order.colorHex,
                          }}
                        />
                      )}
                    >
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        title="Podgląd koloru"
                      />
                    </OverlayTrigger>
                  )}
                  {permissionContext === 'admin' &&
                    !location.pathname.includes('summary') && (
                      <Icon
                        onClick={() => setBlackBase()}
                        icon={faFill}
                        title="Dodaj do bazy kolorów z czarnym podkładem"
                      />
                    )}
                </div>
              }
            />
            <Row
              title="Matowość"
              value={`${order.paintType}${
                order.transparent ? ' (+B)' : ''
              }`}
              valueStyle={
                order.paintType === 'połysk'
                  ? { backgroundColor: '#0080FF' }
                  : {}
              }
            />
            <Row title="Lakierowanie" value={order.paintStyle} />
            <Row
              title="MDF"
              value={order.isWaterproofMdf ? 'Wodoodporny' : 'Zwykły'}
              valueStyle={
                order.isWaterproofMdf ? { backgroundColor: '#62bd6a' } : {}
              }
            />
          </thead>
        </StyledTable>

        {order.millings.length ||
        order.glassCase ||
        order.handles.length ||
        order.pilaster ||
        order.crown ? (
          <StyledTable striped bordered hover size="sm">
            <thead>
              {order.millings.length ? (
                <Row
                  title="Frezowanie"
                  value={
                    <div>
                      {order.millings.map((item) => (
                        <span key={item}>{item.toUpperCase()}, </span>
                      ))}
                    </div>
                  }
                />
              ) : null}
              {order.glassCase ? (
                <Row title="Witryna" value={order.glassCase} />
              ) : null}
              {order.handles.length ? (
                <Row
                  title="Uchwyty"
                  value={
                    <div>
                      {order.handles.map((item) => (
                        <span key={item}>{item.toUpperCase()}, </span>
                      ))}
                    </div>
                  }
                />
              ) : null}
              {order.pilaster ? (
                <Row
                  title="Pilaster"
                  value={order.pilaster.toUpperCase()}
                />
              ) : null}
              {order.crown ? (
                <Row title="Korona" value={order.crown.toUpperCase()} />
              ) : null}
            </thead>
          </StyledTable>
        ) : null}

        <StyledTable striped bordered hover size="sm">
          <thead>
            {order.date &&
              (permissionContext === 'admin' ||
                permissionContext === 'user') && (
                <Row
                  title="Data złożenia"
                  value={dateToString(order.date)}
                />
              )}
            {order.dateSendToCNC && permissionContext !== 'user' && (
              <Row
                title="Data wysłania do CNC"
                value={dateToString(order.dateSendToCNC)}
                valueStyle={
                  order.dateSendToCNC &&
                  !order.dateBackFromCNC &&
                  moment().diff(moment(order.dateSendToCNC), 'days') > 7
                    ? { color: 'red', fontWeight: 'bold' }
                    : {}
                }
              />
            )}
            {order.dateSendToCNC && permissionContext !== 'user' && (
              <Row
                title="Data odbioru z CNC"
                value={
                  order.dateBackFromCNC
                    ? dateToString(order.dateBackFromCNC)
                    : '-'
                }
              />
            )}
            {order.finishDate && (
              <Row
                title="Przewidywana data realizacji"
                value={dateToString(orderFinishDate)}
              />
            )}
            {order.pickUpDate && (
              <Row
                title="Data odbioru"
                value={dateToString(order.pickUpDate)}
              />
            )}
            {order.transportDate && (
              <Row
                title="Data transportu"
                value={dateToString(order.transportDate)}
              />
            )}
            {order.status && (
              <Row
                title="Status"
                valueStyle={{ fontWeight: 'bold', textWrap: 'wrap' }}
                value={order.status.map((item) => (
                  <span
                    className={`${item}`}
                    style={{ margin: '0 1px' }}
                    key={item}
                  >
                    {firstLetterToUpperCase(item)}
                  </span>
                ))}
              />
            )}
          </thead>
        </StyledTable>

        <StyledTable striped bordered hover size="sm">
          <thead>
            <Row
              title="Typ zamówienia"
              value={
                order.orderType
                  ? firstLetterToUpperCase(order.orderType)
                  : 'Nowe zamówienie'
              }
            />
            {order.comments ? (
              <Row title="Uwagi" value={order.comments} />
            ) : null}
          </thead>
        </StyledTable>
      </FlexRow>
      {/* MODALS */}
    </div>
  );
};

export default withContext(Data);

const Header = ({ order }) => (
  <>
    <StyledH1>
      {order.number.toString().includes('.')
        ? order.number.toUpperCase()
        : order.number}{' '}
      {order.name && <StyledSpan>{` - ${order.name}`}</StyledSpan>}
    </StyledH1>
    {order.veneer && <StyledH5>Zamówienie fornirowane</StyledH5>}
  </>
);

const Row = ({ title, value, valueStyle = {}, hint, extra = null }) => (
  <tr>
    <th>{`${title}:`}</th>
    <td title={hint || null}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          ...valueStyle,
        }}
      >
        {typeof value === 'string' ? (
          firstLetterToUpperCase(value)
        ) : (
          <>{value}</>
        )}{' '}
        <div>{extra}</div>
      </div>
    </td>
  </tr>
);
