import React, { useEffect } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { dateToString } from 'services/utils/date';
import { sortMethod } from 'services/utils/sortMethods';
import { useDispatch, useSelector } from 'react-redux';
import { isMessageUnreaded } from 'services/utils/messages';

import withContext from 'hoc/withContext';
import { setMessageReaded } from 'services/apiRequests/messages/update';
import { getCustomerMessages } from 'services/store/actions/auth';
import { domain } from 'services/utils/const';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${({ isOwn }) => (isOwn ? 'flex-end' : 'flex-start')};
`;
const MessageContainer = styled.div`
  border: 1px solid grey;
  padding: 2px;
  border-radius: 5px;
  margin-bottom: 5px;
  width: 90%;
  background-color: ${({ isUnreaded }) =>
    isUnreaded ? 'rgba(0,0,0,0.1)' : 'white'};
`;
const Top = styled.div`
  text-align: ${({ author }) => (author ? 'right' : 'left')};
  font-size: 10px;
  margin-bottom: 2px;
`;
const Info = styled.span`
  font-weight: bold;
`;

const MessagesList = ({ messages, goBack }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    const unreaded = messages
      .filter((msg) => !msg.readedBy.includes(user._id))
      .map((msg) => msg._id);
    Promise.all(unreaded.map((id) => setMessageReaded(id, () => {}))).then(
      () => dispatch(getCustomerMessages(user._id)),
    );
  }, []);

  return (
    <div>
      <FontAwesomeIcon
        icon={faArrowLeft}
        onClick={goBack}
        style={{ cursor: 'pointer', fontSize: 22 }}
      />
      <div style={{ maxHeight: 300, overflowY: 'auto' }}>
        {messages &&
          messages
            .sort((a, b) =>
              sortMethod[sortMethod.getSortMethod('addDate')](
                a,
                b,
                'addDate',
                'descend',
              ),
            )
            .map((msg) => (
              <MessageItem key={msg._id} message={msg} user={user} />
            ))}
      </div>
    </div>
  );
};

export default withContext(MessagesList);

const MessageItem = ({ message, user }) => {
  const isOwn = user._id === message.authorId;
  const isUnreaded = isMessageUnreaded(user, message);
  console.log(message);
  return (
    <Wrapper isOwn={isOwn}>
      <MessageContainer isUnreaded={isUnreaded}>
        <Top author={isOwn}>
          <div>
            <span>{dateToString(message.addDate)}</span>
          </div>
          <Info>{message.author}</Info>
        </Top>
        <div style={{ textAlign: !isOwn ? 'left' : 'right' }}>
          {message.message}{' '}
          {message.imgPath && (
            <div>
              <img
                src={`${domain}/${message.imgPath}`}
                onClick={() =>
                  window.open(
                    `${domain}/${message.imgPath}`,
                    '_blank',
                    'noopener,noreferrer',
                  )
                }
                style={{
                  height: 50,
                  width: 50,
                  cursor: `pointer`,
                }}
              />
            </div>
          )}
        </div>
      </MessageContainer>
    </Wrapper>
  );
};
