import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import Fade from 'react-reveal/Fade';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import {
  handleNewOrderCustomItemInput,
  removeNewOrderCustomItem,
} from 'services/store/actions/newOrder';
import CustomItemType from './Cells/CustomItemType';
import CustomItemThickness from './Cells/CustomItemThickness';
import CustomItemImageCell from './Cells/CustomItemImageCell';

const Input = styled.input`
  height: 28px;
  width: 100%;
  border-radius: 3px;
  border: 1px solid lightgray;
  &:focus {
    background-color: ${({ theme, focus }) =>
      focus ? theme.blowPrimaryLight : null};
  }
`;

const Td = styled.td`
  background-color: ${({ primary, secondary, theme }) => {
    if (primary) return theme.blowGreenLight;
    if (secondary) return theme.blowWarningLight;
  }};
`;

const OrderCustomItemsRow = ({ item, index }) => {
  const dispatch = useDispatch();

  const handleItem = (value, field, isNumber = false) => {
    dispatch(handleNewOrderCustomItemInput(index, field, value, isNumber));
  };

  return (
    <>
      <Fade bottom>
        <tr>
          <td>
            <input
              type="checkbox"
              name="blowMaterial"
              checked={item.blowMaterial}
              onChange={(e) => {
                handleItem(e.target.checked, 'blowMaterial');
              }}
              disabled={item.type === 'korona'}
            />
          </td>
          <td>{index + 1}</td>
          <td>
            <CustomItemType
              handleChange={(e) => {
                handleItem(e.target.value, 'type');
              }}
              value={item.type}
            />
          </td>
          <td>
            <Input
              autoComplete="off"
              name="length"
              value={item.length}
              onChange={(e) => {
                handleItem(e.target.value, 'length', true);
              }}
              focus
            />
          </td>
          <td>
            <Input
              autoComplete="off"
              name="width"
              value={item.width}
              onChange={(e) => {
                handleItem(e.target.value, 'width');
              }}
              focus
            />
          </td>
          <td>
            <CustomItemThickness
              item={item}
              handleChange={(e) => {
                handleItem(e.target.value, 'thickness');
              }}
            />
          </td>
          <td>
            <Input
              autoComplete="off"
              name="quantity"
              value={item.quantity}
              onChange={(e) => {
                handleItem(e.target.value, 'quantity', true);
              }}
              focus
            />
          </td>
          <Td primary>
            <input
              type="checkbox"
              name="paintRight"
              checked={item.paintRight}
              onChange={(e) => handleItem(e.target.checked, 'paintRight')}
            />
          </Td>
          <Td secondary>
            <input
              type="checkbox"
              name="paintLeft"
              checked={item.paintLeft}
              onChange={(e) => handleItem(e.target.checked, 'paintLeft')}
            />
          </Td>
          <td>
            <CustomItemImageCell item={item} index={index} />
          </td>
          <td>
            <Input
              autoComplete="off"
              name="comments"
              value={item.comments}
              onChange={(e) => {
                handleItem(e.target.value, 'comments');
              }}
              focus
            />
          </td>
          <td
            onClick={() => dispatch(removeNewOrderCustomItem(index))}
            style={{ color: '#c01212', cursor: 'pointer' }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </td>
        </tr>
      </Fade>
    </>
  );
};

export default OrderCustomItemsRow;
