import Axios from 'axios';

export const userPasswordChange = async (passwords, onEnd, onErr) => {
  try {
    await Axios.put(`/api/users/password`, passwords);

    onEnd();
  } catch (error) {
    console.log(error.response.data);
    if (error.response && error.response.status === 401)
      return onErr(error.response.data.message);
    if (error.response && error.response.status === 404)
      return onErr(error.response.data.message);
    return onErr();
  }
};

export const passwordRemind = async (email, onEnd, onErr) => {
  try {
    await Axios.put(`/api/users/passwordremind`, { email });

    onEnd();
  } catch (error) {
    console.log(error);
    return onErr();
  }
};

export const changePermission = async (
  userId,
  userPermission,
  onEnd,
  onErr,
) => {
  try {
    const res = await Axios.put(`/api/users/permission/${userId}`, {
      userPermission,
    });

    onEnd(res.data.user);
  } catch (error) {
    console.log(error, error.response);
    return onErr();
  }
};
