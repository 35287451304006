import update from 'immutability-helper';
import { OrderType } from 'services/enums/Orders.enum';

export const setOrderField = (state, field, value) =>
  update(state, { [field]: { $set: value } });

export const setOrderType = (state, orderType) => {
  if (orderType === 'Lista elementów') {
    const items = state.items.map((item) => ({
      ...item,
      blowMaterial: true,
    }));
    return { ...state, items, orderType };
  }
  if (orderType === 'Materiał klienta') {
    const items = state.items.map((item) => ({
      ...item,
      blowMaterial: false,
    }));
    return { ...state, items, orderType };
  }
  return { ...state, orderType };
};
