import React from 'react';
import styled from 'styled-components';
import AuthTemplate from 'PageTemplates/AuthTemplate';
import { Route, Switch } from 'react-router';
import Login from './Login';
import Register from './Register';
import PasswordRemind from './PasswordRemind';
import LandingPage from './Landing';

const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 600px) {
    flex-direction: column;
    justify-content: 'center';
  }
`;
const StyledWrapper = styled.div`
  width: 50%;
  height: 100vh;
  @media (max-width: 600px) {
    width: 100%;
    order: ${({ order }) => order};
  }
`;

const Sign = () => {
  return (
    <AuthTemplate>
      <StyledContainer>
        <StyledWrapper order={2}>
          <LandingPage />
        </StyledWrapper>
        <StyledWrapper order={1}>
          <Switch>
            <Route path="/login" component={Login} />
            <Route path="/register" component={Register} />
            <Route path="/passwordremind" component={PasswordRemind} />
          </Switch>
        </StyledWrapper>
      </StyledContainer>
    </AuthTemplate>
  );
};

export default Sign;
