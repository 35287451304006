/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
/* eslint-disable no-use-before-define */
export const getCustomItemsPrice = (order, prices) => {
  let customItemsPrice = 0;

  const customItems = order.customItems.map((item, index) => {
    console.log(`ELEMENT: ${index + 1}`);
    let itemPrice = 0;
    const setItemPrice = (value) => (itemPrice = value);

    item = handleItem(order, item, setItemPrice, prices);

    customItemsPrice += itemPrice || 0;
    return item;
  });

  return {
    customItems,
    customItemsPrice,
  };
};

const handleItem = (order, item, setItemPrice, prices) => {
  const { type, paintRight, paintLeft, blowMaterial, quantity } = item;
  const length = (item.length / 1000) * quantity;

  if (order.orderType.toLowerCase() === 'reklamacja (wina blow)') {
    setItemPrice(0);
    return item;
  }

  if (type === 'pilaster') {
    if (blowMaterial) {
      if ((paintRight && !paintLeft) || (!paintRight && paintLeft)) {
        const price =
          length * prices.paint.companyMaterial.pilasters.oneSide;
        setItemPrice(price);
        console.log(`Pilaster blow jednostronny ${length}m.b.: ${price}`);
        return item;
      }
      if (paintRight && paintLeft) {
        const price =
          length * prices.paint.companyMaterial.pilasters.twoSides;
        setItemPrice(price);
        console.log(`Pilaster blow dwustronny ${length}m.b.: ${price}`);
        return item;
      }
    } else {
      if ((paintRight && !paintLeft) || (!paintRight && paintLeft)) {
        const price =
          length * prices.paint.customerMaterial.pilasters.oneSide;
        setItemPrice(price);
        console.log(
          `Pilaster powierzony jednostronny ${length}m.b.: ${price}`,
        );
        return item;
      }
      if (paintRight && paintLeft) {
        const price =
          length * prices.paint.customerMaterial.pilasters.twoSides;
        setItemPrice(price);
        console.log(
          `Pilaster powierzony jednostronny ${length}m.b.: ${price}`,
        );
        return item;
      }
    }
  }
  if (type === 'korona') {
    if ((paintRight && !paintLeft) || (!paintRight && paintLeft)) {
      const price = length * prices.paint.customerMaterial.crowns.oneSide;
      setItemPrice(price);
      console.log(
        `Korona powierzony jednostronny ${length}m.b.: ${price}`,
      );
      return item;
    }
    if (paintRight && paintLeft) {
      const price = length * prices.paint.customerMaterial.crowns.twoSides;
      setItemPrice(price);
      console.log(`Korona powierzony dwustronny ${length}m.b.: ${price}`);
      return item;
    }
  }

  return item;
};
