import { useMutation } from '@tanstack/react-query';
import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import Spinner from 'components/spinner';
import React, { ChangeEvent, FC, useState } from 'react';
import { useAlert } from 'react-alert';
import { Form } from 'react-bootstrap';
import { ordersApi } from 'services/apiRequests/orders.api';
import { IOrder } from 'services/interfaces/orders/Orders.interface';
import { getOrderPrice } from 'services/utils/orders';
import { parseStringToNumber } from 'services/utils/parseStringToNumber';

interface IProps {
  order: IOrder;
  closeModal: () => void;
  afterSubmit?: (order: IOrder) => void;
}

const ChangeOrderPriceModal: FC<IProps> = ({
  order,
  closeModal,
  afterSubmit,
}) => {
  const alert = useAlert();
  const [price, setPrice] = useState<number>(0);

  const handlePrice = (e: ChangeEvent<HTMLInputElement>) => {
    const value = parseStringToNumber(e.target.value);
    setPrice(value);
  };

  const { mutate: changePrice, isLoading } = useMutation(
    async () => {
      return ordersApi.updatePrice(order._id, { price });
    },
    {
      onSuccess: async (data) => {
        alert.success('Cena zaktualizowana');
        if (afterSubmit) afterSubmit(data.data.order);
        closeModal();
      },
      onError: () => {
        alert.error('Błąd!');
      },
    },
  );

  return (
    <ModalWrapper
      title="Cena zamówienia"
      closeModal={closeModal}
      onOk={changePrice}
      okButtonDisabled={!price}
    >
      {isLoading && <Spinner />}
      Aktualna cena lakierowania zamówienia Wynosi:{' '}
      <strong>{order.price ? order.price.toFixed(2) : 0}</strong> zł.
      <br />
      Chcesz ją zmienić na:{' '}
      <Form.Control
        type="text"
        placeholder="kwota"
        style={{ width: '30%', display: 'inline' }}
        value={price}
        onChange={handlePrice}
      />
      <hr />
      {(order.manHours || order.ventilations) && (
        <>
          <div>
            <small>
              Do ceny zostanie dodane: <br />
              <strong>{order.manHours.price.toFixed(2) || 0}</strong>
              zł za <strong>{order.manHours.hours || 0}</strong>h prac
              stolarskich.
              <br />
              <strong>{order.ventilations.price.toFixed(2) || 0}</strong>
              zł za <strong>{order.ventilations.quantity || 0}</strong>szt
              kratek wentylacyjnych.
            </small>
          </div>
          <div>
            Cena zamówienia wyniesie:{' '}
            <strong>{getOrderPrice({ ...order, price }, true)}</strong>
          </div>
        </>
      )}
    </ModalWrapper>
  );
};

export default ChangeOrderPriceModal;
