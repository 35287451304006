import React, { useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import FlexRow from 'PageTemplates/FlexRowTemplate';
import { StyledH3 } from 'components/header';
import { Formik } from 'formik';
import styled from 'styled-components';
import { faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Wrapper = styled.div`
  width: 600px;
`;
const List = styled.div`
  max-height: 600px;
  width: 300px;
  overflow-y: auto;
`;
const RemoveIcon = styled(FontAwesomeIcon)`
  color: red;
  cursor: ${({ disabled }) => (disabled ? 'normal' : 'pointer')};
  opacity: 0.2;
  &:hover {
    opacity: ${({ disabled }) => (disabled ? 0.2 : 1)};
  }
`;

// FIXME REFACTOR CAŁYCH USTAWIEN, model podzielić na części, listy z opisami jako oddzielne schematy
const CashRegister = ({ cashRegister, handleSubmit }) => {
  const [isEdit, setIsEdit] = useState(false);
  const [desc, setDesc] = useState('');
  const [depositDesc, setDepositDesc] = useState('');

  const removeItem = (desc, arr, arrName, setFieldValue) => {
    const newArr = arr.filter((item) => item !== desc);
    setFieldValue(arrName, newArr);
  };
  const addItem = (arr, arrName, setFieldValue, value) => {
    const newArr = arr.concat(value);
    setFieldValue(arrName, newArr);
    setDesc('');
    setDepositDesc('');
  };
  return (
    <div>
      <Formik
        // validationSchema={schema}
        // ONSUBMIT REQUEST
        onSubmit={async (values) =>
          await handleSubmit(
            { cashRegister: { ...values } },
            () => setIsEdit(false),
            'Zaktualizowano opisy wypłat z kasy',
          )
        }
        initialValues={{
          ...cashRegister,
          depositDesc: cashRegister.depositDesc || [],
        }}
        render={(props) => {
          const { values, handleSubmit, setFieldValue } = props;
          return (
            <>
              <Form noValidate onSubmit={handleSubmit}>
                <Wrapper>
                  <div>
                    <StyledH3>Wypłaty z kasy</StyledH3>
                    {values.withdrawalDesc &&
                    values.withdrawalDesc.length ? (
                      <>
                        <List>
                          <ul>
                            {values.withdrawalDesc
                              .sort((a, b) => `${a}`.localeCompare(b))
                              .map((item) => (
                                <li key={item}>
                                  <FlexRow justify="space-between">
                                    {item}{' '}
                                    <RemoveIcon
                                      disabled={!isEdit}
                                      title="Usuń"
                                      icon={faMinusCircle}
                                      onClick={
                                        !isEdit
                                          ? null
                                          : () => {
                                              removeItem(
                                                item,
                                                values.withdrawalDesc,
                                                'withdrawalDesc',
                                                setFieldValue,
                                              );
                                            }
                                      }
                                    />
                                  </FlexRow>
                                </li>
                              ))}
                          </ul>
                        </List>
                      </>
                    ) : null}
                    {isEdit && (
                      <InputGroup style={{ width: 400 }}>
                        <Form.Control
                          type="text"
                          placeholder="Przyczyna"
                          value={desc}
                          onChange={(e) => setDesc(e.target.value)}
                        />
                        <Button
                          variant="outline-primary"
                          onClick={() =>
                            addItem(
                              values.withdrawalDesc,
                              'withdrawalDesc',
                              setFieldValue,
                              desc,
                            )
                          }
                        >
                          Dodaj
                        </Button>
                      </InputGroup>
                    )}
                  </div>
                  <div>
                    <StyledH3>Wpłaty do kasy</StyledH3>
                    {values.depositDesc && values.depositDesc.length ? (
                      <>
                        <List>
                          <ul>
                            {values.depositDesc
                              .sort((a, b) => `${a}`.localeCompare(b))
                              .map((item) => (
                                <li key={item}>
                                  <FlexRow justify="space-between">
                                    {item}{' '}
                                    <RemoveIcon
                                      disabled={!isEdit}
                                      title="Usuń"
                                      icon={faMinusCircle}
                                      onClick={
                                        !isEdit
                                          ? null
                                          : () => {
                                              removeItem(
                                                item,
                                                values.depositDesc,
                                                'depositDesc',
                                                setFieldValue,
                                              );
                                            }
                                      }
                                    />
                                  </FlexRow>
                                </li>
                              ))}
                          </ul>
                        </List>
                      </>
                    ) : null}
                    {isEdit && (
                      <InputGroup style={{ width: 400 }}>
                        <Form.Control
                          type="text"
                          placeholder="Typ wpłaty"
                          value={depositDesc}
                          onChange={(e) => setDepositDesc(e.target.value)}
                        />
                        <Button
                          variant="outline-primary"
                          onClick={() =>
                            addItem(
                              values.depositDesc,
                              'depositDesc',
                              setFieldValue,
                              depositDesc,
                            )
                          }
                        >
                          Dodaj
                        </Button>
                      </InputGroup>
                    )}
                  </div>
                  <FlexRow justify="flex-end">
                    <Button
                      variant="success"
                      type="submit"
                      disabled={!isEdit}
                    >
                      Zatwierdź
                    </Button>
                    <Button
                      variant={isEdit ? 'outline-secondary' : 'secondary'}
                      onClick={() => setIsEdit(!isEdit)}
                    >
                      {isEdit ? 'Zablokuj edycję' : 'Edytuj'}
                    </Button>
                  </FlexRow>
                </Wrapper>
              </Form>
            </>
          );
        }}
      />
    </div>
  );
};

export default CashRegister;
